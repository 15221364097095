import { Component, Input, OnInit } from '@angular/core';
import { ScApiService, ScUploadService } from '@sc-ui';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import industries from '../../../../refs/sc-ui/resources/industries';
import * as _ from 'lodash';
const utils = require('../../../utils/diff');

@Component({
  selector: 'app-company-base',
  templateUrl: './company-base.component.html',
  styleUrls: ['./company-base.component.scss']
})
export class CompanyBaseComponent implements OnInit {

  isSaving = false;

  managers: any = [];

  private _savedCompany: any;

  @Input()
  company: any;

  constructor(private api: ScApiService, private uploadApi: ScUploadService) { }

  ngOnInit() {
    this.api.get("companies/1/people").subscribe((d: any) => {
      this.managers = d;
    });
  }

  uploadLogo(files) {
    this.uploadApi.upload(files, (d: any) => {
      this.company.Profile.LogoUri = d.Uri;
      this.save();
    });
  }

  ngOnChanges() {
    this._savedCompany = _.cloneDeep(this.company);
  }

  save() {
    if (this.isSaving) return;
    this.isSaving = true;

    var diff = utils.diff(this._savedCompany, this.company);
    this.api.put("companies/" + this.company.Id + "/merge", diff).subscribe((d: any) => {
      this.company.Names = d.Names;
      this.company.Domains = d.Domains;
      this._savedCompany = _.cloneDeep(this.company);
      this.isSaving = false;
    });
  }

  saveSoon() {
    console.log('soon');
    if (this["saveTimeout"]) clearTimeout(this["saveTimeout"]);
    this["saveTimeout"] = setTimeout(() => { this.save(); }, 500);
  }

  industryIdToName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry?.name;
  };

  industryIdToIdWithName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry.id + ' - ' + industry.name;
  };

  searchIndustries: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return industries
          .filter(i => i.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort(i => i.name.toLowerCase().indexOf(term.toLowerCase()))
          .slice(0, 10)
          .map(i => i.id);
      }));
}
