<div class="list-group">

  <a *ngFor="let project of projects" routerLink="/projects/{{project.Id}}" class="list-group-item list-group-item-action fw-normal">

    <div class="row align-items-center flex-nowrap">

      <div class="col col-name">
        <div class="project-name-avatar">
          <div class="project-avatar">
            <sc-avatar class="d-inline-block" [user]="project.Manager" size="sm"></sc-avatar>
          </div>
          <div class="project-name">
            <span class="project-name-headline h6 mb-1 oneline">
                {{project.Name}} <!-- <small class="font-weight-normal">m|w|d</small> -->
            </span>

            <span class="card-text text-muted small mb-0 oneline project-subline">
              {{ project.Id }} <span *ngIf="project.Company">- {{project.Company?.Name }}</span>
            </span>
          </div>
        </div>
      </div>

      <div class="col-auto col-status" *ngIf="showStatus">
        <app-status-badge-project [project]="project"></app-status-badge-project>
      </div>

      <div class="col-auto col-due" *ngIf="showDue">
        <span class="badge badge-pill badge-light me-2" [title]="project.NextTask | task" *ngIf="project.NextTask && !project.NextTask?.IsDue">{{ project.NextTask?.Due | date : 'dd.MM.' }}</span>
        <span class="badge badge-pill badge-danger-soft me-2" [title]="project.NextTask | task" *ngIf="project.NextTask?.IsDue">{{ project.daysToDue <= -1.0 ? project.daysToDue + ' Tage' : 'heute' }}</span>
      </div>

      <div class="col-auto col-guarantee" *ngIf="showGuarantee">
        <span class="badge badge-pill badge-light" *ngIf="project.GuaranteeGranted && project.ShortlistedCandidates == 0"><i class="fa-regular fa-lock me-1"></i> 0</span>
        <span class="badge badge-pill badge-success-soft" *ngIf="project.GuaranteeGranted && project.ShortlistedCandidates > 0"><i class="fa-regular fa-lock me-1"></i> {{ project.ShortlistedCandidates }}</span>
      </div>
    </div>
  </a>
  <div class="list-group-item justify-content-center d-flex" *ngIf="showMore">
    <button class="btn btn-secondary btn-round btn-sm" (click)="moreClick.next()" [disabled]="isLoadingMore">Mehr</button>
  </div>
</div>
