import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-review-completed',
  templateUrl: './activity-item-project-review-completed.component.html',
  styleUrls: ['./activity-item-project-review-completed.component.scss']
})
export class ActivityItemProjectReviewCompletedComponent {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;
  
}
