<div class="card" [scAnchorTarget]="'Chats'">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Chats
        </h6>
      </div>
      <div class="col-auto">
        <button (click)="startChatbot()" class="btn btn-sm btn-circle btn-secondary">
          <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <div>
          <div style="height: 50vh; overflow-y: auto;" class="scrollbar-minimal">
            <sc-chatbot-chat-list [chats]="chats" (selectedChange)="select($event)"></sc-chatbot-chat-list>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div *ngIf="!selectedChat" class="d-flex align-items-center h-100 w-100">
          <div class="row w-100 justify-content-center">
            <div class="col-10 col-md-5 col-xl-4 my-5">
              <img class="w-100" src="/assets/svg/sc-paper-plane.svg" />
            </div>
          </div>
        </div>

        <div class="d-flex h-100 w-100" *ngIf="selectedChat">
          <sc-chatbot-chat-header class="d-block d-md-none" [chat]="selectedChat" [showHide]="false" [showBack]="false"></sc-chatbot-chat-header>
          <sc-chatbot-stream [chat]="selectedChat" [isReadOnly]="true"></sc-chatbot-stream>
        </div>

      </div>
    </div>

  </div>

</div>


<app-modal-select-chatbot #selectModal></app-modal-select-chatbot>

<sc-modal #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title">Wirklich löschen?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="deleteModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>Willst du den Chatbot wirklich unwiderruflich löschen? Bitte gib in das Textfeld <i>löschen</i>
          (klein
          geschrieben) ein um fortzufahren.</p>
      </div>
      <div class="col-12">
        <input class="form-control" #deleteConfirmation />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">Abbrechen</button>
    <button class="btn btn-danger" [disabled]="deleteConfirmation.value != 'löschen'" (click)="deleteModal.complete();">Unwiderruflich
      löschen</button>
  </div>
</sc-modal>

<sc-modal #remindModal size="lg">
  <div class="modal-header">
    <h4 class="modal-title">Chat-Reminder ausspielen</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="remindModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-3">
        <label>Titel</label>
      </div>
      <div class="col-9">
        <input class="form-control form-control-sm" [(ngModel)]="reminder.Subject" placeholder="Ohne Eingabe wird der Notification-Titel des Chatbots verwendet" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <label>Nachricht</label>
      </div>
      <div class="col-9">
        <textarea rows="6" class="form-control form-control-sm" [(ngModel)]="reminder.Content" placeholder="Ohne Eingabe wird die Notification-Nachricht des Chatbots verwendet"></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <label>Push</label>
      </div>
      <div class="col-9">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="SendPushNotification" name="SendPushNotification" [(ngModel)]="reminder.SendPushNotification" />
          <label class="form-check-label" for="SendPushNotification"></label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <label>E-Mail</label>
      </div>
      <div class="col-9">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="SendEMailNotification" name="SendEMailNotification" [(ngModel)]="reminder.SendEMailNotification" />
          <label class="form-check-label" for="SendEMailNotification"></label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="remindChat()"><i class="fa-regular fa-bell me-2"></i> Reminder senden</button>
  </div>
</sc-modal>
