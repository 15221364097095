<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div *ngIf="project" class="container">

    <div class="row">

      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Review
            </h6>
          </div>
          <div class="card-body">

            <sc-form (change)="saveSoon()">

              <sc-form-row label="Review-Termin">
                <sc-date-picker-inline [timeSelectable]="true" [(date)]="project.ReviewDate" (dateChange)="saveSoon()"></sc-date-picker-inline>
              </sc-form-row>


              <sc-form-row label="Buchungslink">
                <div class="form-control" style="overflow: hidden;">
                  <a class="oneline" [href]="project.ReviewBookingLink" target="_blank">{{ project.ReviewBookingLink }}</a>
                </div>                
              </sc-form-row>

              <sc-form-row label="Protokoll">
                <autosize-textarea [(text)]="project.Profile.Result.CustomerComment"></autosize-textarea>
              </sc-form-row>
            </sc-form>


            <!-- <div class="row mb-2">
              <div class="col-3 oneline">
                NPS
              </div>
              <div class="col-9">
                <select class="custom-select" [(ngModel)]="project.NetPromoterScore" (ngModelChange)="saveSoon()">
                  <option [ngValue]="i" *ngFor="let i of [10,9,8,7,6,5,4,3,2,1,0]">
                    <span *ngIf="i > 0">{{ i }}</span>
                    <span *ngIf="i == 0">Nicht erfasst</span>
                  </option>
                </select>
              </div>
            </div> -->

          </div>
        </div>

      </div>
    </div>
  </div>

</div>
