<ng-template [appTopbarTemplate]>
  <div class="title">
    <h4 class="mb-0">{{ candidate?.FirstName }} {{ candidate?.LastName }}</h4>
  </div>
  <div class="nav">
    <ul class="nav nav-tabs nav-overflow">
      <li class="nav-item"><a class="nav-link" routerLink="/candidates/{{ candidate?.Guid }}/base" routerLinkActive="active">Stammdaten</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/candidates/{{ candidate?.Guid }}/sourcing" routerLinkActive="active">Ansprachen</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/candidates/{{ candidate?.Guid }}/account" routerLinkActive="active">Kundenportal</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/candidates/{{ candidate?.Guid }}/sales" routerLinkActive="active">Sales</a></li>
    </ul>
  </div>
  <div class="actions">
    <div class="row align-items-center">
      <div class="col">
        <ng-content></ng-content>
      </div>
      <div class="col-auto">
        <div ngbDropdownToggle>
          <button class="btn btn-secondary btn-sm btn-circle">
            <i class="fa-regular fa-ellipsis"></i>
          </button>
        </div>
        <div ngbDropdownMenu>
          <a class="dropdown-item" (click)="deleteModal.show();"><i class="fa-regular fa-trash me-2"></i> Löschen</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="d-inline-block me-2">

  </div>
  <div class="d-inline-block" ngbDropdown>

  </div> -->
</ng-template>

<sc-modal #deleteModal>
  <sc-modal-header>
    Wirklich löschen?
  </sc-modal-header>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>
          Willst du das Profil wirklich unwiderruflich löschen?
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">Abbrechen</button>
    <button class="btn btn-dark" (click)="delete(); deleteModal.dismiss();">Unwiderruflich löschen</button>
  </div>
</sc-modal>
