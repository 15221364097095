<ng-template [appTopbarTemplate]>
	<div class="title h4">
	  Training-Batches
	</div>
  </ng-template>
  
  <div class="main-content">
	<div class="container">
	  <div class="row">
		<div class="col-12">
		  <div class="card">
			<div class="card-body card-body-list">
			  <ul class="list-group">
				<a class="list-group-item list-group-item-action" *ngFor="let batch of batches">
				  <div class="row align-items-center">
					<a class="col"  href="/projects/{{ batch.ProjectId }}/sourcing/screening?filter=%7B%22ProjectId%22:%22{{ batch.ProjectId }}%22,%22BatchId%22:%22{{batch.BatchId}}%22%7D" target="_blank" >
						<h6 class="mb-1 oneline">{{ batch.Project.Name }}</h6>
						<span class="card-text text-muted small mb-0 oneline project-subline">
							{{ batch.ProjectId }} | Batch {{ batch.BatchId }}
						</span>
					</a>
					<div class="col-auto">
						<button class="btn btn-sm btn-secondary btn-round" *ngIf="!batch.IsVerified" (click)="batch.IsVerified = true; saveBatch(batch)">Verifizieren</button>
						<button class="btn btn-sm btn-dark btn-round" *ngIf="batch.IsVerified" (click)="batch.IsVerified = false; saveBatch(batch)"><i class="fa-sharp fa-solid fa-check me-2"></i> Verifiziert</button>
					</div>
				  </div>
				</a>
			  </ul>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>