import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import * as _ from 'lodash';
import disciplines from 'refs/sc-ui/resources/disciplines';
import { ProjectService } from 'src/services/project.service';

declare var moment;

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {


  @Input()
  project;

  company: any;

  id: any;

  managers: any = [];

  users : any = [];

  billingCompany: any;

  billingAddresses: any = [];

  billingCompanyContacts: any = [];

  deleteConfirmation = '';

  disciplines = disciplines;

  postponeMessage = "";


  constructor(
    private api: ScApiService,
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService) {
  }

  ngOnInit(): void {

    this.api.get("companies/1/contacts").subscribe((d: any) => {
      this.managers = d;
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.get('jobs/' + this.id + '/users').subscribe(d => {
        this.users = d;
      });

      var cached = this.projectService.getFromCache(this.id);
      if (cached) {
        this.project = cached;
        this.loadDueDate();
        this.loadCompany();
        this.loadBillingCompany();
      }

      this.projectService.load(this.id).subscribe(p => {
        this.project = p;
        this.loadDueDate();
        this.loadCompany();
        this.loadBillingCompany();
      });
    });
  }

  loadDueDate() {
    if (!this.project.NextDueDate) return;
    var now = new moment();
    var valueMoment = moment(this.project.NextDueDate);
    var diff = valueMoment.diff(now, 'minutes');
    this.project.minutesToDue = diff;
  }

  postpone(days: number) {
    this.api.post(`projects/${this.id}/postpone`, { Days: days, Message: this.postponeMessage }).subscribe((d: any) => {
      this.project.NextDueDate = d.NewDueDate;
      this.loadDueDate();
    });
  }

  loadCompany() {
    if (this.company != null && this.company.Id == this.project?.CompanyId) return;
    this.api.get('companies/' + this.project.CompanyId).subscribe(c => this.company = c);

  }

  loadBillingCompany() {
    this.api.get('companies/' + this.project.BillingCompanyId).subscribe(c => this.billingCompany = c);
    this.api.get('companies/' + this.project.BillingCompanyId + '/contacts').subscribe(d => this.billingCompanyContacts = d);
    this.api.get('companies/' + this.project.BillingCompanyId + '/billing-addresses').subscribe(c => {
      this.billingAddresses = c;
      this.billingAddresses.forEach(a => a.onelineAddress = a.Address.replace(new RegExp('\n', 'g'), ', '));
    });
  }

  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  save() {
    this.projectService.save(this.project);
  }

  delete() {
    this.api.delete("jobs/" + this.project.Id).subscribe(d => {
      this.router.navigateByUrl('/dashboard');
    })
  }
}
