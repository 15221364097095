<ng-template [appTopbarTemplate]>

  <div class="title h4">
    {{ pool?.Name }}
  </div>

  <div class="actions">
    <div ngbDropdown container="body">
      <button class="btn btn-secondary btn-circle btn-sm" ngbDropdownToggle><i class="fa-regular fa-ellipsis"></i></button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem (click)="notifyNewCandidatesModal.show()"><i class="fa fa-bell me-2"></i> Neue Kandidat:innen</button>
        <button ngbDropdownItem (click)="broadcastChatbot()"><i class="fa fa-bell me-2"></i> Chatbot ausspielen</button>
      </div>
    </div>
  </div>

  <div class="nav">
    <app-nav-tabs></app-nav-tabs>
  </div>
</ng-template>

<div class="scroll-dummy" *ngIf="!pool"></div>

<div class="content" [@fadeIn]="state">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card box-shadow-lg">
          <div class="card-body">
            <app-pool-base class="d-block mb-5" [pool]="pool"></app-pool-base>            
            <app-pool-candidates class="d-block mb-5" [pool]="pool"></app-pool-candidates>
          </div>

          <!-- <app-pool-groups [pool]="pool"></app-pool-groups> -->
        </div>
      </div>
      <div class="col-12 mt-5">
        <div class="card card-xl shadow-lg pool-content">
          <div class="card-body">
            <app-pool-activities [pool]="pool"></app-pool-activities>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-select-chatbot #selectModal></app-modal-select-chatbot>

<app-modal-action #notifyNewCandidatesModal (action)="notifyNewCandidates($event)">
  <div class="body">
    Möchtest du eine Benachrichtigung über neue Kandidat:innen an die Pool-Teilnehmer senden?
  </div>
</app-modal-action>

<sc-modal #chatbotModal size="lg">
  <div class="modal-header">
    <h6 class="modal-title">Chatbot wird ausgespielt</h6>    
  </div>
  <div class="modal-body">
    <p>Bitte warten...</p>
  </div>
</sc-modal>
