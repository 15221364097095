import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-project-process-action-modal',
  templateUrl: './project-process-action-modal.component.html',
  styleUrls: ['./project-process-action-modal.component.scss']
})
export class ProjectProcessActionModalComponent implements OnInit {

  @ViewChild(ModalWizardComponent)
  modal : ModalWizardComponent;

  @ViewChild('start')
  startPage : ModalWizardPageComponent;

  @ViewChild('confirmation')
  confirmationPage : ModalWizardPageComponent;

  @ViewChild('error')
  errorPage : ModalWizardPageComponent;

  @Input()
  project;

  @Input()
  title;

  @Input()
  showMailOption = false;

  @Input()
  actionName;

  @Input()
  confirmBtnClass = "btn-dark";

  sendMail = true;

  isExecuting = false;

  errorMessage = '';

  constructor(private api : ScApiService) { }

  ngOnInit(): void {
  }

  execute()
  {
    if (this.isExecuting) return;
    this.isExecuting = true;

    var uri = `projects/${this.project.Id}/actions/${this.actionName}`;
    if (this.showMailOption) uri += `?sendMail=${this.sendMail}`;

    this.api.post(uri, null).subscribe((d : any) => {
      
      if (d?.Status) this.project.Status = d.Status;
      if (d?.StatusIndex) this.project.StatusIndex = d.StatusIndex;
      if (d?.IsCancelled) this.project.IsCancelled = d.IsCancelled;      
      this.project.minutesToDue = 1000;
      
      this.modal.navigateTo(this.confirmationPage);
    }, (e : any) => {
      this.errorMessage = e.error.Message;
      this.modal.navigateTo(this.errorPage)
    }, () => {
      this.isExecuting = false;
    });
  }

  show()
  {
    this.errorMessage = '';
    this.isExecuting = false;    
    this.modal.show(this.startPage).subscribe();
  }

}
