<ng-template [appTopbarTemplate]>
  <div class="title h4" *ngIf="cadence">
    {{ cadence.Name }}
  </div>
  <div class="nav">
    <ul class="nav nav-tabs nav-overflow">
      <li class="nav-item">
        <a class="nav-link" routerLink="/cadences/{{cadence?.Id}}">
          Übersicht
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/cadences/{{cadence?.Id}}/people">
          Personen
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<div class="main-content" *ngIf="cadence">

  <div class="container">
    <div class="row">
      <div class="col-6 col-lg-4 mb-4">
        <input class="form-control search" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
      </div>
      <div class="col-12">
        <div class="card">


              <div class="card-body card-body-list">
                <div class="list-group">

                  <a *ngFor="let person of people" (click)="modal.show(person.Assignment)" class="list-group-item list-group-item-action">

					<div class="row align-items-center flex-nowrap">
						<div class="col-auto">
						  <sc-avatar class="d-inline-block" [user]="person" size="sm"></sc-avatar>
						</div>
	  
						<div class="col">
						  <span>
							<h6 class="mb-1 oneline d-inline">
							  {{person.FirstName}} {{ person.LastName}}
							</h6>
						  </span>
	  
						  <p class="card-text text-muted small mb-0 oneline">
							{{ person.JobTitle }}
						  </p>
						</div>
	  
						<div class="col-auto">
						  <app-status-badge-cadence-person-assignment [assignment]="person.Assignment"></app-status-badge-cadence-person-assignment>
						</div>
					  </div>

                  </a>

                </div>

                <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
                  <div class="col-auto">
                    <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
                  </div>
                </div>
              </div>

        </div>
      </div>
    </div>
  </div>
</div>


<app-modal-cadence-person-assignment [showGoToProfile]="true" #modal>
</app-modal-cadence-person-assignment>
