<app-topbar-candidate [candidate]="candidate"></app-topbar-candidate>

<div class="main-content">

  <div class="container" *ngIf="candidate">

    <div class="row">

      <div class="col-8 candidate-content">
        <app-candidate-base [candidateActivities]="activities" class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-base>
        <app-candidate-portal-account [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-portal-account>
        <app-candidate-projects class="mb-3" [(candidate)]="candidate"></app-candidate-projects>
        <app-candidate-pools class="mb-3" [(candidate)]="candidate"></app-candidate-pools>
        <app-candidate-skills class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-skills>
        <app-candidate-cv class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-cv>
        <app-candidate-chats class="mb-5" [candidateId]="candidate.Guid"></app-candidate-chats>
        
        <!-- <app-candidate-structured-data class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-structured-data>
        <app-candidate-search-criteria class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-search-criteria> -->
      </div>

      <div class="col-4 candidate-content">
        <app-candidate-organizations [(organizationId)]="candidate.OrganizationId" (organizationIdChange)="saveSoon()"></app-candidate-organizations>
        <app-candidate-tasks class="mb-3" [(candidate)]="candidate"></app-candidate-tasks>
        <app-candidate-cadences class="mb-3" [(candidate)]="candidate"></app-candidate-cadences>     
        <app-candidate-activities #activities [candidate]="candidate"></app-candidate-activities>   
      </div>

    </div>
  </div>
</div>

<sc-modal #deleteModal>
  <sc-modal-header>
    Wirklich löschen?
  </sc-modal-header>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>
          Willst du das Profil wirklich unwiderruflich löschen? 
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">Abbrechen</button>
    <button class="btn btn-dark" (click)="delete(); deleteModal.dismiss();">Unwiderruflich löschen</button>
  </div>
</sc-modal>

<sc-modal #errorModal>
  <sc-modal-header>
    Fehler
  </sc-modal-header>
  <sc-modal-body>
    <p>Kann nicht gespeichert werden.</p>
    <p>{{ errorMessage }}</p>
  </sc-modal-body>
</sc-modal>