<sc-modal-wizard #modal>

  <sc-modal-wizard-page #start>
    <sc-modal-header>
      Projektzuordnung bearbeiten
    </sc-modal-header>
    <sc-modal-body style="height: 50vh;">
      <div class="list-group">
        <div class="list-group-item list-group-item-action" [class.not-intended]="!status.IsIntended" (click)="selectedStatus=status; modal.navigateTo(confirm);" [class.selected-status]="status.Name == assignment?.Status" *ngFor="let status of intendedStati">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="mb-1">{{ status.DisplayName }}</h6>
              <p class="card-text small text-muted mb-0">
                {{ status.Description }}
              </p>
            </div>
            <div class="col-auto">
              <i class="fa-regular fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <div class="list-group-item list-group-item-action" (click)="modal.navigateTo(allStati);">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="mb-1">Anderer Status</h6>
              <p class="card-text small text-muted mb-0">
                Anderen Status auswählen
              </p>
            </div>
            <div class="col-auto">
              <i class="fa-regular fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
    </sc-modal-body>
    <sc-modal-footer>
      <a class="btn btn-secondary" href="https://app.skillconomy.com/projects/{{assignment?.ProjectId}}/candidates/{{assignment?.CandidateId}}" target="_blank">Im Kundenportal öffnen</a>
      <a class="btn btn-secondary" href="/projects/{{assignment?.ProjectId}}" target="_blank">Zum Projekt</a>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #allStati>
    <sc-modal-header>
      Projektzuordnung bearbeiten
    </sc-modal-header>
    <sc-modal-body>
      <div class="alert alert-warning mb-3">
        Ein Wechsel in einen der nachfolgenden Stati ist nicht vorgesehen.
      </div>
      <div class="list-group">
        <div class="list-group-item list-group-item-action" (click)="selectedStatus=status; modal.navigateTo(confirm);" [class.selected-status]="status.Name == assignment?.Status" *ngFor="let status of unintendedStati">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="mb-1">{{ status.DisplayName }}</h6>
              <p class="card-text small text-muted mb-0">
                {{ status.Description }}
              </p>
            </div>
            <div class="col-auto">
              <i class="fa-regular fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary" (click)="modal.navigateTo(start)">Zurück</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #confirm>
    <sc-modal-header>
      Fortfahren?
    </sc-modal-header>
    <sc-modal-body>
      <p>
        {{ selectedStatus?.ConfirmationMessage }}
      </p>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary" (click)="modal.navigateTo(start)">Zurück</button>
      <button class="btn btn-dark" (click)="setStatus(selectedStatus.Name); modal.dismiss();">
        Fortfahren
      </button>
    </sc-modal-footer>
  </sc-modal-wizard-page>
</sc-modal-wizard>
