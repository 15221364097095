import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss']
})
export class ListProjectsComponent {

  @Input()
  projects : any = [];

  @Input()
  showStatus = true;

  @Input()
  showDue = true;

  @Input()
  showGuarantee = true;

  @Input()
  showMore = false;

  @Input()
  isLoadingMore = false;

  @Output()
  moreClick = new EventEmitter<any>();

}
