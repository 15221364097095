<ng-template [appTopbarTemplate]>
  <div class="title h4">Kunden</div>

  <div class="nav">
    <ul class="nav nav-tabs nav-overflow">

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Closed'" (click)="salesStatus = 'Closed'; load();">
          Aktiv&nbsp;<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Closed">
            {{ stats.Closed }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Inactive'" (click)="salesStatus = 'Inactive'; load();">
          Inaktiv<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Inactive">
            {{ stats.Inactive }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Lost'" (click)="salesStatus = 'Lost'; load();">
          Verloren<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Lost">
            {{ stats.Lost }}
          </span>
        </a>
      </li>
    </ul>

  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-xl">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-2">
                <input type="search" [(ngModel)]="companyName" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Unternehmen">
              </div>
              <div class="col-2">
                <input type="search" [(ngModel)]="tags" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Tags">
              </div>
              <div class="col">
              </div>
              <div class="col-auto clickable" (click)="selectManager();">
                <div class="row align-items-center">
                  <div class="col-auto pe-0">
                    <sc-avatar *ngIf="selectedManager" [user]="selectedManager"></sc-avatar>
                    <div *ngIf="!selectedManager" class="avatar avatar-sm">
                      <span class="avatar-icon">
                        <i class="fa-regular fa-users"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-auto">
                    <span>
                      <i class="fa-regular fa-chevron-down"></i>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- <div class="card-body" *ngIf="isLoading">
			  <div class="row justify-content-center">
				<div class="col-auto">
				  <div class="spinner-border text-primary" role="status">
				  </div>
				</div>
			  </div>
			</div> -->

          <div class="card-body card-body-list">

            <div class="list-group fw-normal">

              <div class="list-group-item justify-content-center d-flex" *ngIf="isLoading">
                <span class="muted">Bitte warte...</span>
              </div>

              <a class="list-group-item list-group-item-action" [routerLink]="'/companies/' + account.Id + '/base'" [queryParams]="{view: 'people'}" [class.on-hold]="account.IsOnHold" *ngFor="let account of accounts">

                <!-- <div class="row flex-nowrap">
					<div class="col-auto" *ngIf="ownerId==0">
					  <sc-avatar class="d-inline-block" [user]="account.Leads[0].SalesOwner" size="sm"></sc-avatar>
					</div>
  
					<div class="col">
  
					  <h6 class="mb-2">
						{{ account.Name }}
					  </h6>
  
					  <div class="row mb-1 align-items-center flex-nowrap" *ngFor="let person of account.Leads" [routerLink]="'/candidates/' + person.Guid + '/sales'" [queryParams]="{ownerId: ownerId}">
  
						<div class="col-auto">
						  <sc-avatar class="d-inline-block" [user]="person" size="xs"></sc-avatar>
						</div>
  
						<div class="col px-0">
						  <span>
							<span class="mb-1 oneline d-inline">
							  {{person.FirstName}} {{ person.LastName}}
							</span>
						  </span>
						  <p class="card-text text-muted small mb-0 oneline">
							{{ person.JobTitle }}
						  </p>
						</div>
						<div class="col-auto" *ngIf="person.IsOnHold">
						  <span class="badge badge-pill badge-light">
							<i class="fa-solid fa-volume-slash me-2"></i> {{ person.SalesOnHoldUntil | date : 'dd.MM.yy' }}
						  </span>
						</div>
  
					  </div>
  
					</div>
  
					<div class="col-auto" *ngIf="account.IsOnHold">
					  <span class="badge badge-pill badge-light">
						<i class="fa-solid fa-volume-slash me-2"></i> {{ account.OnHoldUntil | date : 'dd.MM.yy' }}
					  </span>
					</div>
				  </div> -->

                <div class="row">

                  <div class="col-auto">
                    <sc-avatar class="d-inline-block" [user]="account.Manager" size="sm"></sc-avatar>
                  </div>

                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        {{ account.Name }}
                      </h6>
                    </span>

                    <p class="card-text text-muted small mb-0 oneline">
                      <span *ngIf="account.Leads?.length">{{account.Leads[0].FirstName}} {{ account.Leads[0].LastName}} <span class="small" *ngIf="account.Leads.length > 1">+ {{ account.Leads.length - 1 }} weitere</span></span>
                      <span *ngIf="!account.Leads?.length">keine Personen</span>
                    </p>
                  </div>

                  <div class="col-auto">
                    <sc-avatar-list size="xs" [users]="account.Leads"></sc-avatar-list>
                    <!-- <sc-avatar class="d-inline-block" [user]="account.Leads[0]" size="sm"></sc-avatar> -->
                  </div>

                  <div class="col-auto" *ngIf="account.IsOnHold">
                    <span class="badge badge-pill badge-light">
                      <i class="fa-sharp fa-light fa-calendar me-1"></i> {{ account.OnHoldUntil | date : 'dd.MM.yy' }}
                    </span>
                  </div>

                </div>

              </a>

              <div class="list-group-item justify-content-center d-flex" *ngIf="hasMoreItems">
                <button class="btn btn-secondary btn-round btn-sm" (click)="loadMore()" [class.loading]="isLoading">Mehr</button>
              </div>

            </div>

            <!-- <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
				<div class="col-auto">
				  <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
				</div>
			  </div> -->

          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<app-modal-select-manager #userModal (selected)="userModal.complete($event);"></app-modal-select-manager>
