<div *ngIf="sidebarService.profile">
  <div class="row align-items-start">
    <div class="col-12">
      <span class="mb-0 h1 oneline">Suche in XTM</span>
    </div>
    
    <div class="col-12 mt-5">
      <ul class="list-unstyled">
        <li>
          <app-sidebar-candidate-exact-item icon="fa-solid fa-user-plus" (click)="importSearch()">
            Ergebnisse über Assistenten importieren
          </app-sidebar-candidate-exact-item>
        </li>
      </ul>
    </div>

	<div class="col-12 mt-5">
		<div class="bg-light p-2" style="overflow-wrap: anywhere; font-family: 'Courier New', Courier, monospace;">
		  {{ uri }}
		</div>
	  </div>
  </div>

</div>
