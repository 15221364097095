<div class="row">
  
  <div class="col-12 mb-3">
    <h6 class="oneline mb-0">{{mail?.Payload?.Subject}}</h6>
    {{mail?.Payload?.Timestamp | date: 'dd.MM.yyy hh:mm'}} Uhr<br>
    {{mail?.Payload?.SenderEMail}}
  </div>
  <div class="col-12 mb-3">
    <div class="mail-container scrollbar-minimal border" [innerHTML]="mail?.Payload?.BodyHtml"></div>
  </div>
  <div class="col-12 mb-3" *ngIf="phoneNumbers?.length">
    <div class="mb-n2">
      <div *ngFor="let phoneNumber of phoneNumbers">
        <button *ngIf="phoneNumber.Kind != 'MobilePhoneNumber'" (click)="savePhoneNumber(phoneNumber)" class="btn btn-secondary btn-block mb-2">Festnetz: {{ phoneNumber.FormattedNumber }}</button>
        <button *ngIf="phoneNumber.Kind == 'MobilePhoneNumber'" (click)="savePhoneNumber(phoneNumber)" class="btn btn-secondary btn-block mb-2">Mobil: {{ phoneNumber.FormattedNumber }}</button>
      </div>
    </div>
  </div>
</div>
