import { Component, ViewChild } from '@angular/core';
import { ScuiModalComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-modal-select-cadence',
  templateUrl: './modal-select-cadence.component.html',
  styleUrls: ['./modal-select-cadence.component.scss']
})
export class ModalSelectCadenceComponent {
  
  q: any = '';

  cadences: any = [];

  @ViewChild(ScuiModalComponent)
  modal: ScuiModalComponent;

  constructor(private api: ScApiService) { }

  ngOnInit() {
    this.search();
  }

  searchSoon() {
    if (this["searchTimeout"]) clearTimeout(this["searchTimeout"]);
    this["searchTimeout"] = setTimeout(() => {
      this.search();
    }, 500);
  }

  search() {
    this.api.get('cadences?take=10&q=' + encodeURIComponent(this.q)).subscribe((d: any) => {
      this.cadences = d;
    });
  }

  show() {
    return this.modal.show()
  }
}
