import { Component, Output, Input, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { ModalSelectPersonComponent, ModalSelectPersonOptions } from '../modal-select-person/modal-select-person.component';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-inline-select-person',
  templateUrl: './inline-select-person.component.html',
  styleUrls: ['./inline-select-person.component.scss']
})
export class SelectorPersonComponent implements OnChanges {

  @Input()
  organizationId = 0;

  @Input()
  allowCreate = false;

  @Input()
  allowSearch = false;

  @Input()
  personId : any;

  @Output()
  personIdChange = new EventEmitter<any>();

  @ViewChild(ModalSelectPersonComponent)
  modal : ModalSelectPersonComponent;

  person : any;

  constructor(private api : ScApiService)
  {}

  ngOnChanges()
  {
    if(!this.personId)
    {
      this.person = null;
      return;
    }
    if (this.personId != this.person?.Id)
    {
      this.api.get('people/' + this.personId).subscribe(d => {
        this.person = d;
      })
    }
  }

  selectPerson()
  {
    var options = new ModalSelectPersonOptions();

    options.organizationId = this.organizationId;
    options.allowCreate = this.allowCreate;
    options.allowSearch = this.allowSearch;
    
    this.modal.show(null, options).subscribe((d : any) => {
      this.person = d;
      this.personId = d.Id;
      this.personIdChange.next(this.personId);
    });
  }
}
