import { Component, OnInit, NgZone, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-location-list-editor',
  templateUrl: './location-list-editor.component.html',
  styleUrls: ['./location-list-editor.component.scss']
})
export class LocationListEditorComponent implements OnInit {

  @Input()
  locations : any = [];

  @Output()
  locationsChange = new EventEmitter<any>();
  
  @ViewChild("search")
  searchElementRef: ElementRef<HTMLInputElement>;

  constructor(private mapsApiLoader : MapsAPILoader, private ngZone : NgZone) { }

  ngOnInit() {
    this.loadGoogleMap();
  }

  loadGoogleMap() {
    //load Places Autocomplete
    this.mapsApiLoader.load().then(() => {
      var autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {

          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          var city = '';
          var street = '';
          var zip = '';
          var number = '';
          var country = '';

          place.address_components.forEach(c => {
            if (c.types.indexOf('locality') >= 0)  city = c.long_name;
            if (c.types.indexOf('route') >= 0)  street = c.long_name;
            if (c.types.indexOf('postal_code') >= 0)  zip = c.long_name;
            if (c.types.indexOf('street_number') >= 0)  number = c.long_name;
            if (c.types.indexOf('country') >= 0)  country = c.long_name;
          })

          var location = {
            Latitude : place.geometry.location.lat(),
            Longitude : place.geometry.location.lng(),
            Zip: zip,
            City: city,
            Street: street,
            Number: number,
            Country: country,
            Radius: 0
          };

          if (!this.locations) this.locations = [];
          this.locations.push(location);     
          this.locationsChange.emit(this.locations);

          this.searchElementRef.nativeElement.value = '';
        });
      });
    });
  }

  removeLocation(location)
  {
    var i = this.locations.indexOf(location);
    this.locations.splice(i, 1);
    this.locationsChange.emit(this.locations);
  }
}
