<span *ngIf="settings.q" class="d-inline-block badge badge-sm rounded-pill badge-light me-2 mb-2">
	<i class="fa-regular fa-filter"></i> Enthält: {{ settings.q }} <i class="fa-regular fa-xmark me-3 clickable" (click)="settings.q =''; onChange();"></i>
  </span>
  <span *ngIf="settings.userProjectsOnly" class="d-inline-block badge badge-sm rounded-pill badge-light me-2 mb-2">
	<i class="fa-regular fa-filter"></i> Nur eigene <i class="fa-regular fa-xmark me-3 clickable" (click)="settings.userProjectsOnly = false; onChange();"></i>
  </span>
  <span *ngIf="settings.status" class="d-inline-block badge badge-sm rounded-pill badge-light me-2 mb-2">
	<i class="fa-regular fa-filter"></i> Nur Status: {{ settings.status | projectStatus }} <i class="fa-regular fa-xmark me-3 clickable" (click)="settings.status =''; onChange();"></i>
  </span>
  <span *ngIf="!settings.includeCancelled" class="d-inline-block badge badge-sm rounded-pill badge-light me-2 mb-2">
	<i class="fa-regular fa-filter"></i> Nicht abgebrochen/archiviert <i class="fa-regular fa-xmark me-3 clickable" (click)="settings.includeCancelled =true; onChange();"></i>
  </span>
  <span *ngIf="settings.dueOnly" class="d-inline-block badge badge-sm rounded-pill badge-light me-2 mb-2">
	<i class="fa-regular fa-filter"></i> Nur überfällig <i class="fa-regular fa-xmark me-3 clickable" (click)="settings.dueOnly = false; onChange();"></i>
  </span>