<ng-template [appTopbarTemplate]>
  <div class="title h4">
    {{ chatbot?.Name }}
  </div>

  <div class="nav">

    <ul class="nav nav-tabs nav-overflow">

      <li class="nav-item">
        <a class="nav-link active" routerLink="/chatbots/{{chatbot?.Id}}" >
          Inhalt
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/chatbots/{{chatbot?.Id}}/history">
          Historie
        </a>
      </li>
    </ul>
  </div>

  <div class="actions">
    <a class="btn btn-rounded btn-secondary me-2" href="/chatjourneyprint/{{chatbot?.Id}}" target="_blank">Drucken</a>
    <button class="btn btn-rounded btn-secondary me-2" (click)="copy()">Kopieren</button>
    <button class="btn btn-rounded btn-secondary" (click)="deleteModal.show()">Löschen</button>

  </div>
</ng-template>

<div class="main-content" *ngIf="chatbot">

  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              Stammdaten
            </h4>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-3">
                Bezeichnung
              </div>
              <div class="col-9">
                <input name="title" [(ngModel)]="chatbot.Name" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Bezeichnung">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Funktionsname
              </div>
              <div class="col-9">
                <input name="title" [(ngModel)]="chatbot.FunctionalName" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Funktionsname">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                ID
              </div>
              <div class="col-9">
                <input class="form-control form-control-sm" readonly name="chatbot.Id" [(ngModel)]="chatbot.Id" placeholder="chatbot.Id" />
              </div>
            </div>
            <div class="row mb-2 form-row-sm">
              <div class="col-3">
                Notification Betreff
              </div>
              <div class="col-9">
                <input name="NotificationTitle" [(ngModel)]="chatbot.NotificationTitle" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Betreff">
              </div>
            </div>
            <div class="row mb-2 form-row-sm">
              <div class="col-3">
                Notification Nachricht
              </div>
              <div class="col-9">
                <input name="NotificationMessage" [(ngModel)]="chatbot.NotificationMessage" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Nachricht">
              </div>
            </div>

            <div class="row mb-2 form-row-sm">
              <div class="col-3">
                Report per Mail
              </div>
              <div class="col-9">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="chatbot.SendReport" (ngModelChange)="saveSoon()">
                </div>
              </div>
            </div>
            <div class="row mb-2 form-row-sm" *ngIf="chatbot.SendReport">
              <div class="col-3">
                Report-E-Mails
              </div>
              <div class="col-9">
                <input name="ReportRecipients" [(ngModel)]="chatbot.ReportRecipients" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="E-Mail-Adressen mit Semikolon getrennt">
              </div>
            </div>
            <div class="row form-row-sm">
              <div class="col-3">
                Bild
              </div>
              <div class="col-9">

                <sc-file-button (filesChanged)="uploadImage($event)">
                  <a class="avatar" *ngIf="chatbot.Image">
                    <img [src]="chatbot.Image" alt="Chat Image" class="avatar-img rounded">
                  </a>

                  <a class="avatar" *ngIf="!chatbot.Image">
                    <img src="/assets/img/illustrations/bot-illu.png" alt="Chat Image" class="avatar-img rounded">
                  </a>
                </sc-file-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">

        <div class="card">
          <div class="card-header">

            <h4 class="card-header-title">
              Schritte
              <button class="btn btn-sm btn-circle btn-white ms-3" (click)="createStep()">+</button>
            </h4>

          </div>

          <div class="card-body" cdkDropList [cdkDropListData]="content.Steps" (cdkDropListDropped)="onDrop($event)">
            <div class="row mb-2" *ngFor="let step of content.Steps" cdkDrag>

              <div class="col">
                <input class="form-control form-control-sm" name="step.Body" [(ngModel)]="step.Body" placeholder="step.Body" (ngModelChange)="saveSoon()" />
              </div>
              <div class="col-auto">
                <select class="custom-select custom-select-sm" [(ngModel)]="step.Type" (ngModelChange)="saveSoon()">
                  <option value="Empty">Keine</option>
                  <option value="Text">Freitext</option>
                  <option value="Choice">Auswahl</option>
                  <option value="YesNo">Ja/Nein</option>
                  <option value="FileUpload">Datei-Upload</option>
                  <option value="Location">Ort / Radius</option>
                  <option value="Relay">Weiterleitung</option>
                  <option value="End">Ende des Chats</option>
                </select>
              </div>

              <div class="col-auto">

                <button class="btn btn-sm btn-circle me-2" [class.btn-primary]="step.OnSendingScript" [class.btn-secondary]="!step.OnSendingScript" (click)="selectedStep = step; scriptOnSendingModal.show();"><i class="fa-regular fa-code"></i></button>

                <button class="btn btn-sm btn-circle me-2" [class.btn-primary]="step.OnReplyScript" [class.btn-secondary]="!step.OnReplyScript" (click)="selectedStep = step; scriptOnReplyModal.show();"><i class="fa-regular fa-code"></i></button>

                <button class="btn btn-sm btn-circle btn-secondary" (click)="selectedStep = step; stepModal.show();"><i class="fa-regular fa-pen-to-square"></i></button>
              </div>
              <div class="col-auto">
                <i *ngIf="!step.validation?.HasErrors && !step.validation?.HasWarnings" class="fa-regular fa-check text-success"></i>
                <i *ngIf="!step.validation?.HasErrors && step.validation?.HasWarnings" class="fa-regular fa-triangle-exclamation text-warning"></i>
                <i *ngIf="step.validation?.HasErrors" class="fa-regular fa-triangle-exclamation text-danger"></i>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <button class="btn btn-sm btn-circle btn-white" (click)="createStep()">+</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #stepModal size="xl">
  <sc-modal-header>
    Schritt bearbeiten <small class="text-muted ms-4">{{selectedStep?.Id}}</small>
  </sc-modal-header>

  <div class="modal-body" *ngIf="selectedStep && stepModal.isOpen">
    <div class="w-100">
      <div class="row mb-2" *ngIf="selectedStep.validation?.HasErrors || selectedStep.validation?.HasWarnings">
        <div class="col-12" *ngFor="let message of selectedStep.validation.Messages">

          <div class="alert alert-warning mb-2" *ngIf="message.Type == 1">
            Warnung: {{ message.Message }}
          </div>

          <div class="alert alert-danger mb-2" *ngIf="message.Type == 2">
            Fehler: {{ message.Message }}
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Fragentyp
        </div>
        <div class="col-9 mb-2">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.Type" (ngModelChange)="saveSoon()">
            <option value="Empty">Keine</option>
            <option value="Text">Freitext</option>
            <option value="Choice">Auswahl</option>
            <option value="YesNo">Ja/Nein</option>
            <option value="FileUpload">Datei-Upload</option>
            <option value="Location">Ort / Radius</option>
            <option value="Relay">Weiterleitung</option>
            <option value="End">Ende des Chats</option>
          </select>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Tracking Label
        </div>
        <div class="col-9">
          <input class="form-control form-control-sm" name="selectedStep.TrackingLabel" [(ngModel)]="selectedStep.TrackingLabel" (ngModelChange)="saveSoon()" placeholder="Tracking-Label" />
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Text
        </div>
        <div class="col-9">
          <ngx-monaco-editor style="min-height: 250px;" id="editor" [options]="editorOptions" [(ngModel)]="selectedStep.Body" (ngModelChange)="saveSoon()"></ngx-monaco-editor>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'End'">
      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NextStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'Text'">
      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NextStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-2 form-row-sm">
        <div class="col-3">
          Passwort
        </div>
        <div class="col-9">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="selectedStep.IsPassword" (ngModelChange)="saveSoon()">
          </div>
        </div>
      </div>

      <div class="row mb-2 form-row-sm">
        <div class="col-3">
          Mehrzeilig
        </div>
        <div class="col-9">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="selectedStep.IsMultiline" (ngModelChange)="saveSoon()">
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Validierung
        </div>
        <div class="col-auto">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="selectedStep.ValidateIsPhone" (ngModelChange)="saveSoon()">
            <label>Telefon</label>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="selectedStep.ValidateIsEMail" (ngModelChange)="saveSoon()">
            <label>E-Mail</label>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'Empty'">
      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NextStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Weiter mit Klick
        </div>
        <div class="col-auto">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="selectedStep.AskToProceed" (ngModelChange)="saveSoon()">
          </div>
        </div>
        <div class="col">
          <select class="custom-select custom-select-sm" [disabled]="selectedStep.AskToProceed" [(ngModel)]="selectedStep.ProceedDelay" (ngModelChange)="saveSoon()">
            <option *ngFor="let i of [ 500, 1000, 1500, 2000, 3000, 4000, 5000, 7000, 10000]" [ngValue]="i">
              {{ i }} ms</option>
          </select>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'Choice'">
      <div class="row">

        <div class="col-12 mb-2">
          <div class="row mb-2">
            <div class="col-3">
              Auswahl
            </div>
            <div class="col-9">
              <autosize-textarea [(text)]="selectedStep.Choices" (textChange)="saveSoon()" placeholder="Einträge durch Umbruch trennen"></autosize-textarea>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-3">
              Min./Max.
            </div>
            <div class="col-1">
              <input class="form-control form-control-sm" name="MinimumSelectedChoices" [(ngModel)]="selectedStep.MinimumSelectedChoices" (ngModelChange)="saveSoon()" placeholder="MinimumSelectedChoices" />
            </div>
            <div class="col-auto">
              -
            </div>
            <div class="col-1">
              <input class="form-control form-control-sm" name="MaximumSelectedChoices" [(ngModel)]="selectedStep.MaximumSelectedChoices" (ngModelChange)="saveSoon()" placeholder="MaximumSelectedChoices" />
            </div>
            <div class="col-5">
              <button class="btn btn-secondary btn-sm mb-2 me-2" (click)="selectedStep.MinimumSelectedChoices = 1; selectedStep.MaximumSelectedChoices=1; saveSoon();">Einfachauswahl</button>
              <button class="btn btn-secondary btn-sm mb-2" (click)="selectedStep.MinimumSelectedChoices = 1; selectedStep.MaximumSelectedChoices=100; saveSoon();">Mehrfachauswahl</button>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              Weiterleitungsregeln
            </div>
            <div class="col-9">
              <div class="row mb-2" *ngFor="let rule of selectedStep.GoToRules">
                <div class="col">
                  <input class="form-control form-control-sm" name="i" [(ngModel)]="rule.Evaluation" (ngModelChange)="saveSoon()" placeholder="Regel" />
                </div>
                <div class="col">
                  <select class="custom-select custom-select-sm" [(ngModel)]="rule.StepId" (ngModelChange)="saveSoon()">
                    <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
                      {{s.Body}}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <i class="fa-regular fa-trash" style="cursor: pointer;" (click)="deleteGoToRule(selectedStep, rule)"></i>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <button class="btn btn-secondary btn-sm mb-2" (click)="createGoToRule(selectedStep);">Neue
                    Weiterleitung</button>
                </div>
              </div>

            </div>

          </div>

          <div class="row mb-2">
            <div class="col-3">
              Fallback Weiterleitung
            </div>
            <div class="col-9">
              <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.FallbackStepId" (ngModelChange)="saveSoon()">
                <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
                  {{s.Body}}
                </option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'YesNo'">
      <div class="row mb-2">
        <div class="col-3">
          Ja-Text
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.YesLabel" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Bezeichnung">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Ja-Button-Style
        </div>
        <div class="col-6">
          <input name="n" [(ngModel)]="selectedStep.YesCssClass" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Bezeichnung">
        </div>
        <div class="col-3">
          <button class="btn btn-block btn-sm {{ selectedStep.YesCssClass }}">{{ selectedStep.YesLabel ? selectedStep.YesLabel : "Ja" }}</button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Ja Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.YesStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Nein-Text
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.NoLabel" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Bezeichnung">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Nein-Button-Style
        </div>
        <div class="col-6">
          <input name="n" [(ngModel)]="selectedStep.NoCssClass" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Bezeichnung">
        </div>
        <div class="col-3">
          <button class="btn btn-block btn-sm {{ selectedStep.NoCssClass }}">{{ selectedStep.NoLabel ? selectedStep.NoLabel : "Nein" }}</button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Nein Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NoStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'FileUpload'">

      <div class="row mb-2">
        <div class="col-3">
          Label
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.Label" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Label">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Confirmation Nachricht
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.ConfirmationMessage" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Confirmation Nachricht">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Upload URL
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.UploadUri" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Upload URL">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Max. Dateigröße
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.MaximumFileSize" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Max. Dateigröße">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NextStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>

    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'Location'">

      <div class="row mb-2">
        <div class="col-3">
          Label
        </div>
        <div class="col-9">
          <input name="n" [(ngModel)]="selectedStep.Label" (ngModelChange)="saveSoon()" type="text" class="form-control form-control-sm" placeholder="Label">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung
        </div>
        <div class="col-9">
          <select class="custom-select custom-select-sm" [(ngModel)]="selectedStep.NextStepId" (ngModelChange)="saveSoon()">
            <option *ngFor="let s of content.Steps" [ngValue]="s.Id">
              {{s.Body}}
            </option>
          </select>
        </div>
      </div>

    </div>

    <div class="w-100" *ngIf="selectedStep.Type == 'Relay'">
      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung: Bot ID
        </div>
        <div class="col-9">
          <input class="form-control form-control-sm" name="selectedStep.ChatbotId" [(ngModel)]="selectedStep.ChatbotId" (ngModelChange)="saveSoon()" placeholder="selectedStep.ChatbotId" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Weiterleitung: Schritt Id
        </div>
        <div class="col-9">
          <input class="form-control form-control-sm" name="selectedStep.StepId" [(ngModel)]="selectedStep.StepId" (ngModelChange)="saveSoon()" placeholder="selectedStep.StepId" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Nach Ende: Bot ID
        </div>
        <div class="col-9">
          <input class="form-control form-control-sm" name="selectedStep.ReturnToChatbotId" [(ngModel)]="selectedStep.ReturnToChatbotId" (ngModelChange)="saveSoon()" placeholder="selectedStep.ReturnToChatbotId" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3">
          Nach Ende: Schritt Id
        </div>
        <div class="col-9">
          <input class="form-control form-control-sm" name="selectedStep.ReturnToStepId" [(ngModel)]="selectedStep.ReturnToStepId" (ngModelChange)="saveSoon()" placeholder="selectedStep.ReturnToStepId" />
        </div>
      </div>

    </div>

    <div class="w-100">
      <div class="row">
        <div class="col-3">
          Assets
        </div>
        <div class="col-9">
          <div class="row mb-2" *ngFor="let asset of content.Assets">
            <div class="col-3">
              <img [src]="asset.Uri" class="img-fluid" style="max-height: 150px;" />
            </div>
            <div class="col">
              <input readonly class="form-control form-control-sm" name="asset.Uri" [(ngModel)]="asset.Uri" placeholder="asset.Uri" />
            </div>
            <div class="col-auto pl-0">
              <button class="btn btn-sm btn-secondary" (click)="copyAsset(asset)"><span class="fa-regular fa-copy"></span></button>
            </div>
            <div class="col-auto pl-0">
              <button class="btn btn-sm btn-secondary" (click)="deleteAsset(asset)"><span class="fa-regular fa-trash"></span></button>
            </div>
          </div>
          <sc-file-button (filesChanged)="uploadAsset($event)">
            <div class="btn btn-sm btn-secondary"><i class="fa-regular fa-cloud-arrow-up"></i>
              Hochladen</div>
          </sc-file-button>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn btn-danger" (click)="deleteStep(selectedStep);">Schritt löschen</button>
    <button class="btn btn-secondary" (click)="stepModal.dismiss()">Schließen</button>
  </div>
</sc-modal>

<sc-modal #scriptOnSendingModal size="xl">
  <sc-modal-header>
    Schritt bearbeiten - Script onSending
  </sc-modal-header>

  <div class="modal-body" *ngIf="selectedStep">
    <div class="w-100">
      <div class="row">
        <div class="col-12">
          <ngx-monaco-editor style="height: 80vh;" id="editor" [options]="editorJsOptions" [(ngModel)]="selectedStep.OnSendingScript" (ngModelChange)="saveSoon()"></ngx-monaco-editor>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="scriptOnSendingModal.dismiss()">Ok</button>
  </div>
</sc-modal>

<sc-modal #scriptOnReplyModal size="xl">
  <sc-modal-header>
    Schritt bearbeiten - Script onReply
  </sc-modal-header>
  <div class="modal-body" *ngIf="selectedStep && scriptOnReplyModal.isOpen">
    <div class="w-100">
      <div class="row">
        <div class="col-12">
          <ngx-monaco-editor style="height: 80vh;" id="editor" [options]="editorJsOptions" [(ngModel)]="selectedStep.OnReplyScript" (ngModelChange)="saveSoon()"></ngx-monaco-editor>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="scriptOnReplyModal.dismiss()">Ok</button>
  </div>
</sc-modal>

<sc-modal #deleteModal>
  <sc-modal-header>
    Wirklich löschen?
  </sc-modal-header>
  <sc-modal-body>
    <div class="row">
      <div class="col-12">
        <p>Willst du den Chatbot wirklich unwiderruflich löschen? Bitte gib in das Textfeld <i>löschen</i>
          (klein
          geschrieben) ein um fortzufahren.</p>
      </div>
      <div class="col-12">
        <input class="form-control" #deleteConfirmation />
      </div>
    </div>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">Abbrechen</button>
    <button class="btn btn-danger" [disabled]="deleteConfirmation.value != 'löschen'" (click)="deleteModal.complete();">Unwiderruflich
      löschen</button>
  </sc-modal-footer>
</sc-modal>
