<ng-template [appTopbarTemplate]>
  <div class="title h4">Personen</div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-3">
                <input type="search" [(ngModel)]="lastName" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Nachname">
              </div>
              <div class="col-3">
                <input type="search" [(ngModel)]="firstName" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Vorname">
              </div>
              <div class="col-3">
                <input type="search" [(ngModel)]="eMail" (ngModelChange)="searchSoon()" class="form-control search" placeholder="E-Mail">
              </div>
              <div class="col-3 text-right">
                <span class="text-primary" *ngIf="total">{{ total }} Ergebnisse</span>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="isLoading">
            <div class="row justify-content-center">
              <div class="col-auto">
                <div class="spinner-border text-primary" role="status">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="!people?.length">
            <div class="row">
              <div class="col-4 offset-4 my-6">
                <p class="text-center text-muted">
                  Die Suche wird exakt ausgeführt, es werden also nur Ergebnisse angezeigt, deren Nachname, Vorname und E-Mail genau der Eingabe entspricht.
                  Die Suche nur nach Vorname wird nicht unterstützt.
                </p>
              </div>
            </div>

          </div>
          <div class="card-body card-body-list" *ngIf="people?.length">

            <div class="list-group">

              <a class="list-group-item list-group-item-action" routerLink="/candidates/{{person.Guid}}" *ngFor="let person of people">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <sc-avatar class="d-inline-block" [user]="person" size="sm"></sc-avatar>
                  </div>
                
                  <div class="col">
                    <span>
                    <h6 class="mb-1 oneline d-inline">
                      {{person.FirstName}} {{ person.LastName}}
                    </h6>
                    </span>
                
                    <p class="card-text text-muted small mb-0 oneline">
                    {{ person.JobTitle }} - {{ person.Company }}
                    </p>
                  </div>
        
                  <div class="col-auto">
                    <span class="badge badge-pill badge-light">
                      {{ person.SalesStatus }}
                    </span>						
                  </div>			  
                  </div>
                </a>

            </div>

            <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
              <div class="col-auto">
                <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>

</div>
