import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-detail-default',
  templateUrl: './task-detail-default.component.html',
  styleUrls: ['./task-detail-default.component.scss']
})
export class TaskDetailDefaultComponent {

  @Input()
  task : any;
  
}
