<sc-activity-item-comment [activity]="activity">
	<div class="comment-label">
		hat eine E-Mail gesendet
	</div>
	<div class="comment-body">
		<p>
			{{ activity.Payload?.StrippedBodyText | truncate : 400 }}			
		</p>
		<a class="clickable" (click)="modal.show()">E-Mail anzeigen</a>
	</div>
</sc-activity-item-comment>

<sc-modal #modal size="lg">
	<sc-modal-header>
		E-Mail: {{ activity?.Payload?.Subject }}
	</sc-modal-header>
	<sc-modal-body>
		<div [innerHTML]="activity.Payload?.BodyHtml"></div>
	</sc-modal-body>
</sc-modal>