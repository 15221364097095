import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'month-year-date-input',
  templateUrl: './month-year-date-input.component.html',
  styleUrls: ['./month-year-date-input.component.scss']
})
export class MonthYearDateInputComponent implements OnInit, OnChanges {
  
  _rawValue = "";

  get rawValue()
  {
    return this._rawValue;
  }
  set rawValue(value)
  {
    this._rawValue = value;

    var previousIsoDate = this.isoDate;

    if (!this.validate(value))
    {
      this.isValid = false;
      this.isoDate = null;
    }
    else {
      this.isValid = true;
      this.isoDate = this.convertInputToIsoString(value);
    }

    if (this.isoDate != previousIsoDate)
    {      
      this.isoDateChange.next(this.isoDate);
    }

    console.log(this.isValid);
    console.log(this.isoDate);
  }

  isValid = true;

  @Input()
  isoDate = "";

  @Input()
  size = "";

  @Output()
  isoDateChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isoDate) return;
    if (this.isoDate == "" && !this.isValid) return;
    this._rawValue = this.convertIsoStringToInput(this.isoDate);    
  }

  validate(value)
  {
    var split = this._rawValue.split('/');
    if (split.length != 2) return false;
    if (split[0].length != 2) return false;
    if (split[1].length != 4) return false;
    var month = parseInt(split[0]);
    var year = parseInt(split[1]);
    if (month < 1 || month > 12) return false;
    if (year < 1900 || year > 2200) return false;
    return true;
  }

  convertInputToIsoString(value)
  {
    var split = this._rawValue.split('/');
    if (split.length != 2) return "";
    var month = split[0];
    var year = split[1];
    var iso = year + "-" + month + "-01T12:00:00Z";
    return iso;
  }

  convertIsoStringToInput(isoString)
  {
    if (!isoString) return "";
    var split = isoString.split("-");
    var year = split[0];
    var month = split[1];
    return month + "/" + year;
  }


}
