import { Component, Input, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-project-briefing-modal-skills',
  templateUrl: './project-briefing-modal-skills.component.html',
  styleUrls: ['./project-briefing-modal-skills.component.scss']
})
export class ProjectBriefingModalSkillsComponent {

  skill = {
    Name : '',
    Type : 'MustHave',
    AlternativeTo: ''
  }

  @Input()
  skills : any = []

  @ViewChild('modal')
  modal : ScuiModalComponent;

  ngOnChanges()
  {
  }

  show()
  {
    return this.modal.show();
  }

  select() {
    this.modal.complete(this.skill);
    this.skill = {
      Name : '',
      Type : 'MustHave',
      AlternativeTo: ''
    }

  }

}
