<ng-template [appTopbarTemplate]>
  <div class="title h4">Sales-Leads</div>

  <div class="nav">
    <ul class="nav nav-tabs nav-overflow">

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'New'" (click)="salesStatus = 'New'; load();">
          Qualified&nbsp;<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.New">
            {{ stats.New }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'WarmDirect'" (click)="salesStatus = 'WarmDirect'; load();">
          Warm<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.WarmDirect">
            {{ stats.WarmDirect }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Scheduled'" (click)="salesStatus = 'Scheduled'; load();">
          Terminiert&nbsp;<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Scheduled">
            {{ stats.Scheduled }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Closing'" (click)="salesStatus = 'Closing'; load();">
          Closing&nbsp;<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Closing">
            {{ stats.Closing }}
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [class.active]="salesStatus == 'Upselling'" (click)="salesStatus = 'Upselling'; load();">
          Upsell&nbsp;<span class="badge badge-pill badge-light ms-2 d-inline" *ngIf="stats?.Upselling">
            {{ stats.Upselling }}
          </span>
        </a>
      </li>
    </ul>

  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="card shadow-xl">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-2">
                <input type="search" [(ngModel)]="lastName" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Nachname">
              </div>
              <div class="col-2">
                <input type="search" [(ngModel)]="firstName" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Vorname">
              </div>
              <div class="col-2">
                <input type="search" [(ngModel)]="eMail" (ngModelChange)="searchSoon()" class="form-control search" placeholder="E-Mail">
              </div>
              <div class="col-2">
                <input type="search" [(ngModel)]="tags" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Tags">
              </div>
              <div class="col">

              </div>
              <div class="col-auto clickable" (click)="selectManager();">
                <div class="row align-items-center">
                  <div class="col-auto pe-0">
                    <sc-avatar *ngIf="selectedManager" [user]="selectedManager"></sc-avatar>
                    <div *ngIf="!selectedManager" class="avatar avatar-sm">
                      <span class="avatar-icon">
                        <i class="fa-regular fa-users"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-auto">
                    <span>
                      <i class="fa-regular fa-chevron-down"></i>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>  
          
          <div class="card-body card-body-list">

            <div class="list-group fw-normal">

              <div class="list-group-item justify-content-center d-flex" *ngIf="isLoading">
                <span class="muted">Bitte warte...</span>
              </div>

              <a class="list-group-item list-group-item-action" [routerLink]="'/candidates/' + account.Leads[0].Guid + '/sales'" [class.on-hold]="account.IsOnHold" *ngFor="let account of people">

                <div class="row">
                  <div class="col-auto">
                    <sc-avatar class="d-inline-block" [user]="account.Manager" size="sm"></sc-avatar>
                  </div>

                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        {{ account.Name }}
                      </h6>
                    </span>

                    <p class="card-text text-muted small mb-0 oneline">
                      <span *ngIf="account.Leads?.length">{{account.Leads[0].FirstName}} {{ account.Leads[0].LastName}} <span class="small" *ngIf="account.Leads.length > 1">+ {{ account.Leads.length - 1 }} weitere</span></span>
                      <span *ngIf="!account.Leads?.length">keine Personen</span>
                    </p>
                  </div>

                  <div class="col-auto">
                    <sc-avatar-list size="xs" [users]="account.Leads"></sc-avatar-list>
                  </div>

                  <div class="col-auto" *ngIf="account.IsOnHold">
                    <span class="badge badge-pill badge-light">
                      <i class="fa-sharp fa-light fa-calendar me-1"></i> {{ account.OnHoldUntil | date : 'dd.MM.yy' }}
                    </span>
                  </div>
                </div>
              </a>

              <div class="list-group-item justify-content-center d-flex" *ngIf="hasMoreItems">
                <button class="btn btn-secondary btn-round btn-sm" (click)="loadMore()" [class.loading]="isLoading">Mehr</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<app-modal-select-manager #userModal (selected)="userModal.complete($event);"></app-modal-select-manager>
