import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-projects',
  templateUrl: './sidebar-projects.component.html',
  styleUrls: ['./sidebar-projects.component.scss']
})
export class SidebarProjectsComponent implements OnInit {

  q = '';

  projects : any = [];

  constructor(private api : ScApiService, private sidebarService : SidebarService) { }

  ngOnInit(): void {
    this.loadResults();
  }

  loadResults() {

    var encodedQ = encodeURIComponent(this.q);

    var uri = 'jobs?q=' + encodedQ + '&userProjectsOnly=false';

    this.api.get(uri).subscribe((r: any) => {
      this.projects = r.Items;
    });
  }

  selectProject(p)
  {
    this.sidebarService.setProjectId(p.Id);
  }

  loadResultsSoon()
  {
    if (this["loadTimeout"]) clearTimeout(this["loadTimeout"]);
    this["loadTimeout"] = setTimeout(() => { this.loadResults(); }, 500);
  }

}
