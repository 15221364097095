import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { environment } from 'src/environment.prod';

@Component({
  selector: 'app-chatbot-history',
  templateUrl: './chatbot-history.component.html',
  styleUrls: ['./chatbot-history.component.scss']
})
export class ChatbotHistoryComponent implements OnInit {

  id;

  chatbot : any;

  chats : any = [];

  
  @ViewChild('remindModal', {static : true})
  remindModal : ScuiModalComponent;

  constructor(
    private route: ActivatedRoute,
    private api: ScApiService,) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.api.get('chatbots/' + this.id).subscribe((p: any) => {
        this.chatbot = p;    
      });
      this.api.get('chatbots/' + this.id + '/chats').subscribe((p: any) => {
        this.chats = p;    
      });
    });
  }

  delete(chat)
  {
    this.api.delete('chats/' + chat.Chat.Id).subscribe(() => {
      var i = this.chats.indexOf(chat);
      this.chats.splice(i, 1);
    });
  }

  reminder : any = {};

  selectedChat : any;

  showRemindModal(chat)
  {
    this.selectedChat = chat;

    this.reminder = {
      SendPushNotification: true,
      SendEMailNotification: true
    };  
    
    this.remindModal.show();  
  }

  remindChat()
  {
    this.api.post('chats/' + this.selectedChat.Id + '/reminder', this.reminder, environment.chatApi).subscribe();
  }

}
