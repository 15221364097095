import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, ScUploadService, ScuiModalComponent } from '@sc-ui';
import { Guid } from "guid-typescript";
import { ClipboardService } from 'ngx-clipboard';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {

  editorOptions = {
    theme: 'vs-light',
    language: 'html',
    automaticLayout: true,
    wordWrap: 'on',
    codeLense: false,
    minimap: {
      enabled: false
    }
  };

  editorJsOptions = {
    theme: 'vs-light',
    language: 'javascript',
    //automaticLayout: true,    
    codeLense: false,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  id: any = '';

  chatbot: any = {};

  content: any = {};

  selectedStep: any;

  saveTimeout: any;

  @ViewChild('stepModal')
  stepModal: ScuiModalComponent;

  @ViewChild('deleteModal')
  deleteModal: ScuiModalComponent;

  deleteConfirmation = "";

  constructor(private route: ActivatedRoute,
    private router: Router,
    private api: ScApiService,
    private uploadApi: ScUploadService,
    private clipboard: ClipboardService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.api.get('chatbots/' + this.id).subscribe((p: any) => {
        this.chatbot = p;
        if (p.Content) {
          this.content = JSON.parse(p.Content);
        }
        else {
          this.content = { Steps: [] };
        }

        if (this.content.Steps.length > 0)
          this.selectedStep = this.content.Steps[0];

        this.validate();

        console.log(this.content);

      });
    });
  }

  validate() {
    this.api.get(`chatbots/${this.id}/validation`).subscribe((p: any) => {
      this.content.Steps.forEach(s => {
        s.validation = p.StepResults[s.Id];
      })
    });

  }

  saveSoon() {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => { this.save(); }, 1000);
  }


  save() {
    this.content.Steps.forEach(s => {
      var clrTypeName = "Skillconomy.Chats.Steps.Serializable" + s.Type + "ChatStep, sc-chats"
      s.$type = clrTypeName;
    });

    this.chatbot.Content = JSON.stringify(this.content, null, 3);

    this.api.put("chatbots/" + this.id, this.chatbot).subscribe(data => {
      console.log(data);
      this.validate();
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.save();
  }

  createStep() {
    if (!this.content.Steps) this.content.Steps = [];

    var s = {
      $type: '',
      Id: Guid.create().toString(),
      Name: 'Neu',
      Type: 'Text',
      ProceedDelay: 500,
      UploadUri: 'https://bot.skillconomy.com/api/blob',
      MaximumFileSize: 10 * 1024
    };

    this.content.Steps.push(s);
  }

  deleteStep(s) {
    var index = this.content.Steps.indexOf(s);
    this.content.Steps.splice(index, 1);
    this.selectedStep = null;
    this.stepModal.dismiss();
    this.saveSoon();
  }

  createGoToRule(s) {
    if (!s.GoToRules) s.GoToRules = [];
    s.GoToRules.push({ Evaluation: '', StepId: '' });
    this.save();
  }

  deleteGoToRule(s, r) {
    var index = s.GoToRules.indexOf(r);
    s.GoToRules.splice(index, 1);
    this.save();
  }

  uploadAsset(files) {
    this.uploadApi.upload(files, (d: any) => {
      if (!this.content.Assets) this.content.Assets = [];
      var asset = { Uri: d.Uri };
      this.content.Assets.push(asset);
      this.save();
    });
  }

  deleteAsset(asset) {
    var index = this.content.Assets.indexOf(asset);
    this.content.Assets.splice(index, 1);
  }

  uploadImage(files) {
    this.uploadApi.upload(files, (d: any) => {
      this.chatbot.Image = d.Uri;
      this.save();
    });
  }

  copyAsset(asset) {
    var isImage = false;
    var isVideo = false;
    var content = asset.Uri;

    var lowerUri = asset.Uri.toLowerCase();
    var imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];
    var videoExtensions = ['mp4'];
    var assetExtension = "";

    imageExtensions.forEach(e => {
      if (lowerUri.indexOf(`.${e}?`) < 0) return;
      isImage = true
      assetExtension = e;
    });

    videoExtensions.forEach(e => {
      if (lowerUri.indexOf(`.${e}?`) >= 0) isVideo = true
    });

    if (isImage) {
      content = `<img src="data:image/${assetExtension};base64, \$inline-asset: ${asset.Uri}\$" class="img-fluid" />`;
    }

    if (isVideo) {
      content = `<div class="embed-inner embed-responsive-item">
    <iframe src="${asset.Uri}" frameborder="0" 
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
  </div>`
    }

    this.clipboard.copyFromContent(content);
  }

  delete()
  {
    this.api.delete("chatbots/" + this.chatbot.Id).subscribe(() => {
      this.router.navigateByUrl('/chatbots');
    });
  }

  copy()
  {
    this.content.Steps.forEach(s => {
      var clrTypeName = "Skillconomy.Chats.Steps.Serializable" + s.Type + "ChatStep, sc-chats"
      s.$type = clrTypeName;
    });

    this.chatbot.Content = JSON.stringify(this.content, null, 3);

    var newChatbot : any = {};

    Object.assign(newChatbot, this.chatbot);

    newChatbot.Id = null;
    newChatbot.Name += ' (Kopie)';

    newChatbot = {
      Name : this.chatbot.Name + ' (Kopie)',
      Content : this.chatbot.Content,
      NotificationTitle : this.chatbot.NotificationTitle,
      NotificationMessage : this.chatbot.NotificationMessage,
      Image : this.chatbot.Image
    };

    this.api.post("chatbots", newChatbot).subscribe((d : any) => {
      this.router.navigateByUrl('/chatbots/' + d.Id);
    });
  }
}
