<div class="card" scAnchorTarget="Rechnungen">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h6 class="card-header-title">
                    Rechnungen
                </h6>
            </div>
        </div>
    </div>

    <div class="card-body card-body-list">
		
        <app-list-invoices [invoices]="invoices"></app-list-invoices>
    </div>
</div>