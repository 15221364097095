<div class="main-content">

    <div class="container">
        <div class="row">

            <div class="col-12" style="height: 90vh; width: 100%;">
                <sc-chatbot-stream
                *ngIf="chat"
                [chat]="chat" 
                isReadOnly="true">
                </sc-chatbot-stream>
            </div>
        </div>
    </div>
</div>