import { Component, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService, ScuiModalComponent } from '@sc-ui';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-select-organization',
  templateUrl: './modal-select-organization.component.html',
  styleUrls: ['./modal-select-organization.component.scss']
})
export class ModalSelectOrganizationComponent {

  name: any = '';

  domain: any = '';

  organizations: any;

  isCreating = false;

  error = '';

  @ViewChild(ModalWizardComponent)
  modal: ModalWizardComponent;

  @ViewChild('start')
  startPage: ModalWizardPageComponent;

  constructor(private api: ScApiService) { }

  ngOnInit() {
  }

  searchSoon() {
    if (this["searchTimeout"]) clearTimeout(this["searchTimeout"]);
    this["searchTimeout"] = setTimeout(() => {
      this.search();
    }, 500);
  }

  search() {
    this.api.get('companies?take=10&q=' + encodeURIComponent(this.name)).subscribe((d: any) => {
      this.organizations = d;
    });
  }

  createOrganization() {

    if (this.isCreating) return;
    this.isCreating = true;
    this.error = '';

    var args: any = {
      Name: this.name
    };

    if (this.domain) {
      args.Domains = [
        {
          Host: this.domain,
          IsApproved: true
        }
      ];
    }

    this.api.post('companies', args).subscribe((d: any) => {
      this.modal.complete(d);
    },
      () => { },
      () => {
        this.isCreating = false;
      })
  }

  public show() {
    return this.modal
      .show(this.startPage)
      .pipe(tap(i => { 
        this.name = '';
       }));
  }

  public dismiss()
  {
    this.modal.dismiss();
  }
}
