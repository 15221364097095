import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { tap } from 'rxjs/operators';

export class TaskRepository {

  private groupByItemId = {};

  private groupByKey = {};

  private itemsByPersonId = {};

  private itemsByOrganizationId = {};

  private itemsByProjectId = {};

  public items: any = [];

  public groups: TaskGroup[] = [];

  public dueCount = 0;

  salesStati = [
    'None',
    'Qualified',
    'Outreach',
    'NotInterested',
    'Lead',
    'Contact',
    'MainContact',
    'Disqualified'
  ];

  public addItems(items) {
    items.forEach(i => this.addItem(i));
  }

  public updateCount() {
    var now = new Date();
    var dueCount = 0;

    this.items.forEach(i => {
      var due = new Date(i.Due);
      if (due <= now) dueCount++;
    });

    this.dueCount = dueCount;
  }

  public addItem(i) {

    this.items.push(i);
    this.updateCount();

    if (i.Candidate) 
    {
      var items = this.itemsByPersonId[i.Candidate.Id];

      if (!items) {
        items = [];
        this.itemsByPersonId[i.Candidate.Id] = items;
      }

      items.push(i);
    }

    if (i.Organization) 
    {
      var items = this.itemsByOrganizationId[i.Organization.Id];

      if (!items) {
        items = [];
        this.itemsByOrganizationId[i.Organization.Id] = items;
      }

      items.push(i);
    }

    if (i.Project) 
    {
      var items = this.itemsByProjectId[i.Project.Id];

      if (!items) {
        items = [];
        this.itemsByProjectId[i.Project.Id] = items;
      }

      items.push(i);
    }
    
    if (!i.IsDueSoon && !i.IsDue) return;

    var group = this.getGroupForItem(i);
    group.addItem(i);

    this.groupByItemId[i.Id] = group;


  }

  public removeItem(i) {

    var indexInItems = this.items.indexOf(i);
    this.items.splice(indexInItems, 1);
    this.updateCount();

    var group: TaskGroup = this.groupByItemId[i.Id];

    if (group) {
      group.removeItem(i);
      this.groupByItemId[i.Id] = null;
    }

    if (group.subGroups.length == 0) {
      var index = this.groups.indexOf(group);
      this.groups.splice(index, 1);
      this.groupByKey[group.key] = null;
    }

    if (i.Candidate) {
      var items = this.itemsByPersonId[i.Candidate.Id];
      var index = items.indexOf(i);
      items.splice(index, 1);
    }

    if (i.Organization) {
      var itemsOfOrganization = this.itemsByOrganizationId[i.Organization.Id];
      var index = itemsOfOrganization.indexOf(i);
      itemsOfOrganization.splice(index, 1);
    }

    if (i.Project) {
      var items = this.itemsByProjectId[i.Project.Id];
      var index = items.indexOf(i);
      items.splice(index, 1);
    }
  }

  public getTasksOfPerson(id) {
    var items = this.itemsByPersonId[id];
    if (!items) {
      items = [];
      this.itemsByPersonId[id] = items;
    }

    return items;
  }

  public getTasksOfOrganization(id) {
    var items = this.itemsByOrganizationId[id];
    if (!items) {
      items = [];
      this.itemsByOrganizationId[id] = items;
    }

    return items;
  }

  public getTasksOfProject(id) {
    var items = this.itemsByProjectId[id];
    if (!items) {
      items = [];
      this.itemsByProjectId[id] = items;
    }

    return items;
  }

  private getGroupForItem(i): TaskGroup {

    var key = 'none';
    var name = 'Ohne Zuordnung';
    var preTitle = 'Allgemein';
    var orderIndex = 0;

    if (i.Project) {
      key = 'project_' + i.Project.Id;
      name = i.Project.Name;
      preTitle = 'Projekt';
    }
    if (i.Candidate?.SalesStatus && i.Candidate?.SalesStatus != 'None') {
      key = 'salesstatus_' + i.Candidate.SalesStatus.toLowerCase();
      name = i.Candidate.SalesStatus;
      preTitle = 'Sales';
      orderIndex = 300 - this.salesStati.indexOf(i.Candidate.SalesStatus);
    }
    if (i.Type == 'SalesProjectInvoice') {
      key = 'salesstatus_customer';
      name = 'Customer';
      preTitle = 'Sales';
      orderIndex = 100;
    };
    if (i.Type == 'SalesProjectQuote') {
      key = 'salesstatus_customer';
      name = 'Customer';
      preTitle = 'Sales';
      orderIndex = 200;
    };

    var group: TaskGroup = this.groupByKey[key];

    if (!group) {
      group = new TaskGroup();
      group.key = key;
      group.name = name;
      group.preTitle = preTitle;
      group.orderIndex = orderIndex;

      for (var g = 0; g <= this.groups.length; g++) {
        if (g == this.groups.length) {
          this.groups.push(group);
          break;
        }

        var groupAtG = this.groups[g];

        if (groupAtG.orderIndex >= group.orderIndex) {
          this.groups.splice(g, 0, group);
          break;
        }
      }

      this.groupByKey[key] = group;
    }

    return group;
  }

  clear() {
    this.items.length = 0;
    this.groups.length = 0;
    this.dueCount = 0;

    Object.keys(this.itemsByPersonId).forEach(key => {
      this.itemsByPersonId[key].length = 0;
    });

    Object.keys(this.itemsByOrganizationId).forEach(key => {
      this.itemsByOrganizationId[key].length = 0;
    });

    Object.keys(this.itemsByProjectId).forEach(key => {
      this.itemsByProjectId[key].length = 0;
    });

    this.groupByItemId = {};
    this.groupByKey = {};
  }
}

export class TaskGroup {

  private subGroupByItemId = {};

  private subGroupByKey = {};

  public key = '';

  public name = '';

  public preTitle = '';

  public orderIndex = 0;

  public subGroups: TaskSubGroup[] = [];

  public addItem(i) {
    var key = i.Type;
    var subGroup = this.subGroupByKey[key];

    if (!subGroup) {
      subGroup = new TaskSubGroup();
      subGroup.name = key;

      this.subGroups.push(subGroup);
      this.subGroupByKey[key] = subGroup;
    }

    subGroup.items.push(i);
    subGroup.hasDue = subGroup.hasDue || i.IsDue;

    this.subGroupByItemId[i.Id] = subGroup;
  }

  removeItem(i) {
    var subGroup: TaskSubGroup = this.subGroupByItemId[i.Id];
    var index = subGroup.items.indexOf(i);
    if (index == -1) return;

    subGroup.items.splice(index, 1);
    subGroup.hasDue = subGroup.items.some(i => i.IsDue);

    this.subGroupByItemId[i.Id] = null;

    if (subGroup.items.length == 0) {
      var subGroupIndex = this.subGroups.indexOf(subGroup);
      this.subGroups.splice(subGroupIndex, 1);
      this.subGroupByKey[subGroup.name] = null;
    }
  }
}

export class TaskSubGroup {

  public name: string = '';

  public items: any[] = [];

  public hasDue = false;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  onLoad = new EventEmitter<any>();

  onPin = new EventEmitter<any>();

  onUnpin = new EventEmitter<any>();

  isPinned = false;

  isLoading = false;

  isLoaded = false;

  items: any = [];

  public tasks: TaskRepository = new TaskRepository();

  managerId = 0;

  constructor(private router: Router, private api: ScApiService) {   
    
    this.isPinned = localStorage.getItem('taskPinned') == 'true';

    this.api.loggedOut.subscribe(() => {
      this.tasks = new TaskRepository();
      this.isLoaded = false;
      this.isLoading = false;
    });

    setInterval(() => {
      this.load(true);
    }, 60000);
  }

  pin()
  {
    this.isPinned = true;
    this.onPin.next();
    localStorage.setItem('taskPinned', this.isPinned.toString());
  }

  unpin()
  {
    this.isPinned = false;
    this.onUnpin.next();
    localStorage.setItem('taskPinned', this.isPinned.toString());
  }

  load(force = false) {
    
    if (!force && (this.isLoaded || this.isLoading)) return;

    this.isLoading = true;

    this.api.get('notifications?managerId=' + this.managerId).subscribe((d: any) => {
      this.items = d;
      this.tasks.clear();
      this.tasks.addItems(d);
      this.isLoaded = true;
      this.isLoading = false;
      this.onLoad.emit();
    });
  }

  execute(i) {

    if (i.Project && !i.Candidate) {
      var uri = '/projects/' + i.Project.Id;
      this.router.navigateByUrl(uri);
    }

    else if (i.Candidate) {
      var uri = '/candidates/' + i.Candidate.Id;
      this.router.navigateByUrl(uri);
    }
  }

  save(n) {

    var args = {
      Id: n.Id,
      IsClosed: n.IsClosed,
      Due: n.Due,
      Description: n.Description,
      Payload: n.Payload
    };

    return this.api.put('notifications', args);
  }

  create(n) {

    var args = {
      Due: n.Due,
      Payload: n.Payload,
      CandidateId: n.CandidateId,
      OrganizationId: n.OrganizationId,
      ProjectId: n.ProjectId,
      Content: n.Content
    };

    return this.api.post('notifications', args).pipe(tap((d: any) => {
      this.tasks.addItem(d);
      this.items.push(d);
    }));
  }

  close(n) {

    n.IsClosed = true;

    return this.save(n).pipe(tap(() => {
      var itemIndexInItems = this.items.indexOf(n);
      if (itemIndexInItems >= 0) this.items.splice(itemIndexInItems, 1);
      this.tasks.removeItem(n);
    }));
  }
}
