<div class="card mb-5">

	<div class="card-header">
	  <div class="row align-items-center">
		<div class="col">
		  <h6 class="card-header-title">
			Empfänger
		  </h6>
		</div>
		<div class="col-auto">
		  <div class="btn btn-sm btn-dark btn-circle lift" (click)="addUser()">
			<i class="fa-regular fa-plus"></i>
		  </div>
		</div>
	  </div>
	</div>
  
	<div class="card-body card-body-list">
	  <div class="list-group" *ngIf="project">
		<div class="list-group-item"*ngFor="let user of users">
		  <div class="row align-items-center">
			<div class="col-auto">
			  <sc-avatar [user]="user" size="sm"></sc-avatar>
			</div>
  
			<div class="col">
  
			  <h6 class="mb-0 name">
				<span *ngIf="user.LastName || user.FirstName">{{ user.LastName }}, {{ user.FirstName }} <span class="text-muted small me-3">{{ user.Gender }}</span></span>
				<span class="text-muted" *ngIf="!user.LastName && !user.FirstName">Keine Namensangabe</span>
			  </h6>
  
			  <span class="small text-muted">
				{{ user.EMail }}
			  </span>
  
			</div>
  
			<div class="col-auto">
			  <button class="btn btn-sm btn-circle btn-secondary"(click)="removeUser(user)"><i class="fa-regular fa-xmark"></i></button>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
  
  <app-modal-select-person #addUserModal></app-modal-select-person>