import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { taskTypes } from 'src/pipes/task-title.pipe';
import { NotificationsService, TaskGroup, TaskSubGroup } from 'src/services/notifications.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  @Output()
  modalOpenChange = new EventEmitter<any>();

  @Output()
  allTasksDone = new EventEmitter<any>();

  selectedTaskIndex = 0;

  selectedTask: any;

  selectedSubGroup: TaskSubGroup;

  selectedGroup: TaskGroup;

  showTaskDetail = false;

  taskTypes = taskTypes;

  selectedManager: any;

  managers: any = [];

  @ViewChild('userModal')
  userModal: ScuiModalComponent;

  constructor(public notifications: NotificationsService, private router: Router, private api: ScApiService, private userService: UserService) { }

  ngOnInit(): void {
    this.api.get('companies/1/contacts').subscribe((d: any) => {
      this.managers = d;

      var managerId = localStorage.getItem('taskSelectedManagerId');
      if (!managerId) managerId = this.userService.me.Id;

      this.selectedManager = d.find(i => i.Id == managerId);
      this.notifications.managerId = this.selectedManager ? this.selectedManager.Id : 0;
      this.notifications.load(true);
    });
  }

  selectTask(subGroup: TaskSubGroup, group: TaskGroup) {

    var task = subGroup.items[0];

    this.selectedGroup = group;
    this.selectedSubGroup = subGroup;
    this.selectedTaskIndex = 0;
    this.selectedTask = task;
    this.showTaskDetail = true;

    this.navigate(task);

  }

  selectManager() {

    this.modalOpenChange.next(true);

    this.userModal.show().subscribe(
      (m: any) => {
        
        if (m == null)
        {
          this.selectedManager = null;
          this.selectedManager = m;
          localStorage.setItem('taskSelectedManagerId', "0");
          this.notifications.managerId = 0; 
        }
        else
        {
          this.selectedManager = m;
          localStorage.setItem('taskSelectedManagerId', m.Id);
          this.notifications.managerId = m.Id; 
        }
        
               
        this.notifications.load(true);

      },
      () => {
        this.modalOpenChange.next(false);
      },
      () => {
        this.modalOpenChange.next(false);
      });
  }

  navigate(task) {
    var knownType = taskTypes[task.Content];
    if (!knownType) return;
    var route = knownType.route(task);
    if (!route) return;
    this.router.navigateByUrl(route);
  }

  selectNextTask() {
    if (this.selectedTaskIndex < this.selectedSubGroup.items.length - 1) {
      this.selectedTaskIndex++;
      this.selectedTask = this.selectedSubGroup.items[this.selectedTaskIndex];
      this.navigate(this.selectedTask);
    }
  }

  selectPreviousTask() {
    if (this.selectedTaskIndex > 0) {
      this.selectedTaskIndex--;
      this.selectedTask = this.selectedSubGroup.items[this.selectedTaskIndex];
      this.navigate(this.selectedTask);
    }
  }

  done() {
    this.notifications.close(this.selectedTask).subscribe(() => {
      if (this.selectedSubGroup.items.length == 0) {
        this.showTaskDetail = false;
      }
      else if (this.selectedTaskIndex == this.selectedSubGroup.items.length) {
        this.selectedTaskIndex--;
        this.selectedTask = this.selectedSubGroup.items[this.selectedTaskIndex];
        this.navigate(this.selectedTask);
      }
      else {
        this.selectedTask = this.selectedSubGroup.items[this.selectedTaskIndex];
        this.navigate(this.selectedTask);
      }
    });
  }
}
