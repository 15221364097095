<app-topbar-candidate [candidate]="candidate"></app-topbar-candidate>

<div class="main-content">

  <div class="container" *ngIf="candidate">

    <div class="row">

      <div class="col-8 candidate-content">
        <div class="card mb-5">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Account
                </h6>
              </div>
              <div class="col-auto" *ngIf="!account">
                <button class="btn btn-round btn-dark" (click)="createAccount()">Account erstellen</button>
              </div>
              <div class="col-auto" *ngIf="account && !account.IsRegistered">
                <button class="btn btn-round btn-dark" (click)="registerAccount()">Registrieren</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" *ngIf="!account">
              <div class="col">
                <p class="text-muted">
                  Es existiert kein Account
                </p>
              </div>
            </div>
            <div class="row" *ngIf="account">
              <div class="col">
                <sc-form>
                  <sc-form-row label="Id">
                    <input [ngModel]="account.Id" readonly />
                  </sc-form-row>
                  <sc-form-row label="E-Mail">
                    <input [ngModel]="account.EMail" readonly />
                  </sc-form-row>
                  <sc-form-row label="Vorname">
                    <input [ngModel]="account.FirstName" readonly />
                  </sc-form-row>
                  <sc-form-row label="Nachname">
                    <input [ngModel]="account.LastName" readonly />
                  </sc-form-row>       
                  <sc-form-row label="Rolle">
                    <select [(ngModel)]="account.UserRole" (ngModelChange)="saveAccount()">
                      <option [ngValue]="'None'">Keine</option>
                      <option [ngValue]="'HumanResources'">HR</option>
                      <option [ngValue]="'SpecialistDepartment'">Fachabteilung</option>
                      <option [ngValue]="'Other'">Andere</option>
                    </select>
                  </sc-form-row>                  
                  <sc-form-row label="Registriert">
                    <input [ngModel]="account.IsRegistered ? 'ja' : 'nein'" readonly />
                  </sc-form-row>
                  <sc-form-row label="Registrierungslink" *ngIf="!account.IsRegistered">
                    <input [ngModel]="'https://app.skillconomy.com/register/' + account.Secret" readonly />
                  </sc-form-row>
                  <sc-form-row label="Demo-Zugang">
                    <sc-switch [(ngModel)]="account.IsDemo" (ngModelChange)="saveAccount()" />
                  </sc-form-row>
                  <sc-form-row label="KI-Account">
                    <sc-switch [(ngModel)]="account.IsAiAccount" (ngModelChange)="saveAccount()" />
                  </sc-form-row>
                </sc-form>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="projects?.length">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Projekte
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body card-body-list">
            <app-list-projects [projects]="projects"></app-list-projects>
          </div>
        </div>
      </div>

      <div class="col-4 candidate-content">
      </div>

    </div>
  </div>
</div>
