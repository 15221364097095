import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-candidate-rejected',
  templateUrl: './activity-item-candidate-rejected.component.html',
  styleUrls: ['./activity-item-candidate-rejected.component.scss']
})
export class ActivityItemCandidateRejectedComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;

  reasons = {
    "0" : "Nicht aussagekräftig",
    "1" : "Berufserfahrung",
    "2" : "Qualifikation",
    "3" : "Branche",
    "4" : "Stelle ist besetzt",
    "5" : "Andere Gründe"
  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
