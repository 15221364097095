<div class="task-container row align-items-center clickable">
  <div class="col-auto">
    <sc-avatar [user]="task.Contact" size="md"></sc-avatar>
  </div>
  <div class="col">
    <h6 class="oneline mb-0">{{ task | task }}</h6>
    <div class="text-muted oneline">{{ task.Due | humanizedDate }}</div>
    <div *ngIf="task.IsDue">
      <span  class="badge badge-pill badge-danger-soft"><i class="fa-solid fa-circle-exclamation"></i> Überfällig</span>
    </div>
  </div>
</div>

<div class="row pt-5">
  <div class="col-12">
    <div [ngSwitch]="task.Content">
      <app-task-detail-default *ngSwitchDefault [task]="task"></app-task-detail-default>
      <app-task-detail-custom *ngSwitchCase="'#custom'" [task]="task"></app-task-detail-custom>
      <app-task-detail-cadence-mail-reply *ngSwitchCase="'#cadencemailreply'" [task]="task"></app-task-detail-cadence-mail-reply>
      <app-task-detail-project-send-followup-messages *ngSwitchCase="'#projectsendfollowupmessages'" [task]="task" (done)="done.next()"></app-task-detail-project-send-followup-messages>
    </div>
  </div>
</div>
