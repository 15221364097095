<sc-modal-wizard #modal>
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <i class="me-3" class="fa-solid fa-alarm-clock"></i> 
            
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-wizard-body">
        

        <sc-modal-wizard-page #addUserPage>
            <div class="modal-body">
                <input class="form-control form-control-search mb-4" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
                <div class="user-list-container">
                    <sc-user-list [users]="candidates" (addUser)="addUser($event)" (removeUser)="removeUser($event)"></sc-user-list>
                </div>
                
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" (click)="modal.dismiss();">Fertig</button>
            </div>
        </sc-modal-wizard-page>

    </div>
</sc-modal-wizard>