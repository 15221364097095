import {
    trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const SlideInOutAnimation = [

    trigger('slideInOut', [ 
        state('in', style({transform: 'translateX(0)'})), 
        state('out', style({transform: 'translateX(-100%)'})),
        state('in-from-right', style({transform: 'translateX(0)'})), 
        state('out-to-right', style({transform: 'translateX(100%)'})), 
        transition('in => out', [group([
            style({transform: 'translateX(0)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(-100%)'}))
        ])]),
        transition('in-from-right => out', [group([
            style({transform: 'translateX(0)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(-100%)'}))
        ])]),
        transition('in => out-to-right', [group([
            style({transform: 'translateX(0)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(100%)'}))
        ])]),
        transition('in-from-right => out-to-right', [group([
            style({transform: 'translateX(0)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(100%)'}))
        ])]),
        transition('* => in', [group([
            style({transform: 'translateX(-100%)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(0)'}))
        ])]),
        transition('* => in-from-right', [group([
            style({transform: 'translateX(100%)'}),
            animate('400ms ease-in-out', style({transform: 'translateX(0)'}))
        ])])
    ])

]

export const ViewAnimation = [
    trigger('viewAnimation', [
        transition(':enter', [
          style({opacity: 0, transform: 'translateY(-100%)'}),
          animate('0.3s ease-in', style({'opacity': '1', transform: 'translateY(0%)'}))
        ]) ,
        transition(':leave', [
          style({opacity: 1, transform: 'translateY(0%)'}),
          animate('0.3s ease-out', style({'opacity': '0', transform: 'translateY(-100%)'}))
        ])
      ])

]