import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-associated-companies',
  templateUrl: './company-associated-companies.component.html',
  styleUrls: ['./company-associated-companies.component.scss']
})
export class CompanyAssociatedCompaniesComponent {
  
  @Input() 
  company : any;

  companies : any = [];

  constructor(private api : ScApiService, private router : Router)
  {}

  ngOnChanges()
  {
    if (!this.company) 
    {
      this.companies = [];
      return;
    }

    this.api.get('companies/' + this.company.Id + '/associated-companies').subscribe(d => {
      this.companies = d;
    });
  }
}
