<sc-activity-item-simple [activity]="activity" [context]="context" *ngIf="condensed">
    hat eine Einschätzung zu
    <a routerLink="/candidates/{{ activity.Candidate.Id }}">
        <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
    </a> abgegeben
</sc-activity-item-simple>

<sc-activity-item-comment [activity]="activity" [context]="context" *ngIf="!condensed">
    <div class="comment-label" *ngIf="!activity.Candidate">
        hat eine Einschätzung abgegeben (Profil gelöscht)
      </div>
    <div class="comment-label" *ngIf="activity.Candidate">
        <p class="mb-0 activity">
            hat eine Einschätzung zu
            <a routerLink="/candidates/{{ activity.Candidate.Id }}">
                <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
            </a> abgegeben
        </p>
    </div>
    <div class="comment-body">
        <div class="mb-3 mt-3">
            <sc-star-rating isReadOnly="true" [rating]="activity.Payload.Value"></sc-star-rating>
        </div>
        <p [innerHTML]="activity.Payload?.Comment"></p>
    </div>
</sc-activity-item-comment>