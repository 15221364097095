<app-topbar-candidate [candidate]="candidate"></app-topbar-candidate>

<div class="main-content">

  <div class="container" *ngIf="candidate">

    <div class="row">

      <div class="col-8 candidate-content">
        <app-candidate-base [candidateActivities]="activities" class="d-block mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-base>
        <app-candidate-skills class="d-block mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-skills>
        <app-candidate-cv class="d-block mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-cv>
      </div>

      <div class="col-4 candidate-content">
        <app-candidate-organizations class="d-block mb-3" [(organizationId)]="candidate.OrganizationId" (organizationIdChange)="saveSoon()"></app-candidate-organizations>
        <app-candidate-tasks class="d-block mb-3" [(candidate)]="candidate"></app-candidate-tasks>
        <app-candidate-cadences class="d-block mb-3" [(candidate)]="candidate"></app-candidate-cadences>     
        <app-candidate-activities #activities [candidate]="candidate"></app-candidate-activities>   
      </div>

    </div>
  </div>
</div>

<app-candidate-error-modal #errorModal>
</app-candidate-error-modal>