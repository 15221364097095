<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Chatbots
  </div>
  <div class="actions">
    <button class="btn btn-sm btn-dark btn-circle" (click)="create()">
      <i class="fa-solid fa-plus"></i>
    </button>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row mb-3">
      <div class="col-4">
        <input [(ngModel)]="q" (ngModelChange)="searchSoon()" class="form-control form-control-prepended search" placeholder="Suchen">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="card">
          <div class="card-body card-body-list">
            <div class="list-group list-group-lg list-group-flush list">
              <a class="list-group-item list-group-item-action" routerLink="/chatbots/{{chatbot.Id}}" *ngFor="let chatbot of chatbots">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <div class="avatar avatar-sm" *ngIf="chatbot.Image">
                      <img [src]="chatbot.Image" alt="Chat Image" class="avatar-img rounded">
                    </div>

                    <div class="avatar avatar-sm" *ngIf="!chatbot.Image">
                      <img src="/assets/img/illustrations/bot-illu.png" alt="Chat Image" class="avatar-img rounded">
                    </div>
                  </div>

                  <div class="col">
                    <h6 class="mb-1 oneline d-inline">
                      {{chatbot.Name}}
                    </h6>
                    <p class="card-text text-muted small mb-0 oneline">
                      {{ chatbot.Id }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
