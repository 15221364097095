import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';
import Chart from 'chart.js/auto';
import { ChartColorsService } from 'src/services/chart-colors.service';

@Component({
  selector: 'app-cadence-edit',
  templateUrl: './cadence-edit.component.html',
  styleUrls: ['./cadence-edit.component.scss']
})
export class CadenceEditComponent implements OnInit {

  id: any;

  cadence: any;

  steps: any = [];

  selectedStep: any;

  selectedVariant: any;

  editorOptions = {
    theme: 'vs-light',
    language: 'html',
    automaticLayout: true,
    wordWrap: 'on',
    codeLense: false,
    minimap: {
      enabled: false
    }
  };

  hideStats = false;

  @ViewChild('editMailStepModal')
  editMailStepModal: ModalWizardComponent;

  @ViewChild('editBaseInfoPage')
  editBaseInfoPage: ModalWizardPageComponent;

  constructor(private route: ActivatedRoute, private api: ScApiService, private colors : ChartColorsService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.api.get('cadences/' + this.id).subscribe(d => {
          this.cadence = d;
        });

        this.api.get('cadences/' + this.id + '/steps').subscribe(d => {
          this.steps = d;
        });

        this.api.get('cadences/' + this.id + '/stats').subscribe((d: any) => {

          if (!d?.length) {
            this.hideStats = true;
            return;
          }

          var datasets = [];

          d[0].StepResults.forEach((i, index) => {
            datasets.push({
              label: i.Name,
              data: d.map(i2 => i2.StepResults[index].Completions),
              backgroundColor: this.colors.getColor(index),
              borderColor: this.colors.getColor(index)
            });
          });

          new Chart('chart', {
            type: 'bar',
            data: {
              labels: d.map(i => i.Period),    
              datasets: datasets
            }
          });
        });
      }
    })
  }

  editStep(s) {
    this.selectedStep = s;
    if (s.Type == 'Mail') {
      this.editMailStepModal.navigateTo(this.editBaseInfoPage, 'none');
      this.editMailStepModal.show();
    }
  }

  saveStep(s) {
    this.api.put('cadences/' + this.id + '/steps', s).subscribe(d => { });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);

    var i = 0;
    this.steps.forEach(s => {
      s.OrderIndex = i;
      this.saveStep(s);
      i++;
    });
  }

  addVariant() {
    if (!this.selectedStep) return;
    if (!this.selectedStep.Payload) this.selectedStep.Payload = { Variants: [] };

    this.selectedStep.Payload.Variants.push({
      Title: 'Neue Variante',
      Frequency: 1
    });
  }

  deleteVariant(v) {
    var i = this.selectedStep.Payload.Variants.indexOf(v);
    this.selectedStep.Payload.Variants.splice(i, 1);
    this.selectedVariant = null;
  }

}
