<ng-template [appTopbarTemplate]>
  <div class="title">
    <h4 class="mb-0">Retention Dashboard</h4>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <!-- <div class="row mb-4">
		<div class="col-3">
		  <app-card-kpi 
			label="Umsatz"
			[value]="turnoverData?.CurrentYear" 
			[trend]="turnoverData?.Trend">
		  </app-card-kpi>
		</div>
  
		<div class="col-3">
		  <app-card-kpi 
			label="Umsatz im Vergleichszeitraum"
			[value]="turnoverData?.LastYearUntilCurrentDate" >
		  </app-card-kpi>
		</div>
  
		<div class="col-3">
		  <app-card-kpi 
			label="Umsatz Vorjahr"
			[value]="turnoverData?.LastYear" >
		  </app-card-kpi>
		</div>
  
		<div class="col-3">
		  <app-card-kpi 
			label="Forecast"
			[value]="turnoverData?.Forecast" >
		  </app-card-kpi>        
		</div>
  
	  </div> -->
    <div class="row">

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Reaktivierung
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="statusChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

	  <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Reaktivierungsrate
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="reactivationRateChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Deaktivierung
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="deativatedChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

	  <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Deaktivierungsrate
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="deactivationRateChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Verlust
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="lostChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

	  <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Verlustrate
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="lossRateChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Kundenbasis
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="closedChart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
