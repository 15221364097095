<ng-template [appTopbarTemplate]>
    <div class="row align-items-center g-0" *ngIf="company">            
      <div class="col d-none d-md-block">
        <h4 class="mb-0" *ngIf="!company.Profile?.LogoUri">{{ company.Name }} - Rechnungsstellung</h4>
        <img class="logo" *ngIf="company.Profile?.LogoUri" [src]="company.Profile?.LogoUri" />
      </div>
      <div class="col-auto mt-md-1">
        <app-nav-company [company]="company"></app-nav-company>
      </div>
      <div class="col-auto mt-md-1 pl-4">
        <button class="btn btn-sm btn-danger btn-circle" (click)="deleteModal.show()"><i class="fa-regular fa-trash"></i></button>
      </div>
    </div>
  </ng-template>

  
