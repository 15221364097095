<div>

  <div class="card mb-3">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Call
          </h6>
        </div>
        <!-- <div class="col-auto" *ngIf="logWizard.currentPage == startPage">
          <button class="btn btn-sm btn-dark btn-round me-2" [disabled]="!candidate?.Phone" (click)="callPhone();"><i class="fa-solid fa-phone me-2"></i> Festnetz</button>
          <button class="btn btn-sm btn-dark btn-round" [disabled]="!candidate.Mobile" (click)="callMobile();"><i class="fa-solid fa-phone me-2"></i> Mobil</button>
        </div> -->

      </div>
    </div>

    <div class="card-body">

      <sc-wizard #logWizard>

        <sc-modal-wizard-page #startPage>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-secondary btn-round me-2 mb-2" [disabled]="!candidate?.Phone" (click)="callPhone();"><i class="fa-solid fa-phone me-2"></i> Festnetz wählen</button>
              <button class="btn btn-secondary btn-round me-2 mb-2" [disabled]="!candidate.Mobile" (click)="callMobile();"><i class="fa-solid fa-phone me-2"></i> Mobil wählen</button>
              <button class="btn btn-secondary btn-round me-2 mb-2" (click)="logWizard.navigateTo(logPage);">Nachträglich loggen</button>
            </div>
          </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page [definesHeight]="true" #logPage>

          <div class="row">
            <div class="col-12">
              <textarea class="form-control" rows="4" [(ngModel)]="comment" placeholder="Zusatzinfos, falls erreicht"></textarea>
            </div>

            <div class="col-12 mt-4">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="selectOutcome('NotReached')" checked>
                <label class="form-check-label" for="flexRadioDefault1">
                  Nicht erreicht
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="selectOutcome('Success')">
                <label class="form-check-label" for="flexRadioDefault2">
                  Erfolg
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" (click)="selectOutcome('Cancelled');">
                <label class="form-check-label" for="flexRadioDefault3">
                  Abbruch
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" (click)="selectOutcome('Redirected');">
                <label class="form-check-label" for="flexRadioDefault4">
                  Weiterleitung
                </label>
              </div>
              <!-- <button class="btn btn-secondary me-2 mb-2" (click)="setNextSuccessStatus(); logWizard.navigateTo(logSuccessPage)">Erfolg</button>
              <button class="btn btn-secondary me-2 mb-2" (click)="logWizard.navigateTo(logNotReachedPage)">Nicht erreicht</button>
              <button class="btn btn-secondary me-2 mb-2" (click)="logWizard.navigateTo(cancelPage)">Abbruch</button>
              <button class="btn btn-secondary me-2 mb-2" (click)="selectRedirectPerson()">Weiterleitung</button> -->
            </div>

            <div class="col-12 mt-4">
              <select class="custom-select" [(ngModel)]="newStatus">
                <option ngValue="None">Kein Status</option>
                <option ngValue="New">Qualified</option>
                <option ngValue="WarmDirect">Warm</option>
                <option ngValue="Scheduled">Terminiert</option>
                <option ngValue="Closing">Closing</option>
                <option ngValue="Upselling">Upselling</option>
                <option ngValue="Disqualified">Disqualified</option>
              </select>
            </div>

            <div class="col-12 mt-2" *ngIf="outcome != 'Redirected'">
              <sc-date-picker-inline [(date)]="onHoldDate"></sc-date-picker-inline>
            </div>

            <div class="col-12 mt-2" *ngIf="outcome == 'Redirected'">
              <app-inline-select-person [canSetNull]="true" [(personId)]="redirectPersonId"></app-inline-select-person>
            </div>

          </div>

          <div class="row mt-5 align-items-center">
            <div class="col-auto">
              <button class="btn btn-sm btn-circle btn-secondary" (click)="logWizard.navigateTo(startPage)">
                <i class="fa-sharp fa-solid fa-chevron-left"></i>
              </button>

            </div>
            <div class="col">

            </div>
            <div class="col-auto">
              <button class="btn btn-round btn-dark" (click)="proceed()">Log Call</button>
            </div>
          </div>

        </sc-modal-wizard-page>

        <sc-modal-wizard-page #confirmationPage>
          <p>
            Der Call wurde geloggt.
          </p>

          <div class="row mt-2">
            <div class="col-12">
              <button class="btn btn-dark me-2 mb-2" (click)="goToNextLead();">Nächster Lead</button>
              <button class="btn btn-dark me-2 mb-2" *ngIf="redirectPersonId" (click)="goToRedirectLead();">Zur Weiterleitung</button>
            </div>
          </div>

        </sc-modal-wizard-page>

      </sc-wizard>
    </div>

    <!-- <div class="card-footer">
      <div class="row">
        <div class="col"></div>
        <div class="col-auto">
          <button class="btn btn-round btn-dark" [disabled]="logWizard.currentPage != startPage" (click)="proceed()">Log Call</button>
        </div>
      </div>
    </div> -->
  </div>
</div>

<app-modal-select-person #selectPersonModal></app-modal-select-person>
