import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss']
})
export class ListInvoicesComponent {

  @Input()
  invoices : any = [];

}
