<ng-template [appTopbarTemplate]>

  <div class="title h4">
    Projekte
  </div>

  <div class="actions">
    
    <div class="d-inline-block me-2" ngbDropdown>
      <button ngbDropdownToggle class="btn btn-sm btn-secondary btn-round">
        <i class="fa-solid fa-sort me-2"></i> <span *ngIf="settings.sort == 'id'">Neuste zuerst</span> <span *ngIf="settings.sort == 'due'">Höchste Fälligkeit zuerst</span>
      </button>
      <div ngbDropdownMenu>
        <a ngbDropdownItem (click)="settings.sort = 'id'; loadResults();">Neuste zuerst</a>
        <a ngbDropdownItem (click)="settings.sort = 'due'; loadResults();">Höchste Fälligkeit zuerst</a>
      </div>
    </div>

    <button class="btn btn-sm btn-secondary btn-circle me-2" (click)="filterModal.show()">
      <i class="fa-solid fa-filter"></i>
    </button>

    <button class="btn btn-sm btn-secondary btn-circle" (click)="newProjectModal.show()">
      <i class="fa-solid fa-plus"></i>
    </button>

  </div>

</ng-template>

<div class="main-content">

  <div class="container" *ngIf="projects">

    <div class="row mb-3">
      <div class="col-12 mb-n2">
        <app-projects-filter-badges [settings]="settings" (changed)="loadResults()"></app-projects-filter-badges>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <input type="search" [(ngModel)]="settings.q" (ngModelChange)="qChanged($event)" class="form-control search" placeholder="Suchen">
      </div>
    </div>

    <div class="row">

      <div class="col-12">
        <app-list-projects [projects]="projects" [showMore]="hasMoreItems" (moreClick)="loadMoreResults()" [isLoadingMore]="isLoading"> </app-list-projects>
      </div>
    </div>

  </div>

</div>

<sc-modal #newProjectModal>
  <div class="modal-header">
    <h4 class="modal-title">Neues Projekt</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="newProjectModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <label>Stellenbezeichnung</label>
      </div>
      <div class="col-12">
        <input class="form-control" [(ngModel)]="newProject.Name" placeholder="ohne (m/w/d)" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        Unternehmen:
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="input-group input-group-flush input-group-merge">
          <input type="search" [(ngModel)]="qCompany" (ngModelChange)="searchCompaniesSoon()" class="form-control form-control-prepended search" placeholder="Name des Unternehmens eingeben">
          <div class="input-group-text">
            <i class="fa-regular fa-magnifying-glass"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="height: 30vh;">
          <div *ngFor="let company of companies" (click)="selectCompany(company)" [ngClass]="{'text-primary fw-bold': company.Id == newProject.CompanyId}">
            {{company.Name}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-dark" (click)="addProject(); newProjectModal.dismiss();">OK</button>
  </div>
</sc-modal>

<app-projects-filter-modal #filterModal [settings]="settings" (changed)="loadResults()"></app-projects-filter-modal>
