<div *ngIf="projects?.length">
  <div class="card mb-3">
    <div class="card-header">
      <h6 class="card-header-title">
        Projekte
	  </h6>
    </div>
    <div class="card-body card-body-list">
      <app-list-projects [showDue]="false" [showGuarantee]="false" [projects]="projects" [showMore]="hasMoreProjects" (moreClick)="openOrganizationProjects()"></app-list-projects>
	  <app-list-loader></app-list-loader>
    </div>
  </div>
</div>
