import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-activity-item-candidate-status-update',
  templateUrl: './activity-item-candidate-status-update.component.html',
  styleUrls: ['./activity-item-candidate-status-update.component.scss']
})
export class ActivityItemCandidateStatusUpdateComponent {

  displayNames = {
    None : "Kein Status",
    AwaitingInformation : "Informationen angefragt",
    AwaitingFeedback: "Warte auf Feedback",
    AwaitingFirstInterview: "Erstgespräch",
    AwaitingSecondInterview: "Zweitgespräch",
    Offer: "Angebot unterbreitet  ",
    Hired: "Eingestellt",
    Ended: "Keine Einstellung"
  }
  
  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;
  
  constructor() { }

  ngOnInit(): void {
  }
}
