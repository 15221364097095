import { Component, HostBinding, Input } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-candidate-tasks',
  templateUrl: './candidate-tasks.component.html',
  styleUrls: ['./candidate-tasks.component.scss']
})
export class CandidateTasksComponent {

  @Input()
  candidate: any;

  tasks : any = [];

  constructor(public notifications: NotificationsService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
      this.tasks = this.notifications.tasks.getTasksOfPerson(this.candidate.Guid);
  }
}
