import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';

@Component({
  selector: 'app-candidate-account',
  templateUrl: './candidate-account.component.html',
  styleUrls: ['./candidate-account.component.scss']
})
export class CandidateAccountComponent {
  
  id : any;

  candidate: any;

  account : any;

  projects : any = []

  constructor(private api: ScApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      
      this.id = params.id;

      this.api.get('candidates/' + params.id + '/portal-accounts').subscribe((d : any) => {
        if (d.Exists)
        {
          this.account = d.Account;

          this.api.get('portal-accounts/' + this.account.Id + '/projects').subscribe((d : any) => {
            this.projects = d;
          });
        }
        
      });

      this.api.get('candidates/' + params.id).subscribe((d: any) => {
        this.candidate = d;
      });
    });
  }

  createAccount()
  {
    this.api.post('candidates/' + this.id + '/portal-accounts', null).subscribe(d => {
      this.account = d;
    });
  }

  registerAccount()
  {
    this.api.post('candidates/' + this.id + '/portal-accounts/register', null).subscribe(d => {
      this.account = d;
    });
  }

  saveAccount() {
    this.api.put('candidates/' + this.id + '/portal-accounts', this.account).subscribe();
  }

}
