import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScuiModalComponent, ScApiService } from '@sc-ui';
import { ModalSelectPersonComponent } from 'src/components/modal-select-person/modal-select-person.component';

@Component({
  selector: 'app-project-quote-recipients',
  templateUrl: './project-quote-recipients.component.html',
  styleUrls: ['./project-quote-recipients.component.scss']
})
export class ProjectQuoteRecipientsComponent {

  @Input()
  project: any;

  users: any = [];

  selectedUser: any;

  companyUsers: any = [];

  contacts: any = [];

  qContact = '';

  admins: any = [];

  isSendingInvitation: boolean = false;

  eMail: string = "";

  success = false;

  successEMail = "";

  isRemoving = false;

  @ViewChild('editUsersModal')
  editUsersModal: ScuiModalComponent;

  @ViewChild('addUserModal')
  addUserModal: ModalSelectPersonComponent;

  constructor(private api: ScApiService, private router : Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && this.project) {
      this.load();
    }
  }

  load() {
    this.api.get('jobs/' + this.project.Id + '/quote-recipients').subscribe(d => {
      this.users = d;

      this.api.get("companies/" + this.project.CompanyId + "/people").subscribe((d: any) => {
        this.contacts = d.filter(i => !this.users.some(u => u.Id == i.Id));
      });
    });
  }

  navigateToProfile(u)
  {
    this.router.navigateByUrl('/candidates/' + u.Guid);
  }

  addUser() {

    this.addUserModal.show(this.contacts).subscribe((p: any) => {
      
      this.api.post("jobs/" + this.project.Id + "/quote-recipients", p).subscribe(
        (d: any) => {
          if (!d) return;
          this.users.push(d);
        });
    });
  }

  removeUser(u) {

    this.isRemoving = true;

    this.api.delete("jobs/" + this.project.Id + "/quote-recipients/" + u.Id).subscribe(
      d => {
        var index = this.users.indexOf(u);
        this.users.splice(index, 1);
        this.editUsersModal.dismiss();
      },
      e => { },
      () => {
        this.isRemoving = false;
      })

  }
}
