import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-analysis-completed',
  templateUrl: './activity-item-project-analysis-completed.component.html',
  styleUrls: ['./activity-item-project-analysis-completed.component.scss']
})
export class ActivityItemProjectAnalysisCompletedComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;
  
  constructor() { }

  ngOnInit(): void {
  }


}
