<div class="card">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Rechnungsinformationen
        </h6>
      </div>
      <div class="col-auto">
        <button class="btn btn-secondary btn-sm" (click)="addModal.show()"><i class="fa-regular fa-plus me-2"></i> Rechnungsadresse</button>
      </div>
    </div>
  </div>

  <div class="card-body">

    <sc-form (change)="onChange()" *ngIf="!company.BillingType">
      <sc-form-row label="Abrechnungsart">
        <select [(ngModel)]="company.BillingType">
          <option value="Direct">Direkt</option>
          <option value="OtherOrganization">Andere Organisation</option>
        </select>
      </sc-form-row>
    </sc-form>

    <sc-form (change)="onChange()" *ngIf="company.BillingType == 'OtherOrganization'">    
      <sc-form-row label="Abrechnungsart">
        <select [(ngModel)]="company.BillingType">
          <option value="Direct">Direkt</option>
          <option value="OtherOrganization">Andere Organisation</option>
        </select>
      </sc-form-row>  
      <sc-form-row label="Rechnungsempfänger">
        <app-inline-select-organization [canSetNull]="true" [(organizationId)]="company.DefaultBillingCompanyId" (organizationIdChange)="company.DefaultQuoteRecipientId = 0; company.DefaultBillingAddressId = 0; onChange()"></app-inline-select-organization>
      </sc-form-row>
      <sc-form-row label="Rechnungsadresse" [disabled]="!company.DefaultBillingCompanyId">
        <app-inline-select-billing-address [organizationId]="company.DefaultBillingCompanyId" [(addressId)]="company.DefaultBillingAddressId" (addressIdChange)="onChange()"></app-inline-select-billing-address>
      </sc-form-row>
      <sc-form-row label="Angebotsempfänger" [disabled]="!company.DefaultBillingCompanyId">
        <app-inline-select-person [organizationId]="company.DefaultBillingCompanyId" [canSetNull]="true" [allowCreate]="false" [allowSearch]="false" [(personId)]="company.DefaultQuoteRecipientId" (personIdChange)="onChange()"></app-inline-select-person>
      </sc-form-row>
    </sc-form>

    <sc-form (change)="onChange()" *ngIf="company.BillingType == 'Direct'">      
      <sc-form-row label="Abrechnungsart">
        <select [(ngModel)]="company.BillingType">
          <option value="Direct">Direkt</option>
          <option value="OtherOrganization">Andere Organisation</option>
        </select>
      </sc-form-row>
      <sc-form-row label="Lieferantennr.">
        <input [(ngModel)]="company.SupplierId" />
      </sc-form-row>
      <sc-form-row label="Bestellnr.">
        <sc-switch [(ngModel)]="company.IsOrderNumberRequired"></sc-switch>
      </sc-form-row>
      <sc-form-row label="Rabatt">
        <input [(ngModel)]="company.DefaultDiscount" />
      </sc-form-row>
      <sc-form-row label="Rabatt-Text">
        <input [(ngModel)]="company.DefaultDiscountText" />
      </sc-form-row>
      <sc-form-row label="Steuersatz">
        <input [(ngModel)]="company.DefaultTaxRate" />
      </sc-form-row>
      <sc-form-row label="Anmerkungen">
        <autosize-textarea [(text)]="company.Conditions"></autosize-textarea>
      </sc-form-row>
      <sc-form-row label="Rechnungsadressen">
        <app-list-billing-address [billingAddresses]="billingAddresses" (selected)="selectedBillingAddress = $event; editModal.show();"></app-list-billing-address>
      </sc-form-row>
    </sc-form>
  </div>
</div>

<sc-modal #addModal>

  <sc-modal-header>
    Neue Rechnungsadresse
  </sc-modal-header>

  <div class="modal-body" *ngIf="addModal.isOpen">
    <div class="row mb-2">
      <div class="col-3">
        E-Mail
      </div>
      <div class="col-9">
        <input class="form-control" [(ngModel)]="newBillingAddress.EMail" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label>Adresse</label>
      </div>
      <div class="col-9">
        <textarea class="form-control" [(ngModel)]="newBillingAddress.Address" rows="5"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" [disabled]="!newBillingAddress.Address" (click)="addBillingAddress();addModal.dismiss();">Hinzufügen</button>
  </div>
</sc-modal>

<sc-modal #editModal>
  <sc-modal-header>
    Rechnungsadresse bearbeiten
  </sc-modal-header>
  <div class="modal-body" *ngIf="editModal.isOpen">
    <div class="row mb-2">
      <div class="col-3">
        E-Mail
      </div>
      <div class="col-9">
        <input class="form-control" [(ngModel)]="selectedBillingAddress.EMail" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label>Adresse</label>
      </div>
      <div class="col-9">
        <textarea class="form-control" [(ngModel)]="selectedBillingAddress.Address" rows="5"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="company?.DefaultBillingAddressId == selectedBillingAddress?.Id" class="btn btn-secondary" disabled><i class="fa-regular fa-check"></i> Standard</button>
    <button *ngIf="company?.DefaultBillingAddressId != selectedBillingAddress?.Id" class="btn btn-secondary" (click)="makeDefaultBillingAddress(selectedBillingAddress)">Als Standard verwenden</button>
    <button class="btn btn-secondary" (click)="deleteSelectedBillingAddress();">Löschen</button>
    <button class="btn btn-dark" (click)="saveSelectedBillingAddress();editModal.dismiss();">Speichern</button>
  </div>
</sc-modal>
