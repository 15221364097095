import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';

@Component({
  selector: 'app-candidate-sourcing',
  templateUrl: './candidate-sourcing.component.html',
  styleUrls: ['./candidate-sourcing.component.scss']
})
export class CandidateSourcingComponent {
  
  @Input()
  candidate: any;
  
  private _savedCandidate: any;

  constructor(private api: ScApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.api.get('candidates/' + params.id).subscribe((d: any) => {
        this.candidate = d;
        this._savedCandidate = _.cloneDeep(d);
      });
    });
  }
}
