<div [scAnchorTarget]="'Lebenslauf'" *ngIf="candidate">

  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">Berufserfahrung</h6>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-circle btn-secondary" (click)="addExperience()">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card-body card-body-list">
      <ul class="list-group" cdkDropList [cdkDropListData]="candidate.Profile.Experience" (cdkDropListDropped)="onDrop($event)">

        <li class="list-group-item text-muted" *ngIf="!candidate.Profile.Experience?.length">
          Keine Angaben
        </li>

        <li *ngFor="let entry of candidate.Profile.Experience" (click)="selectedExperience = entry; editExperienceModal.show();" cdkDrag class="list-group-item clickable border-none">

          <div class="row">

            <div class="col-8">
              <h6 class="mb-1">{{entry.Title}}</h6>
              <p class="mb-0 fw-normal">{{entry.CompanyName}}</p>

              <p class="mt-2 mb-0 fw-normal cv-description" *ngIf="entry.Description">{{ entry.Description }}</p>
            </div>

            <div class="col-4 fw-normal">

              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start != entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis {{ entry.End | date:'MM/y'}}<br>
                <span>{{ entry.Start | humanizedDateDiff : entry.End }}</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start == entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>ca. ein Monat</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && !entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>unbekannte Dauer</span>
              </p>

              <p *ngIf="entry.ToPresent && entry.Start" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis heute<br>
                <span *ngIf="entry.Start">{{ entry.Start | humanizedDateDiff }}</span>
              </p>

              <p *ngIf="entry.ToPresent && !entry.Start" class="text-right mb-0">
                bis heute<br>
                <span *ngIf="entry.Start">unbekannte Dauer</span>
              </p>

            </div>

          </div>

        </li>

      </ul>
    </div>

  </div>
  <div class="card">

    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">Ausbildung</h6>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-circle btn-secondary" (click)="addEducation()">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card-body card-body-list">

      <ul class="list-group" cdkDropList [cdkDropListData]="candidate.Profile.Education" (cdkDropListDropped)="onDrop($event)">

        <li class="list-group-item text-muted border-none" *ngIf="!candidate.Profile.Education?.length">
          Keine Angaben border-none
        </li>
        <li *ngFor="let entry of candidate.Profile.Education" (click)="selectedEducation = entry; editEducationModal.show();" cdkDrag class="list-group-item clickable border-none">

          <div class="row">

            <div class="col-8">
              <h6 class="mb-1">{{entry.Title}}</h6>              
              <p class="mb-0 fw-normal">{{entry.Institute}}</p>              
            </div>

            <div class="col-4 fw-normal">

              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start != entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis {{ entry.End | date:'MM/y'}}<br>
                <span>{{ entry.Start | humanizedDateDiff : entry.End }}</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start == entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>ca. ein Monat</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && !entry.End" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>unbekannte Dauer</span>
              </p>

              <p *ngIf="entry.ToPresent && entry.Start" class="text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis heute<br>
                <span *ngIf="entry.Start">{{ entry.Start | humanizedDateDiff }}</span>
              </p>

              <p *ngIf="entry.ToPresent && !entry.Start" class="text-right mb-0">
                bis heute<br>
                <span *ngIf="entry.Start">unbekannte Dauer</span>
              </p>

            </div>

          </div>

        </li>

      </ul>
    </div>

    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">Qualifikationen</h6>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-circle btn-secondary" (click)="addQualification()">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card-body card-body-list">

      <ul class="list-group" cdkDropList [cdkDropListData]="candidate.Profile.Qualifications" (cdkDropListDropped)="onDrop($event)">

        <li class="list-group-item text-muted border-none" *ngIf="!candidate.Profile.Qualifications?.length">
          Keine Angaben
        </li>

        <li *ngFor="let entry of candidate.Profile.Qualifications" (click)="selectedQualification = entry; editQualificationModal.show();" cdkDrag class="list-group-item clickable border-none">

          <div class="row">

            <div class="col-8">
              <h6 class="mb-1">{{entry.Title}}</h6>
              <p class="mb-0">{{entry.QualificationTitle}}</p>
              <p class="mb-0 fw-normal">{{entry.Institute}}</p>
            </div>

            <div class="col-4 fw-normal">

              <p *ngIf="entry.Date" class="text-right mb-0">
                {{ entry.Date | date:'MM/y'}}<br>
                <span>{{ entry.Date | humanizedDate }}</span>
              </p>

              <p *ngIf="!entry.Date" class="text-right mb-0">
                Unbekannt<br>
                <span>Unbekannte Dauer</span>
              </p>

            </div>

          </div>

        </li>

      </ul>
    </div>

    <div class="card-body">
      <sc-file-button *ngIf="!candidate.CvFile" (filesChanged)="uploadCv($event)">
        <div class="btn btn-sm btn-secondary">
          <i class="fa-regular fa-cloud-arrow-up me-2"></i> CV hochladen
        </div>
      </sc-file-button>

      <div ngbDropdown placement="bottom-right" *ngIf="candidate.CvFile">
        <div ngbDropdownToggle>
          <sc-item-file name="Lebenslauf.pdf" type="pdf" size="1.3 MB"></sc-item-file>
        </div>
        <div ngbDropdownMenu>
          <sc-file-button ngbDropdownItem (filesChanged)="uploadCv($event)">
            <i class="fa-regular fa-cloud-arrow-up"></i> Austauschen
          </sc-file-button>
          <button ngbDropdownItem (click)="downloadCv()">
            <i class="fa-regular fa-cloud-arrow-down"></i> Download
          </button>
          <button ngbDropdownItem (click)="deleteCv()" class="text-danger">
            <i class="fa-regular fa-trash"></i> Löschen
          </button>
        </div>

      </div>
      <sc-item-file *ngFor="let f of candidate.Files" [name]="f.Name" [uri]="f.Uri" [type]="f.Type" size="1.3 MB" openOnClick="true"></sc-item-file>
    </div>
  </div>
</div>

<sc-modal #editExperienceModal>
  <div class="modal-header">
    Berufserfahrung bearbeiten
  </div>
  <div class="modal-body" *ngIf="selectedExperience">
    <div class="row mb-2">
      <div class="col-3">
        Von
      </div>
      <div class="col-9">
        <month-year-date-input [(isoDate)]="selectedExperience.Start" (isoDateChange)="onChange()">
        </month-year-date-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Bis
      </div>
      <div class="col-9">
        <month-year-date-input [(isoDate)]="selectedExperience.End" (isoDateChange)="setToPresent(selectedExperience); onChange()">
        </month-year-date-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Titel
      </div>
      <div class="col-9">
        <input class="form-control"[(ngModel)]="selectedExperience.Title" placeholder="Bezeichnung" (ngModelChange)="onChange()" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Unternehmen
      </div>
      <div class="col-9">
        <input class="form-control" [(ngModel)]="selectedExperience.CompanyName" placeholder="Unternehmen" (ngModelChange)="onChange()" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Beschreibung
      </div>
      <div class="col-9">
        <textarea class="form-control" rows="6" [(ngModel)]="selectedExperience.Description" placeholder="Unternehmen" (ngModelChange)="onChange()"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="removeExperience(selectedExperience); editExperienceModal.dismiss();">Löschen</button>
    <button class="btn btn-secondary" (click)="editExperienceModal.dismiss();">Ok</button>
  </div>
</sc-modal>

<sc-modal #editEducationModal>
  <div class="modal-header">
    Berufsausbildung bearbeiten
  </div>
  <div class="modal-body" *ngIf="selectedEducation">
    <div class="row mb-2">
      <div class="col-3">
        Von
      </div>
      <div class="col-9">
        <month-year-date-input [(isoDate)]="selectedEducation.Start" (isoDateChange)="onChange()">
        </month-year-date-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Bis
      </div>
      <div class="col-9">
        <month-year-date-input [(isoDate)]="selectedEducation.End" (isoDateChange)="setToPresent(selectedEducation); onChange()">
        </month-year-date-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Titel
      </div>
      <div class="col-9">
        <input class="form-control" name="selectedEducation.Title" [(ngModel)]="selectedEducation.Title" (ngModelChange)="onChange()" placeholder="Bezeichnung" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Institution
      </div>
      <div class="col-9">
        <input class="form-control" name="selectedEducation.Institute" [(ngModel)]="selectedEducation.Institute" (ngModelChange)="onChange()" placeholder="Organisation" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="removeEducation(selectedEducation); editEducationModal.dismiss();">Löschen</button>
    <button class="btn btn-secondary" (click)="editEducationModal.dismiss();">Ok</button>
  </div>
</sc-modal>

<sc-modal #editQualificationModal>
  <div class="modal-header">
    Qualifikation bearbeiten
  </div>
  <div class="modal-body" *ngIf="selectedQualification">
    <div class="row mb-2">
      <div class="col-3">
        Datum
      </div>
      <div class="col-9">
        <month-year-date-input size="sm" [(isoDate)]="selectedQualification.Date" (isoDateChange)="onChange()">
        </month-year-date-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Titel
      </div>
      <div class="col-9">
        <input class="form-control" name="selectedQualification.Title" [(ngModel)]="selectedQualification.Title" (ngModelChange)="onChange()" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Institution
      </div>
      <div class="col-9">
        <input class="form-control" name="selectedQualification.Institute" (ngModelChange)="onChange()" [(ngModel)]="selectedQualification.Institute" p />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="removeQualification(selectedQualification); editQualificationModal.dismiss();">Löschen</button>
    <button class="btn btn-secondary" (click)="editQualificationModal.dismiss();">Ok</button>
  </div>
</sc-modal>
