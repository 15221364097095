<sc-activity-item-simple [activity]="activity" [context]="context" *ngIf="condensed">
    hat den Status von
    <a routerLink="/candidates/{{ activity.Candidate.Id }}">
        <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
    </a> aktualisiert
</sc-activity-item-simple>

<sc-activity-item-comment [activity]="activity" [context]="context" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat den Status von
            <a routerLink="/candidates/{{ activity.Candidate.Id }}">
                <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
            </a> aktualisiert
        </p>
    </div>
    <div class="comment-body">        
        <p>
			Alter Status: {{ displayNames[activity.Payload?.OldStatus] }} <br>
			Neuer Status: {{ displayNames[activity.Payload?.NewStatus] }}
		</p>
    </div>
</sc-activity-item-comment>