import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'sc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input()
  label = ''

  @Input() 
  isChecked: boolean;

  @Output()
  isCheckedChange = new EventEmitter<any>();

  @Input()
  hasNullState = false;
  
  elementId: string;

  ngOnInit() {
    var S4 = function () { return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); };
    var generateId = function (length) {
      var id = "";
      for (var i = 0; i < length; i++) id += S4();
      return id;
    }

    this.elementId = 'check_' + generateId(4);
  }

  toggle()
  {
    if (!this.hasNullState)
      this.isChecked = !this.isChecked;

    else
    {
      if (this.isChecked)
      {
        this.isChecked = false;
      }
      else if (this.isChecked == false)
      {
        this.isChecked = null;
      }
      else
      {
        this.isChecked = true;
      }
    }

    this.isCheckedChange.emit(this.isChecked);

  }

  

}
