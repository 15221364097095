<div class="row" cdkDropList [cdkDropListData]="entries" (cdkDropListDropped)="onDrop($event);">
    <div class="col-12 mb-2" cdkDrag *ngFor="let e of entries">
        <div class="row">
            <div class="col">
                <div class="input-group input-group-sm has-validation">
            <input class="form-control form-control-sm" [class.is-invalid]="e.isInvalid" [(ngModel)]="e.Content" (ngModelChange)="updateProject()" />
            <div class="invalid-feedback" style="height: auto;" *ngIf="e.error">
                {{ e.error }}
            </div>
        </div>
            </div>
            <div class="col-auto">
                <button (click)="deleteEntry(e)" class="btn btn-sm btn-circle"><i class="fa-regular fa-trash text-danger"></i></button>
            </div>
        </div>
        
    </div>
    <div class="col-12 mb-2">
        <button (click)="addEntry()" class="btn btn-secondary btn-sm btn-circle"><i class="fa-regular fa-plus"></i></button>
    </div>
</div>