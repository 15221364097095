<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Rechnungen
            </h6>
          </div>
          <div class="card-body card-body-list">
            <app-list-invoices [invoices]="invoices"></app-list-invoices>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>