<sc-modal #modal>
	<sc-modal-header>
	  Pool auswählen
	</sc-modal-header>
	<sc-modal-body>
  
	  <div>
		<input class="form-control search mb-2" [(ngModel)]="q" (ngModelChange)="searchSoon()" />
	  </div>
	  <div class="list-group">
		<div (click)="modal.complete(pool)" class="list-group-item list-group-item-action" *ngFor="let pool of pools">
		  {{ pool.Name }}
		</div>
	  </div>
	</sc-modal-body>
  </sc-modal>
  