<sc-modal #modal>
  <sc-modal-header>
    Rechnungsadressen
  </sc-modal-header>

  <sc-modal-body class="modal-body-list">

    <p class="p-3" *ngIf="!addresses.length">
      Keine Adressen gefunden
    </p>

	<app-list-billing-address [billingAddresses]="addresses" (selected)="modal.complete($event)"></app-list-billing-address>

  </sc-modal-body>
</sc-modal>
