import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-contact-edit',
  templateUrl: './modal-contact-edit.component.html',
  styleUrls: ['./modal-contact-edit.component.scss']
})
export class ModalContactEditComponent implements OnInit {

  contactId;

  contact : any;

  saveContactError = "";

  locations : any = [];

  @Output()
  saved = new EventEmitter<any>();

  @ViewChild('contactModal')
  contactModal : ScuiModalComponent;

  constructor(private api : ScApiService) { }

  ngOnInit(): void { }
  
  save() {
    this.saveContactError = "";

    this.api.put("contacts/" + this.contact.Id, this.contact).subscribe(d => {
      this.saved.emit(d);
    }, (e : any) => {
      this.saveContactError = e.error.Message;
    });
  }

  show(contactId)
  {
    if (!contactId) {
      
      return;
    }


    if (this.contactId == contactId)
    {
      this.contactModal.show();
      this.loadLocations();
      return;
    }

    this.contactId = contactId;

    this.api.get(`contacts/${this.contactId}`).subscribe(d => {
      this.contact = d;
      this.contactModal.show();
      this.loadLocations();
    });    
  }

  loadLocations()
  {
    this.api.get("companies/" + this.contact.CompanyId + '/locations').subscribe(d => {
        this.locations = d;          
      });
  }
}
