<sc-modal #modal>
	<sc-modal-header>
	  {{ headline }}
	</sc-modal-header>
	<sc-modal-body class="modal-body-list">
	  <div class="list-group">
		<a class="list-group-item list-group-item-action" (click)="modal.complete();">
		  <div class="row align-items-center flex-nowrap" *ngIf="canSelectAll">
			<div class="col-auto">
			  <div class="avatar avatar-sm">
				<span class="avatar-icon">
				  <i class="fa-regular fa-users"></i>
				</span>
			  </div>
			</div>
			<div class="col">
			  <h6 class="mb-1 oneline d-inline">
				Alle
			  </h6>
			</div>
		  </div>
		</a>
		<a class="list-group-item d-none"></a>
	  </div>
	  <app-list-people [people]="managers" (selected)="modal.complete($event);"></app-list-people>
	</sc-modal-body>
  </sc-modal>