import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { CandidateActivitiesComponent } from '../../_shared/candidate-activities/candidate-activities.component';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-candidate-base',
  templateUrl: './candidate-base.component.html',
  styleUrls: ['./candidate-base.component.scss'],
  providers: [NgbDropdown]
})
export class CandidateBaseComponent implements OnInit, OnChanges {

  @Input()
  candidate: any;

  @Input()
  candidateActivities: CandidateActivitiesComponent;

  @Output()
  candidateChange = new EventEmitter<any>();

  linkedInSearchUri = '';

  xingSearchUri = '';

  constructor(private api: ScApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.api.get('candidates/' + this.candidate.Id + '/duplicates').subscribe();
    this.linkedInSearchUri = encodeURI('https://www.linkedin.com/search/results/all/?keywords=' + this.candidate.FirstName + ' ' + this.candidate.LastName + ' ' + this.candidate.Company);
    this.xingSearchUri = 'https://www.xing.com/xtm/search/results?query%5Bkeywords%5D=' + encodeURI(this.candidate.FirstName + ' ' + this.candidate.LastName + ' ' + this.candidate.Company);
  }

  onChange() {
    this.candidateChange.next(this.candidate);
  }

  setLocation(location) {
    this.candidate.Longitude = location.Longitude;
    this.candidate.Latitude = location.Latitude;
    this.candidate.City = location.City;
    this.onChange();
  }

  setImage(data: any) {
    var uri = data.Uri;
    this.candidate.Image1024 = uri;
    this.onChange();
  }

  callCandidate() {
    var phoneLink = "tel:" + this.candidate.Phone;
    window.open(phoneLink, '_blank');
    this.candidateActivities.callModal.show();
  }

}
