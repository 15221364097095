import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chatbots',
  templateUrl: './chatbots.component.html',
  styleUrls: ['./chatbots.component.scss']
})
export class ChatbotsComponent implements OnInit {

  chatbots : any = [];

  q: string = '';

  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit() {
    this.search();
  }

  searchSoon() {
    if (this["searchTimeout"]) clearTimeout(this["searchTimeout"]);
    this["searchTimeout"] = setTimeout(() => {
      this.search();
    }, 500);
  }

  search() {
    this.api.get('chatbots?take=50&q=' + encodeURIComponent(this.q)).subscribe((d: any) => {
      this.chatbots = d;
    });
  }

  create()
  {
    this.api.post('chatbots', { Name: 'Neu' }).subscribe((d : any) => {
        this.router.navigateByUrl('/chatbots/' + d.Id);
    });
  }

}
