<div class="card" scAnchorTarget="Projekte">

    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h6 class="card-header-title">
                    Projekte
				</h6>
            </div>
        </div>
    </div>

	<div class="card-body card-body-list">
		<app-list-projects [projects]="projects"></app-list-projects>
	</div>
</div>