import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService, ScuiModalComponent } from '@sc-ui';
import { ModalSelectBillingAddressComponent } from '../modal-select-billing-address/modal-select-billing-address.component';

@Component({
  selector: 'app-invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.scss']
})
export class InvoiceEditorComponent implements OnInit {

  @Input()
  id: any;

  @Input()
  project: any;

  @ViewChild(ModalWizardComponent)
  confirmModal: ModalWizardComponent;

  @ViewChild('confirmation')
  confirmation: ModalWizardPageComponent;

  invoice: any;

  billingAddresses: any = [];

  isUpdating = false;

  isSending = false;

  statusLabels = {
    'Billed': 'Gebucht',
    'Draft': 'Entwurf'
  }

  statusColors = {
    'Billed': 'success-soft',
    'Draft': 'light'
  }

  errorMessage = '';

  @ViewChild('sendModal')
  sendModal : ModalWizardComponent;

  @ViewChild('sendModalErrorPage')
  sendModalErrorPage : ModalWizardPageComponent;


  @ViewChild(ModalSelectBillingAddressComponent)
  addressModal: ModalSelectBillingAddressComponent;

  constructor(public api: ScApiService, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.api.get('invoices/' + this.id).subscribe(d => {
      this.invoice = d;
    });
  }

  selectAddress() {

    this.addressModal.show(this.invoice.CompanyId).subscribe((d: any) => {
      this.invoice.Address = d.Address;
      this.invoice.AddressEMail = d.EMail;
      this.saveSoon();
    })
  }

  billingCompanyChanged() {
    this.invoice.Address = '';
    this.saveSoon();
  }

  saveSoon()
  {
    if (this["saveTimeout"]) clearTimeout(this["saveTimeout"]);
    this["saveTimeout"] = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    this.api.put('invoices/', this.invoice).subscribe(d => {});
  }

  generateInvoiceDocument()
  {
    if (this.isUpdating) return;
    this.isUpdating = true;

    this.api.post('invoices/' + this.invoice.Id + '/generate', null).subscribe((d : any) => {
      this.invoice.Uri = d.Uri;
      this.isUpdating = false;
    })
  }

  cancelInvoice() {
    if (this.isSending) return;
    this.isSending = true;

    this.api.post('invoices/' + this.invoice.Id + '/cancellation', null).subscribe(
      (d: any) => {
        this.router.navigateByUrl(`/invoices/${d.Id}`);
      },
      null,
      () => {
        this.isSending = false;
      });
  }

  sendInvoice() {

    if (this.isSending) return;
    this.isSending = true;

    this.api.post('invoices/send', this.invoice).subscribe(
      (d: any) => {
        this.confirmModal.navigateTo(this.confirmation);
      }, (e : any) => {
        console.log(e);
        this.errorMessage = e.error.Message;
        this.sendModal.navigateTo(this.sendModalErrorPage);
        this.isSending = false;
      }, () => {
        this.isSending = false;
      });
  }

  sendFirstReminder() {
    if (this.isSending) return;
    this.isSending = true;

    this.api.post('invoices/' + this.invoice.Id + '/first-reminder', null).subscribe(
      (d: any) => {
        this.invoice.IsFirstReminderSent = true;
      },
      null,
      () => {
        this.isSending = false;
      }
    );
  }

  sendSecondReminder() {
    if (this.isSending) return;
    this.isSending = true;

    this.api.post('invoices/' + this.invoice.Id + '/second-reminder', null).subscribe(
      (d: any) => {
        this.invoice.IsSecondReminderSent = true;
      },
      null,
      () => {
        this.isSending = false;
      }
    );
  }

}
