import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-quotes',
  templateUrl: './list-quotes.component.html',
  styleUrls: ['./list-quotes.component.scss']
})
export class ListQuotesComponent {

  @Input()
  quotes : any = [];

}
