import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-billed',
  templateUrl: './activity-item-project-billed.component.html',
  styleUrls: ['./activity-item-project-billed.component.scss']
})
export class ActivityItemProjectBilledComponent {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;

}
