<!-- <app-topbar></app-topbar> -->

<div class="app-container">
  <div class="app-nav">
    <app-nav-primary></app-nav-primary>
  </div>
  <div class="app-content">

    <div class="app-content-topbar">
      <app-topbar></app-topbar>
    </div>

    <div class="app-content-main">
      <main>
        <router-outlet #outlet></router-outlet>
      </main>
    </div>
  </div>
</div>
