<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">
  <div *ngIf="project" class="container">
    <div class="row">
      <div class="col-9">
        <div class="card shadow-lg mb-3">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-3">
                Bezeichnung
              </div>
              <div class="col-2 pe-0">
                <input [(ngModel)]="project.Id" readonly type="text" class="form-control">
              </div>
              <div class="col-7">
                <input name="jobTitle" [(ngModel)]="project.Name" (ngModelChange)="saveSoon()" type="text" class="form-control" placeholder="Bezeichnung der Stelle ohne (m/w)">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Tätigkeitsfeld
              </div>
              <div class="col-9">
                <select class="custom-select" [(ngModel)]="project.DisciplineId" (ngModelChange)="saveSoon()">
                  <option *ngFor="let i of disciplines" [ngValue]="i.id">{{ i.name }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Tags
              </div>
              <div class="col-9">
                <input name="jobTags" [(ngModel)]="project.Tags" (ngModelChange)="saveSoon()" type="text" class="form-control" placeholder="#hashtags">
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3 oneline">
                Kunde
              </div>
              <div class="col-9">
                <app-inline-select-organization [(organizationId)]="project.CompanyId" (organizationIdChange)="saveSoon()"></app-inline-select-organization>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3 oneline">
                Manager:in
              </div>
              <div class="col-9">
                <app-inline-select-manager [(managerId)]="project.ManagerId" (managerIdChange)="saveSoon()"></app-inline-select-manager>
              </div> 
            </div>
            
            <div class="row mb-2">
              <div class="col-3">
                Bestellnummer
              </div>
              <div class="col-9">
                <input name="jobTags" [(ngModel)]="project.OrderNumber" (ngModelChange)="saveSoon()" type="text" class="form-control">
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                Erstellt
              </div>
              <div class="col mb-2">
                <input [ngModel]="project.Created | date : 'dd.MM.yyyy'" readonly type="text" class="form-control">
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                Briefing
              </div>
              <div class="col mb-2">
                <sc-date-picker-inline [timeSelectable]="true" [(date)]="project.BriefingDate" (dateChange)="saveSoon()"></sc-date-picker-inline>
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                Review
              </div>
              <div class="col mb-2">
                <sc-date-picker-inline [timeSelectable]="true" [(date)]="project.ReviewDate" (dateChange)="saveSoon()"></sc-date-picker-inline>
              </div>
            </div>

          </div>
        </div>

        <app-project-dashboard-people [project]="project" (changed)="saveSoon()"></app-project-dashboard-people>

        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Aktivitäten
            </h6>
          </div>
          <div class="card-body">
            <sc-activity-feed *ngIf="project" uri="projects/{{id}}/activities" [readOnly]="true" headline="" pageSize="40">
              <ng-template let-activity="activity">
                <sc-activity-item [activity]="activity"></sc-activity-item>
              </ng-template>
            </sc-activity-feed>
          </div>
        </div>
      </div>
      <div class="col-3">
        <app-project-dashboard-tasks [project]="project"></app-project-dashboard-tasks>
      </div>
    </div>
  </div>
</div>

<sc-modal #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title">Wirklich löschen?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="deleteModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>Willst du das Projekt wirklich unwiderruflich löschen? Bitte gib in das Textfeld <i>löschen</i> (klein
          geschrieben) ein, um fortzufahren.</p>
      </div>
      <div class="col-12">
        <input class="form-control" [(ngModel)]="deleteConfirmation" name="deleteConfirmation" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">Abbrechen</button>
    <button class="btn btn-danger" [disabled]="deleteConfirmation != 'löschen'" (click)="delete(); deleteModal.dismiss();">Unwiderruflich
      löschen</button>
  </div>
</sc-modal>

<sc-modal #postponeModal>
  <sc-modal-header>
    Frist verschieben
  </sc-modal-header>
  <sc-modal-body>

    <div class="row">
      <div class="col-12">
        <textarea class="form-control mb-3" placeholder="Verschiebungsgrund aangeben" [(ngModel)]="postponeMessage" rows="5"></textarea>
      </div>
      <div class="col-12">
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(1)" class="btn btn-secondary btn-sm me-1 mb-1">Einen Tag</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(2)" class="btn btn-secondary btn-sm me-1 mb-1">2 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(3)" class="btn btn-secondary btn-sm me-1 mb-1">3 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(4)" class="btn btn-secondary btn-sm me-1 mb-1">4 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(5)" class="btn btn-secondary btn-sm me-1 mb-1">5 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(6)" class="btn btn-secondary btn-sm me-1 mb-1">6 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(7)" class="btn btn-secondary btn-sm me-1 mb-1">7 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(10)" class="btn btn-secondary btn-sm me-1 mb-1">10 Tage</button>
        <button [disabled]="postponeMessage.length < 5" (click)="postpone(14)" class="btn btn-secondary btn-sm me-1 mb-1">14 Tage</button>
      </div>
    </div>
  </sc-modal-body>
</sc-modal>
