<sc-modal #contactModal size="lg">
    <div class="modal-header">
        <h6 class="modal-title">Kontakt bearbeiten</h6>
        <button type="button" class="close" aria-label="Close" (click)="contactModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="contact">

        <div class="row mb-2">
            <div class="col-3">
                <label>E-Mail</label>
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="contact.EMail" />
            </div>
        </div>
        
        <div class="row mb-2">
            <div class="col-3">
                <label>Anrede</label>
            </div>
            <div class="col-9">
                <select class="custom-select custom-select-sm" [(ngModel)]="contact.Gender">
                    <option value="m">Herr</option>
                    <option value="f">Frau</option>
                </select>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Vorname</label>
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="contact.FirstName" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Nachname</label>
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="contact.LastName" />
            </div>
        </div>   

        <div class="row mb-2">
            <div class="col-3">
                <label>Registrierungslink</label>
            </div>
            <div class="col-9">
                <div class="input-group input-group-sm">
                    <div class="form-control form-control-sm" style="overflow: hidden; text-overflow: ellipsis;">
                        <a *ngIf="!contact.IsRegistered" class="text-primary" href="https://app.skillconomy.com/register/{{contact.Secret}}" target="_blank">https://app.skillconomy.com/register/{{contact.Secret}}</a>
                        <span *ngIf="contact.IsRegistered">Bereits registriert</span>
                    </div>
                    <button class="btn btn-sm btn-secondary" [disabled]="contact.IsRegistered" scCopyClick="https://app.skillconomy.com/register/{{contact.Secret}}"><i class="fa-regular fa-copy"></i></button>
                </div>
            </div>
        </div>

        

        <div class="row mb-2 mt-2" *ngIf="saveContactError">
            <div class="col-12">
                <div class="alert alert-danger mb-0">{{saveContactError}}</div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="contactModal.dismiss()">Abbrechen</button>
        <button class="btn btn-dark" (click)="save()">Speichern</button>
    </div>
</sc-modal>