import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScLayoutEmptyComponent } from './layouts/sc-layout-empty/sc-layout-empty.component';
import { LoginComponent } from './views/login/login.component';
import { CompaniesComponent } from './views/companies/companies.component';
import { CompanyComponent } from './views/company/company.component';
import { ProjectBriefingComponent } from './views/project/project-briefing/project-briefing.component';
import { ProjectSearchComponent } from './views/project/project-search/project-search.component';
import { ProjectTemplatesComponent } from './views/project/project-templates/project-templates.component';
import { CandidateComponent } from './views/candidate/candidate.component';
import { BlacklistComponent } from './views/blacklist/blacklist.component';
import { SalesDashboardComponent } from './views/dashboard-sales/sales-dashboard.component';
import { ChatbotsComponent } from './views/chatbots/chatbots.component';
import { ChatbotComponent } from './views/chatbot/chatbot.component';
import { CandidatesComponent } from './views/candidates/candidates.component';
import { ChatbotHistoryComponent } from './views/chatbot-history/chatbot-history.component';
import { ChatComponent } from './views/chat/chat.component';
import { ChatbotPrintComponent } from './views/chatbot-print/chatbot-print.component';
import { ProjectImportsComponent } from './views/project/project-imports/project-imports.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { ProjectBillingComponent } from './views/project/project-billing/project-billing.component';
import { ProjectDashboardComponent } from './views/project/project-dashboard/project-dashboard.component';
import { ProjectAnalysisComponent } from './views/project/project-analysis/project-analysis.component';
import { ProjectQuoteComponent } from './views/project/project-quote/project-quote.component';
import { ProjectJobsiteComponent } from './views/project/project-jobsite/project-jobsite.component';
import { ProjectDebriefingComponent } from './views/project/project-debriefing/project-debriefing.component';
import { PackageComponent } from './views/package/package.component';
import { ProjectListingComponent } from './views/project/project-listing/project-listing.component';
import { InvoicesComponent } from './views/invoices/invoices.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { CompanyBillingComponent } from './views/company-billing/company-billing.component';
import { PackagesComponent } from './views/packages/packages.component';
import { QuotesComponent } from './views/quotes/quotes.component';
import { ProjectProfileComponent } from './views/project/project-profile/project-profile.component';
import { ChromeExtensionAuthComponent } from './views/chrome-extension-auth/chrome-extension-auth.component';
import { DashboardTamComponent } from './views/dashboard-tam/dashboard-tam.component';
import { PoolComponent } from './views/pool/pool.component';
import { PoolsComponent } from './views/pools/pools.component';
import { PoolGroupComponent } from './views/pool-group/pool-group.component';
import { SidebarComponent } from './views/sidebar/sidebar.component';
import { ScLayoutSidebarComponent } from './layouts/sc-layout-sidebar/sc-layout-sidebar.component';
import { SidebarCandidateExactComponent } from './views/sidebar/sidebar-candidate-exact/sidebar-candidate-exact.component';
import { SidebarProjectsComponent } from './views/sidebar/sidebar-projects/sidebar-projects.component';
import { SidebarCandidateNewComponent } from './views/sidebar/sidebar-candidate-new/sidebar-candidate-new.component';
import { SidebarLoginComponent } from './views/sidebar/sidebar-login/sidebar-login.component';
import { SidebarSearchComponent } from './views/sidebar/sidebar-search/sidebar-search.component';
import { SidebarCandidateMergeComponent } from './views/sidebar/sidebar-candidate-merge/sidebar-candidate-merge.component';
import { CadencesComponent } from './views/cadences/cadences.component';
import { CadenceEditComponent } from './views/cadence-edit/cadence-edit.component';
import { CadencePeopleComponent } from './views/cadence-people/cadence-people.component';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';
import { SettingsComponent } from './views/settings/settings.component';
import { ReportsComponent } from './views/reports/reports.component';
import { LeadsComponent as BoardCustomerAcquisition } from './views/board-customer-acquisition/board-customer-acquisition.component';
import { SkillsComponent } from './views/skills/skills.component';
import { SkillComponent } from './views/skill/skill.component';
import { TrainingBatchesComponent } from './views/training-batches/training-batches.component';
import { CandidateOverviewComponent } from './views/candidate/candidate-overview/candidate-overview.component';
import { CandidateAccountComponent } from './views/candidate/candidate-account/candidate-account.component';
import { CandidateSourcingComponent } from './views/candidate/candidate-sourcing/candidate-sourcing.component';
import { CandidateSalesComponent } from './views/candidate/candidate-sales/candidate-sales.component';
import { DashboardMarketingComponent } from './views/dashboard-marketing/dashboard-marketing.component';
import { MailingsComponent } from './views/mailings/mailings.component';
import { MailingComponent } from './views/mailing/mailing.component';
import { ProjectScreeningComponent } from './views/project/project-screening/project-screening.component';
import { BoardCustomerRetentionComponent } from './views/board-customer-retention/board-customer-retention.component';
import { DashboardRetentionComponent } from './views/dashboard-retention/dashboard-retention.component';

const routes: Routes = [
  {
    path: '',
    component: ScLayoutEmptyComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  {
    path: '',
    component: ScLayoutSidebarComponent,
    children: [
      {
        path: 'sidebar',
        component: SidebarComponent,
        children: [
          {
            path: '',
            redirectTo: '/sidebar/projects',
            pathMatch: 'full'
          },
          {
            path: 'login',
            component: SidebarLoginComponent
          },
          {
            path: 'projects',
            component: SidebarProjectsComponent
          },
          {
            path: 'candidate/:id',
            component: SidebarCandidateExactComponent
          },
          {
            path: 'candidate-merge/:id',
            component: SidebarCandidateMergeComponent
          },
          {
            path: 'candidate-new',
            component: SidebarCandidateNewComponent
          },
          {
            path: 'search',
            component: SidebarSearchComponent
          }
        ]
      }
    ]
  },
  {
    path: 'chatjourneyprint/:id',
    component: ChatbotPrintComponent
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    children: [      
      {
        path: 'projects',
        component: ProjectsComponent
      },
      {
        path: 'companies',
        component: CompaniesComponent
      },
      {
        path: 'companies/:id',
        redirectTo: 'companies/:id/base',
        pathMatch: 'full'
      },
      {
        path: 'companies/:id/base',
        component: CompanyComponent
      },
      {
        path: 'companies/:id/billing',
        component: CompanyBillingComponent
      },
      {
        path: 'packages',
        component: PackagesComponent
      },
      {
        path: 'packages/:id',
        component: PackageComponent
      },
      {
        path: 'projects/:id',
        redirectTo: 'projects/:id/overview'
      },
      {
        path: 'projects/:id/overview',
        component: ProjectDashboardComponent
      },
      {
        path: 'projects/:id/analysis',
        component: ProjectAnalysisComponent
      },
      {
        path: 'projects/:id/quote',
        component: ProjectQuoteComponent
      },
      {
        path: 'projects/:id/briefing',
        component: ProjectBriefingComponent
      },
      {
        path: 'projects/:id/screening',
        component: ProjectScreeningComponent
      },
      {
        path: 'projects/:id/jobsite',
        component: ProjectJobsiteComponent
      },
      {
        path: 'projects/:id/sourcing',
        redirectTo: 'projects/:id/sourcing/screening',
        pathMatch: 'full'
      },
      {
        path: 'projects/:id/sourcing/imports',
        component: ProjectImportsComponent
      },
      {
        path: 'projects/:id/sourcing/profile',
        component: ProjectProfileComponent
      },
      {
        path: 'projects/:id/sourcing/screening',
        component: ProjectSearchComponent
      },
      {
        path: 'projects/:id/sourcing/listing',
        component: ProjectListingComponent
      },
      {
        path: 'projects/:id/sourcing/templates',
        component: ProjectTemplatesComponent
      },
      {
        path: 'projects/:id/debriefing',
        component: ProjectDebriefingComponent
      },
      {
        path: 'projects/:id/billing',
        component: ProjectBillingComponent
      },
      {
        path: 'pools',
        component: PoolsComponent,
        data: { animation: 'pools' }
      },
      {
        path: 'pools/:id',
        component: PoolComponent,
        data: { animation: 'pool' }
      },
      {
        path: 'pools/:poolId/groups/:id',
        component: PoolGroupComponent,
        data: { animation: 'pool' }
      },
      {
        path: 'candidates',
        component: CandidatesComponent
      },
      {
        path: 'projects/:projectId/candidates/:id',
        component: CandidateComponent
      },
      {
        path: 'candidates/:id',
        children: [
          {
            path: '',
            redirectTo: 'base',
            pathMatch: 'full'
          },
          {
            path: 'base',
            component: CandidateOverviewComponent
          },
          {
            path: 'account',
            component: CandidateAccountComponent
          },
          {
            path: 'sourcing',
            component: CandidateSourcingComponent
          },
          {
            path: 'sales',
            component: CandidateSalesComponent
          }
        ]
      },
      {
        path: 'people/:personId',
        component: CandidateComponent
      },
      {
        path: 'candidates/:candidateId/chats/:id',
        component: ChatComponent
      },
      {
        path: 'leads',
        component: BoardCustomerAcquisition
      },
      {
        path: 'board-retention',
        component: BoardCustomerRetentionComponent
      },      
      {
        path: 'invoices',
        component: InvoicesComponent
      },
      {
        path: 'invoices/:id',
        component: InvoiceComponent
      },
      {
        path: 'quotes',
        component: QuotesComponent
      },
      {
        path: 'blacklist',
        component: BlacklistComponent
      },
      {
        path: 'sales-dashboard',
        component: SalesDashboardComponent
      },
      {
        path: 'tam-dashboard',
        component: DashboardTamComponent
      },
      {
        path: 'dashboard-retention',
        component: DashboardRetentionComponent
      },
      {
        path: 'marketing-dashboard',
        component: DashboardMarketingComponent
      },
      {
        path: 'chatbots',
        component: ChatbotsComponent
      },
      {
        path: 'chatbots/:id',
        component: ChatbotComponent
      },
      {
        path: 'chatbots/:id/history',
        component: ChatbotHistoryComponent
      },
      {
        path: 'mailings',
        component: MailingsComponent
      },
      {
        path: 'mailings/:id',
        component: MailingComponent
      },
      {
        path: 'cadences',
        component: CadencesComponent
      },
      {
        path: 'cadences/:id',
        component: CadenceEditComponent
      },
      {
        path: 'cadences/:id/people',
        component: CadencePeopleComponent
      },
      {
        path: 'plugin-auth',
        component: ChromeExtensionAuthComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'skills',
        component: SkillsComponent
      },
      {
        path: 'skills/:id',
        component: SkillComponent
      },
      {
        path: 'training-batches',
        component: TrainingBatchesComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
