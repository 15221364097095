import { Component, OnInit, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-save-modal-conflict',
  templateUrl: './project-save-modal-conflict.component.html',
  styleUrls: ['./project-save-modal-conflict.component.scss']
})
export class ProjectSaveModalConflictComponent implements OnInit {

  @ViewChild('modal')
  modal: ScuiModalComponent;

  project : any;
  
  constructor(private projectService : ProjectService) { }

  ngOnInit(): void {
    this.projectService.onConflict.subscribe((e) => {
      this.project = e.Project;
      this.modal.show().subscribe();
    });
  }

  saveForced()
  {
    this.projectService.save(this.project, true).subscribe();
    this.modal.dismiss();
  }
}
