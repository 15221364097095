import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-task-detail-project-send-followup-messages',
  templateUrl: './task-detail-project-send-followup-messages.component.html',
  styleUrls: ['./task-detail-project-send-followup-messages.component.scss']
})
export class TaskDetailProjectSendFollowupMessagesComponent {

  @Input()
  task: any;

  @Output()
  done = new EventEmitter<any>();

  isCreatingTask = false;

  isTaskCreated = false;

  constructor(private api: ScApiService) { }

  sendFollowupMessages() {

    if (!this.task.Project) return;

    if (this.isCreatingTask) return;
    this.isCreatingTask = true;

    var newImport = {
      ProjectId: this.task.Project.Id,
      Type: 'SecondMessage',
      Status: 'Pending',
      Payload: {
        BatchId: -1,
        TemplateId: 508
      }
    };

    this.api.post('projects/' + this.task.Project.Id + '/imports', newImport).subscribe(() => {
      this.isTaskCreated = true;
      this.isCreatingTask = false;

      setTimeout(() => {
        this.done.next();
      }, 1500);
    }, (e) => {
      this.isCreatingTask = false;
    });
  }
}
