import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, ScUploadService } from '@sc-ui';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-chatbot-print',
  templateUrl: './chatbot-print.component.html',
  styleUrls: ['./chatbot-print.component.scss']
})
export class ChatbotPrintComponent implements OnInit {

  id : any;

  journey: any;

  content: any;

  constructor(private route: ActivatedRoute,
    private router : Router,
    private api: ScApiService,
    private uploadApi: ScUploadService,
    private clipboard: ClipboardService,
    private titleService: Title) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.api.get('chatbots/' + this.id + '/compiled').subscribe((p: any) => {
        this.journey = p;
        this.titleService.setTitle('skillconomy Chatbot: ' + p.Name + ' (' + p.Id + ')');
        if (p.Content) {
          this.content = JSON.parse(p.Content);
        }
        else {
          this.content = { Steps: [] };
        }       

      });
    });
  }

}
