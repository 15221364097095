<!-- <div #editableDiv class="form-control form-control-{{size}}" [class.empty]="!text" #x [attr.contenteditable]="readonly ? 'false' : 'true'" 
(keydown)="onKeydown($event)"
(paste)="onPaste($event)"
(input)="onInput($event)" 
[attr.placeholder]="placeholder">
</div> -->



<div class="autosize-container">
	<div class="form-control">
		{{ text }}
	</div>
	<textarea class="form-control" [(ngModel)]="text" (ngModelChange)="onTextChange()" [placeholder]="placeholder"></textarea>
</div>