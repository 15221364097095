<sc-modal-wizard #modal>

  <sc-modal-wizard-page #suggestionsPage>
    <sc-modal-header>
      Person auswählen
    </sc-modal-header>
    <sc-modal-body style="height: 50vh;">
      <div>
        <input class="form-control search mb-2" placeholder="Suche" [(ngModel)]="qSuggestions" (ngModelChange)="searchSuggestions()" />
      </div>
      <app-list-people [people]="filteredSuggestions" (selected)="modal.complete($event);"></app-list-people>
    </sc-modal-body>
    <sc-modal-footer>
      
      <button class="btn btn-dark" (click)="search(); modal.navigateTo(start);">Andere Person finden</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #start>
    <sc-modal-header>
      Person finden
    </sc-modal-header>
    <sc-modal-body style="height: 50vh;">
      <sc-form>
        <sc-form-row label="Vorname">
          <input [(ngModel)]="firstName" autocomplete="off" />
        </sc-form-row>
        <sc-form-row label="Nachname">
          <input [(ngModel)]="lastName" autocomplete="off" />
        </sc-form-row>
        <sc-form-row label="E-Mail">
          <input [(ngModel)]="eMail" autocomplete="off" />
        </sc-form-row>
      </sc-form>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary" *ngIf="suggestions?.length" (click)="modal.navigateTo(suggestionsPage)">Zurück</button>
      <button class="btn btn-dark" [disabled]="!lastName && !eMail" (click)="search(); modal.navigateTo(results);">Suchen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #results>
    <sc-modal-header>
      Ergebnisse
    </sc-modal-header>
    <sc-modal-body class="modal-body-list">
      <p *ngIf="!isLoading && !people?.length">
        Es wurden keine Person gefunden
      </p>
      <app-list-people [people]="people" (selected)="modal.complete($event);" [showMore]="hasMoreItems" (moreClick)="loadMoreItems()" [isLoadingMore]="isLoading"></app-list-people>
    </sc-modal-body>
    <sc-modal-footer *ngIf="allowCreate || allowSearch">
      <button *ngIf="allowSearch" class="btn btn-secondary" (click)="modal.navigateTo(start)">Zurück</button>
      <button *ngIf="allowCreate" class="btn btn-dark" (click)="modal.navigateTo(create)">Neu erstellen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #create>
    <sc-modal-header>
      Person erstellen
    </sc-modal-header>
    <sc-modal-body>
      <sc-form>
        <sc-form-row label="Vorname">
          <input [(ngModel)]="firstName" autocomplete="off" />
        </sc-form-row>
        <sc-form-row label="Nachname">
          <input [(ngModel)]="lastName" autocomplete="off" />
        </sc-form-row>
        <sc-form-row label="E-Mail">
          <input [(ngModel)]="eMail" autocomplete="off" />
        </sc-form-row>
      </sc-form>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary" (click)="modal.navigateTo(results)">Zurück</button>
      <button class="btn btn-dark" (click)="createPerson()">Erstellen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #errorPage>
    <sc-modal-header>
      Person erstellen
    </sc-modal-header>
    <sc-modal-body>
     <div class="alert alert-error">
      <strong>Fehler</strong><br />
      {{ error }}
     </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary" (click)="modal.navigateTo(create)">Zurück</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

</sc-modal-wizard>
