import { Component, OnInit, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent, MapMarkerRadiusComponent, EventService } from '@sc-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequest, HttpEventType } from '@angular/common/http';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { Location } from '@angular/common';
import { ModalEditLocationsComponent } from './candidate-overview/modal-edit-locations/modal-edit-locations.component';
import { ModalEditSkillsComponent } from './candidate-overview/modal-edit-skills/modal-edit-skills.component';
import { CandidateModalEditSkillComponent } from './candidate-overview/candidate-modal-edit-skill/candidate-modal-edit-skill.component';
import { CandidateBaseComponent } from './candidate-overview/candidate-base/candidate-base.component';


import * as _ from 'lodash';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
const utils = require('../../utils/diff');

@Component({
  selector: 'candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
  providers: [NgbDropdown]
})
export class CandidateComponent implements OnInit {

  id: any = '';

  isSaving = false;

  private _savedCandidate: any;

  candidate: any;

  editorOptions: JsonEditorOptions;

  data: any;

  skills: any = [];

  devices: any = [];

  selectedEducation: any = null;

  selectedExperience: any = null;

  selectedQualification: any = null;

  selectedSkill: any = null;

  $destroy: Subject<any> = new Subject<any>();

  hasError = false;

  error: string;

  errorTitle: string;

  errorLog: string;

  unsavedChanges: boolean = false;

  saveTimer: any;

  deleteConfirmation = "";

  @ViewChild('modal')
  confirmPublishModal: ScuiModalComponent;

  @ViewChild('createContactModal')
  createContactModal: ScuiModalComponent;

  @ViewChild('deleteModal')
  deleteModal: ScuiModalComponent;

  @ViewChild(CandidateBaseComponent, { static: false })
  base: CandidateBaseComponent;

  @ViewChild(ModalEditLocationsComponent)
  locationsModal: ModalEditLocationsComponent;

  @ViewChild(ModalEditSkillsComponent)
  skillsModal: ModalEditSkillsComponent;

  @ViewChild(CandidateModalEditSkillComponent)
  editSkillModal: CandidateModalEditSkillComponent;

  salesCompanyQ = '';

  tags: any = [];

  createContactNewCompanyName = '';

  createSalesInfo: any = {
    Company: null,
    IsNewCompany: false,
    Channel: ''
  };

  createContactError: string;

  @ViewChild('map')
  map: MapMarkerRadiusComponent;

  @ViewChild('errorModal')
  errorModal: ScuiModalComponent;

  errorMessage = '';

  dataJson = "";

  constructor(private api: ScApiService,
    private route: ActivatedRoute,
    private location: Location) {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }


  ngOnInit() {

    this.api.get('tags/leadchannel').subscribe(d => {
      this.tags = d;
    });

    this.route.params.pipe(takeUntil(this.$destroy)).subscribe(
      params => {

        if (params.id) {
          if (this.id == params.id) return;

          this.id = params.id;
          this.loadCandidate();          
        }

        if (params.personId) {
          this.api.get('people/' + params.personId).subscribe((d: any) => {
            this.id = d.Guid;
            this.candidate = d;
            this._savedCandidate = _.cloneDeep(d);
          });
        }

      },
      this.showError);
  }

  loadCandidate() {
    this.api.get('candidates/' + this.id).subscribe((d: any) => {
      this.candidate = d;
      this._savedCandidate = _.cloneDeep(d);
    });
  }

  onAvatarSelected(files) {

    if (files.length != 1) return;

    var file = files[0];

    const formData = new FormData();
    formData.append(file.name, file);
    const uploadReq = new HttpRequest("POST", "candidates/" + this.id + "/avatar", formData, { reportProgress: true });

    this.api.request(uploadReq).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / event.total);
        console.log(progress);

      } else if (event.type === HttpEventType.Response) {
        const uri = event.body.uri;
      }
    },
      (error: any) => {

      });

  }

  private showError(error: any) {
    this.hasError = true;
    this.errorTitle = null;
    this.errorLog = error;
  }

  setLocation(location) {
    this.candidate.Longitude = location.Longitude;
    this.candidate.Latitude = location.Latitude;
    this.candidate.City = location.City;
    this.save();
  }

  setToPresent(i) {
    i.ToPresent = i.End == null;
  }


  saveSoon() {
    if (this.saveTimer) clearTimeout(this.saveTimer);

    this.saveTimer = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    if (this.isSaving) return;
    this.isSaving = true;
    this.errorMessage = '';

    var diff = utils.diff(this._savedCandidate, this.candidate);

    this.api.put('candidates/' + this.id, diff).subscribe(
      (d: any) => {
        this.unsavedChanges = false;
        this.candidate.PortalAccount = d.PortalAccount;
        this._savedCandidate = _.cloneDeep(this.candidate);
      },
      (e) => {
        console.log(e);
        this.errorModal.show();
        this.errorMessage = e.error;
      },
      () => {
        this.isSaving = false;
      });
  }

  showDeleteModal() {
    this.deleteModal.show().subscribe(() => {
      this.delete();
    })
  }

  delete() {
    this.api.delete("candidates/" + this.id).subscribe(() => {
      this.location.back();
    });
  }

  deleteSkill(s) {
    var i = this.skills.indexOf(s);
    this.skills.splice(i, 1);
  }

  publish(a) {
    this.confirmPublishModal.show().subscribe(
      () => {
        this.api.get('candidates/' + this.id + '/assignments/' + a.Id + '/publish').subscribe(d => {
          a.IsShortlisted = true;
          a.ShortlistedAt = new Date().toISOString();
        });
      });
  }

  showCreateContact() {
    this.salesCompanyQ = this.candidate.Company;
    this.createContactModal.show().subscribe(() => { });
  }

  createContact() {

    this.createContactError = "";

    if (!this.createSalesInfo.IsNewCompany) {
      if (!this.createSalesInfo.Company) {
        this.createContactError = "Bitte Unternehmen auswählen oder Checkbox 'Neues Unternehmen' markieren";
        return;
      }
    }
    else {
      this.createSalesInfo.Company = {
        Name: this.salesCompanyQ
      };
    }

    this.api.post("candidates/" + this.id + '/contact', this.createSalesInfo).subscribe(d => {
      this.candidate.Contact = d;
    });
  }





}
