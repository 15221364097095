<div class="container-fluid bg-white">
    <div class="container" *ngIf="content">
        <div class="row pt-5">
            <div class="col-12">
                <img src="/assets/logos/sc-logo.svg" style="width: 200px;" />
            </div>
        </div>
        <div class="row pt-6 pb-6">
            <div class="col-12">
                <h1>{{ journey.Name }}</h1>
                <div class="text-muted">ID: {{ journey.Id }}</div>
            </div>
        </div>
        <div class="row" *ngFor="let step of content.Steps">

            <div class="col-8 mb-3">
                <div [innerHTML]="step.Body | bypassSecurityTrust : 'html'"></div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'Empty'">
                <div><i class="fa-regular fa-arrow-right"></i> Keine Antwortmöglichkeit</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'Text'">
                <div><i class="fa-regular fa-arrow-right"></i> Freitexteingabe</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'FileUpload'">
                <div><i class="fa-regular fa-arrow-right"></i> Datei-Upload</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'Location'">
                <div><i class="fa-regular fa-arrow-right"></i> Ort / Radius</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'Location'">
                <div><i class="fa-regular fa-arrow-right"></i> Weiterleitung</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'End'">
                <div><i class="fa-regular fa-arrow-right"></i> Ende</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'YesNo'">
                <div><i class="fa-regular fa-arrow-right"></i> {{ step.YesLabel }} / {{ step.NoLabel }}</div>
            </div>

            <div class="col-8 text-muted" *ngIf="step.Type == 'Choice'">
                <div>Auswahl: <span>{{ step.Choices }} </span> </div>
            </div>

            <div class="col-12">
                <hr>
            </div>




        </div>
    </div>
</div>