import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ModalSelectProjectPackageComponent } from '../modal-select-project-package/modal-select-project-package.component';

@Component({
  selector: 'app-inline-select-project-package',
  templateUrl: './inline-select-project-package.component.html',
  styleUrls: ['./inline-select-project-package.component.scss']
})
export class ProjectPackageSelectionComponent implements OnChanges {

  @Input()
  organizationId = 0;

  @Input()
  packageId = 0;

  @Output()
  packageIdChange = new EventEmitter<any>();

  package : any;

  @ViewChild(ModalSelectProjectPackageComponent)
  modal : ModalSelectProjectPackageComponent;

  constructor(private api: ScApiService) { }

  ngOnChanges() {
    if(!this.packageId)
    {
      this.package = null;
      return;
    }
    if (this.packageId != this.package?.Id)
    {
      this.api.get('packages/' + this.packageId).subscribe(d => {
        this.package = d;
      })
    }
  }

  select()
  {
    this.modal.show(this.organizationId).subscribe((d : any) => {
      this.package = d;
      this.packageId = d.Id;
      this.packageIdChange.next(this.packageId);
    });
  }
}
