<ng-template [appTopbarTemplate]>
  <div class="title h4">Mailings</div>
  <div class="actions">
	<button class="btn btn-round btn-dark" (click)="create()"> Neu</button>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="card">
          <div class="card-body card-body-list">
            <div class="list-group">
              <a class="list-group-item list-group-item-action" routerLink="/mailings/{{mailing.Id}}" *ngFor="let mailing of mailings">
                <div class="row align-items-center">
                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        {{mailing.Title}}
                      </h6>
                    </span>
                    <p class="card-text text-muted small mb-0 oneline">
                      {{ mailing.Description }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
