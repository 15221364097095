<sc-activity-item-highlight icon="fa-solid fa-check" [activity]="activity" [context]="context" *ngIf="condensed">
    hat das Briefing abgeschlossen
</sc-activity-item-highlight>

<sc-activity-item-comment [activity]="activity" [context]="context" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat das Briefing abgeschlossen
        </p>
    </div>
    <div class="comment-body">        
        <p>
			<strong>Must-Haves:</strong> <br> 
            {{ activity.Payload?.MustHaves }} 
		</p>
		<p>
			<strong>Nice-To-Haves:</strong> <br>
            {{ activity.Payload?.NiceToHaves }} 
		</p>
    </div>
</sc-activity-item-comment>