<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Rechnungen
  </div>
  <div class="actions">
    <button class="btn btn-dark btn-circle" (click)="newInvoiceModal.show();">
      <i class="fa-regular fa-plus"></i>
    </button>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body card-body-list">
            <app-list-invoices [invoices]="invoices"></app-list-invoices>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #newInvoiceModal size="lg">
  <sc-modal-header>
    Rechnung erstellen
  </sc-modal-header>
  <div class="modal-body">
    <p>
      Soll ein neuer Rechnungsentwurf erstellt werden?
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="newInvoiceModal.dismiss()">Abbrechen</button>
    <button class="btn btn-dark" (click)="addInvoice(); newInvoiceModal.dismiss();">Erstellen</button>
  </div>
</sc-modal>