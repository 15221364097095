<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Skills
  </div>
  <div class="actions">
    <div class="btn me-2" [class.btn-secondary]="!ambiguousOnly" [class.btn-dark]="ambiguousOnly" (click)="ambiguousOnly = !ambiguousOnly; load();">Ambiguous</div>
    <div class="btn me-2" [class.btn-secondary]="!suggestionsOnly" [class.btn-dark]="suggestionsOnly" (click)="suggestionsOnly = !suggestionsOnly; load();">Suggestions</div>
    <div class="btn " [class.btn-secondary]="!unapprovedOnly" [class.btn-dark]="unapprovedOnly" (click)="unapprovedOnly = !unapprovedOnly; load();">Unapproved</div>
  </div>
</ng-template>

<div class="main-content">

  <div class="container">

    <div class="row mb-3">
      <div class="col-4">
        <input type="search" [(ngModel)]="query" (ngModelChange)="loadSoon()" class="form-control search" placeholder="Suchen">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" href="/skills/{{mapping.Id}}" target="_blank" *ngFor="let mapping of mappings">
            <div class="row">
              <div class="col">
                <ul class="tag-list list-inline">
                  <li class="list-inline-item" *ngFor="let tag of mapping.Tags" [class.ambigious]="tag.IsAmbiguous">
                    <span class="tag tag-clip-text tag-light">{{tag.Tag}}</span>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <small class="text-muted">{{mapping.Frequency}}</small>
              </div>
            </div>

            <!-- <span class="badge badge-pill badge-light me-2 mb-2" *ngFor="let tag of mapping.Tags" [class.ambigious]="tag.IsAmbiguous">{{tag.Tag}}</span> -->
            <!-- <div style="float: left;">
				  <input class="form-control mr-2 mb-2" [(ngModel)]="tag.Tag" >
				</div> -->
          </a>
        </div>
      </div>

    </div>

  </div>

</div>
