<ng-template [appTopbarTemplate] *ngIf="project">
  <div class="title">
    <div class="row align-items-center">
      <div class="col-auto">
        <h4 class="mb-0 oneline d-inline">{{ project?.Name }}&nbsp;</h4>
        <span class="small">m|w|d</span>
      </div>
      <div class="col-auto">        
        <app-status-badge-project class="ms-3" [project]="project"></app-status-badge-project>
      </div>
    </div>
  </div>
  <div class="nav">
    <app-nav-project [project]="project"></app-nav-project>
  </div>
  <div class="actions" >
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingAnalysis'" class="btn btn-dark btn-round " (click)="completeAnalysisModal.show()">
      Analyse abschließen
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingQuote'" class="btn btn-dark btn-round" (click)="sendQuoteModal.show()">
      Angebot versenden
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingOrder'" class="btn btn-dark btn-round" (click)="completeOrderModal.show()">
      Angebot angenommen
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingBriefing'" class="btn btn-dark btn-round" (click)="completeBriefingModal.show()">
      Briefing abschließen
    </button>
    <button *ngIf="!project.IsCancelled && (project?.Status == 'AwaitingJobsite' || project?.Status == 'AwaitingJobsiteChanges')" class="btn btn-dark btn-round" (click)="completeJobsiteModal.show()">
      Jobsite abschließen
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingJobsiteFeedback'" class="btn btn-dark btn-round" (click)="confirmJobsiteModal.show()">
      Jobsite freigegeben
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingSourcing'" class="btn btn-dark btn-round" (click)="startSourcingModal.show()">
      Ansprache starten
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'Sourcing'" class="btn btn-dark btn-round" (click)="completeSourcingModal.show()">
      Ansprache abschließen
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingReview'" class="btn btn-dark btn-round" (click)="proceedAfterReviewModal.show()">
      Projekt fortsetzen
    </button>
    <button *ngIf="!project.IsCancelled && project?.Status == 'AwaitingSecondSourcing'" class="btn btn-dark btn-round" (click)="startSecondSourcingModal.show()">
      Zweitansprache starten
    </button>  
    <button *ngIf="!project.IsCancelled && project?.Status == 'SecondSourcing'" class="btn btn-dark btn-round" (click)="completeModal.show()">
      Projekt abschließen
    </button>

    <div class="d-inline ms-2" ngbDropdown>
      <button ngbDropdownToggle class="btn btn-circle btn-secondary">
        <i class="fa-regular fa-ellipsis"></i>
      </button>
      <div ngbDropdownMenu>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && project?.Status == 'AwaitingAnalysis'" (click)="assignModal.show()">
          Neu zuweisen
        </a>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && (project?.Status == 'AwaitingQuote' || project?.Status == 'AwaitingAnalysis')" (click)="rejectModal.show()">
          Projekt ablehnen
        </a>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && project?.Status == 'AwaitingOrder'" (click)="sendQuoteModal.show()">
          Neues Angebot versenden
        </a>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && project?.IsBilled && project.BillingType == 'SingleProject'" (click)="sendInvoiceCorrectionModal.show()">
          Rechnungskorrektur
        </a>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && !project.IsBilled" (click)="completeBillingModal.show()">
          Rechnung stellen
        </a>
        <a ngbDropdownItem *ngIf="!project.IsCancelled && project?.Status == 'AwaitingReview'" (click)="completeModal.show()">
          Beenden
        </a>        
        <a ngbDropdownItem *ngIf="!project.IsCancelled" (click)="cancelModal.show()">
          Abbrechen
        </a>        
        <a ngbDropdownItem *ngIf="project.IsCancelled" (click)="reactivateModal.show()">
          Reaktivieren
        </a>
        <a ngbDropdownItem (click)="deleteModal.show()">
          Projekt löschen
        </a>

      </div>
    </div>
  </div>
</ng-template>

<app-project-process-action-modal #assignModal [project]="project" title="Zuweisen?" [showMailOption]="false" actionName="assign">
  <div class="body">
    Zuweisen?
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeAnalysisModal [project]="project" title="Potenzialanalyse vollständig?" [showMailOption]="false" actionName="complete-analysis">
  <div class="body">
    Ist die Potenzialanalyse vollständig? Dann wird als nächstes der Vertrieb darüber informiert, dass das Angebot
    erstellt und versendet werden soll.
  </div>
  <div class="success">
    Der Vertrieb wird informiert! Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #rejectModal [project]="project" title="Projekt ablehnen?" [showMailOption]="false" actionName="reject">
  <div class="body">
    Das Projekt soll abgelehnt werden? Der Kunde wird informiert, dass kein hinreichendes Kandidat:innen-Potential vorhanden ist.
  </div>
  <div class="success">
    Das Projekt wurde abgelehnt
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #sendQuoteModal [project]="project" title="Angebot versenden?" [showMailOption]="false" actionName="send-quote">
  <div class="body">
    <div *ngIf="project?.BillingType == 'SingleProject' || project?.BillingType == 'CustomBilling'">
      Die Ergebnisse der Potenzialanalyse werden jetzt zusammen mit dem
      Angebot im Anhang <strong>an die Angebotsempfänger</strong> versendet!
    </div>
    <div *ngIf="project?.BillingType == 'PackageProject'">
      Die Ergebnisse der Potenzialanalyse werden <strong>an die Angebotsempfänger</strong> versendet! Du hast das Projekt einem Kontingent
      zugeordnet. Die E-Mail wird keine Preisinformationen beinhalten.
    </div>
  </div>
  <div class="success">
    Vielen Dank! Dann warten wir nun auf den Auftrag... 🤞
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeOrderModal [project]="project" title="Bestellvorgang abschließen?" [showMailOption]="true" actionName="complete-order">
  <div class="body">
    Das Angebot wurde beauftragt? Dann wird nun die Auftragsbestätigung versendet, sofern du die Option
    zur Benachrichtigung des Kunden aktiviert lässt.
  </div>
  <div class="success">
    Der Bestellvorgang wurde abgeschlossen. Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeBriefingModal [project]="project" title="Briefing abschließen?" [showMailOption]="true" actionName="complete-briefing">
  <div class="body">
    Das Briefing wurde abgeschlossen? Dann erhält der Kunde nun mit der Bestätigung eine Zusammenfassung des Briefings (sofern du den Schalter zur Benachrichtigung ausgewählt lässt).
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeJobsiteModal [project]="project" title="Jobsite fertig?" [showMailOption]="true" actionName="complete-jobsite">
  <div class="body">
    Die Jobsite ist fertig? Dann erhält der Kunde nun eine Aufforderung, die Jobsite zu feedbacken und freizugeben (sofern du den Schalter zur Benachrichtigung ausgewählt lässt).
  </div>
  <div class="success">
    Der Kunde wurde informiert. Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #confirmJobsiteModal [project]="project" title="Jobsite freigegeben?" [showMailOption]="true" actionName="confirm-jobsite">
  <div class="body">
    Der Kunde hat die Jobsite freigegeben? Dann erhält er eine Benachrichtigung, dass wir nun mit der Ansprache beginnen (sofern du den Schalter zur Benachrichtigung ausgewählt lässt).
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #startSourcingModal [project]="project" title="Ansprache beginnen?" [showMailOption]="true" actionName="start-sourcing">
  <div class="body">
    Das erste Sourcing beginnt? Der Process-Owner wird informiert und erhält eine Aufforderung zur Buchung des Reviews.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeSourcingModal [project]="project" title="Projekt beenden?" [showMailOption]="true" actionName="complete-sourcing">
  <div class="body">
    Die erste Ansprache ist abgeschlossen? Der Process-Owner wird informiert.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #proceedAfterReviewModal [project]="project" title="Projekt fortsetzen?" [showMailOption]="true" actionName="proceed-after-review">
  <div class="body">
    Das Review ist beendet und es geht in die zweite Anspracherunde? Der Process-Owner wird informiert.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #startSecondSourcingModal [project]="project" title="Ansprache beginnen?" [showMailOption]="true" actionName="start-second-sourcing">
  <div class="body">
    Die zweite Ansprache beginnt? Der Process-Owner wird informiert.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeModal [project]="project" title="Projekt beenden?" [showMailOption]="true" actionName="complete">
  <div class="body">
    Das Projekt ist abgeschlossen? Das Projekt wird nun abgerechnet, wenn die Garantie erfüllt wurde und bislang noch keine Rechnung gestellt wurde. 
    Der Process-Owner wird informiert.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #completeBillingModal [project]="project" title="Projekt abrechnen?" [showMailOption]="false" actionName="complete-billing">
  <div class="body">
    Das Projekt wird nun abgerechnet. Der Process-Owner erhält außerdem eine Aufforderung zur Buchung des Abschlusscalls.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #sendInvoiceCorrectionModal [project]="project" title="Rechnung korrigieren?" [showMailOption]="false" actionName="send-invoice-correction">
  <div class="body">
    Die bestehende Rechnung wird storniert und eine neue Rechnung gemäß der Angaben im Projekt versendet!
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #cancelModal [project]="project" title="Projekt abbrechen?" [showMailOption]="false" actionName="cancel" confirmBtnClass="btn-danger">
  <div class="body">
    Soll das Projekt wirklich abgebrochen werden? Es werden keine Benachrichtigungen verschickt und eine Abrechnung findet nicht statt!
  </div>
  <div class="success">
    Das Projekt wurde abgebrochen!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #reactivateModal [project]="project" title="Projekt reaktivieren?" [showMailOption]="false" actionName="reactivate">
  <div class="body">
    Soll das Projekt wirklich reaktiviert werden?
  </div>
  <div class="success">
    Das Projekt wurde reaktiviert!
  </div>
</app-project-process-action-modal>

<sc-modal #deleteModal>
  <sc-modal-header>
    Projekt unwiderruflich löschen?
  </sc-modal-header>
  <sc-modal-body>
    Das Projekt kann nur gelöscht werden, wenn noch keine Kandidat:innen kontaktiert wurden. Das Löschen kann nicht rückgängig gemacht werden!
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-primary" (click)="delete()">Unwiderruflich löschen</button>
  </sc-modal-footer>
</sc-modal>