import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-company-billing',
  templateUrl: './company-billing.component.html',
  styleUrls: ['./company-billing.component.scss']
})
export class CompanyBillingComponent implements OnInit {

  id : any;

  company : any;

  invoices : any = [];

  billingAddresses : any = [];

  newBillingAddress : any = {
    Country : "Deutschland"    
  };

  selectedBillingAddress : any = null;

  constructor(private api: ScApiService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params["id"];

        this.api.get("companies/" + this.id).subscribe((d: any) => {
          this.company = d;         
        });

        this.api.get("invoices?companyId=" + this.id).subscribe((d: any) => {
          this.invoices = d;         
        });

        this.api.get("companies/" + this.id + '/billing-addresses').subscribe(d => {
          this.billingAddresses = d;
          this.newBillingAddress.CompanyId = this.id;
        });

        this.api.get(`invoices/turnovers/month?companyId=${this.id}`).subscribe((d : any) => {

          var currentYear = new Date().getFullYear();
          var currentYearData = d.filter(i => i.Year == currentYear);
          var lastYearData = d.filter(i => i.Year == currentYear - 1);
          var twoYearsAgoData = d.filter(i => i.Year == currentYear - 2);
          var labels = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
          new Chart('turnoverChart', {            
            type: 'bar',
            data: {
              labels: labels,
              datasets: [{
                label: 'Umsatz ' + currentYear,
                data: currentYearData.map(i => i.Amount),
                backgroundColor: 'rgba(0,98,215,0.8)',
                borderColor: 'rgba(0,98,215,0.8)',
              },
              {
                label: 'Umsatz ' + (currentYear - 1),
                data: lastYearData.map(i => i.Amount),
                borderColor: '#f39681',
                backgroundColor: '#f39681',
                cubicInterpolationMode: 'default',
                type: 'line'
              },
              {
                label: 'Umsatz ' + (currentYear - 2),
                data: twoYearsAgoData.map(i => i.Amount),
                backgroundColor: '#ebf3fc',
                borderColor: '#ebf3fc',
                type: 'line'
              }]
            }
          });
        });
      });
  }

  saveTimer: any;

  saveSoon() {
    if (this.saveTimer) clearTimeout(this.saveTimer);
    this.saveTimer = setTimeout(() => { this.save(); }, 1000);
  }

  save() {
    this.api.put("companies/" + this.company.Id, this.company).subscribe(d => { });
  }

  addBillingAddress() {
    this.newBillingAddress.CompanyId = this.company.Id;
    this.api.post('billing-addresses/', this.newBillingAddress).subscribe((d : any) => {
      this.billingAddresses.push(d.BillingAddress);
      this.company.DefaultBillingAddressId = d.DefaultBillingAddressId;
      this.newBillingAddress = { Country: 'Deutschland'};
    });
  }

  saveSelectedBillingAddress() {
    this.api.put("billing-addresses/", this.selectedBillingAddress).subscribe(d => {
    });
  }

  deleteSelectedBillingAddress() {
    this.api.delete("billing-addresses/" + this.selectedBillingAddress.Id).subscribe((d : any) => {
      var i = this.billingAddresses.indexOf(this.selectedBillingAddress);
      this.billingAddresses.splice(i, 1);
      this.company.DefaultBillingAddressId = d.DefaultBillingAddressId;
    });
  }

}
