import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-assets',
  templateUrl: './company-assets.component.html',
  styleUrls: ['./company-assets.component.scss']
})
export class CompanyAssetsComponent implements OnChanges {

  assetLanguage = 'de';

  isGeneratingAssets = false;

  isCopyingAssets = false;

  includeLogo = true;

  includeBanner = true;

  includeDescription = true;

  includeHighlights = true;

  includeWorkplaceHighlights = true;

  includeVideo = true;

  includeImpressions = true;

  includeBenefits = true;

  @Input()
  company: any;

  @Output()
  companyChange = new EventEmitter<any>();

  q: any;

  scrapeSources: any = [];

  scrapeResult : any;

  @ViewChild('scrapeResultPage')
  scrapeResultPage : ModalWizardPageComponent;

  @ViewChild('scrapeModal')
  scrapeModal : ModalWizardComponent;

  constructor(private api: ScApiService) { }

  ngOnChanges()
  {
    if (this.company)
    {
      this.q = this.company.Name;
      this.searchScrapeSources();
    }
  }

  generateAssets() {
    
  }

  copyAssets() {
    this.isCopyingAssets = true;

    this.api.post('companies/' + this.company.Id + '/copy-assets', { ProjectId: 0 }).subscribe((c: any) => {
      Object.apply(this.company.Profile, c.Profile);
      this.isCopyingAssets = false;
    })
  }

  getImageIndex() {
    return this.company.Id % 3 + 1;
  }

  searchScrapeSources() {
    if (this['searchTimeout']) clearTimeout(this['searchTimeout']);
    this['searchTimeout'] = setTimeout(() => {
      this.api.get('companies/search-scrape-source?q=' + this.q).subscribe((d: any) => {
        this.scrapeSources = d;
      });
    }, 500);
  }

  openSourceUrl(e, item) {
    e.preventDefault();
    window.open(item.Uri, '_blank').focus();
  }

  selectSource(item) { 

    this.isGeneratingAssets = true;

    var escapedName = encodeURIComponent(item.name);

    this.api.post('companies/scrape/' + escapedName , null).subscribe((c: any) => {
      this.scrapeResult = c;
      this.isGeneratingAssets = false;
      this.scrapeModal.navigateTo(this.scrapeResultPage);
    });

  }

  confirmResults()
  {
    if (this.scrapeResult.Banner && this.includeBanner)
      this.company.Profile.Banner = this.scrapeResult.Banner;

    if (this.scrapeResult.Description && this.includeDescription)
      this.company.Profile.Description = this.scrapeResult.Description;

    if (this.scrapeResult.Highlights && this.includeHighlights)
      this.company.Profile.Highlights = this.scrapeResult.Highlights;

    if (this.scrapeResult.WorkplaceHighlights && this.includeWorkplaceHighlights)
      this.company.Profile.WorkplaceHighlights = this.scrapeResult.WorkplaceHighlights;

    if (this.scrapeResult.LogoUri && this.includeLogo)
      this.company.Profile.LogoUri = this.scrapeResult.LogoUri;

    if (this.scrapeResult.BenefitIds?.length && this.includeBenefits)
      this.company.Profile.BenefitIds = this.scrapeResult.BenefitIds;

    if (this.scrapeResult.Impressions?.length && this.includeImpressions)
      this.company.Profile.Impressions = this.scrapeResult.Impressions;

    if (this.scrapeResult.VideoUri && this.includeVideo)
      this.company.Profile.VideoUri = this.scrapeResult.VideoUri;

    this.companyChange.next(this.company);
  }

}
