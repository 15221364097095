<div class="task-container row g-0 align-items-center clickable">
  <div class="col-auto pe-4">
    <div class="icon-container">
	  <div class="icon-bg" [style.background-color]="task.color"></div>
	  <fa [class]="task.icon" [style.color]="task.color"></fa>
    </div>
  </div>
  <div class="col">
    <h5 class="oneline mb-0">{{ task.title }}</h5>
    <div class="text-muted oneline">{{ task.description }}</div>
  </div>

  <div class="col-auto d-flex justify-content-end clickable">
    <i class="fa-regular fa-chevron-right text-muted fa-xs"></i>
  </div>
</div>
