<div class="row mb-3">
    <div class="col-auto">                  
      <div class="custom-control custom-checkbox-toggle">
        <input type="checkbox" class="custom-control-input" [id]="elementId" [(ngModel)]="value" (blur)="onBlur()">
        <label class="custom-control-label" [for]="elementId"></label>
      </div>

    </div>
    <div class="col ms--2">
      <small>
        {{label}}
      </small>
    </div>
  </div>
