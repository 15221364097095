<!-- <div class="custom-control custom-checkbox">
  <input type="checkbox" class="custom-control-input" [id]="elementId">
  <label class="custom-control-label" [for]="elementId">{{ label }}</label>
</div> -->


<div class="row align-items-center clickable" (click)="toggle()">
  <div class="col-auto">
    <svg *ngIf="isChecked == false && hasNullState" style="width:1.9rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M16 48l0 416 416 0 0-416L16 48zM0 32l16 0 416 0 16 0 0 16 0 416 0 16-16 0L16 480 0 480l0-16L0 48 0 32zM153.8 174.5l5.7 5.7L224 244.7l64.6-64.6 5.7-5.7 11.3 11.3-5.7 5.7L235.3 256l64.6 64.6 5.7 5.7-11.3 11.3-5.7-5.7L224 267.3l-64.6 64.6-5.7 5.7-11.3-11.3 5.7-5.7L212.7 256l-64.6-64.6-5.7-5.7 11.3-11.3z"/></svg>
    <svg *ngIf="isChecked == null || (isChecked == false && !hasNullState)" style="width:1.9rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M432 48l0 416L16 464 16 48l416 0zM16 32L0 32 0 48 0 464l0 16 16 0 416 0 16 0 0-16 0-416 0-16-16 0L16 32z"/></svg>
    <svg *ngIf="isChecked == true"  style="width:1.9rem"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M16 48l0 416 416 0 0-416L16 48zM0 32l16 0 416 0 16 0 0 16 0 416 0 16-16 0L16 480 0 480l0-16L0 48 0 32zM325.7 197.7l-128 128-5.7 5.7-5.7-5.7-64-64-5.7-5.7L128 244.7l5.7 5.7L192 308.7 314.3 186.3l5.7-5.7L331.3 192l-5.7 5.7z"/></svg>
  </div>
  <div class="col ps-0">
    <span class="fw-normal">{{ label }}</span>
  </div>
</div>