import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-pools',
  templateUrl: './pools.component.html',
  styleUrls: ['./pools.component.scss'],
  animations: [ fadeIn ]
})
export class PoolsComponent implements OnInit {

  state = 'out';

  hasMoreItems = false;

  isLoading = false;

  page = 1;

  q = '';

  pools : any = [];

  totalCount = 0;

  constructor(private api : ScApiService) { }

  ngOnInit() {
    
    this.search();
  } 

  searchTimeout = null;

  searchSoon()
  {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.search();
    }, 500);

  }

  search()
  {
    this.page = 0;
    this.pools = [];
    this.loadMore();
  }

  loadMore()
  {    
    this.page++;

    // this.isLoading = true;

    this.api.get(`pools?q=${this.q}&page=${this.page}`).subscribe((d : any) => {
      this.pools.push.apply(this.pools, d.Items);
      this.totalCount = d.TotalCount;
      this.hasMoreItems = this.pools.length < d.TotalCount;
      this.isLoading = false;
      this.state = 'in';
    }, e => this.api.redirectAnonymousToLogin(e));
  }

}
