import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-candidate-exact-item',
  templateUrl: './sidebar-candidate-exact-item.component.html',
  styleUrls: ['./sidebar-candidate-exact-item.component.scss']
})
export class SidebarCandidateExactItemComponent implements OnInit {

  @Input()
  user : any;

  @Input()
  icon = 'far fa-copy';
  
  constructor() { }

  ngOnInit(): void {
  }

}
