import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { QueryParamsService } from 'src/services/query-params.service';

const LOCAL_STORAGE_KEY = 'projects_view';

declare var moment;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [QueryParamsService]
})
export class ProjectsComponent implements OnInit, OnDestroy {

  
  sub: Subscription;

  qCompany: string = '';

  skip = 0;

  take = 50;

  projects: any = null;

  companies: any = [];

  settings: any = {
    q: '',
    status: '',    
    userProjectsOnly: true,
    dueOnly: false,
    includeCancelled: false,
    stati : [
      { name: 'AwaitingAnalysis', isSelected: false },
      { name: 'AwaitingQuote', isSelected: false },
      { name: 'AwaitingOrder', isSelected: false },
      { name: 'AwaitingBriefing', isSelected: false },
      { name: 'AwaitingJobsite', isSelected: false },
      { name: 'AwaitingJobsiteFeedback', isSelected: false },
      { name: 'AwaitingJobsiteChanges', isSelected: false },
      { name: 'AwaitingSourcing', isSelected: false },
      { name: 'Sourcing', isSelected: false },    
      { name: 'AwaitingReview', isSelected: false },
      { name: 'AwaitingSecondSourcing', isSelected: false },
      { name: 'SecondSourcing', isSelected: false }, 
      { name: 'Completed', isSelected: false }
    ],
    sort: 'id'
  };

  newProject: any = {};

  hasMoreItems = false;

  isLoading = false;

  $q = new Subject<string>();  

  constructor(
    public api: ScApiService,
    private router: Router) {
  }

  ngOnInit() {

    this.$q
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.loadResults();
      });

    var storedStateJson = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedStateJson) {
      var storedState = JSON.parse(storedStateJson);
      if (storedState?.Version == 4) {
        this.settings = storedState.Settings;
      }
    }

    this.loadResults();
  }

  loadResults() {
    this.skip = 0;
    this.projects = [];
    this.loadMoreResults();
  }

  toggleDue()
  {
    if (!this.settings.dueOnly)
    {
      this.settings.q = "";
      this.settings.userProjectsOnly = true;
      this.settings.dueOnly = true;
    }
    else
    {
      this.settings.dueOnly = false;      
    }
    
    this.loadResults();
  }

  loadMoreResults() {

    var encodedQ = encodeURIComponent(this.settings.q);

    var statusString = '';
    this.settings.stati.filter(s => s.isSelected).forEach(s => statusString += s.name + ',');

    var uri = 'jobs?q=' + encodedQ
      + '&status=' + statusString
      + '&sort=' + this.settings.sort
      + '&userProjectsOnly=' + this.settings.userProjectsOnly
      + '&dueOnly=' + this.settings.dueOnly
      + '&includeCancelled=' + this.settings.includeCancelled
      + '&take=' + this.take
      + '&skip=' + this.skip;

    this.isLoading = true;

    this.api.get(uri).subscribe((d: any) => {

      var items = d.Items;
      this.projects = this.projects.concat(items);
      this.hasMoreItems = this.skip + items.length < d.Total;
      this.skip += this.take;
      this.isLoading = false;

      var now = moment();
      
      this.projects.forEach(p => {
        if(!p.NextTask) return;
        var valueMoment = moment(p.NextTask.Due);
        var diff = valueMoment.diff(now, 'days');
        p.daysToDue = diff;
      });

      var state = {
        Version: 4,
        Settings: this.settings
      };

      var stateJson = JSON.stringify(state);
      localStorage.setItem(LOCAL_STORAGE_KEY, stateJson);
    });
  }

  toggleWithInterestedNotShortlistedCandidatesOnly() {
    this.settings.withInterestedNotShortlistedCandidatesOnly = !this.settings.withInterestedNotShortlistedCandidatesOnly;
    this.loadResults();
  }

  toggleWithUnhandledCandidatesOnly() {
    this.settings.withUnhandledCandidatesOnly = !this.settings.withUnhandledCandidatesOnly;
    this.loadResults();
  }

  toggleUserProjectsOnly() {
    this.settings.userProjectsOnly = !this.settings.userProjectsOnly;
    this.loadResults();
  }  

  qChanged(text: string) {
    this.$q.next(text);
  }

  navigateToProject(p) {
    this.router.navigateByUrl('projects/' + p.Id + '/briefing');
  }

  ngOnDestroy() {
    if (this.sub == null) return;
    this.sub.unsubscribe();
  }

  addProject() {
    this.api.post("jobs", this.newProject).subscribe((d : any) => {
      this.router.navigateByUrl('/projects/' + d.Id);
    })
  }

  searchTimer: any;

  search() {
    this.api.get("companies?q=" + encodeURIComponent(this.qCompany)).subscribe(d => {
      this.companies = d;
    });
  }

  searchCompaniesSoon() {
    if (this.searchTimer) clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => { this.search(); }, 750);
  }

  selectCompany(company) {
    this.newProject.CompanyId = company.Id;
  }


}
