import { Directive, TemplateRef } from '@angular/core';
import { TopbarService } from 'src/services/topbar.service';

@Directive({
  selector: '[appTopbarTemplate]'
})
export class TopbarTemplateDirective {

  constructor(private host: TemplateRef<any>, private topbarService : TopbarService) { 
    this.topbarService.template = host;
  }

}
