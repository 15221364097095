
<sc-modal-wizard #actionModal size="md">
    <div class="modal-wizard-body">
        <sc-modal-wizard-page #start>
            <sc-modal-header>
                {{ title }}
            </sc-modal-header>
            <div class="modal-body" style="min-height: 20vh;">
               
                <div class="row align-items-center">
                    <div class="col">
                        <ng-content select=".body"></ng-content>                            
                    </div>
                </div>

                <div class="row mt-4" *ngIf="showMailOption">
                    <div class="col">
                        Kunden per E-Mail benachrichtigen?
                    </div>
                    <div class="col-auto">
                        <sc-switch [(ngModel)]="sendMail"></sc-switch>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="isExecuting" class="btn {{ confirmBtnClass }}" (click)="execute()">
                    <span class="spinner-border spinner-border-sm" *ngIf="isExecuting"></span>
                    Fortfahren
                </button>
            </div>
        </sc-modal-wizard-page>
  
        <sc-modal-wizard-page #confirmation>
            <sc-modal-header>
                Erledigt
            </sc-modal-header>
            <div class="modal-body">
                <div class="alert alert-success mb-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <ng-content select=".success"></ng-content>                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" (click)="actionModal.dismiss();">Ok</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page #error>
            <sc-modal-header>
                Fehler
            </sc-modal-header>
            <div class="modal-body">
                <div class="alert alert-error mb-0">
                    <div class="row align-items-center">
                        <div class="col">
                            {{ errorMessage }}                         
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" (click)="actionModal.dismiss();">Ok</button>
            </div>
        </sc-modal-wizard-page>
    </div>
  </sc-modal-wizard>