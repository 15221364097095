import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-candidates',
  templateUrl: './pool-candidates.component.html',
  styleUrls: ['./pool-candidates.component.scss']
})
export class PoolCandidatesComponent implements OnInit, OnChanges {

  @Input()
  pool: any;

  q = '';

  hasMoreItems = false;

  isLoading = false;

  skip = 0;

  take = 10;

  candidates: any = [];

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.load();
  }

  load() {
    this.candidates.length = 0;
    this.skip = 0;
    this.q = '';
    this.loadMore();
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.candidates.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    if (!this.pool) return;
    if (this.isLoading) return;

    this.isLoading = true;

    var uri = `pools/${this.pool.Id}/candidates?skip=${this.skip}&take=${this.take}`;
    if (this.q) uri += `&q=${this.q}`;

    this.api.get(uri).subscribe(
      (d: any) => {
        this.skip += this.take;
        this.candidates.push.apply(this.candidates, d.Items);
        this.hasMoreItems = this.candidates.length < d.TotalCount;
      },
      () => { },
      () => { this.isLoading = false }
    );
  }

  updateStatus(candidate, status)
  {
    candidate.Assignment.Status = status;
    this.api.put(`pools/${this.pool.Id}/candidates/${candidate.Id}/assignment`, candidate.Assignment).subscribe(() => {});
  }

  success(candidate)
  {
    candidate.Assignment.Status = 'Success';
    this.api.post(`pools/${this.pool.Id}/candidates/${candidate.Id}/success`, null).subscribe(() => {

    });
  }
}
