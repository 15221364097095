<sc-modal #modal>
  <sc-modal-header>
    Skill hinzufügen
  </sc-modal-header>
  <sc-modal-body>
    <div class="row">
      <div class="col-12">
        <sc-form>
          <sc-form-row label="Name">
            <input class="form-control" [(ngModel)]="skill.Name" />
          </sc-form-row>

          <sc-form-row label="Typ">
            <select [(ngModel)]="skill.Type">
              <option [ngValue]="'MustHave'">Must-Have</option>
              <option [ngValue]="'NiceToHave'">Nice-to-Have</option>
              <option [ngValue]="'Alternative'" *ngIf="skills?.length">Alternative</option>
            </select>
          </sc-form-row>
          <sc-form-row label="Alternative zu" [disabled]="skill.Type != 'Alternative'">
            <select [(ngModel)]="skill.AlternativeTo">
              <option *ngFor="let s of skills" [ngValue]="s.Name">{{ s.Name }}</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>
    </div>
  </sc-modal-body>

  <sc-modal-footer>
    <button class="btn btn-secondary btn-round" (click)="modal.dismiss()">Abbrechen</button>
    <button class="btn btn-dark btn-round" (click)="select()" [disabled]="!skill.Name || (skill.Type == 'Alternative' && !skill.AlternativeTo)">Hinzufügen</button>
  </sc-modal-footer>
</sc-modal>
