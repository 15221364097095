<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Rechnung
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-invoice-editor *ngIf="id" [id]="id"></app-invoice-editor>
      </div>
    </div>
  </div>
</div>
