import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import * as _ from 'lodash';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-listing',
  templateUrl: './project-listing.component.html',
  styleUrls: ['./project-listing.component.scss']
})
export class ProjectListingComponent implements OnInit {

  id: any;

  project: any;

  stats : any;

  candidates: any = [];

  groups: any = [];

  @ViewChild('errorModal')
  errorModal : ScuiModalComponent;

  errorMessage = '';

  private _projectSaved: any;

  constructor(private api: ScApiService, private route: ActivatedRoute, private projectService : ProjectService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {     

      this.id = params['id'];
        var cached = this.projectService.getFromCache(this.id);
        if (cached) this.project = cached;    
        this.projectService.load(this.id).subscribe(p => { 
          this.project = p;
          this._projectSaved = _.cloneDeep(p);
        });

      this.api.get(`projects/${this.id}/stats`).subscribe(p => {
        this.stats = p;
      });

      this.candidates = [];

      this.api.post(`jobs/${this.id}/candidates/search`, { IsShortlisted: true, BatchId: -1 }).subscribe((d: any) => {
        this.candidates.push.apply(this.candidates, d.Results);
        this.createGroups();        
      });

      this.api.post(`jobs/${this.id}/candidates/search`, { IsShortlisted: false, IsInterested: true, BatchId: -1 }).subscribe((d: any) => {
        this.candidates.push.apply(this.candidates, d.Results);
        this.createGroups();        
      });

    });
  }

  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  createGroups()
  {
    var groups = [];
    var results = this.candidates;

    var interested = results.filter(a => a.Assignment.Status == 'Interested');
    if (interested.length > 0) {
      groups.push({
        name: 'Interessiert',
        items: interested
      });
    }

    var shortlisted = results.filter(a => a.Assignment.Status == 'Listed');
    if (shortlisted.length > 0) {
      groups.push({
        name: 'Vorgestellt',
        items: shortlisted
      });
    }

    var shortlistedNonCommittal = results.filter(a => a.Assignment.Status == 'ListedNonCommittal');
    if (shortlistedNonCommittal.length > 0) {
      groups.push({
        name: 'Vorgestellt (unverbindlich)',
        items: shortlistedNonCommittal
      });
    }

    var accepted = results.filter(a => a.Assignment.Status == 'Accepted');
    if (accepted.length > 0) {
      groups.push({
        name: 'In der Auswahl',
        items: accepted
      });
    }

    var hired = results.filter(a => a.Assignment.Status == 'Hired');
    if (hired.length > 0) {
      groups.push({
        name: 'Eingestellt',
        items: hired
      });
    }

    var rejected = results.filter(a => a.Assignment.Status == 'Rejected');
    if (rejected.length > 0) {
      groups.push({
        name: 'Abgelehnt',
        items: rejected
      });
    }

    var revoked = results.filter(a => a.Assignment.Status == 'Revoked');
    if (revoked.length > 0) {
      groups.push({
        name: 'Zurückgezogen',
        items: revoked
      });
    }

    this.groups = groups;
  }

  saveAssignment(a) {
    this.api.put('candidates/' + a.CandidateId + '/assignments/' + a.Id, a).subscribe(d => {
      this.createGroups();
     });
  }

  addToShortlist(a)
  {
    var uri = this.getActionUri(a, 'add-to-shortlist');
    this.api.post(uri, null).subscribe(d => {
      a.IsShortlisted = true;
      this.createGroups();
    });
  }

  reject(a)
  {
    this.errorMessage = '';

    var uri = this.getActionUri(a, 'reject');
    this.api.post(uri, null).subscribe(d => {
      a.IsRejected = true;
      this.createGroups();
    }, this.showErrorModal);
  }

  hired(a)
  {
    this.errorMessage = '';

    var uri = this.getActionUri(a, 'hired');
    this.api.post(uri, null).subscribe(d => {
      a.IsHired = true;
      this.createGroups();
    }, this.showErrorModal);
  }

  revoke(a)
  {
    this.errorMessage = '';
    
    var uri = this.getActionUri(a, 'revoke');
    this.api.post(uri, null).subscribe(d => {
      a.IsRejected = false;
      a.IsRevoked = true;
      this.createGroups();
    }, this.showErrorModal);
  }

  private getActionUri(a, actionName)
  {
    return `projects/${this.project.Id}/candidates/${a.CandidateId}/actions/${actionName}`;    
  }

  private showErrorModal(e)
  {
    this.errorMessage = e.error.Message;
    this.errorModal.show;
  }

}
