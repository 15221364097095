import { Component, ViewChild } from '@angular/core';
import { ScuiModalComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-modal-select-chatbot',
  templateUrl: './modal-select-chatbot.component.html',
  styleUrls: ['./modal-select-chatbot.component.scss']
})
export class ModalSelectChatbotComponent {

  q: any = '';

  chatbots: any = [];

  @ViewChild(ScuiModalComponent)
  modal: ScuiModalComponent;

  constructor(private api: ScApiService) { }

  ngOnInit() {
    this.search();
  }

  searchSoon() {
    if (this["searchTimeout"]) clearTimeout(this["searchTimeout"]);
    this["searchTimeout"] = setTimeout(() => {
      this.search();
    }, 500);
  }

  search() {
    this.api.get('chatbots?take=10&q=' + encodeURIComponent(this.q)).subscribe((d: any) => {
      this.chatbots = d;
    });
  }

  show() {
    return this.modal.show()
  }

}
