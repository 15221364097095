import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from 'src/environment.prod';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-project-search-post-chat',
  templateUrl: './modal-project-search-post-chat.component.html',
  styleUrls: ['./modal-project-search-post-chat.component.scss']
})
export class ModalProjectSearchPostChatComponent implements OnInit {

  @Input()
  projectId : number;

  @Input() 
  tags : any;

  @Input()
  filter : any;

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  isPosting = false;

  sendNotifications = true;

  createIfCompleted = false;

  constructor(private api : ScApiService) { }

  ngOnInit() {
  }

  postChat(chatbotId)
  {
    this.isPosting = true;

    var args = {
      ChatbotId: chatbotId,
      ProjectId: this.projectId,
      BatchId: this.filter.batchId,
      Tags: this.filter.tags,
      SendNotifications: this.sendNotifications,
      CreateIfCompleted: this.createIfCompleted
    };

    this.api.post('chats/batch', args).subscribe(d => {
      console.log(d);
      this.isPosting = false;
      this.modal.dismiss();
    });

    console.log(chatbotId);
  }

  selectedTags = () => this.tags.filter(t => t.Tag && (t.isSelected || t.isExcluded));

  show()
  {
    return this.modal.show();
  }

  truncate(text: string, length) {
    if (!text) return '';
    if (text.length <= length) return text;
    text = text.substring(0, length) + "...";
    return text;
  }
}
