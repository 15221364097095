import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-sidebar-login',
  templateUrl: './sidebar-login.component.html',
  styleUrls: ['./sidebar-login.component.scss']
})
export class SidebarLoginComponent implements OnInit {

  constructor(
    private router: Router,
    private api: ScApiService) { }

  eMail: string;

  password: string;

  ngOnInit() { }

  login() {

    this.api.login(this.eMail, this.password).subscribe(
      (data: any) => {
        console.log(data);
        if (data.Succeeded) {
          this.router.navigateByUrl('/sidebar');
        }
      },
      error => {
        console.log(error);
      });
  }


}
