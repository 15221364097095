import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-cadences',
  templateUrl: './cadences.component.html',
  styleUrls: ['./cadences.component.scss']
})
export class CadencesComponent implements OnInit {

  cadences : any = [];
  
  constructor(private api : ScApiService) { }

  ngOnInit(): void {
    this.api.get('cadences').subscribe(d => this.cadences = d);
  }

}
