import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-kpi',
  templateUrl: './card-kpi.component.html',
  styleUrls: ['./card-kpi.component.scss']
})
export class CardKpiComponent {

  @Input()
  value : number;

  @Input()
  trend : number;

  @Input()
  label = '';

  @Input()
  unit = '€';

}
