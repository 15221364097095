<sc-modal #modal>
    <div class="modal-header">
      <h4 class="modal-title">Das Speichern überschreibt neuere Änderungen</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <p>
        Es wurden Änderungen am Projekt vorgenommen, nachdem diese Seite geladen wurde. Wenn du deine Inhalte speicherst,
        gehen diese Änderungen verloren. Sichere deine Änderungen ggf. in die Zwischenablage und aktualisiere die Seite,
        um den aktuellen Stand zu laden.
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-dark" (click)="modal.dismiss()">Abbrechen</button>
      <button class="btn btn-danger" (click)="saveForced();">Überschreiben</button>
    </div>
  </sc-modal>