<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div *ngIf="project" class="container">

    <div class="row">

      <div class="col-12">
        <div class="card" scAnchorTarget="Potentialanalyse">
          <div class="card-header">
            <h6 class="card-header-title">
              Potenzialanalyse
            </h6>
          </div>
          <div class="card-body">

            <sc-form>
              <sc-form-row label="Unternehmen">
                <app-inline-select-organization [(organizationId)]="project.CompanyId" (organizationIdChange)="saveSoon()"></app-inline-select-organization>
              </sc-form-row>
              <sc-form-row label="Stellenbezeichnung">
                <input name="jobTitle" [(ngModel)]="project.Name" (ngModelChange)="saveSoon()" placeholder="ohne (m/w)">
              </sc-form-row>
              <sc-form-row label="Stelleninformationen">
                <autosize-textarea [(text)]="project.Profile.Comment" (textChange)="saveSoon()"></autosize-textarea>
              </sc-form-row>
              <sc-form-row label="Garantie">
                <select class="col-6" [(ngModel)]="project.Profile.Offer.GuaranteeGranted" (ngModelChange)="saveSoon()">
                  <option [ngValue]="true">ja</option>
                  <option [ngValue]="false">nein</option>
                </select>
              </sc-form-row>
              <sc-form-row label="Gehalt">
                <select class="col-6" [(ngModel)]="project.Profile.Offer.SalaryRating" (ngModelChange)="saveSoon()">
                  <option [ngValue]="1">Restriktiv</option>
                  <option [ngValue]="2">Neutral</option>
                  <option [ngValue]="3">Gut</option>
                </select>
              </sc-form-row>
              <sc-form-row label="Skills">
                <select class="col-6" [(ngModel)]="project.Profile.Offer.SkillsRating" (ngModelChange)="saveSoon()">
                  <option [ngValue]="1">Restriktiv</option>
                  <option [ngValue]="2">Neutral</option>
                  <option [ngValue]="3">Gut</option>
                </select>
              </sc-form-row>
              <sc-form-row label="Remote-Anteil">
                <select class="col-6" [(ngModel)]="project.Profile.Offer.RemoteRating" (ngModelChange)="saveSoon()">
                  <option [ngValue]="1">Restriktiv</option>
                  <option [ngValue]="2">Neutral</option>
                  <option [ngValue]="3">Gut</option>
                </select>
              </sc-form-row>
              <sc-form-row label="Standort ">
                <select class="col-6" [(ngModel)]="project.Profile.Offer.LocationRating" (ngModelChange)="saveSoon()">
                  <option [ngValue]="1">Restriktiv</option>
                  <option [ngValue]="2">Neutral</option>
                  <option [ngValue]="3">Gut</option>
                </select>
              </sc-form-row>
              <sc-form-row label="Kommentar">
                <autosize-textarea [(text)]="project.Profile.Offer.Comment" (textChange)="saveSoon()"></autosize-textarea>
              </sc-form-row>
            </sc-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>