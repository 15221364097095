<div [ngSwitch]="activity.Content">
   
    <sc-activity-item-candidate-commented *ngSwitchCase="'#commented'" [activity]="activity" [context]="context" [condensed]="condensed">        
    </sc-activity-item-candidate-commented> 
    
    <sc-activity-item-mail *ngSwitchCase="'#mail'" [activity]="activity" [context]="context">        
    </sc-activity-item-mail> 
    
    <sc-activity-item-candidate-interested *ngSwitchCase="'#candidateinterested'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-interested>    

    <sc-activity-item-candidate-accepted *ngSwitchCase="'#accepted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-accepted>    

    <sc-activity-item-candidate-feedback-posted *ngSwitchCase="'#candidatefeedback'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-feedback-posted>

    <sc-activity-item-candidate-status-update *ngSwitchCase="'#statusupdate'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-status-update>

    <sc-activity-item-candidate-hired *ngSwitchCase="'#hired'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-hired>

    <sc-activity-item-candidate-rejected *ngSwitchCase="'#rejected'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-rejected>

    <sc-activity-item-candidate-rejected *ngSwitchCase="'#rejectedunlisted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-candidate-rejected>

    <sc-activity-item-candidate-shortlisted *ngSwitchCase="'#shortlistedcandidate'" [activity]="activity" [context]="context" [condensed]="condensed">        
    </sc-activity-item-candidate-shortlisted>

    <sc-activity-item-project-jobsite-commented *ngSwitchCase="'#jobsitecomment'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-jobsite-commented>

    <sc-activity-item-project-jobsite-confirmed *ngSwitchCase="'#jobsiteconfirmed'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-jobsite-confirmed>

    <sc-activity-item-project-jobsite-ready *ngSwitchCase="'#jobsiteready'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-jobsite-ready>

    <sc-activity-item-project-jobsite-work-item-commented *ngSwitchCase="'#jobsiteworkitemcomment'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-jobsite-work-item-commented>

    <sc-activity-item-project-messaging-done *ngSwitchCase="'#messagingdone'" [activity]="activity" [context]="context" [condensed]="condensed">        
    </sc-activity-item-project-messaging-done>

    <sc-activity-item-project-briefing-booked *ngSwitchCase="'#projectbriefingbooked'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-briefing-booked> 

    <sc-activity-item-project-briefing-completed *ngSwitchCase="'#projectbriefingcompleted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-briefing-completed> 

    <sc-activity-item-project-user-invited *ngSwitchCase="'#userinvited'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-user-invited> 

    <sc-activity-item-project-created *ngSwitchCase="'#projectcreated'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-created> 

    <sc-activity-item-project-analysis-completed *ngSwitchCase="'#projectanalysiscompleted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-analysis-completed> 

    <sc-activity-item-project-quote-sent *ngSwitchCase="'#projectquotesent'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-quote-sent> 

    <sc-activity-item-project-ordered *ngSwitchCase="'#projectordered'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-ordered> 

    <sc-activity-item-project-jobsite-revision-requested *ngSwitchCase="'#jobsiterevisionrequested'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-jobsite-revision-requested> 

    <sc-activity-item-project-sourcing-completed *ngSwitchCase="'#projectsourcingcompleted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-sourcing-completed> 

    <sc-activity-item-project-sourcing-started *ngSwitchCase="'#projectsourcingstarted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-sourcing-started> 

    <sc-activity-item-project-review-booked *ngSwitchCase="'#projectreviewbooked'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-review-booked> 

    <sc-activity-item-project-review-completed *ngSwitchCase="'#projectreviewcompleted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-review-completed> 
    
    <sc-activity-item-project-billed *ngSwitchCase="'#projectbilled'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-billed> 

    <sc-activity-item-project-completed *ngSwitchCase="'#projectcompleted'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-completed> 

    <sc-activity-item-project-rated *ngSwitchCase="'#projectrated'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-project-rated> 

    <sc-activity-item-introductory-call *ngSwitchCase="'#democall'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-introductory-call> 

    <sc-activity-item-call *ngSwitchCase="'#sales'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-call> 

    <sc-activity-item-sales-organization-status-changed *ngSwitchCase="'#sales_organization_status_changed'" [activity]="activity" [context]="context" [condensed]="condensed">
    </sc-activity-item-sales-organization-status-changed> 

    <sc-activity-item-simple *ngSwitchDefault [activity]="activity" [context]="context" [condensed]="condensed">
        {{ activity.Content }}
    </sc-activity-item-simple>
</div>