import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-list-people',
  templateUrl: './list-people.component.html',
  styleUrls: ['./list-people.component.scss']
})
export class ListPeopleComponent implements OnChanges {

  @Input()
  people : any = [];

  @Input()
  navigateOnClick = false;

  @Output()
  selected = new EventEmitter<any>();

  @Input()
  showMore = false;

  @Input()
  isLoadingMore = false;

  @Output()
  moreClick = new EventEmitter<any>();

  onClick(person)
  {
    this.selected.next(person);
  }

  ngOnChanges(): void {
  }
}
