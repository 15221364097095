import { Component, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-modal-task-edit',
  templateUrl: './modal-task-edit.component.html',
  styleUrls: ['./modal-task-edit.component.scss']
})
export class ModalTaskEditComponent {

  task : any;

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  constructor(private notifications : NotificationsService)
  {}

  show(task : any)
  {
    this.task = task;
    this.modal.show();
  }

  saveTask()
  {
    this.notifications.save(this.task).subscribe((d) => {
    });
  }

  deleteTask()
  {
    this.task.IsClosed = true;
    this.notifications.close(this.task).subscribe();
  }

  setDue(task, months)
  {
    var date = new Date();
    date.setMonth(date.getMonth() + months);
    task.Due = date.toISOString();
  }
}
