import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-badge-project-person-assignment',
  templateUrl: './status-badge-project-person-assignment.component.html',
  styleUrls: ['./status-badge-project-person-assignment.component.scss']
})
export class StatusBadgeProjectPersonAssignmentComponent implements OnInit {

  @Input()
  assignment : any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
