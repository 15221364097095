<p>
	Bitter erstelle die Erfolgsprognose für das angefragte Projekt.
</p>

<sc-form>
	<sc-form-row label="Größe der Zielgruppe">
		<input />
	</sc-form-row>

	<sc-form-row label="Wettbewerb">
		<input />
	</sc-form-row>

	<sc-form-row label="Umkreis">
		<input />
	</sc-form-row>

	<sc-form-row label="Erwartet">
		<input />
	</sc-form-row>

	<sc-form-row label="Garantiert">
		<input />
	</sc-form-row>

	

</sc-form>

<div class="row">
	<div class="col-12  d-flex justify-content-end">
		<button class="btn btn-primary">
			Absenden
		</button>
	</div>
</div>