import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-projects-filter-badges',
  templateUrl: './projects-filter-badges.component.html',
  styleUrls: ['./projects-filter-badges.component.scss']
})
export class ProjectsFilterBadgesComponent implements OnInit {

  @Input()
  settings : any;

  @Output()
  changed = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  onChange()
  {
    this.changed.next(this.settings);
  }

}
