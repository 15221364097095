import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import * as _ from 'lodash';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-quote',
  templateUrl: './project-quote.component.html',
  styleUrls: ['./project-quote.component.scss']
})
export class ProjectQuoteComponent implements OnInit {

  id: any;

  project: any;

  quotes : any;
  
  billingAddresses: any = [];

  packagesAvailable = false;

  isUpdating = false;

  isSending = false;

  isGenerating = false;

  selectedQuote : any;

  @ViewChild('confirmQuoteDeletionModal')
  confirmQuoteDeletionModal: ScuiModalComponent;

  constructor(private api: ScApiService,
    private projectService: ProjectService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.get(`quotes?projectId=${this.id}`).subscribe(q => this.quotes = q);

      this.checkPackages();

      var cached = this.projectService.getFromCache(this.id);

      if (cached) {
        this.project = cached;
        this.checkPackages();
      }

      this.projectService.load(this.id).subscribe(p => {
        this.project = p;
        this.checkPackages();
      });
    });
  }

  billingCompanyChange()
  {
    this.project.BillingAddressId = 0;
    this.project.PackageId = 0;    
    this.saveSoon();
    this.checkPackages();
  }

  checkPackages()
  {
    if (!this.project?.BillingCompanyId)
    {
      this.packagesAvailable = false;
      return;
    }

    this.api.get('packages?' + '&organizationId=' + this.project.BillingCompanyId + '&activeOnly=true').subscribe((d : any) => {
      var usableVolumeSum = 0;

      d.forEach((p : any) => {
        usableVolumeSum += p.UsableVolume;
      });

      this.packagesAvailable = usableVolumeSum > 0; 
    });
  }

  onSentQuote()
  {
    this.projectService.load(this.id).subscribe(p => {
      this.project = p;
    });
  }

  switchToPackage() {
    if (this.project.QuoteId) {
      this.confirmQuoteDeletionModal.show().subscribe(() => {
        this.api.delete(`quotes/${this.project.QuoteId}`).subscribe(() => {
          this.project.QuoteId = null;
        });
      })
    }
  }

  switchToSingleProject() {

    if (this.project.PackageId) {

      this.project.PackageId = 0;
      
      this.save().subscribe(() => {
        this.generateQuote();
      });
    }
    else {
      this.generateQuote();
    }
  }

  generateQuote() {
    if (this.isGenerating) return;
    this.isGenerating = true;

    this.api.post('projects/' + this.id + '/quotes', null).subscribe((d: any) => {      
      this.isGenerating = false;
      this.quotes.push(d);
    });
  }

  deleteQuote() {
    this.api.delete(`quotes/${this.project.QuoteId}`).subscribe(() => {
      this.project.QuoteId = null;
    });
  }

  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  save() {
    return this.projectService.save(this.project);
  }
}