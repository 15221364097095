<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Active'">
    Aktiv
</span>
<span class="badge badge-pill badge-light" *ngIf="assignment.Status == 'Paused'">
    Pausiert
</span>
<span class="badge badge-pill badge-danger-soft" *ngIf="assignment.Status == 'Cancelled'">
    Abgebrochen
</span>
<span class="badge badge-pill badge-danger-soft" *ngIf="assignment.Status == 'Failed'">
    Fehler
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Success'">
    Erfolg
</span>