<div class="list-group">

  <a *ngFor="let company of organizations" [routerLink]="navigateOnClick ? '/companies/' + company.Id : null" (click)="selected.next(company)" class="list-group-item list-group-item-action">

    <div class="row align-items-center">

      <div class="col">
        <span>
          <h6 class="mb-1 d-inline">
            {{company.Name}}
          </h6>
          <span *ngIf="company.IsGroup" class="badge badge-pill badge-light ms-2">Gruppe</span>
        </span>
        <p class="card-text text-muted small mb-0 oneline">
          <span class="me-2" *ngFor="let d of company.Domains">{{ d.Host }}</span>
        </p>
      </div>

      <div class="col-auto">
        <span class="badge badge-pill badge-light">
          {{ company.SalesStatus }}
        </span>
      </div>
    </div>
  </a>
</div>
