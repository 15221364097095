import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-billing',
  templateUrl: './project-billing.component.html',
  styleUrls: ['./project-billing.component.scss']
})
export class ProjectBillingComponent implements OnInit {

  id: any;

  project: any;

  invoices : any = [];

  constructor(
    private api: ScApiService,
    private projectService : ProjectService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.get(`invoices?projectId=${this.id}`).subscribe(q => this.invoices = q);

      var cached = this.projectService.getFromCache(this.id);
      if (cached)
      {
        this.project = cached;
      }

      this.projectService.load(this.id).subscribe(p => {
        this.project = p;
      });
    });
  }
}
