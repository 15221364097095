import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-project-package-action-modal',
  templateUrl: './project-package-action-modal.component.html',
  styleUrls: ['./project-package-action-modal.component.scss']
})
export class ProjectPackageActionModalComponent implements OnInit {

  @ViewChild(ModalWizardComponent)
  modal : ModalWizardComponent;

  @ViewChild('confirmation')
  confirmation : ModalWizardPageComponent;

  @ViewChild('error')
  error : ModalWizardPageComponent;

  @Input()
  package;

  @Input()
  title;

  @Input()
  showMailOption = false;

  @Input()
  actionName;

  sendMail = true;

  isExecuting = false;

  errorMessage = '';

  constructor(private api : ScApiService) { }

  ngOnInit(): void {
  }

  execute()
  {
    if (this.isExecuting) return;
    this.isExecuting = true;

    var uri = `packages/${this.package.Id}/actions/${this.actionName}`;
    if (this.showMailOption) uri += `?sendMail=${this.sendMail}`;

    this.api.post(uri, null).subscribe((d : any) => {
      
      if (d?.Status) this.package.Status = d.Status;
      if (d?.StatusIndex) this.package.StatusIndex = d.StatusIndex;
      if (d?.IsCancelled) this.package.IsCancelled = d.IsCancelled;      
      
      this.modal.navigateTo(this.confirmation);
    }, (e : any) => {
      this.errorMessage = e.error.Message;
      this.modal.navigateTo(this.error)
    }, () => {
      this.isExecuting = false;
    });
  }

  show()
  {
    this.modal.show().subscribe();
  }

}
