<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div class="header">
    <div class="container">
      <div class="row">

        <div class="col">
          <app-nav-project-sourcing [project]="project" active="screening"></app-nav-project-sourcing>
        </div>

        <div class="col-auto">
          <div ngbDropdown>
            <button ngbDropdownToggle class="btn btn-sm btn-secondary btn-circle">
              <i class="fa-regular fa-ellipsis"></i>
            </button>

            <div ngbDropdownMenu class="dropdown-menu">

              <a ngbDropdownItem class="dropdown-item" (click)="score();">
                <i class="fa-solid fa-play me-2"></i> Neu matchen
              </a>

              <div class="dropdown-divider"></div>

              <a ngbDropdownItem class="dropdown-item" (click)="addCandidate()">
                <i class="fa-solid fa-user-plus me-2"></i> Kandidat:in hinzufügen
              </a>

              <a ngbDropdownItem class="dropdown-item" (click)="importCandidatesModal.show()">
                <i class="fa-solid fa-user-plus me-2"></i> Kandidat:innen aus Projekt importieren
              </a> 

              <div class="dropdown-divider"></div>

              <div class="dropdown-divider"></div>

              <a ngbDropdownItem class="dropdown-item" (click)="setBatchState('excluded', true);">
                <i class="fa-solid fa-thumbs-down me-2"></i> Rest ausschließen
              </a>
              <a ngbDropdownItem class="dropdown-item" (click)="setBatchState('included', true);">
                <i class="fa-solid fa-thumbs-up me-2"></i> Rest einschließen
              </a>
              <!-- <a ngbDropdownItem class="dropdown-item" (click)="moveRestToBatch();">
                Rest in neue Batch
              </a>-->
              <a ngbDropdownItem class="dropdown-item" (click)="deleteCandidates();">
                Rest löschen
              </a>               
              <a ngbDropdownItem class="dropdown-item" (click)="markBAsAProfiles();">
                <i class="fa-solid fa-thumbs-up me-2"></i> B- zu A-Profilen machen
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="container" *ngIf="project">

    <div class="row mb-2">
      <div class="col"></div>
      <div class="col-auto">
        <span class="text-muted">{{ resultCount }} / {{ stats?.Total }} Ergebnissen</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row mt-3">
              <div class="col">
                <select class="custom-select" [(ngModel)]="sort" (ngModelChange)="loadCandidatesSoon();">
                  <option [ngValue]="'score'">Sortierung: Score</option>
                  <option [ngValue]="'name'">Sortierung: Nachname</option>
                  <option [ngValue]="'company'">Sortierung: Unternehmen</option>
                </select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <select class="custom-select" [(ngModel)]="filter.BatchId" (ngModelChange)="loadCandidatesSoon();">
                  <option [ngValue]="'-1'">Alle Batches</option>
                  <option *ngFor="let batch of project.Batches" [ngValue]="batch.toString()">Batch {{ batch }}</option>
                </select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <input [(ngModel)]="filter.QueryHaves" class="form-control" placeholder="Haves" (scOnEnter)="loadCandidatesSoon();">
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <input [(ngModel)]="filter.QueryName" class="form-control" placeholder="Name" (scOnEnter)="loadCandidatesSoon();">
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <input [(ngModel)]="filter.QueryJobTitle" class="form-control" placeholder="Job-Titel" (scOnEnter)="loadCandidatesSoon();">
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <input [(ngModel)]="filter.QueryEmployer" class="form-control" placeholder="Unternehmen" (scOnEnter)="loadCandidatesSoon();">
              </div>
            </div>

            <div class="row mt-4">

              <div class="col text-truncate">
                <span class="mb-0 me-1">Matches: {{stats?.Matches}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsMatch" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col text-truncate">
                <span class="mb-0 me-1">A-Profil: {{stats?.Included}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsIncluded" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-truncate">
                <span class="mb-0 me-1">B-Brofil: {{stats?.IncludedSecondary}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsIncludedSecondary" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-truncate">
                <span class="mb-0 me-1">Ausgeschlossen: {{stats?.Excluded}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsExcluded" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-truncate">
                <span class="mb-0 me-1">Blacklist: {{stats?.Blacklisted}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsBlacklisted" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col text-truncate">
                <span class="mb-0 me-1">Kontaktiert: {{stats?.Contacted}}</span>
              </div>

              <div class="col-auto">
                <filter-badge [(filterValue)]="filter.IsContacted" (filterValueChange)="loadCandidatesSoon()">
                </filter-badge>
              </div>

            </div>

            <div class="row mt-4 mb-2" *ngIf="tags?.length > 0">
              <div class="col-12">
                <span class="badge ms-1 mb-2 me-1" [class.bg-light]="!tag.isSelected && !tag.isExcluded" [class.badge-primary]="tag.isSelected" [class.bg-danger]="tag.isExcluded" *ngFor="let tag of tags" (click)="toggleTag(tag);" style="cursor: pointer;" [title]="tag.Tag">{{truncate(tag.Tag, 50)}} ({{ tag.Count }})</span>
              </div>

            </div>

            <div class="row mt-4">
              <div class="col-12">
                <button class="btn btn-block btn-sm btn-dark" (click)="clearFilters()">
                  Filter zurücksetzen
                </button>
              </div>
              <div class="col-12 mt-2">
                <button class="btn btn-block btn-sm btn-dark" (click)="setFiltersForScreening()">
                  Screening-Filter setzen
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="col-md-9">

        <div class="card" *ngIf="isScoringInProgress">
          <div class="card-body">
            <ngb-progressbar class="w-100" type="primary" [value]="scoringProgress"></ngb-progressbar>
          </div>
        </div>

        <div>

          <div class="alert alert-secondary mb-3" *ngIf="hasInconsistentScores">
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="fa-regular fa-triangle-exclamation"></i>
              </div>
              <div class="col">
                Die Scoring-Ergebnisse sind nicht aktuell!
              </div>
              <div class="col-auto">
                <button class="btn btn-dark" (click)="score();">Jetzt neu matchen</button>
              </div>
            </div>
          </div>

          <div class="card mb-3" *ngFor="let result of results; let index = index;">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <div class="card-title mb-0">
                    <span>
                      <h4 class="mb-0 d-inline-block me-2">
                        {{result.JobTitle}}
                      </h4>
                      <app-status-badge-project-person-assignment class="me-2" [assignment]="result.Assignment"></app-status-badge-project-person-assignment>
                      <span *ngIf="result.Assignment.IsHiredPreviously" class="badge badge-pill badge-warning-soft me-2">Vormalige Einstellung</span>
                      <span *ngIf="result.Assignment.IsAcceptedCurrently" class="badge badge-pill badge-warning-soft me-2">Anderweitige Bewerbung</span>
                      <app-project-search-result-error-badges [result]="result.Result"></app-project-search-result-error-badges>
                    </span>

                    <div class="row mt-3 small">
                      <div class="col-12 oneline">
                        <span><i class="fa-regular fa-user me-2"></i> {{result.FirstName}} {{result.LastName}}</span> <br>
                      </div>
                      <div class="col-12 oneline">
                        <span><i class="fa-regular fa-building me-2"></i> {{result.Company}} </span> <br>
                      </div>
                      <div class="col-12 oneline">
                        <span><i class="fa-regular fa-location-dot me-2"></i> {{result.City}} - {{result.Result.Distance | number:'1.0-0'}}km </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-auto">
                  <a class="avatar avatar-xl" routerLink="/candidates/{{result.Id}}" target="_blank">
                    <img [preferredSrc]="result.Image" fallbackSrc="assets/img/avatar-placeholder.jpg" class="avatar-img rounded-circle">
                  </a>
                </div>
              </div>
            </div>

            <div class="card-body" *ngIf="result.Result.Warnings?.length">
              <p *ngFor="let warning of result.Result.Warnings" class="badge badge-pill badge-warning me-1 mb-1">
                <i class="fa-regular fa-triangle-exclamation"></i>&nbsp;{{warning}}
              </p>
            </div>

            <div class="card-body">
              <div *ngIf="!result.Result?.HaveResults?.length">
                <ul class="tag-list list-inline mb-0">
                  <li class="list-inline-item" *ngFor="let tag of result.havesList"><span class="tag tag-clip-text tag-light">{{tag}}</span></li>
                </ul>
              </div>
              <div class="tag-list">
                <span class="tag mb-2 me-2" [class.badge-light]="!haveResult.IsPositiveMatch && !haveResult.IsNegativeMatch" [class.badge-success-soft]="haveResult.IsPositiveMatch && !haveResult.IsNegativeMatch" [class.badge-danger-soft]="haveResult.IsNegativeMatch" *ngFor="let haveResult of result.Result.HaveResults" [title]="haveResult.Key">{{truncate(haveResult.Key, 50)}}</span>
              </div>

            </div>

            <div class="card-body">
              <div class="row mb-n3">
                <div class="col-md-12" *ngIf="result.Profile?.Experience?.length">                  

                  <div class="row mb-3" *ngFor="let e of result.Profile.Experience;">
                    <div class="col-auto">
                      <i class="fa-sharp fa-solid fa-industry-windows"></i>
                      
                    </div>
                    <div class="col">
                      <h6 class="mb-0">{{e.Title}}</h6>
                      <span class="small">{{e.CompanyName}}</span>
                    </div>
                    <div class="col-auto">
                      <span *ngIf="!e.ToPresent && e.Start && e.End" class="mb-1">{{e.Start | date : 'MM/yyyy'}} -
                        {{e.End | date : 'MM/yyyy' }}</span>
                      <span *ngIf="!e.ToPresent && e.Start && !e.End" class="mb-1">{{e.Start | date : 'MM/yyyy'}}
                        - n.a.</span>
                      <span *ngIf="!e.ToPresent && !e.Start && e.End" class="mb-1">n.a. -
                        {{e.End | date : 'MM/yyyy' }}</span>
                      <span *ngIf="!e.ToPresent && !e.Start && !e.End" class="mb-1">n.a.</span>
                      <span *ngIf="e.ToPresent && e.Start" class="mb-1">{{e.Start | date : 'MM/yyyy'}} -
                        heute</span>
                      <span *ngIf="e.ToPresent && !e.Start" class="mb-1">n.a. - heute</span>
                    </div>
                  </div>

                </div>

                <div class="col-12" *ngIf="result.Profile?.Education?.length && result.Profile?.Experience?.length">
                  <hr>
                </div>

                <div class="col-md-12 mt-4" *ngIf="result.Profile?.Education?.length">

                  

                  <div class="row mb-3" *ngFor="let e of result.Profile.Education;">
                    <div class="col-auto">
                      <i class="fa-sharp fa-solid fa-graduation-cap"></i>
                    </div>
                    <div class="col">
                      <h6 class="mb-0">{{e.Title}}</h6>
                      <span class="small">{{e.Institute}}</span>
                      <br>
                    </div>
                    <div class="col-auto text-right">
                      <span *ngIf="!e.ToPresent && e.Start && e.End" class="mb-1">{{e.Start | date : 'MM/yyyy'}} -
                        {{e.End | date : 'MM/yyyy' }}</span>
                      <span *ngIf="!e.ToPresent && e.Start && !e.End" class="mb-1">{{e.Start | date : 'MM/yyyy'}}
                        - n.a.</span>
                      <span *ngIf="!e.ToPresent && !e.Start && e.End" class="mb-1">n.a. -
                        {{e.End | date : 'MM/yyyy' }}</span>
                      <span *ngIf="!e.ToPresent && !e.Start && !e.End" class="mb-1">n.a.</span>
                      <span *ngIf="e.ToPresent && e.Start" class="mb-1">{{e.Start | date : 'MM/yyyy'}} -
                        heute</span>
                      <span *ngIf="e.ToPresent && !e.Start" class="mb-1">n.a. - heute</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col"></div>
                <div class="col-auto">

                  <!-- <div title="AI Score" class="btn btn-circle btn-dark me-2 ai" (click)="getAIScore(result)">
                    <i class="fa-regular fa-microchip-ai"></i>
                  </div> -->

                  <div title="A-Profil" class="btn btn-circle {{ result.Assignment.IsIncluded ? 'btn-dark' : 'btn-secondary' }} me-2" (click)="toggleIncluded(result)">
                    <i class="fa-regular fa-thumbs-up"></i>
                  </div>

                  <div title="B-Profil" class="btn btn-circle {{ result.Assignment.IsIncludedSecondary ? 'btn-dark' : 'btn-secondary' }} me-2" (click)="toggleIncludedSecondary(result)">
                    <i class="fa-regular fa-flag"></i>
                  </div>

                  <div class="btn btn-circle {{ result.Assignment.IsExcluded ? 'btn-dark' : 'btn-secondary' }}" (click)="toggleExcluded(result)">
                    <i class="fa-regular fa-thumbs-down"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row d-flex justify-content-center mb-4" *ngIf="results?.length > 0">
            <div>
              <button class="btn btn-sm btn-secondary me-2" [disabled]="itemsLoading" (click)="loadMoreCandidates()">{{ itemsLoading ? 'Lädt...' : 'Weitere laden'}}</button>
              <button class="btn btn-sm btn-secondary" [disabled]="itemsLoading" (click)="excludeAll()">Rest ausschließen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #scoringReportModal size="xl">
  <div class="modal-header">
    <h4 class="modal-title">Scoring Report</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="scoringReportModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="selectedResult">
    <textarea style="font-family:monospace;" [(ngModel)]="selectedResult.Result.Report" class="form-control" name="blacklist" rows="30" readonly></textarea>
  </div>
</sc-modal>

<app-modal-select-person #addPersonModal></app-modal-select-person>

<!-- <app-modal-project-search-post-chat #postChatModal [projectId]="id" [filter]="filter" [tags]="tags" #modal size="lg"></app-modal-project-search-post-chat> -->

<sc-modal #proceedModal>
  <sc-modal-header>
    Fortfahren?
  </sc-modal-header>
  <sc-modal-body>
    {{ proceedMessage }}
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-secondary" (click)="proceedModal.dismiss();">Abbrechen</button>
    <button class="btn btn-primary" (click)="proceedModal.complete();">Fortfahren</button>
  </sc-modal-footer>
</sc-modal>

<app-project-search-modal-add-to-pool #addToPoolModal [project]="project" [filter]="filter">
</app-project-search-modal-add-to-pool>

<sc-modal #createTrainingBatchModal>
  <sc-modal-header>
    Training-Batch erzeugen
  </sc-modal-header>
  <sc-modal-body>

  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-dark" (click)="createTrainingBatch(); createTrainingBatchModal.dismiss()">Erzeugen</button>
  </sc-modal-footer>
</sc-modal>

<sc-modal #importCandidatesModal>
  <sc-modal-header>
    Kandidat:innen aus Projekt importieren
  </sc-modal-header>
  <sc-modal-body>
    <sc-form>
      <sc-form-row label="Projekt-ID (Quelle)">
        <input [(ngModel)]="importArgs.SourceProjectId" />
      </sc-form-row>
      <sc-form-row label="Batch-ID (Quelle)">
        <input [(ngModel)]="importArgs.SourceBatchId" />
      </sc-form-row>
      <sc-form-row label="Batch-ID (Ziel)">
        <input [(ngModel)]="importArgs.TargetBatchId" />
      </sc-form-row>
      <sc-form-row label="Kontaktierte Profile einschließen">
        <sc-switch [(ngModel)]="importArgs.IncludeContacted" ></sc-switch>
      </sc-form-row>
      <sc-form-row label="Screening-Status übernehmen">
        <sc-switch [(ngModel)]="importArgs.CopyIncludedExcluded" ></sc-switch>
      </sc-form-row>
    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-dark" (click)="importCandidates()" [disabled]="isImporting">Importieren</button>
  </sc-modal-footer>
</sc-modal>

