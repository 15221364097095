<div class="comment">

    <div class="row">
        <div class="col-auto">
            <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
        </div>

        <div class="col ms-n2">
            <div class="comment-header">
                <div class="row">
                    <div class="col">
                        <sc-activity-item-header [context]="context" [activity]="activity"></sc-activity-item-header>
                        <div class="comment-label">
                            <p class="mb-0 activity" *ngIf="activity?.Candidate">
                                <span *ngIf="activity.Payload?.Type == 'Call'">Call</span>
                                <span *ngIf="activity.Payload?.Type == 'EMail'">Mail</span>
                                <span *ngIf="activity.Payload?.Type == 'DemoCall'">Demo-Call</span>
                                <span *ngIf="activity.Payload?.Type == 'Webinar'">Webinar</span>
                                <span *ngIf="activity.Payload?.Type == 'None' || activity.Payload?.Type == 'Other' || !activity.Payload?.Type" >Sales-Ereignis</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comment-body-container" *ngIf="!condensed && (activity.Payload?.Outcome || activity.Payload?.NewStatus || activity.Payload?.Comment)">
                <div class="comment-body">
                    <div class="row mb-1 align-items-center no-gutters" *ngIf="activity.Payload?.NewStatus && activity.Payload?.NewStatus != activity.Payload?.OldStatus">
                        <div class="col-auto pe-2" *ngIf="activity.Payload?.OldStatus && activity.Payload?.OldStatus != 'None' && activity.Payload?.OldStatus != ''">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.OldStatus }}</span>
                        </div>
                        <div class="col-auto pe-2">
                            <span><i class="fa-sharp fa-solid fa-arrow-right"></i></span>
                        </div>
                        <div class="col-auto">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.NewStatus }}</span>
                        </div>
                    </div>

                    <div class="row mb-1 align-items-center no-gutters" *ngIf="activity.Payload?.NewSalesOnHoldUntil && activity.Payload?.NewSalesOnHoldUntil != activity.Payload?.OldSalesOnHoldUntil">
                        <div class="col-auto pe-2" *ngIf="activity.Payload?.OldSalesOnHoldUntil">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.OldSalesOnHoldUntil | date : 'dd.MM.yyyy' }}</span>
                        </div>
                        <div class="col-auto pe-2">
                            <span><i class="fa-sharp fa-solid fa-arrow-right"></i></span>
                        </div>
                        <div class="col-auto">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.NewSalesOnHoldUntil | date : 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>

                    <p class="mb-1" *ngIf="activity.Payload?.Outcome"><strong>Outcome: </strong> {{ activity.Payload?.Outcome }} </p> 
                    <p class="mb-1" *ngIf="activity.Payload?.Comment" [innerHTML]="activity.Payload?.Comment"></p>
                </div>
            </div>
        </div>

    </div>
</div>