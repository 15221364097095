import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feedbackRating'
})
export class FeedbackRatingPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == 0) return "Trifft nicht zu";
    if (value == 1) return "Trifft eher nicht zu";
    if (value == 2) return "Neutral";
    if (value == 3) return "Trifft eher zu";
    if (value == 4) return "Trifft zu";
    return "n.a.";
  }

}
