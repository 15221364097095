import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectManagerComponent } from '../modal-select-manager/modal-select-manager.component';

@Component({
  selector: 'app-inline-select-manager',
  templateUrl: './inline-select-manager.component.html',
  styleUrls: ['./inline-select-manager.component.scss']
})
export class InlineSelectManagerComponent {
  @Input()
  managerId : any;

  @Output()
  managerIdChange = new EventEmitter<any>();

  @ViewChild(ModalSelectManagerComponent)
  modal : ModalSelectManagerComponent;

  person : any;

  constructor(private api : ScApiService)
  {}

  ngOnChanges()
  {
    if(!this.managerId)
    {
      this.person = null;
      return;
    }
    if (this.managerId != this.person?.Id)
    {
      this.api.get('contacts/' + this.managerId).subscribe(d => {
        this.person = d;
      })
    }
  }

  selectPerson()
  {    
    this.modal.show().subscribe((d : any) => {
      this.person = d;
      this.managerId = d.Id;
      this.managerIdChange.next(this.managerId);
    });
  }
}
