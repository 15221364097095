import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScuiModalComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-modal-cadence-person-assignment',
  templateUrl: './modal-cadence-person-assignment.component.html',
  styleUrls: ['./modal-cadence-person-assignment.component.scss']
})
export class ModalCadencePersonAssignmentComponent {

  assignment: any;

  @Input()
  showGoToProfile = false;
  
  @Input()
  showGoToCadence = false;

  steps: any = [];

  @Output()
  change = new EventEmitter<any>();

  @ViewChild('modal')
  modal: ScuiModalComponent;

  constructor(private api: ScApiService, private router : Router) { }

  public show(assignment) {

    this.assignment = assignment;

    this.api.get('cadences/' + assignment.CadenceId + '/steps').subscribe(s => {
      this.steps = s;
    })

    this.modal.show();
  }

  public saveSoon() {
    if (this["saveTimeout"]) clearTimeout(this["saveTimeout"]);
    this["saveTimeout"] = setTimeout(() => {
      this.api.put('cadence-person-assignments', this.assignment).subscribe(() => {
        this.change.next(this.assignment);
      });
    });
  }

  navigateToPerson()
  {
    this.modal.dismiss();
    this.router.navigateByUrl('/candidates/' + this.assignment.PersonGuid);  
  }
  
  navigateToCadence()
  {
    this.modal.dismiss();
    this.router.navigateByUrl('/cadences/' + this.assignment.CadenceId);  
  }
}
