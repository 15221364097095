<app-topbar-candidate [candidate]="candidate">
</app-topbar-candidate>

<div class="main-content">

  <div class="container" *ngIf="candidate">

    <div class="row">

      <div class="col-8 candidate-content">
        <app-candidate-sales-base [activityFeed]="activities" [scAnchorTarget]="'Stammdaten'" [candidateActivities]="activities" class="mb-5" [(candidate)]="candidate" (candidateChange)="saveSoon()"></app-candidate-sales-base>
        <app-candidate-sales-projects [(organizationId)]="candidate.OrganizationId"></app-candidate-sales-projects>
        <app-candidate-activities #activities [candidate]="candidate"></app-candidate-activities>
      </div>

      <div class="col-4">

        <div class="sticky-sidebar scrollbar-none" [appFixPosition]>
          <app-candidate-sales-log-activity [activityFeed]="activities" [(candidate)]="candidate" (candidateChange)="saveSoon()" (nextLead)="goToNextLead()"></app-candidate-sales-log-activity>
          <app-candidate-organizations [(organizationId)]="candidate.OrganizationId" (organizationIdChange)="saveSoon()"></app-candidate-organizations>
          <app-candidate-tasks class="mb-3" [(candidate)]="candidate"></app-candidate-tasks>
        </div>
      </div>

    </div>
  </div>
</div>
