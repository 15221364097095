<div *ngIf="company">

  <div class="card">

    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Aufgaben
          </h6>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-circle btn-secondary" (click)="addTaskModal.show(null, company)">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card-body card-body-list" *ngIf="tasks?.length">
      <div class="list-group">
        <div class="list-group-item list-group-item-action" (click)="editTaskModal.show(task);" *ngFor="let task of tasks">
          <div class="row align-items-center flex-nowrap">

            <div class="col-auto">
              <sc-avatar class="d-inline-block" [user]="task.Contact" size="sm"></sc-avatar>
            </div>

            <div class="col">
              <span>
                <h6 class="mb-1 oneline d-inline" *ngIf="task.Payload?.Description">
                  {{ task.Payload?.Description }}
                </h6>
                <h6 class="mb-1 oneline d-inline" *ngIf="!task.Payload?.Description">
                  {{ task | task }}
                </h6>
              </span>

              <p class="card-text text-muted small mb-0 oneline">
                <span>{{ task.Due | humanizedDate }}</span>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-task-edit #editTaskModal></app-modal-task-edit>
<app-modal-task-add #addTaskModal></app-modal-task-add>

