import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { SidebarService } from 'src/services/sidebar.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  message: any = '';

  messages: any = [];

  candidate: any;

  mode = 'overview';

  lastXtmProfile: any = null;

  autoSelectProject = false;

  constructor(private api: ScApiService,
    private router: Router,
    public sidebarService: SidebarService) { }

  ngOnInit(): void {
    if (!this.checkAuth()) return;
    this.refreshInspectedTab();
    this.sidebarService.onDevToolsMessage.subscribe(m => this.handleMessage(m));
  }

  checkAuth() {
    if (!this.api.userEMail || !this.api.userToken) {
      this.router.navigateByUrl('/sidebar/login');
      return false;
    }

    return true;
  }

  refreshInspectedTab() {
    var args = {
      type: 'refreshInspectedTab'
    };

    this.sidebarService.sendDevToolsMessage(args);
  }

  handleMessage(message) {
    if (message.type == 'chrome.devtools.network.onRequestFinished') {
      if (message.match == 'xingProfile') {

      }
      if (message.match == 'xingOne') {

        this.handleOneApiResponse(message);
      }

      if (message.match == 'xingMessages') {

        var response = JSON.parse(message.body);

        if (!response.messages?.length) return;

        var recipients = response.messages[0].to;
        if (recipients.length == 0) return;
        
        var recipient = recipients[0];
        recipient.id = recipient.identityId;

        this.api.post('sidebar/xtm-profile-info', recipient).subscribe((d: any) => {
          if (d.ExactMatch) {
            this.router.navigateByUrl('/sidebar/candidate/' + d.ExactMatch.Guid + '?autoProject=true');
            return;
          }
        });
      }

      if (message.match == 'linkedInRecruiterProfile') {
        var response = JSON.parse(message.body);

        this.api.post('sidebar/linkedin-profile-info', response).subscribe((d: any) => {

          this.sidebarService.setPotentialMatches(d.PotentialMatches);
          this.sidebarService.setProfile(d.NewCandidate);

          if (d.ExactMatch) {
            this.router.navigateByUrl('/sidebar/candidate/' + d.ExactMatch.Guid + '?autoProject=' + this.autoSelectProject);
            return;
          }

          this.router.navigateByUrl('/sidebar/candidate-new');
        })
      }
    }

    if (message.type == 'chrome.tabs.onUpdated') {

      if (message.url.startsWith('https://www.xing.com/xtm/search/results')) {
        var search = { source: 'xtm', uri: message.url };
        this.sidebarService.setSearch(search);
        this.router.navigateByUrl('/sidebar/search');
      }

      else if (message.url.startsWith('https://www.linkedin.com/talent/inbox/')) {
        this.autoSelectProject = true;
      }

      else {
        this.autoSelectProject = false;
      }
    }
  }


  handleXtmProfileResponse(message) {
    // var xtmProfile = JSON.parse(message.body);
    // var xingId = this.lastXtmProfile.id;
    // this.lastXtmProfile = xtmProfile;
    // this.requestInfoIfXtmProfileComplete();
  }

  handleOneApiResponse(message) {

    var response = JSON.parse(message.body);

    var scrambledId = response?.data?.xingId?.id;
    if (!scrambledId) return;
    var xingId = parseInt(scrambledId.split('.')[0]);

    this.lastXtmProfile = {
      id: xingId
    };

    this.lastXtmProfile.oneApiReceived = true;

    if (response.data?.profileWorkExperience?.collection) {
      this.lastXtmProfile.workExperience = response.data?.profileWorkExperience?.collection;
      response.data.profileWorkExperience.collection.forEach(e => {
        if (e.currentCompany && e.primaryJob) {
          this.lastXtmProfile.companyName = e.companyName;
          this.lastXtmProfile.companyTitle = e.jobTitle;
        }
      })
    }

    if (response.data?.profileEducation?.education) {
      this.lastXtmProfile.education = response.data?.profileEducation?.education;
    }

    if (response.data?.profileSkills?.skills) {
      this.lastXtmProfile.haves = response.data?.profileSkills?.skills;
    }

    if (response.data?.profileLanguageSkills?.languageSkills) {
      this.lastXtmProfile.languages = response.data?.profileLanguageSkills?.languageSkills;
    }

    if (response.data?.xingId?.firstName) {
      this.lastXtmProfile.firstName = response.data?.xingId?.firstName;
    }

    if (response.data?.xingId?.lastName) {
      this.lastXtmProfile.lastName = response.data?.xingId?.lastName;
    }

    if (response.data?.xingId?.profileImage?.length) {
      this.lastXtmProfile.photoUrl = response.data?.xingId?.profileImage[0]?.url.replace('.128x128.jpg', '');
    }

    this.api.post('sidebar/xtm-profile-info', this.lastXtmProfile).subscribe((d: any) => {

      this.sidebarService.setPotentialMatches(d.PotentialMatches);
      this.sidebarService.setProfile(d.NewCandidate);

      if (d.ExactMatch) {
        this.router.navigateByUrl('/sidebar/candidate/' + d.ExactMatch.Guid);
        return;
      }

      this.router.navigateByUrl('/sidebar/candidate-new');
    });
  }
}
