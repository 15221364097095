<div *ngIf="sidebarService.profile">

  <div class="row align-items-start">
    <div class="col-12  mb-6 mt-5">
      <app-sidebar-candidate-header [candidate]="sidebarService.profile"></app-sidebar-candidate-header>
    </div>

    <div class="col-12">
      <div class="mb-2" *ngIf="sidebarService.blacklistInfo?.Pass == false">
        <div class="alert alert-error">
          <strong>Auf Blacklist</strong><br />
          <span *ngFor="let message of sidebarService.blacklistInfo.Messages">
            {{ message }} <br>
          </span>
        </div>
      </div>
      <ul class="list-unstyled">
        <li>
          <app-sidebar-candidate-exact-item icon="fa-solid fa-user-plus" (click)="createCandidate()">Als neues Profil importieren</app-sidebar-candidate-exact-item>
        </li>
        <li *ngFor="let candidate of sidebarService.potentialMatches">
          <app-sidebar-candidate-exact-item icon="fa-regular fa-merge" [user]="candidate" (click)="goToMerge(candidate)">
            <div class="oneline">{{candidate.JobTitle }}</div>
            <div class="oneline small">{{ candidate.Company }}</div>
          </app-sidebar-candidate-exact-item>
        </li>
      </ul>
    </div>
  </div>

</div>
