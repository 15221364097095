import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-search-result-error-badges',
  templateUrl: './project-search-result-error-badges.component.html',
  styleUrls: ['./project-search-result-error-badges.component.scss']
})
export class ProjectSearchResultErrorBadgesComponent {

  @Input()
  result : any;
}
