import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-card-billing-information',
  templateUrl: './company-card-billing-information.component.html',
  styleUrls: ['./company-card-billing-information.component.scss']
})
export class CompanyCardBillingInformationComponent implements OnChanges {

  @Input()
  company : any;

  @Output()
  companyChange = new EventEmitter<any>();

  billingAddresses : any = [];

  newBillingAddress : any = {
    Country : "Deutschland"    
  };

  selectedBillingAddress : any = null;

  constructor(private api : ScApiService) { }

  ngOnChanges()
  {
    this.api.get("companies/" + this.company.Id + '/billing-addresses').subscribe(d => {
      this.billingAddresses = d;
      this.newBillingAddress.CompanyId = this.company.Id;
    });
  }

  onChange()
  {
    this.companyChange.next(this.company);
  }

  addBillingAddress() {
    this.newBillingAddress.CompanyId = this.company.Id;
    this.api.post('billing-addresses/', this.newBillingAddress).subscribe((d : any) => {
      this.billingAddresses.push(d.BillingAddress);
      this.company.DefaultBillingAddressId = d.DefaultBillingAddressId;
      this.newBillingAddress = { Country: 'Deutschland'};
    });
  }

  saveSelectedBillingAddress() {
    this.api.put("billing-addresses/", this.selectedBillingAddress).subscribe(d => {
    });
  }

  deleteSelectedBillingAddress() {
    this.api.delete("billing-addresses/" + this.selectedBillingAddress.Id).subscribe((d : any) => {
      var i = this.billingAddresses.indexOf(this.selectedBillingAddress);
      this.billingAddresses.splice(i, 1);
      this.company.DefaultBillingAddressId = d.DefaultBillingAddressId;
    });
  }

  makeDefaultBillingAddress(d)
  {
    this.company.DefaultBillingAddressId = d.Id;
    this.billingAddresses.forEach(i => i.IsDefault = i.Id == d.Id);
    this.onChange();
  }

}
