import { Component, Input, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-task-detail-cadence-mail-reply',
  templateUrl: './task-detail-cadence-mail-reply.component.html',
  styleUrls: ['./task-detail-cadence-mail-reply.component.scss']
})
export class TaskDetailCadenceMailReplyComponent implements OnChanges {

  @Input()
  task : any;

  mail : any;

  phoneNumbers : any;

  organization: any;

  person : any;
  
  constructor(private api : ScApiService) {}

  ngOnChanges()
  {
    if(!this.task) return;

    this.api.get('notifications/' + this.task.Id + '/details').subscribe((d : any) => {
      this.mail = d.Mail;
      this.person = d.Person;
      this.organization = d.Organization;
      this.phoneNumbers = d.RecognizedPhoneNumbers;
    });
  }

  savePhoneNumber(phoneNumber)
  {
    var value = phoneNumber.FormattedNumber;
    var isMobile = phoneNumber.Kind == 'MobilePhoneNumber';
    var arg = isMobile ? { Mobile: value } : { Phone : value };
    this.api.put('candidates/' + this.person.Guid, arg).subscribe();
  }
}
