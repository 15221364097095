<ng-template [appTopbarTemplate]>
  <div class="title h4">
    {{ company?.Name }}
  </div>

  <div class="nav">
    <!-- <app-nav-tabs></app-nav-tabs> -->

    <ul class="nav nav-tabs nav-overflow" *ngIf="company">

      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'base'" (click)="activateView('base')">
          Stammdaten
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'people'" (click)="activateView('people')">
          Personen
        </a>
      </li>
      <li class="nav-item" *ngIf="!company.IsGroup">
        <a class="nav-link" [class.active]="view == 'assets'" (click)="activateView('assets')">
          Assets
        </a>
      </li>
      <li class="nav-item" *ngIf="!company.IsGroup">
        <a class="nav-link" [class.active]="view == 'projects'" (click)="activateView('projects')">
          Projekte
        </a>
      </li>
      <li class="nav-item" *ngIf="!company.IsGroup">
        <a class="nav-link" [class.active]="view == 'packages'" (click)="activateView('packages')">
          Kontingente
        </a>
      </li>
      <li class="nav-item" *ngIf="!company.IsGroup">
        <a class="nav-link" [class.active]="view == 'invoices'" (click)="activateView('invoices')">
          Rechnungen
        </a>
      </li>
      <li class="nav-item" *ngIf="!company.IsGroup">
        <a class="nav-link" [class.active]="view == 'billing-information'" (click)="activateView('billing-information')">
          Rechnungsinformationen
        </a>
      </li>
      <li class="nav-item" *ngIf="company?.IsGroup">
        <a class="nav-link" [class.active]="view == 'associated-companies'" (click)="activateView('associated-companies')">
          Tochtergesellschaften
        </a>
      </li>
    </ul>
  </div>

  <div class="actions">

    <div class="d-inline-block" ngbDropdown>
      <div ngbDropdownToggle>
        <button class="btn btn-secondary btn-sm btn-circle">
          <i class="fa-regular fa-ellipsis"></i>
        </button>
      </div>
      <div ngbDropdownMenu>
        <a class="dropdown-item" (click)="blacklistModal.show();">Blacklists bearbeiten</a>       
        <a class="dropdown-item" (click)="deleteModal.show();"><i class="fa-regular fa-trash me-2"></i> Löschen</a>

      </div>
    </div>
  </div>
</ng-template>

<div class="main-content" *ngIf="company">

  <div class="container">

    <div class="row" *ngIf="view == 'base'">

      <div class="col-8">
        <app-company-base [company]="company"></app-company-base>   
      </div>

      <div class="col-4">

        <div class="card mb-3">
          <div class="card-body d-flex align-items-center">
            <sc-file-button class="mx-auto" (filesChanged)="uploadLogo($event)">

              <img *ngIf="company.Profile?.LogoUri" class="logo" [src]="company.Profile?.LogoUri" />

              <div *ngIf="!company.Profile?.LogoUri" class="logo-placeholder">
                <i class="fa-regular fa-image fa-3x mx-auto"></i>
              </div>
            </sc-file-button>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header">
            <h6 class="card-header-title">
              Bezeichnungen
            </h6>
          </div>
          <div class="card-body">

            <div class="list-group mb-2">
              <div class="list-group-item py-1" *ngFor="let i of company.Names">
                <div class="row">
                  <div class="col oneline">
                    {{ i.NormalizedName }}
                  </div>
                  <div class="col-auto clickable" (click)="removeName(i)">
                    <i class="fa-regular fa-sm fa-xmark"></i>
                  </div>
                </div>
              </div>
            </div>

            <input #n class="form-control form-control-sm" (scOnEnter)="addName(n.value)" />

          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header">
            <h6 class="card-header-title">
              Domains
            </h6>
          </div>
          <div class="card-body">
            <div class="list-group mb-2">
              <div class="list-group-item py-1" *ngFor="let i of company.Domains">
                <div class="row">
                  <div class="col oneline">
                    {{ i.Host }}
                  </div>
                  <div class="col-auto clickable" (click)="removeDomain(i)">
                    <i class="fa-regular fa-sm fa-xmark"></i>
                  </div>
                </div>
              </div>
            </div>

            <input #d class="form-control form-control-sm" (scOnEnter)="addDomain(d.value)" />

          </div>
        </div>

        <app-company-tasks [company]="company"></app-company-tasks>
      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <app-company-associated-companies *ngIf="view == 'associated-companies'" [company]="company"></app-company-associated-companies>
        <app-company-people *ngIf="view == 'people'" [company]="company"></app-company-people>
        <app-company-card-invoices *ngIf="view == 'invoices'" [company]="company"></app-company-card-invoices>
        <app-company-card-billing-information *ngIf="view == 'billing-information'" [company]="company" (companyChange)="saveSoon()"></app-company-card-billing-information>
        <app-company-projects *ngIf="view == 'projects'" [company]="company"></app-company-projects>
        <app-company-card-packages *ngIf="view == 'packages'" [company]="company"></app-company-card-packages>
        <app-company-assets *ngIf="view == 'assets'" [company]="company" (companyChange)="saveSoon()"></app-company-assets>
      </div>
    </div>

  </div>

</div>


<sc-modal #tagsModal size="lg">
  <div class="modal-header">
    <h4 class="modal-title">Hashtags</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="tagsModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let tag of tags">
      <div class="col-3">
        <button (click)="addTag(tag.Tag)" class="btn btn-sm btn-secondary">
          {{tag.Tag}}
        </button>
      </div>
      <div class="col-9 text-muted">
        {{tag.Description}}
      </div>
      <div class="col-12">
        <hr />
      </div>
    </div>
  </div>
</sc-modal>

<sc-modal size="lg" #blacklistModal>
  <div class="modal-header">
    <h4 class="modal-title">Blacklists</h4>
  </div>
  <div class="modal-body" *ngIf="blacklistModal.isOpen">
    <div class="row mb-2">
      <div class="col-3 oneline">
        Blacklist (global)
      </div>
      <div class="col-9">
        <autosize-textarea [(text)]="company.BlacklistGlobal" (textChange)="saveSoon()"></autosize-textarea>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Blacklist (Unternehmensprojekte)
      </div>
      <div class="col-9">
        <autosize-textarea [(text)]="company.Blacklist" (textChange)="saveSoon()"></autosize-textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="blacklistModal.dismiss()">Schließen</button>
  </div>
</sc-modal>

<sc-modal #deleteModal>
  <sc-modal-header>
    Wirklich löschen?
  </sc-modal-header>
  
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>Willst du das Unternehmen wirklich unwiderruflich löschen? <strong>Alle Aktivitäten und Kontakte des Unternehmens werden ebenfalls gelöscht!</strong></p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteModal.dismiss()">
      Abbrechen
    </button>
    <button class="btn btn-dark" (click)="delete(); deleteModal.dismiss();">
      Unwiderruflich löschen
    </button>
  </div>
</sc-modal>
