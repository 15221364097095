import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-cadence-people',
  templateUrl: './cadence-people.component.html',
  styleUrls: ['./cadence-people.component.scss'],
  animations: [fadeIn]
})
export class CadencePeopleComponent implements OnInit {

  id : any;

  cadence: any;

  people : any = [];

  state = 'out';

  q = '';

  hasMoreItems = false;

  isLoading = false;

  skip = 0;

  take = 10;

  constructor(private route: ActivatedRoute, private api: ScApiService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {

        this.id = params.id;
        this.api.get('cadences/' + this.id).subscribe(d => {
          this.cadence = d;          
        });

        this.load();
      }
    })
  }

  load() {
    this.people.length = 0;
    this.skip = 0;
    this.q = '';
    this.loadMore();
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.people.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    if (!this.id) return;
    if (this.isLoading) return;

    this.isLoading = true;

    var uri = `cadences/${this.id}/people?skip=${this.skip}&take=${this.take}`;
    if (this.q) uri += `&q=${this.q}`;

    this.api.get(uri).subscribe(
      (d: any) => {
        this.skip += this.take;
        this.people.push.apply(this.people, d.Items);
        this.hasMoreItems = this.people.length < d.TotalCount;
        this.state = 'in';
      },
      () => { },
      () => { this.isLoading = false }
    );
  }
}
