import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-task-group',
  templateUrl: './task-group.component.html',
  styleUrls: ['./task-group.component.scss']
})
export class TaskGroupComponent implements OnDestroy {

  @Output()
  taskGroupSelected = new EventEmitter<any>();

  @Input()
  group: any;

  select(subGroup)
  {
    this.taskGroupSelected.next(subGroup);
  }

  ngOnDestroy(): void {
  }
}
