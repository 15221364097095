<sc-activity-item-highlight icon="fa-solid fa-check" [activity]="activity" [context]="context" *ngIf="condensed">
    hat das Review abgeschlossen
</sc-activity-item-highlight>

<sc-activity-item-comment [activity]="activity" [context]="context" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat das Review abgeschlossen
        </p>
    </div>
    <div class="comment-body">        
        <p>
			Must-Haves: {{ activity.Payload?.MustHaves }} 
		</p>
		<p>
			Nice-To-Haves: {{ activity.Payload?.NiceToHaves }} 
		</p>
    </div>
</sc-activity-item-comment>