import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-cadences',
  templateUrl: './pool-cadences.component.html',
  styleUrls: ['./pool-cadences.component.scss']
})
export class PoolCadencesComponent implements OnInit, OnChanges {

  @Input()
  pool: any;

  cadenceAssignments: any = []

  selectedCadenceAssignment: any;

  addCount = 10;

  isExecuting = false;

  @HostBinding('class.hide')
  get hidden() {
    return !this.cadenceAssignments?.length;
  }

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (!this.pool) return;

    this.loadCadences();
  }

  loadCadences() {
    this.api.get(`pools/${this.pool.Id}/cadences`).subscribe(d => {
      this.cadenceAssignments = d;
    });
  }

  saveCadenceAssignment(cadenceAssignment) {
    this.api.put(`pools/${this.pool.Id}/cadences`, cadenceAssignment).subscribe();
  }

  addCandidatesToCadence() {
    if (this.isExecuting) return;
    this.isExecuting = true;
    this.api.post(`pools/${this.pool.Id}/actions/add-candidates-to-cadence?cadenceId=${this.selectedCadenceAssignment.CadenceId}&count=${this.addCount}`, null).subscribe((d: any) => {
      this.loadCadences();
      this.isExecuting = false;
    });
  }

}
