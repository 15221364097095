import { Component, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-candidate-error-modal',
  templateUrl: './candidate-error-modal.component.html',
  styleUrls: ['./candidate-error-modal.component.scss']
})
export class CandidateErrorModalComponent {


  errorMessage : any = '';

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  show(message)
  {
    this.errorMessage = message;
    this.modal.show();
  }

}
