import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-projects-filter-modal',
  templateUrl: './projects-filter-modal.component.html',
  styleUrls: ['./projects-filter-modal.component.scss']
})
export class ProjectsFilterModalComponent implements OnInit {

  @Input()
  settings : any;

  @Output()
  changed = new EventEmitter<any>();

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;  
  
  constructor() { }

  ngOnInit(): void {
  }

  public show()
  {
    this.modal.show();
  }

  onChange()
  {
    this.changed.next(this.settings);
  }

  toggleStatus(s)
  {
    var entry = s + ',';

    if(this.settings.status.indexOf(entry) >= 0)
    {
      this.settings.status.replace(entry, '');
    }
    else
    {
      this.settings.status += entry;
    }
  }

}
