import { Component, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-modal-task-add',
  templateUrl: './modal-task-add.component.html',
  styleUrls: ['./modal-task-add.component.scss']
})
export class ModalTaskAddComponent {

  task : any = {};

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  constructor(private notifications : NotificationsService)
  {}

  show(candidate : any, organization : any, project : any = null)
  {
    
      this.task = {
        Content: '#custom',
        OrganizationId: organization?.Id,
        CandidateId: candidate?.Guid,
        ProjectId: project?.Id,
        Payload: {
          Description: 'Wiedervorlage'
        }
      };

      this.setDue(this.task, 0);

      return this.modal.show();
  }

  createTask()
  {
    this.notifications.create(this.task).subscribe();
    this.modal.complete(this.task);
    this.task = {};
  }

  setDue(task, months)
  {
    var date = new Date();
    date.setMonth(date.getMonth() + months);
    task.Due = date.toISOString();
  }

}
