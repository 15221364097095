<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div class="container mb-3">
    <div class="row">

      <div class="col">
        <app-nav-project-sourcing [project]="project" active="profile"></app-nav-project-sourcing>
      </div>

      <div class="col-auto pr-0">
        <button class="btn btn-sm btn-secondary" (click)="editBlacklist()">Arbeitgeber-Blacklist</button>
      </div>

      <div class="col-auto">
        <button class="btn btn-sm btn-secondary" (click)="editNameBlacklist()">Personen-Blacklist</button>
      </div>

    </div>

  </div>

  <div class="container" *ngIf="project">

    <div class="row">
      <div class="col-12">
        <div class="card">

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <label>Skills</label>
                <div class="row">
                  <div class="col-md-12" *ngFor="let skill of project.Profile?.Skills">
                    <div class="row mt-3">
                      <div class="col-md-2">
                        <input [(ngModel)]="skill.Name" class="form-control form-control-flush py-0" style="height: auto;" placeholder="Name">
                      </div>
                      <div class="col">
                        <div>
                          <div class="d-inline-block" *ngFor="let tag of skill.Haves">
                            <span class="badge badge-light ms-1 mb-2 me-1" [title]="tag.Key">
                              {{truncate(tag.Key, 50)}} <span style="cursor: pointer;" (click)="deleteTagFromSkill(skill, tag.Key);"><i class="fa-regular fa-xmark ms-2"></i></span>
                            </span>
                          </div>
                          <div class="d-inline-block ms-2">
                            <input #newTagInput class="form-control form-control-sm form-control-flush d-inline-block" placeholder="+ Tag" (scOnEnter)="addTagToSkill(skill, newTagInput.value); newTagInput.value = '';" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 pr-0">
                        <select class="custom-select custom-select-sm" [(ngModel)]="skill.Type" (ngModelChange)="saveSoon()">
                          <option value="Mandatory">Must-Have</option>
                          <option value="Optional">Nice-to-Have</option>
                          <option value="Excluded">Ausschluss</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select [disabled]="skill.Type == 'Excluded'" class="form-control form-control-sm " [(ngModel)]="skill.Weight" (ngModelChange)="saveSoon()">
                          <option value="0.3">Nicht so wichtig</option>
                          <option value="0.8">Eher nicht so wichtig</option>
                          <option value="0.9">Wichtig</option>
                          <option value="1">Sehr wichtig</option>
                        </select>
                      </div>
                      <div class="col-auto">
                        <button class="btn btn-sm btn-secondary btn-circle" (click)="deleteSkill(skill);">
                          <i class="fa-regular fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <input #newSkillInput type="text" class="form-control form-control-sm" placeholder="+ Skill" (scOnEnter)="addSkill(newSkillInput.value); newSkillInput.value = '';" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row mt-4">
                  <div class="col-md-3">
                    Ausbildung muss enthalten:
                  </div>
                  <div class="col-md-9">
                    <div *ngIf="project.Profile?.EducationInclude?.length > 0" class="mb-0">
                      <span class="badge bg-light ms-1 mb-2 me-1" *ngFor="let key of project.Profile?.EducationInclude" style="cursor: pointer;" (click)="deleteFrom(project.Profile?.EducationInclude, key)" [title]="key">
                        {{truncate(key, 50)}}
                      </span>
                    </div>
                    <input #educationTitleInput type="text" class="form-control form-control-sm form-control-flush" placeholder="+ Tag" (scOnEnter)="addTo(project.Profile?.EducationInclude, educationTitleInput.value); educationTitleInput.value ='';">
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    Job-Titel muss entsprechen:
                  </div>
                  <div class="col-md-9">
                    <div *ngIf="project.Profile?.JobTitleInclude?.length > 0" class="mb-0">
                      <span class="badge bg-light ms-1 mb-2 me-1" *ngFor="let key of project.Profile?.JobTitleInclude" style="cursor: pointer;" (click)="deleteFrom(project.Profile?.JobTitleInclude, key)" [title]="key">{{truncate(key,
                                                        50)}}</span>
                      <span class="badge ms-1 mb-2 me-1 bg-success" *ngIf="manualTag" (click)="addTo(project.Profile?.JobTitleInclude, jobTitleMustInput.value); jobTitleMustInput.value ='';" style="cursor: pointer;">+{{manualTag}}</span>
                    </div>

                    <input #jobTitleMustInput type="text" class="form-control form-control-sm form-control-flush" placeholder="+ Tag" (scOnEnter)="addTo(project.Profile?.JobTitleInclude, jobTitleMustInput.value); jobTitleMustInput.value ='';">

                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    Job-Titel darf nicht enstprechen:
                  </div>
                  <div class="col-md-9">
                    <div *ngIf="project.Profile?.JobTitleExclude?.length > 0" class="mb-0">
                      <span class="badge bg-light ms-1 mb-2 me-1" *ngFor="let key of project.Profile?.JobTitleExclude" style="cursor: pointer;" (click)="deleteFrom(project.Profile?.JobTitleExclude, key)" [title]="key">
                        {{truncate(key, 50)}}
                      </span>
                    </div>
                    <input #jobTitleExcludeInput type="text" class="form-control form-control-sm form-control-flush" placeholder="+ Tag" (scOnEnter)="addTo(project.Profile?.JobTitleExclude, jobTitleExcludeInput.value); jobTitleExcludeInput.value ='';">

                  </div>
                </div>

                <div class="row mt-4 align-items-center">
                  <div class="col-md-3">
                    Ausbildungsbeginn:
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm d-inline-block" [(ngModel)]="project.Profile.EarliestEducationStart" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                  <div class="col-auto">
                    bis
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm d-inline-block" [(ngModel)]="project.Profile.LatestEducationStart" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                </div>
                <div class="row mt-2" align-items-center>
                  <div class="col-md-3">
                    Ausbildungsende:
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.EarliestEducationEnd" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                  <div class="col-auto">
                    bis
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.LatestEducationEnd" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                </div>
                <div class="row mt-2" align-items-center>
                  <div class="col-md-3">
                    Arbeitsbeginn:
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.EarliestExperienceStart" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                  <div class="col-auto">
                    bis
                  </div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.LatestExperienceStart" (ngModelChange)="saveSoon()" placeholder="2007">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #blacklistModal>
  <div class="modal-header">
    <h4 class="modal-title">Blacklist (Arbeitgeber)</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="blacklistModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    <p>Namen mit vorangestelltem '!' werden in aktuellem und vergangenen Jobs gematcht</p>
    <textarea [(ngModel)]="project.Profile.Blacklist" (ngModelChange)="saveSoon()" class="form-control" name="blacklist" rows="20"></textarea>
  </div>
</sc-modal>

<sc-modal #nameBlacklistModal>
  <div class="modal-header">
    <h4 class="modal-title">Blacklist (Name des Kandidaten)</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="nameBlacklistModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    <p>
      Die Einträge werden mit dem vollen Namen des Profils abgeglichen (Vorname Nachname, in dieser Reihenfolge)
    </p>
    <textarea [(ngModel)]="project.Profile.NameBlacklist" (ngModelChange)="saveSoon()" class="form-control" name="blacklist" rows="20"></textarea>
  </div>
</sc-modal>
