<sc-activity-item-simple [activity]="activity" [context]="context" *ngIf="condensed && activity.Candidate">
    hat <a routerLink="/candidates/{{ activity.Candidate.Id }}">
        <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
    </a> auf die Shortlist gesetzt
</sc-activity-item-simple>

<sc-activity-item-highlight icon="fa-solid fa-check" [activity]="activity" [context]="context" *ngIf="!condensed && activity.Candidate">
    hat <a routerLink="/candidates/{{ activity.Candidate.Id }}">
        <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
    </a> auf die Shortlist gesetzt
</sc-activity-item-highlight>

<sc-activity-item-highlight icon="fa-solid fa-check" [activity]="activity" [context]="context" *ngIf="!condensed && !activity.Candidate">
    hat eine Kandidat:in auf die Shortlist gesetzt (Profil gwurde gelöscht)
</sc-activity-item-highlight>