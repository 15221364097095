import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-string-list-editor',
  templateUrl: './string-list-editor.component.html',
  styleUrls: ['./string-list-editor.component.scss']
})
export class StringListEditorComponent implements OnInit, OnChanges {

  @Input()
  text : string;

  private mirroredText : string;

  @Output()
  textChange = new EventEmitter<any>();
  
  entries : any = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes)
  {
    if (this.text == this.mirroredText) return;
    this.readEntries();
  }

  readEntries()
  {
    
    this.entries = this.text
      .split(/\r?\n/)
      .filter(s => s?.length > 0)
      .map(s => { return { Content: s }});
    this.validateEntries();

    this.mirroredText = this.text;
    console.log(this.entries);
  }

  validateEntries()
  {
    this.entries.forEach(e => {

      e.isInvalid = false;
      e.error = null;

      var content = e.Content.trim();

      if (content.length > 70)
      {
        e.error = "Zu lang: Maximal 70 Zeichen";
        e.isInvalid = true;
        return;
      }

      if (content.length > 0 && content[0] == '-')
      {
        e.error = "Bitte keine Spiegelstrich am Anfang verwenden";
        e.isInvalid = true;
        return;
      }

      if (content.length > 0 && content[content.length - 1] == ',')
      {
        e.error = "Bitte kein Komma am Ende verwenden";
        e.isInvalid = true;
        return;
      }

      if (content.length > 0 && content[content.length - 1] == ';')
      {
        e.error = "Bitte kein Semikolon am Ende verwenden";
        e.isInvalid = true;
        return;
      }
    });

    return !this.entries.some(e => e.isInvalid);
  }


  addEntry()
  {
    this.entries.push({ })
  }

  deleteEntry(e)
  {
    var i = this.entries.indexOf(e);
    this.entries.splice(i, 1);
    this.updateProject();
  }

  updateProject()
  {
    var value = "";
    console.log(this.entries);
    var isValid = this.validateEntries();    
    if (!isValid) return;
    
    this.entries.forEach(e => { 
      var isLast = this.entries.indexOf(e) == this.entries.length - 1;    
      var entryValue = e.Content.trim();
      if (!entryValue) return;
      value += entryValue;
      if (!isLast) value += "\r\n";
    });

    this.text = value.trim();
    this.mirroredText = this.text;
    this.textChange.emit(this.text);

  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.updateProject();
  }

}
