import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-activity-item-sales-organization-status-changed',
  templateUrl: './activity-item-sales-organization-status-changed.component.html',
  styleUrls: ['./activity-item-sales-organization-status-changed.component.scss']
})
export class ActivityItemSalesOrganizationStatusChangedComponent {
  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;
}
