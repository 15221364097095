
<sc-modal size="lg" #filterModal>
	<sc-modal-header>
	  Filter
	</sc-modal-header>
	<sc-modal-body>
	  <div class="row mb-2">
		<div class="col-3">
		  Manager
		</div>
		<div class="col-9">		  
			<button class="btn btn-sm me-2" (click)="settings.userProjectsOnly = false" [class.btn-primary]="!settings.userProjectsOnly" [class.btn-secondary]="settings.userProjectsOnly">Alle</button>
		  <button class="btn btn-sm me-2" (click)="settings.userProjectsOnly = true" [class.btn-primary]="settings.userProjectsOnly" [class.btn-secondary]="!settings.userProjectsOnly">Nur eigene</button>
		</div>
	  </div>
	  <hr>
	  <div class="row mb-2">
		<div class="col-3">
		  Aktiv
		</div>
		<div class="col-9">
		  <button class="btn btn-sm me-2" (click)="settings.includeCancelled = true" [class.btn-primary]="settings.includeCancelled" [class.btn-secondary]="!settings.includeCancelled">Alle</button>
		  <button class="btn btn-sm me-2" (click)="settings.includeCancelled = false" [class.btn-primary]="!settings.includeCancelled" [class.btn-secondary]="settings.includeCancelled">Nur aktiv</button>
		</div>
	  </div>
	  <hr>
	  <div class="row mb-2">
		<div class="col-3">
		  Fälligkeit
		</div>
		<div class="col-9">
					  <button class="btn btn-sm me-2" (click)="settings.dueOnly = false" [class.btn-primary]="!settings.dueOnly" [class.btn-secondary]="settings.dueOnly">Alle</button>
		  <button class="btn btn-sm me-2" (click)="settings.dueOnly = true" [class.btn-primary]="settings.dueOnly" [class.btn-secondary]="!settings.dueOnly">Nur überfällig</button>
		</div>
	  </div>
	  <hr>
	  <div class="row mb-2">
		<div class="col-3">
		  Status
		</div>
		<div class="col-9 mb-n2">
		  <button *ngFor="let status of settings.stati" class="btn btn-sm me-2 mb-2" (click)="status.isSelected = !status.isSelected" [class.btn-primary]="status.isSelected" [class.btn-secondary]="!status.isSelected">{{ status.name | projectStatus }}</button>
		</div>
	  </div>
	</sc-modal-body>
	<sc-modal-footer>
	  <button class="btn btn-dark" (click)="onChange(); filterModal.dismiss();">Übernehmen</button>
	</sc-modal-footer>
  </sc-modal>