<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div *ngIf="project" class="container">

    <div class="row">

      <!-- <div class="col-md-3">
        <div class="status-col">
            <app-project-process [project]="project"></app-project-process>
        </div>
      </div> -->

      <div class="col-12">

        <app-project-briefing-files class="mb-5" [project]="project"></app-project-briefing-files>

        <div class="card mb-5">
          <div class="card-header py-2">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Briefing
                </h6>
              </div>
              <div class="col-auto">
                <button class="btn btn-round btn-secondary" (click)="briefingModal.start()"><i class="fa-sharp fa-solid fa-stars me-2"></i> Briefing-Chat</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div [innerHTML]="project.Requirements | bypassSecurityTrust : 'html'"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header py-2">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Suchprofil
                </h6>
              </div>
              <div class="col-auto">
                <button class="btn btn-dark btn-round" (click)="importModal.show()"><i class="fa-sharp fa-solid fa-stars me-2"></i> Import</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <sc-form (change)="saveSoon()">
                  <!-- <sc-form-row label="Batch-Id">
                    <input />
                  </sc-form-row> -->
                  <sc-form-row label="Skills">
                    <div class="tag-list">
                      <div class="tag me-2 mb-2" *ngFor="let s of project.Profile.SkillRequirements">
                        {{ s.Name }}  
                        <span *ngIf="s.Type == 'NiceToHave'" class="ms-2 text-muted">(optional)</span>
                        <span *ngIf="s.Type == 'Alternative'" class="ms-2 text-muted">(alt. zu {{ s.AlternativeTo }})</span>
                        <i (click)="removeSkill(s)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                      </div>

                      <div class="tag clickable me-2 mb-2" (click)="addSkill()">
                        <i class="fa-sharp fa-solid fa-plus"></i>
                      </div>

                    </div>
                  </sc-form-row>
                  <sc-form-row label="Branchen">
                    <div class="tag-list">

                      <div class="tag me-2 mb-2" *ngFor="let s of project.Profile.IndustryRequirements">
                        {{ s.Id | industryName }}  
                        <span *ngIf="s.Type == 'NiceToHave'" class="ms-2 text-muted">(optional)</span>
                        <span *ngIf="s.Type == 'Alternative'" class="ms-2 text-muted">(alt. zu {{ s.AlternativeTo | industryName  }})</span>
                        <i (click)="removeIndustry(s)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                      </div>

                      <div class="tag clickable me-2 mb-2" (click)="addIndustry()">
                        <i class="fa-sharp fa-solid fa-plus"></i> 
                      </div>

                    </div>
                  </sc-form-row>

                  <sc-form-row label="Standorte">
                    <app-location-list-editor [locations]="project.Profile.Locations" (locationsChange)="saveSoon()">
                    </app-location-list-editor>
                  </sc-form-row>
                  
                  <sc-form-row label="Min. Berufserfahrung">
                    <input [(ngModel)]="project.Profile.MinYearsOfExperience" />
                  </sc-form-row>

                  <sc-form-row label="Max. Berufserfahrung">
                    <input [(ngModel)]="project.Profile.MaxYearsOfExperience" />
                  </sc-form-row>

                  <sc-form-row label="Remoteanteil">
                    <input [(ngModel)]="project.Profile.MaxRemote" />
                  </sc-form-row>
                </sc-form>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row align-items-center">
              <div class="col">
                
              </div>
              <div class="col-auto">
                <span *ngIf="matchCount >= 0">{{ matchCount }}</span>
                <span *ngIf="matchCount == -1">Aktualisieren, um Anzahl an Treffern anzuzeigen</span>
              </div>
              <div class="col-auto">
                <button class="btn btn-secondary btn-round btn-circle" (click)="updateMatchCount()">
                  <i class="fa-sharp fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h6 class="card-header-title">
                  Sonstiges
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body">

            <sc-form (change)="saveSoon()" form="horizontal">

              <sc-form-row label="Briefing-Termin">
                <sc-date-picker-inline [timeSelectable]="true" [(date)]="project.BriefingDate" (dateChange)="saveSoon()"></sc-date-picker-inline>
              </sc-form-row>

              <sc-form-row label="Buchungslink">
                <div class="form-control">
                  <a class="oneline d-block" [href]="project.BriefingBookingLink" target="_blank">{{ project.BriefingBookingLink }}</a>
                </div>
              </sc-form-row>

              <sc-form-row label="Bezeichnung">
                <input name="jobTitle" [(ngModel)]="project.Name" placeholder="Bezeichnung der Stelle ohne (m/w)">
              </sc-form-row>

              <sc-form-row label="Kommentar">
                <autosize-textarea [(text)]="project.Profile.Comment"></autosize-textarea>
              </sc-form-row>

              <sc-form-row label="Berufserfahrung">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.MinYearsOfExperience" />
                  </div>
                  <div class="col-auto px-0">-</div>
                  <div class="col-auto">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.MaxYearsOfExperience" />
                  </div>
                  <div class="col">
                    <input class="form-control form-control-sm" [(ngModel)]="project.Profile.ExperienceComment" />
                  </div>
                </div>
              </sc-form-row>

              <sc-form-row label="Must-Haves">
                <app-string-list-editor [(text)]="project.Profile.MustHaves" (textChange)="saveSoon()"></app-string-list-editor>
              </sc-form-row>

              <sc-form-row label="Nice-To-Haves">
                <app-string-list-editor [(text)]="project.Profile.NiceToHaves" (textChange)="saveSoon()"></app-string-list-editor>
              </sc-form-row>

              <sc-form-row label="Deutsch">
                <select class="custom-select custom-select-sm" [(ngModel)]="project.Profile.MinGermanLevel">
                  <option value="0">Beliebig</option>
                  <option value="1">Basis</option>
                  <option value="2">Gut</option>
                  <option value="3">Fließend</option>
                  <option value="4">Muttersprache</option>
                </select>
              </sc-form-row>

              <sc-form-row label="Englisch">
                <select class="custom-select custom-select-sm" [(ngModel)]="project.Profile.MinEnglishLevel">
                  <option value="0">Beliebig</option>
                  <option value="1">Basis</option>
                  <option value="2">Gut</option>
                  <option value="3">Fließend</option>
                  <option value="4">Muttersprache</option>
                </select>
              </sc-form-row>

              <sc-form-row label="Reisetätigkeit">
                <select class="custom-select custom-select-sm" [(ngModel)]="project.Profile.TravelIntensity">
                  <option value="None">Keine</option>
                  <option value="Low">Niedrig</option>
                  <option value="Medium">Mittel</option>
                  <option value="High">Hoch</option>
                </select>
              </sc-form-row>

              <sc-form-row label="Führung">
                <input [(ngModel)]="project.Profile.Responsibility" />
              </sc-form-row>

              <sc-form-row label="Selling Points">
                <autosize-textarea [(text)]="project.Profile.SellingPoints"></autosize-textarea>
              </sc-form-row>

              <sc-form-row label="Teamgröße">
                <input [(ngModel)]="project.Profile.Teamsize" />
              </sc-form-row>

              <sc-form-row label="Gehalt">
                <div class="row">
                  <div class="col-2">
                    <input name="MinSalary" [(ngModel)]="project.Profile.Compensation.MinSalary" />
                  </div>
                  <div class="col-1">
                    bis
                  </div>
                  <div class="col-2">
                    <input name="MaxSalary" [(ngModel)]="project.Profile.Compensation.MaxSalary" />
                  </div>
                  <div class="col-2">
                    <input name="SalaryCurrency" [(ngModel)]="project.Profile.Compensation.SalaryCurrency" />
                  </div>
                </div>
              </sc-form-row>

              <sc-form-row label="Vertragsverhältnis">
                <select class="custom-select custom-select-sm" [(ngModel)]="project.Profile.EmploymentType" (ngModelChange)="saveSoon()">
                  <option value="Permanent">Festanstellung</option>
                  <option value="Interim">Befristete Anstellung</option>
                  <option value="Freelancer">Freelancer</option>
                </select>
              </sc-form-row>

              <sc-form-row label="Teilzeit">
                <sc-switch [(ngModel)]="project.Profile.IsPartTime"></sc-switch>
              </sc-form-row>

              <sc-form-row label="Vollzeit">
                <sc-switch [(ngModel)]="project.Profile.IsFullTime"></sc-switch>
              </sc-form-row>
            </sc-form>

          </div>

          <div class="card-header">
            <h6 class="card-header-title">
              Benefits
            </h6>
          </div>

          <div class="card-body">
            <benefit-selection [benefitIds]="project.Profile.BenefitIdss" (benefitIdsChange)="saveSoon()"></benefit-selection>
          </div>

          <div class="card-body">

            <div class="row mb-2">
              <div class="col-3 oneline">
                Zielunternehmen
              </div>
              <div class="col-9">
                <autosize-textarea [(text)]="project.Profile.TargetCompanies" (textChange)="saveSoon()" placeholder="Zielunternehmen"></autosize-textarea>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3 oneline">
                Blacklist
              </div>
              <div class="col-9">
                <button class="btn btn-secondary btn-sm me-2" (click)="globalBlacklistModal.show()">Global</button>
                <button class="btn btn-secondary btn-sm" (click)="projectBlacklistModal.show()">Projekt</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<sc-modal #globalBlacklistModal size="lg">
  <div class="modal-header">
    <h6 class="modal-title">Blacklist (Global)</h6>
    <button type="button" class="close" aria-label="Close" (click)="globalBlacklistModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Namen mit vorangestelltem '!' werden in aktuellem und vergangenen Jobs gematched</p>

    <textarea [(ngModel)]="blacklist.Content" class="form-control" name="blacklist" rows="20"></textarea>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="saveGlobalBlacklist()">Speichern</button>
  </div>
</sc-modal>

<sc-modal #projectBlacklistModal size="lg">
  <div class="modal-header">
    <h6 class="modal-title">Blacklist (Projekt)</h6>
    <button type="button" class="close" aria-label="Close" (click)="projectBlacklistModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    <p>Namen mit vorangestelltem '!' werden in aktuellem und vergangenen Jobs gematched</p>
    <textarea [(ngModel)]="project.Profile.Blacklist" class="form-control" name="blacklist" rows="20"></textarea>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="save()">Speichern</button>
  </div>
</sc-modal>

<app-modal-briefing [project]="project" #briefingModal></app-modal-briefing>


<app-project-briefing-modal-skills [skills]="project?.Profile?.SkillRequirements" #addSkillModal></app-project-briefing-modal-skills>
<app-project-briefing-modal-industries [industries]="project?.Profile?.IndustryRequirements" #addIndustryModal></app-project-briefing-modal-industries>
<app-project-briefing-modal-import [project]="project" #importModal></app-project-briefing-modal-import>