import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { ClipboardService } from 'ngx-clipboard';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-candidate-exact',
  templateUrl: './sidebar-candidate-exact.component.html',
  styleUrls: ['./sidebar-candidate-exact.component.scss']
})
export class SidebarCandidateExactComponent implements OnInit {

  id: any;

  candidate: any;

  blacklistInfo: any;

  assignment: any = null;

  assignments: any = [];

  skills: any;

  isAssignedToProject = false;

  visibleProjectItemCount = 3;

  updateSettings = {
    Haves: true,
    Experience: true,
    Avatar: true
  };

  isUpdatingProfile = false;

  @ViewChild('updateModal')
  updateModal: ScuiModalComponent;

  constructor(private api: ScApiService,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private clipboardService: ClipboardService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.id = params.id;

      var autoProject = this.route.snapshot.queryParams?.autoProject == 'true';

      if (autoProject) {
        this.api.get('candidates/' + this.id + '/project-assignments/last-contact').subscribe((d: any) => {
          if (d?.Project?.Id) this.sidebarService.setProjectId(d.Project.Id);
          this.load();
        });
      }
      else {
        this.load();
      }
    });
  }

  load() {
    this.api.get('candidates/' + this.id).subscribe((d: any) => {
      this.candidate = d;
    });

    this.api.get('sidebar/blacklist-info/' + this.id + '?projectId=' + this.sidebarService.projectId).subscribe((d: any) => {
      this.blacklistInfo = d;
    });

    this.api.get('candidates/' + this.id + '/project-assignments/').subscribe((d: any) => {
      this.assignments = d;
      this.assignment = d.find(a => a.Project.Id == this.sidebarService.projectId);
      this.isAssignedToProject = this.assignment != null;
    });
  }

  assignProject() {
    var a = {
      ProjectId: this.sidebarService.projectId,
      CandidateId: this.id
    };

    this.api.post('candidates/' + this.id + '/project-assignments/', a).subscribe((d: any) => {
      this.assignment = d;
      this.assignments.push(d);
      this.sidebarService.success("Profil wurde zum Projekt hinzugefügt");
      this.isAssignedToProject = true;
    });
  }

  message(templateId) {
    if (!this.assignment.IsContacted) {
      this.assignment.IsContacted = true;
      this.assignment.ContactedAt = new Date();
      this.saveAssignment().subscribe(() => {
        this.getMessageBody(templateId);
      });
    }

    this.getMessageBody(templateId);
  }

  interested(templateId) {
    this.assignment.IsContacted = true;
    this.assignment.IsInterested = true;
    this.assignment.IsNotInterested = false;
    this.saveAssignment().subscribe(() => {
      this.getMessageBody(templateId);
    })
  }

  notInterested(templateId) {
    this.assignment.IsContacted = true;
    this.assignment.IsInterested = false;
    this.assignment.IsNotInterested = true;
    this.saveAssignment().subscribe(() => {
      this.getMessageBody(templateId);
    })
  }

  onChange() { }

  saveAssignment(): any {
    return this.api.put(`candidates/${this.id}/assignments/${this.assignment.Id}`, this.assignment);
  }

  getMessageBody(templateId): any {
    this.api.get(`candidates/${this.id}/assignments/${this.assignment.Id}/message/${templateId}?sender=manager`).subscribe((d: any) => {
      this.clipboardService.copy(d.Message);
      this.sidebarService.success("Die Nachricht wurde in die Zwischenablage kopiert");
    });
  }

  subject(templateId): any {
    this.api.get(`candidates/${this.id}/assignments/${this.assignment.Id}/message/${templateId}?sender=manager`).subscribe((d: any) => {
      this.clipboardService.copy(d.Subject);
      this.sidebarService.success("Der Betreff wurde in die Zwischenablage kopiert");
    });
  }

  getMessageSubject(templateId): any {
    this.api.get(`candidates/${this.id}/assignments/${this.assignment.Id}/message/${templateId}?sender=manager`).subscribe((d: any) => {
      this.clipboardService.copy(d.Message);
      this.sidebarService.success("Die Nachricht wurde in die Zwischenablage kopiert");
    });
  }

  ebner()
  {
    this.api.get(`candidates/${this.id}/assignments/${this.assignment.Id}/ebner-message?sender=manager`).subscribe((d: any) => {
      this.clipboardService.copy(d.Message);
      this.sidebarService.success("Die Nachricht wurde in die Zwischenablage kopiert");
    });
  }

  updateProfile() {

    var isUpdatingHavesOrExperience = false;
    var isUpdatingAvatar = false;
    this.isUpdatingProfile = true;

    var profile = this.sidebarService.profile;

    if (this.updateSettings.Experience || this.updateSettings.Haves) {

      isUpdatingHavesOrExperience = true;

      var args: any = {};

      if (this.updateSettings.Experience) 
      {
        args.Profile = { 
          Experience: profile.Profile?.Experience,
          Education: profile.Profile?.Education
        };
        
        args.JobTitle = profile.JobTitle;
        args.Company = profile.Company;
      }

      if (this.updateSettings.Haves && profile.Haves) 
      {
        var newHavesString = this.candidate.Haves;
        var newHaves = profile.Haves.split(',').map(s => s.trim());
        var oldHaves = this.candidate.Haves.split(',').map(s => s.trim());

        oldHaves.forEach(s => newHaves.push(s));

        newHaves.forEach(s => {
          if (oldHaves.some(s2 => s.toLowerCase() == s2.toLowerCase())) return;
          newHaves.push(s);
        });

        newHaves.forEach(s => newHavesString += s + ', ');
        newHavesString = newHavesString.trim();
        newHavesString = newHavesString.substring(0, newHavesString.length - 2);

        args.Haves = newHavesString;
      }

      this.api.put(`candidates/${this.candidate.Guid}/merge`, args).subscribe((d: any) => {
        if (this.updateSettings.Experience) {
          this.candidate.JobTitle = args.JobTitle;
          this.candidate.Company = args.Company;

        }

        isUpdatingHavesOrExperience = false;
        this.isUpdatingProfile = isUpdatingHavesOrExperience || isUpdatingAvatar;
        if (!this.isUpdatingProfile) {
          this.notifySuccess();
          this.updateModal.dismiss();
        }
      });
    }

    if (this.updateSettings.Avatar) {

      isUpdatingAvatar = true;

      this.api.put(`candidates/${this.candidate.Guid}/avatar`, { Uri: this.sidebarService.profile.Image }).subscribe((d: any) => {
        this.candidate.Image = d.Uri;
        isUpdatingAvatar = false;
        this.isUpdatingProfile = isUpdatingHavesOrExperience || isUpdatingAvatar;
        if (!this.isUpdatingProfile) {
          this.notifySuccess();
          this.updateModal.dismiss();
        }
      });
    }

    this.isUpdatingProfile = isUpdatingHavesOrExperience || isUpdatingAvatar;
  }

  notifySuccess() {
    this.sidebarService.success('Das Profil wurde aktualisiert');
  }



}
