<main>
  <div class="container-xl">

    <div class="row justify-content-center align-items-end">

      <div class="auth-card col-auto">

        <div class="auth-blob">
          <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
        </div>

        <div class="card shadow-light-lg">

          <div class="card-body">

            <div class="logo">
              <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
            </div>

            <h2 class="text-center mb-5">Schön, dass du da bist!</h2>

            <form>

              <div class="form-group">
                <label class="form-label">E-Mail</label>
                <div class="input-group input-group-merge">
                  <input name="eMail" [(ngModel)]="eMail" type="text" class="form-control" autocomplete="username">
                </div>

              </div>

              <div class="form-group">
                <label class="form-label">Passwort</label>
                <div class="input-group input-group-merge">
                  <input name="password" [(ngModel)]="password" type="password" class="form-control form-control" autocomplete="current-password">
                </div>
              </div>

              <div class="row align-items-center" [hidden]="!loginError">
                <div class="col">
                  <div class="alert alert-danger" role="alert">
                    <p class="alert-heading mb-0">{{loginError}}</p>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <button [disabled]="isSending" class="btn w-75 btn-primary mb-3 mt-3" (click)="login()">
                  <span *ngIf="isSending" class="loader loader-sm loader-white d-inline-block"></span>
                  Anmelden
                </button>
              </div>
            </form>
          </div>

        </div>

      </div>

      <div class="auth-illu col-auto">
        <img src="assets/illus/skillconomy-bot-waving.svg" alt="skillconomy Illu Bot" class="img-fluid">
      </div>

    </div>
  </div>
</main>
