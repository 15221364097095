import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { environment } from 'src/environment.prod';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  
  id;

  candidateId;

  chat : any;

  constructor(private route : ActivatedRoute, private chats : ChatsService) { }

  ngOnInit() {
    this.route.params.subscribe((params : any) => {

      this.candidateId = params.candidateId;
      this.id = params.id;

      this.chats.getChat('candidates/' + this.candidateId + '/chats/' + this.id).subscribe((d : any) => {
        this.chat = d;
      })
    });
  }

}
