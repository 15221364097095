import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-package-projects',
  templateUrl: './package-projects.component.html',
  styleUrls: ['./package-projects.component.scss']
})
export class PackageProjectsComponent {

  @Input()
  package : any;

}
