<sc-activity-item-comment [activity]="activity">
  <div class="comment-label" *ngIf="activity.Candidate">
    hat <a routerLink="/candidates/{{ activity.Candidate.Id }}">
      <strong>{{ activity.Candidate.FirstName }} {{ activity.Candidate.LastName }}</strong>
    </a> abgelehnt
  </div>
  <div class="comment-label" *ngIf="!activity.Candidate">
    hat eine Kandidat:in abgelehnt (nicht mehr vorhanden)
  </div>
  <div class="comment-body">
    <div *ngIf="activity.Payload?.Reasons">
      <strong>Ablehnungsgründe:</strong>
      <p *ngFor="let r of activity.Payload.Reasons">
        - {{ reasons[r] }}
      </p>
    </div>
    <div *ngIf="activity.Payload?.Comment">
      <strong>Kommentar:</strong>
      <p [innerHTML]="activity.Payload.Comment"></p>
    </div>
  </div>
</sc-activity-item-comment>
