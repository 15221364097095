<div class="comment">

    <div class="row">
        <div class="col-auto">
            <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
        </div>

        <div class="col ms-n2">
            <div class="comment-header">
                <div class="row">
                    <div class="col">
                        <sc-activity-item-header [context]="context" [activity]="activity"></sc-activity-item-header>
                        <div class="comment-label">
                            <p class="mb-0 activity" *ngIf="activity?.Candidate">
                                Demo-Call
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comment-body-container" *ngIf="!condensed">
                <div class="comment-body">
                    <p [innerHTML]="activity.Payload?.Comment"></p>
                </div>
            </div>
        </div>

    </div>
</div>