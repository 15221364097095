<div class="row">
  <div class="col-12">
    <p>
      {{ task | task : 'description' }} 
    </p>
    <p *ngIf="task.Project">
      
    </p>
  </div>
</div>
