<ng-template [appTopbarTemplate]>
    <div class="title h4">
      {{ chatbot?.Name }}
    </div>
  
    <div class="nav">
  
      <ul class="nav nav-tabs nav-overflow">
  
        <li class="nav-item">
          <a class="nav-link" routerLink="/chatbots/{{chatbot?.Id}}">
            Inhalt
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" routerLink="/chatbots/{{chatbot?.Id}}/history">
            Historie
          </a>
        </li>
      </ul>
    </div>  
  </ng-template>

<div class="main-content" *ngIf="chatbot">

    <div class="container">
        <div class="row">

            <div class="col-12">
                <ul class="list-group list-group-lg list-group-flush list">
                    <li class="list-group-item py-3" *ngFor="let chat of chats">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="float-left text-truncate clickable" style="width: 300px;">
                                    <span class="fw-bold" routerLink="/candidates/{{chat.Candidate.Guid}}">
                                        {{chat.Candidate.LastName }},
                                        {{chat.Candidate.FirstName }}
                                    </span>
                                    <span routerLink="/chats/{{chat.Chat.Id}}">
                                         zum Chat
                                    </span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="float-left">
                                    {{ chat.Chat.Created | date : 'dd.MM.yyyy HH:mm' }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="float-left">
                                    {{ chat.Chat.Changed | date : 'dd.MM.yyyy HH:mm' }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="float-left">
                                    {{ chat.Chat.CurrentMessageIndex }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-sm btn-circle btn-dark lift ms-4" (click)="showRemindModal(chat.Chat)">
                                    <i class="fa-regular fa-bell"></i>
                                </button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-sm btn-circle btn-danger lift" (click)="delete(chat)"><i class="fa-regular fa-trash"></i></button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<sc-modal #remindModal size="lg">
    <div class="modal-header">
        <h4 class="modal-title">Chat-Reminder ausspielen</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="remindModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mb-2">
            <div class="col-3">
                <label>Titel</label>
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="reminder.Subject" placeholder="Ohne Eingabe wird der Notification-Titel des Chatbots verwendet" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Nachricht</label>
            </div>
            <div class="col-9">
                <textarea rows="6" class="form-control form-control-sm" [(ngModel)]="reminder.Content" placeholder="Ohne Eingabe wird die Notification-Nachricht des Chatbots verwendet"></textarea>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Push</label>
            </div>
            <div class="col-9">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="SendPushNotification" name="SendPushNotification" [(ngModel)]="reminder.SendPushNotification" />
                    <label class="form-check-label" for="SendPushNotification"></label>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>E-Mail</label>
            </div>
            <div class="col-9">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="SendEMailNotification" name="SendEMailNotification" [(ngModel)]="reminder.SendEMailNotification" />
                    <label class="form-check-label" for="SendEMailNotification"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark" (click)="remindChat()"><i class="fa-regular fa-bell me-2"></i> Reminder senden</button>
    </div>
</sc-modal>