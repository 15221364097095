import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-candidate-projects',
  templateUrl: './candidate-projects.component.html',
  styleUrls: ['./candidate-projects.component.scss']
})
export class CandidateProjectsComponent {

  @Input()
  candidate: any;

  @HostBinding("class.hide")
  hide = false;

  projects: any = [];

  errorMessage = '';

  @Output()
  candidateChange = new EventEmitter<any>();

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.api.get('candidates/' + this.candidate.Guid + '/projects').subscribe((d : any) => {
      this.projects = d;
      if(!d?.length) this.hide = true;
    });
  }

  saveAssignment(a) {
    this.api.put('candidates/' + a.CandidateId + '/assignments/' + a.Id, a).subscribe(d => { });
  }

  addToShortlist(a)
  {
    var uri = this.getActionUri(a, 'add-to-shortlist');
    this.api.post(uri, null).subscribe(d => {
      a.IsShortlisted = true;
    });
  }

  accept(a)
  {
    this.errorMessage = '';

    var uri = this.getActionUri(a, 'accept');
    this.api.post(uri, null).subscribe(d => {
      a.IsAccepted = true;
    }, this.showErrorModal);
  }

  reject(a)
  {
    this.errorMessage = '';

    var uri = this.getActionUri(a, 'reject');
    this.api.post(uri, null).subscribe(d => {
      a.IsRejected = true;
    }, this.showErrorModal);
  }

  hired(a)
  {
    this.errorMessage = '';

    var uri = this.getActionUri(a, 'hired');
    this.api.post(uri, null).subscribe(d => {
      a.IsHired = true;
    }, this.showErrorModal);
  }

  revoke(a)
  {
    this.errorMessage = '';
    
    var uri = this.getActionUri(a, 'revoke');
    this.api.post(uri, null).subscribe(d => {
      a.IsRejected = false;
      a.IsRevoked = true;
    }, this.showErrorModal);
  }

  private getActionUri(a, actionName)
  {
    return `projects/${a.Project.Id}/candidates/${a.CandidateId}/actions/${actionName}`;    
  }

  private showErrorModal(e)
  {
    this.errorMessage = e.error.Message;
    // this.errorModal.show;
  }

}
