<div class="card px-2">
	<div class="card-body">
	  <div class="mb-1">
		<div class="h3 mb-0 d-inline-block">
		  {{ value | number : '1.0-0'}} {{ unit }}
		</div>

		<div class="d-inline-block ms-3 text-success" *ngIf="trend > 0">
		  <i class="fa-regular fa-arrow-up"></i>
		  {{ trend | number : '1.0-0'}} %
		</div>

		<div class="d-inline-block ms-3 text-danger" *ngIf="trend < 0">
		  <i class="fa-regular fa-arrow-down ms-2"></i>
		  {{ trend | number : '1.0-0'}} %
		</div>

	  </div>
	  <div class="oneline small">
		<span>{{label}}</span>
	  </div>
	</div>
  </div>