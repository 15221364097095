import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-detail-project-prognosis',
  templateUrl: './task-detail-project-prognosis.component.html',
  styleUrls: ['./task-detail-project-prognosis.component.scss']
})
export class TaskDetailProjectPrognosisComponent {

  @Input()
  task : any;
  
}
