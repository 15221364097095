<div class="card-img-top mb-5">
  <sc-image-select-crop [minWidth]="1000" (uploaded)="pool.Image = $event.Uri; save();">
    <img *ngIf="pool?.Image" [src]="pool.Image | imageSize : 1200" />
    <img *ngIf="!pool?.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{pool?.Id % 3 + 1}}.jpg" />
  </sc-image-select-crop>
</div>

<div *ngIf="pool">

  <div class="row mb-5">

    <div class="col"></div>

    <div class="col-auto pr-0">
      <app-pool-base-user-modal [pool]="pool"></app-pool-base-user-modal>
    </div>

  </div>

  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="pool-subline">{{ pool.Description }}</div>
      <div class="pool-stats mt-3">
        <i class="me-2 fa-solid fa-users"></i> {{ pool.CandidateCount }} Kandidat:innen
      </div>
    </div>
  </div>
</div>


