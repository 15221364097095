import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';
import { SlideInOutAnimation } from 'src/animations/slide';
import { MapsAPILoader } from '@agm/core';

@Component({
  animations: [SlideInOutAnimation],
  selector: 'app-modal-edit-locations',
  templateUrl: './modal-edit-locations.component.html',
  styleUrls: ['./modal-edit-locations.component.scss']
})
export class ModalEditLocationsComponent implements OnInit {

  @Input()
  profile : any = [];

  @Output()
  profileChange = new EventEmitter<any>();

  @ViewChild('modal')
  modal : ScuiModalComponent;

  @ViewChild('placeInput', { static: true })
  placeInput : ElementRef;

  selectedValue : any;

  newLocation : any = { isValid: false };

  newRadius : number = 50;

  constructor(private mapsApiLoader : MapsAPILoader, private ngZone : NgZone) { }

  ngOnInit() {
    
    this.mapsApiLoader.load().then(() => {      
  
      var input = this.placeInput.nativeElement;
      var autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.setComponentRestrictions({ 'country': ['de'] });
  
      google.maps.event.addListener(
        autocomplete,
        'place_changed',
        () => {
          this.ngZone.run(() => {

            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            var city = '';
            var street = '';
            var zip = '';
            var number = '';
            var country = '';
  
            place.address_components.forEach(c => {
              if (c.types.indexOf('locality') >= 0)  city = c.long_name;
              if (c.types.indexOf('route') >= 0)  street = c.long_name;
              if (c.types.indexOf('postal_code') >= 0)  zip = c.long_name;
              if (c.types.indexOf('street_number') >= 0)  number = c.long_name;
              if (c.types.indexOf('country') >= 0)  country = c.long_name;
            })
  
            var location = {
              Latitude : place.geometry.location.lat(),
              Longitude : place.geometry.location.lng(),
              Zip: zip,
              City: city,
              Street: street,
              Number: number,
              Country: country,
              Radius: 50,
              isValid: true
            };

            this.newLocation = location;
          });
        }
      );
    });  
    
  }  

  show()
  {
    this.modal.show();
  }

  deleteLocation(e)
  {
    var i = this.profile.DesiredLocations.indexOf(e);
    this.profile.DesiredLocations.splice(i, 1);
    this.profileChange.emit(this.profile);
  }

  addNewItem()
  {
    if (this.newLocation.isValid)
    {
      this.profile.DesiredLocations.push(this.newLocation);
      this.profileChange.emit(this.profile);
    }    
    
    this.newLocation = {
      isValid: false
    };

    this.placeInput.nativeElement.value = null;
  }


  canAdd()
  {
    return this.newLocation.isValid;
  }

  emitChange()
  {
    this.profileChange.emit(this.profile);
  }


}
