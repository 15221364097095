import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-mailings',
  templateUrl: './mailings.component.html',
  styleUrls: ['./mailings.component.scss']
})
export class MailingsComponent implements OnInit {


  mailings : any = [];

  isCreating = false;

  constructor(private api : ScApiService, private router : Router) {}

  ngOnInit()
  {
    this.api.get('mailings').subscribe((d : any) => {
      this.mailings = d;
    });
  }

  create()
  {
    if (this.isCreating) return;
    this.isCreating = true;

    this.api.post('mailings', null).subscribe((d : any) => {
      this.router.navigateByUrl('/mailings/' + d.Id);
      this.isCreating = false;
    });

  }

}
