<div>

  <div class="card mb-3">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Unternehmen
          </h6>
        </div>
      </div>
    </div>

    <div class="card-body">

      <sc-form form="default">

        <sc-form-row>
          <app-inline-select-organization [canSetNull]="true" [(organizationId)]="organizationId" (organizationIdChange)="onOrganizationIdChange()"></app-inline-select-organization>
        </sc-form-row>
        
        <sc-form-row *ngIf="organization">
          <app-inline-select-contact [(contactId)]="organization.ManagerId" (contactIdChange)="saveOrganization()" organizationId="1" [allowCreate]="false" [allowSearch]="false">
          </app-inline-select-contact>
        </sc-form-row>

        <sc-form-row *ngIf="organization">
          <select [(ngModel)]="organization.SalesStatus" (ngModelChange)="saveOrganization()">         
            <option ngValue="None">Kein Status</option>
            <option ngValue="Opportunity">Opportunity</option>
            <option ngValue="Closed">Closed</option>
            <option ngValue="Lost">Lost</option>
            <option ngValue="Disqualified">Disqualified</option>
            <option ngValue="Blacklisted">Blacklisted</option>
          </select>
        </sc-form-row>

        <!-- <sc-form-row *ngIf="organization" label="Karriereseite">
          <div class="input-group">
            <input class="form-control" placeholder="Karriereseite" [(ngModel)]="organization.CareerSite" (ngModelChange)="saveOrganization(organization)" />
            <div class="input-group-append">
              <a *ngIf="organization.CareerSite" [href]="organization.CareerSite" target="_blank" class="btn btn-secondary">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
              <a *ngIf="!organization.CareerSite" href="https://www.google.com/search?q={{organization.Name}}+karriere" target="_blank" class="btn btn-secondary">
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
            </div>
          </div>
        </sc-form-row> -->
      </sc-form>
    </div>
  </div>
</div>
