import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-list-organizations',
  templateUrl: './list-organizations.component.html',
  styleUrls: ['./list-organizations.component.scss']
})
export class ListOrganizationsComponent {

  @Input()
  organizations : any = [];

  @Input()
  navigateOnClick = false;

  @Output()
  selected = new EventEmitter<any>();

}
