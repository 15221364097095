import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectPersonComponent, ModalSelectPersonOptions } from '../modal-select-person/modal-select-person.component';

@Component({
  selector: 'app-inline-select-contact',
  templateUrl: './inline-select-contact.component.html',
  styleUrls: ['./inline-select-contact.component.scss']
})
export class InlineSelectContactComponent {

  @Input()
  contactId : any;

  @Output()
  contactIdChange = new EventEmitter<any>();

  @Input()
  organizationId = 0;

  @Input()
  allowCreate = false;

  @Input()
  allowSearch = false;

  @ViewChild(ModalSelectPersonComponent)
  modal : ModalSelectPersonComponent;

  person : any;

  constructor(private api : ScApiService)
  {}

  ngOnChanges()
  {
    if(!this.contactId)
    {
      this.person = null;
      return;
    }
    if (this.contactId != this.person?.Id)
    {
      this.api.get('contacts/' + this.contactId).subscribe(d => {
        this.person = d;
      })
    }
  }

  selectPerson()
  {
    var options = new ModalSelectPersonOptions();

    options.organizationId = this.organizationId;
    options.allowCreate = this.allowCreate;
    options.allowSearch = this.allowSearch;

    this.modal.show(null, options).subscribe((d : any) => {
      this.person = d;
      this.contactId = d.PortalAccount?.Id;
      this.contactIdChange.next(this.contactId);
    });
  }
}
