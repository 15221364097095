import { Component } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { Chart } from 'chart.js';
import industries from 'refs/sc-ui/resources/industries';
import { ChartColorsService } from 'src/services/chart-colors.service';

@Component({
  selector: 'app-dashboard-retention',
  templateUrl: './dashboard-retention.component.html',
  styleUrls: ['./dashboard-retention.component.scss']
})
export class DashboardRetentionComponent {
  turnoverData: any;

  acquisitionData: any;

  industriesData: any;

  companyData: any;

  industryNames = {};

  clvData: any;

  months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]

  constructor(private api: ScApiService, private colors : ChartColorsService) { }

  ngOnInit() {

    industries.forEach(i => this.industryNames[i.id] = i.name);
    this.industryNames[0] = "Keine Angabe";

    this.api.get(`dashboard/retention`).subscribe((d: any) => {
      

      new Chart('statusChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.Reactivated.map(i => i.Change),
              backgroundColor: 'rgba(31,194,103,0.2)',
              borderColor: 'rgba(31,194,103,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.Reactivated.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(31,194,103,0.8)',
              borderColor: 'rgba(31,194,103,0.8)',
            }
          ]
        }
      });
      
      new Chart('reactivationRateChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.ReactivationRate.map(i => i.Value),
              backgroundColor: 'rgba(31,194,103,0.2)',
              borderColor: 'rgba(31,194,103,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.ReactivationRate.map(i => i.ValueAverage3M),
              backgroundColor: 'rgba(31,194,103,0.8)',
              borderColor: 'rgba(31,194,103,0.8)',
            }
          ]
        }
      });
      
      new Chart('deativatedChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.Deactivated.map(i => i.Change),
              backgroundColor: 'rgba(249,201,24,0.2)',
              borderColor: 'rgba(249,201,24,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.Deactivated.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(249,201,24,0.8)',
              borderColor: 'rgba(249,201,24,0.8)',
            }
          ]
        }
      });

      new Chart('deactivationRateChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.DeactivationRate.map(i => i.Value),
              backgroundColor: 'rgba(249,201,24,0.2)',
              borderColor: 'rgba(249,201,24,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.DeactivationRate.map(i => i.ValueAverage3M),
              backgroundColor: 'rgba(249,201,24,0.8)',
              borderColor: 'rgba(249,201,24,0.8)',
            }
          ]
        }
      });

      new Chart('lostChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.Lost.map(i => i.Change),
              backgroundColor: 'rgba(255,66,77,0.2)',
              borderColor: 'rgba(255,66,77,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.Lost.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(255,66,77,0.8)',
              borderColor: 'rgba(255,66,77,0.8)',
            }
          ]
        }
      });

      new Chart('lossRateChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.LossRate.map(i => i.Value),
              backgroundColor: 'rgba(255,66,77,0.2)',
              borderColor: 'rgba(255,66,77,0.2)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.LossRate.map(i => i.ValueAverage3M),
              backgroundColor: 'rgba(255,66,77,0.8)',
              borderColor: 'rgba(255,66,77,0.8)',
            }
          ]
        }
      });

      new Chart('closedChart', {
        type: 'line',
        data: {
          labels: d.ActiveNet.map(i => i.Month),

          datasets: [
            {
              label: 'Aktiv',
              type: 'bar',
              data: d.ActiveNet.map(i => i.Cumulated),
              backgroundColor: 'rgba(0,98,215,0.6)',
              borderColor: 'rgba(0,98,215,0.8)',
            }
          ]
        }
      });

    });
  }

  



}
