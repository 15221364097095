import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { TopbarService } from 'src/services/topbar.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit, OnDestroy, AfterViewInit {

  view = 'base';

  id: any;

  package: any;

  company: any;

  billingCompany : any;

  quotes : any = [];

  invoices : any = [];

  isGenerating = false;

  @ViewChild('topbar') topbarTemplate : TemplateRef<any>;

  constructor(private api: ScApiService, 
    private route: ActivatedRoute, 
    private router : Router,
    private topbarService : TopbarService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params : any) => {
      this.view = params.view ?? 'base';
    }); 

    this.route.params.subscribe(
      params => {
        this.id = params["id"];

        this.api.get(`packages/${this.id}/quotes`).subscribe((d: any) => {
          this.quotes = d;
        });

        this.api.get(`packages/${this.id}/invoices`).subscribe((d: any) => {
          this.invoices = d;
        });

        this.api.get(`packages/${this.id}`).subscribe((d: any) => {
          this.package = d;

          this.api.get(`companies/${this.package.CompanyId}`).subscribe(c => {
            this.company = c;
          });

          this.loadBillingCompany();
        });
      });
  }

  loadBillingCompany()
  {
    if (this.billingCompany?.Id == this.package.BillingCompanyId) return;
    this.api.get('companies/' + this.package.BillingCompanyId).subscribe(c => this.billingCompany = c);
  }

  saveTimer : any;

  saveSoon()
  {
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
      this.saveTimer = null;
    }

    this.saveTimer = setTimeout(() => {
      this.save();
    }, 500);


  }
  save()
  {
    this.api.put('packages', this.package).subscribe();
  }

  ngAfterViewInit()
  {
    this.topbarService.template = this.topbarTemplate;
  }

  ngOnDestroy()
  {
    this.topbarService.template = null;
  }

  generateInvoice()
  {
    if (this.isGenerating) return;
    this.isGenerating = true;

    this.api.post('packages/' + this.package.Id + '/invoice', null).subscribe((d : any) => {
      this.package.InvoiceId = d.Id;
      this.isGenerating = false;
    })
  }

  setExpiryInMonth(months) {

    var now = new Date();
    var date = new Date(now.setMonth(now.getMonth() + months));
    this.package.Expiry = date;
  }

  activateView(v)
  {
    this.router.navigateByUrl('/packages/' + this.id + '?view=' + v);
  }

}
