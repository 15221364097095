<ng-template [appTopbarTemplate]>
  <div class="title h4" *ngIf="cadence">
    {{ cadence.Name }}
  </div>
  <div class="nav">
    <ul class="nav nav-tabs nav-overflow">
      <li class="nav-item">
        <a class="nav-link" routerLink="/cadences/{{cadence?.Id}}">
          Übersicht
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/cadences/{{cadence?.Id}}/people">
          Personen
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<div class="main-content" *ngIf="steps">
  <div class="container">
    <div class="row" *ngIf="!hideStats">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Gesendete Nachrichten
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="chart" style="height: 30vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>    
    </div>
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="card">
          <div class="card-header h6">
            Schritte
          </div>
          <div class="card-body card-body-list">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-2">
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-2 oneline">
                        Clicks
                      </div>
                      <div class="col-2 oneline">
                        Replies
                      </div>
                      <div class="col-2 oneline">
                        Aktiv
                      </div>
                      <div class="col-2 oneline">
                        Due
                      </div>
                      <div class="col-2 oneline">
                        Completed
                      </div>
                      <div class="col-2 oneline">
                        Fehler
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action py-2" (click)="editStep(step)" *ngFor="let step of steps">
                <div class="row align-items-center">
                  <div class="col-2">
                    <div class="mb-1 name oneline">
                      <a style="white-space: pre; text-overflow:ellipsis; cursor: pointer;">
                        <strong>{{step.Name}}</strong><br>
                        <small *ngIf="step.MinDueDelayInDays <= 0 && step.MaxDueDelayInDays <= 0">sofort</small>
                        <small *ngIf="step.MinDueDelayInDays > 0 && step.MaxDueDelayInDays == step.MinDueDelayInDays">nach {{ step.MinDueDelayInDays }} Tagen</small>
                        <small *ngIf="step.MinDueDelayInDays > 0 && step.MaxDueDelayInDays != step.MinDueDelayInDays">nach {{ step.MinDueDelayInDays }} bis {{ step.MaxDueDelayInDays }} Tagen</small>
                      </a>
                    </div>
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-2">
                        {{ step.Stats.Clicks }} <small>{{ step.Stats.Clicks / step.Stats.Completed * 100.0 | number : '1.1-1' }}%</small>
                      </div>
                      <div class="col-2">
                        {{ step.Stats.Replies }} <small>{{ step.Stats.Replies / step.Stats.Completed * 100.0 | number : '1.1-1' }}%</small>
                      </div>
                      <div class="col-2">
                        {{ step.Stats.Active }}
                      </div>
                      <div class="col-2">
                        {{ step.Stats.Due }}
                      </div>
                      <div class="col-2">
                        {{ step.Stats.Completed }}
                      </div>
                      <div class="col-2">
                        {{ step.Stats.Failed / (step.Stats.Completed + step.Stats.Failed) * 100.0 | number : '1.1-1' }}%
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal-wizard size="xl" #editMailStepModal>
  <sc-modal-wizard-page #editBaseInfoPage>
    <div class="modal-header">
      {{ selectedStep?.Name }}
    </div>
    <div class="modal-body" style="height: 50vh;">
      <sc-form *ngIf="selectedStep">
        <sc-form-row label="Name">
          <input [(ngModel)]="selectedStep.Name" />
        </sc-form-row>

        <sc-form-row label="Beschreibung">
          <input [(ngModel)]="selectedStep.Description" />
        </sc-form-row>

        <sc-form-row label="Varianten">
          <div class="row">
            <div class="col-12" *ngFor="let variant of selectedStep.Payload?.Variants" (click)="selectedVariant = variant; editMailStepModal.navigateTo(editVariantPage)">
              <div class="activity-item">
                <div class="title">
                  {{ variant.Title }}
                </div>
                <div class="chevron">
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.962524 13.0365C0.853149 12.9545 0.825806 12.8451 0.825806 12.7357C0.825806 12.6264 0.853149 12.517 0.935181 12.435L5.91174 7.04825L0.962523 1.63419C0.798461 1.47012 0.798461 1.19669 0.989867 1.03262C1.15393 0.868561 1.42737 0.868561 1.59143 1.05997L6.84143 6.74747C7.00549 6.91153 7.00549 7.15762 6.84143 7.32169L1.59143 13.0092C1.40002 13.2006 1.12659 13.2006 0.962524 13.0365Z" fill="black" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="col-12">
              <button class="btn btn-secondary btn-sm" (click)="addVariant()">Variante hinzufügen</button>
            </div>
          </div>
        </sc-form-row>
      </sc-form>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="editMailStepModal.navigateTo(deleteStepPage)">Löschen</button>
      <button class="btn btn-dark" (click)="saveStep(selectedStep); editMailStepModal.dismiss()">Speichern</button>
    </div>

  </sc-modal-wizard-page>
  <sc-modal-wizard-page #editVariantPage>
    <div class="modal-header">
      {{ selectedStep?.Name }} - {{ selectedVariant?.Title }}
    </div>

    <div class="modal-body">
      <div class="row h-100" *ngIf="selectedVariant">
        <div class="col-12 h-100">
          <sc-form>
            <sc-form-row label="Bezeichnung">
              <input [(ngModel)]="selectedVariant.Title" />
            </sc-form-row>
            <sc-form-row label="Frequenz">
              <input [(ngModel)]="selectedVariant.Frequency" />
            </sc-form-row>
            <sc-form-row label="Aktiv">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="IsActive" [(ngModel)]="selectedVariant.IsActive">
                <label class="custom-control-label" for="IsActive"></label>
              </div>
            </sc-form-row>
          </sc-form>

          <ngx-monaco-editor style="height: 100%;" id="editor" [options]="editorOptions" [(ngModel)]="selectedVariant.Content"></ngx-monaco-editor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="deleteVariant(selectedVariant)">Variante löschen</button>
      <button class="btn btn-primary" (click)="editMailStepModal.navigateTo(editBaseInfoPage)">Fertig</button>
    </div>
  </sc-modal-wizard-page>
  <sc-modal-wizard-page #deleteStepPage>
    <div class="modal-header">
      {{ selectedStep?.Name }} löschen?
    </div>

    <div class="modal-body">
      Soll der Schritt wirklich gelöscht werden?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="editMailStepModal.dismiss()">Löschen</button>
    </div>
  </sc-modal-wizard-page>
</sc-modal-wizard>
