<ng-template #topbar>
  <div class="title h4">
    {{ package?.Title }} - <span class="clickable" routerLink="/companies/{{company?.Id}}">{{ company?.Name }}</span>
  </div>
  <div class="nav">

    <ul class="nav nav-tabs nav-overflow" *ngIf="package">

      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'base'" (click)="activateView('base')">
          Stammdaten
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'quote'" (click)="activateView('quote')">
          Angebot
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'projects'" (click)="activateView('projects')">
          Projekte
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view == 'invoices'" (click)="activateView('invoices')">
          Rechnungen
        </a>
      </li>
    </ul>
  </div>
  <div class="actions" *ngIf="package">
    <button *ngIf="!package.IsCancelled && package?.Status == 'AwaitingQuote'" class="btn btn-round btn-primary" (click)="sendQuoteModal.show()">Angebot versenden</button>
    <button *ngIf="!package.IsCancelled && package?.Status != 'AwaitingQuote'" class="btn btn-round btn-primary" (click)="sendInvoiceModal.show()">Rechnung stellen</button>
  
    <div class="d-inline ms-2" ngbDropdown>
      <button ngbDropdownToggle class="btn btn-circle btn-secondary">
        <i class="fa-regular fa-ellipsis"></i>
      </button>
      <div ngbDropdownMenu>       
        <a ngbDropdownItem *ngIf="!package.IsCancelled && package?.Status == 'AwaitingOrder'" (click)="sendQuoteModal.show()">
          Neues Angebot versenden
        </a>
        <a ngbDropdownItem *ngIf="!package.IsCancelled && package?.Status != 'AwaitingQuote'" (click)="sendInvoiceCorrectionModal.show()">
          Rechnungskorrektur
        </a>
      </div>
    </div>
  </div>
</ng-template>

<div class="main-content" *ngIf="package">
  <div class="container">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <div class="card" *ngIf="view == 'base'">
              <div class="card-header">
                <h6 class="card-header-title">
                  Stammdaten
                </h6>
              </div>

              <div class="card-body">
                <sc-form (change)="saveSoon()">

                  <sc-form-row label="Leistungsempfänger">
                    <app-inline-select-organization [(organizationId)]="package.CompanyId" (organizationIdChange)="saveSoon();"></app-inline-select-organization>
                  </sc-form-row>

                  <sc-form-row label="Bezeichnung">
                    <input [(ngModel)]="package.Title" />
                  </sc-form-row>

                  <sc-form-row label="Beschreibung">
                    <autosize-textarea [(text)]="package.Comment" (textChange)="saveSoon()"></autosize-textarea>
                  </sc-form-row>

                  <sc-form-row label="Volumen">
                    <input type="number" [(ngModel)]="package.Volume" />
                  </sc-form-row>

                  <sc-form-row label="Aktiv">
                    <sc-switch [(ngModel)]="package.IsActive" (ngModelChange)="saveSoon()"></sc-switch>
                  </sc-form-row>

                  <sc-form-row label="Ablauf">
                    <div class="form-control form-control-sm clickable" (click)="expiryModal.show()">
                      {{ package.Expiry | date : 'dd.MM.yyyy' }}
                    </div>
                  </sc-form-row>

                  <sc-form-row label="Erstellt">
                    <div class="form-control form-control-sm">
                      {{ package.Created | date : 'dd.MM.yyyy' }}
                    </div>
                  </sc-form-row>

                </sc-form>

              </div>
            </div>

            
            <app-package-projects *ngIf="view == 'projects'" [package]="package"> </app-package-projects>
            <app-package-quote *ngIf="view == 'quote'" [package]="package" (packageChange)="saveSoon()"> </app-package-quote>
            <app-package-invoices *ngIf="view == 'invoices'" [package]="package"> </app-package-invoices>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #confirmModal>
  <div class="modal-header">
    <h6 class="modal-title">Rechnung erstellen?</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="confirmModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Wichtig: Die Rechnung wird bei Bestätigung buchhalterisch erzeugt und <strong>muss</strong> gestellt oder storniert werden!
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="generateInvoice(); confirmModal.dismiss()">Ja</button>
  </div>
</sc-modal>

<sc-modal #expiryModal>
  <div class="modal-header">
    <h6 class="modal-title">Ablaufdatum wählen</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="expiryModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="package">
    <div class="row">
      <div class="col-auto">
        <sc-date-picker [(date)]="package.Expiry" (dateChange)="saveSoon()"></sc-date-picker>
      </div>
      <div class="col d-none d-md-block">
        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setExpiryInMonth(1); save();">in 1 Monat</button>
        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setExpiryInMonth(6); save();">in 6 Monaten</button>
        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setExpiryInMonth(12); save();">in 1 Jahr</button>
        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setExpiryInMonth(24); save();">in 2 Jahren</button>
      </div>
    </div>
  </div>
</sc-modal>

<app-project-package-action-modal #sendQuoteModal [package]="package" title="Angebot versenden?" actionName="send-quote">
  <div class="body">
    Angebot versenden?
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-package-action-modal>

<app-project-package-action-modal #sendInvoiceModal [package]="package" title="Rechnung versenden?" actionName="send-invoice">
  <div class="body">
    Rechnung versenden?
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-package-action-modal>

<app-project-package-action-modal #sendInvoiceCorrectionModal [package]="package" title="Rechnungskorrektur versenden?" actionName="send-invoice-correction">
  <div class="body">
    Rechnungskorrektur versenden?
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-package-action-modal>
