<ng-template [appTopbarTemplate]>
  <div class="title">
    <h4 class="mb-0">Marketing-Dashboard</h4>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
					Ereignisse
                </h6>
              </div>
			  <div class="col-2">
				<select class="custom-select" [(ngModel)]="type" (ngModelChange)="reload()">
					<option [ngValue]="'sent_message'">Sent Message</option>
					<option [ngValue]="'first_visit'">First Visit</option>
					<option [ngValue]="'page_view'">Page View</option>
					<option [ngValue]="'demo_scheduled'">Demo Scheduled</option>
					<option [ngValue]="'webinar_scheduled'">Webinar Scheduled</option>
				</select>
			  </div>
			  <div class="col-2">
				<select class="custom-select" [(ngModel)]="period" (ngModelChange)="reload()">
					<option [ngValue]="'1d'">1 Tag</option>
					<option [ngValue]="'14d'">14 Tage</option>
					<option [ngValue]="'1m'">1 Monat</option>
					<option [ngValue]="'6m'">6 Monate</option>
					<option [ngValue]="'12m'">12 Monate</option>
				</select>
			  </div>
			  <div class="col-2">
				<select class="custom-select" [(ngModel)]="utmSource" (ngModelChange)="reload()">
					<option [ngValue]="''">Alle</option>
					<option [ngValue]="'referral'">referral</option>
					<option [ngValue]="'event'">event</option>
					<option [ngValue]="'coldcall'">coldcall</option>
					<option [ngValue]="'linkedin'">linkedin</option>
					<option [ngValue]="'linkedin_direct'">linkedin_direct</option>
					<option [ngValue]="'xing'">xing</option>
					<option [ngValue]="'xing_direct'">xing_direct</option>
					<option [ngValue]="'google_ads'">google_ads</option>
					<option [ngValue]="'google_organic'">google_organic</option>
					<option [ngValue]="'affiliate'">affiliate</option>
					<option [ngValue]="'podcast'">podcast</option>
					<option [ngValue]="'mailing'">mailing</option>
					<option [ngValue]="'mailing_newsletter'">mailing_newsletter</option>					
				</select>
			  </div>
			  <div class="col-2">
				<select class="custom-select" [(ngModel)]="utmCampaign" (ngModelChange)="reload()">
					<option [ngValue]="''">Alle</option>
					<option *ngFor="let c of utmCampaigns" [ngValue]="c">{{ c }}</option>
				</select>
			  </div>
            </div>

          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="eventsChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>


	  <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
					Conversion
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="conversionChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
