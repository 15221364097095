import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-messaging-done',
  templateUrl: './activity-item-project-messaging-done.component.html',
  styleUrls: ['./activity-item-project-messaging-done.component.scss']
})
export class ActivityItemProjectMessagingDoneComponent implements OnInit {
  
  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;

  constructor() { }

  ngOnInit(): void {
  }

}
