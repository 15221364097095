<ng-template [appTopbarTemplate]>

  <div class="title h4">
    Reports
  </div>

</ng-template>

<div class="main-content">

  <div class="container">

    <div class="row">
      <div class="col-6">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" (click)="generate(report)" *ngFor="let report of reports">
            <div class="row align-items-center">
              <div class="col">
                <span>
                  <h6 class="mb-1 oneline d-inline">
                    {{report.Name}}
                  </h6>
                </span>
                <p class="card-text text-muted small mb-0">
                  {{ report.Description }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>

	  <div class="col-6">
		<textarea class="w-100 form-control" rows="30" readonly>{{ report?.Report }}</textarea>
	  </div>
    </div>

  </div>

</div>
