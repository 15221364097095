<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Unternehmen
  </div>

  <div class="actions">
    <a class="btn btn-sm btn-secondary btn-circle lift" (click)="addOrganization()">
      <i class="fa-solid fa-plus"></i>
    </a>
  </div>
</ng-template>

<div class="main-content">

  <div class="container">

    <div class="row mb-3 align-items-center">
      <div class="col-4">
        <input [(ngModel)]="q" (ngModelChange)="reloadItemsSoon()" class="form-control form-control-prepended search" placeholder="Suchen">
      </div>
      <div class="col-3">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="ownAccountsOnly" [(ngModel)]="ownAccountsOnly" (ngModelChange)="reloadItemsSoon()">
          <label class="custom-control-label" for="ownAccountsOnly">
            <span class="oneline">
              Nur eigene Accounts
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-12">

        <app-list-organizations [organizations]="companies" [navigateOnClick]="true"></app-list-organizations>

        <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
          <div class="col-auto">
            <div class="btn btn-secondary btn-sm" [class.loading]="isLoading" [disabled]="isLoading" (click)="loadItems()">Weitere laden</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-modal-select-organization #addModal></app-modal-select-organization>