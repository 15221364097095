import { Component, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-select-project-package',
  templateUrl: './modal-select-project-package.component.html',
  styleUrls: ['./modal-select-project-package.component.scss']
})
export class ModalSelectProjectPackageComponent {

  packages : any = [];

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  constructor(private api : ScApiService)
  {}

  show(organizationId)
  {
    this.api.get('packages?' + '&organizationId=' + organizationId + '&activeOnly=true').subscribe(d => {
      this.packages = d;
    });

    return this.modal.show();
  }
}
