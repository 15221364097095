import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { ScUploadService } from '@sc-ui';

@Component({
  selector: 'app-project-briefing-files',
  templateUrl: './project-briefing-files.component.html',
  styleUrls: ['./project-briefing-files.component.scss']
})
export class ProjectBriefingFilesComponent implements OnInit {

  @ViewChild('deleteFileModal')
  deleteFileModal : ScuiModalComponent;

  @Input()
  project : any = {};

  filesToUpload: any = [];

  isUploading: boolean;

  constructor(
    private api : ScApiService, 
    private uploadApi : ScUploadService) { }

  ngOnInit() {
  }

  uploadFiles() {
    console.log('starting upload');

    this.isUploading = true;

    var runningUploads = 0;
    var copy = this.filesToUpload.filter(i => true);

    copy.forEach(file => {

      runningUploads++;

      file.isUploading = true;
      file.uploadProgress = 0;

      this.uploadApi.upload([file],
      res => {

        console.log(res);
        console.log("File uploaded");

        file.uploadProgress = 99;

        this.api.post("jobs/" + this.project.Id + "/files", res).subscribe(f => {
          file.uploadProgress = 100;
          file.isUploading = false;
          this.project.Files.push(res);
          var index = this.filesToUpload.indexOf(file);
          this.filesToUpload.splice(index, 1);
          runningUploads--;
          this.isUploading = runningUploads > 0;
        });        
      }, 
      p => {
        console.log(p);
        file.uploadProgress = p;
      },
      error => {
        if (error.message)
          file.error = error.message;
        else
          file.error = "Der Upload ist fehlgeschlagen";

        console.log(error);
        runningUploads--;
        this.isUploading = runningUploads > 0;
        file.isUploading = false;
        file.hasError = true;
      });
    });
  }

  deleteFile(file)
  {
    this.deleteFileModal.show().subscribe(() => {
      file.isDeleting = true;

      this.api.delete('jobs/' + this.project.Id + '/files/' + file.Id).subscribe(d => {
        var index = this.project.Files.indexOf(file);
         this.project.Files.splice(index, 1);
      },
      e => {
        file.isDeleting = false;      
      },
      () => {
        file.isDeleting = false; 
      });
    });    
  }


  areUploadsInProgress() {
    if (!this.filesToUpload) return false;
    for (let file of this.filesToUpload) {
      if (file.isUploading) return true;
    }
    return false;
  }

  onDocumentsSelected(e) {
    console.log(e.length);
    for (var i = 0; i < e.length; i++) {
      var file = e[i];
      if (this.filesToUpload.some(i => i.name === file.name)) continue;
      this.filesToUpload.push(file);
    }

    this.uploadFiles();
  }

}
