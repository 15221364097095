<span *ngIf="project?.Status != 'Completed' && !project.IsCancelled" class="badge badge-pill badge-light">
  {{ stati[project.Status].label }}
</span>

<span *ngIf="project.Status == 'Completed' && !project?.IsCancelled" class="badge badge-pill badge-success-soft">
  <i class="fa-solid fa-check"></i> Abgeschlossen
</span>

<span *ngIf="project?.IsCancelled" class="badge badge-pill badge-danger-soft">
  <i class="fa-solid fa-xmark"></i> Abgebrochen
</span>
