import { Component } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-training-batches',
  templateUrl: './training-batches.component.html',
  styleUrls: ['./training-batches.component.scss']
})
export class TrainingBatchesComponent {

  batches : any = []

  constructor(private api : ScApiService)
  {}

  ngOnInit()
  {
    this.api.get('training-batches').subscribe(d => {
      this.batches = d;
    })
  }

  saveBatch(batch)
  {
    this.api.put('training-batches', batch).subscribe()
  }

}
