import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';
import industries from 'refs/sc-ui/resources/industries';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-screening',
  templateUrl: './project-screening.component.html',
  styleUrls: ['./project-screening.component.scss']
})
export class ProjectScreeningComponent {

  id: any;

  project: any;

  searches = []

  isImporting = false;

  searchToImport : any = null;

  @Input()
  industries: any = []

  results: any = []

  newSearch: any = {
    Data: {
      Skills: [],
      NiceToHaveSkills: [],
      Industries: [],
      Locations: [],
      MinYearsOfExperience: 3,
      MaxYearsOfExperience: 30,
      Radius: 50,
      GermanLevel: null,
      EnglishLevel: null,
      Levels: {
        Graduate: null,
        Junior: null,
        Professional: null,
        Senior: null,
        Lead: null,
        Executive: null
      }
    }

  }

  @ViewChild('searchModal')
  searchModal: ModalWizardComponent;

  @ViewChild('startPage')
  startPage: ModalWizardPageComponent;

  @ViewChild('importModal')
  importModal: ModalWizardComponent;

  @ViewChild('successPage')
  successPage: ModalWizardPageComponent;

  @ViewChild('errorPage')
  errorPage: ModalWizardPageComponent;

  importError = '';

  requirements = []

  constructor(private api: ScApiService, private route: ActivatedRoute, private projectService: ProjectService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.id = params['id'];
        var cached = this.projectService.getFromCache(this.id);
        if (cached) this.project = cached;
        this.projectService.load(this.id).subscribe(p => {
          this.project = p;
        });

        this.api.get('projects/' + this.id + '/searches').subscribe((d: any) => {
          this.searches = d;

          if (d?.length) {
            var latestSearch = d[0];
            this.newSearch = structuredClone(d[0]);
            if (!this.newSearch.Data.Levels) this.newSearch.Data.Levels = {}
            this.newSearch.Id = 0;
            this.loadResults(latestSearch);
          }

        });
      });
  }

  addRequirement()
  {
    var req = {
      type: 'feature',
      values: [],
      IsExclusion: false
    };

    this.requirements.push(req);
  }

  addIndustry(i) {
    var id = i.item;
    this.newSearch.Data.Industries.push(id);
    console.log(i)
  }

  loadResults(search) {
    this.api.get('searches/' + search.Id + '/results').subscribe((d: any) => {
      this.results = d;
    });
  }

  createSearch() {

    var args: any = this.newSearch;
    args.ProjectId = this.id;
    args.isLoading = true;

    this.searches.splice(0, 0, args);
    this.searchModal.dismiss();

    this.newSearch = structuredClone(args);
    this.newSearch.Id = 0;

    this.api.post('searches', args).subscribe((d: any) => {
      args.Id = d.Id;
      args.Data.Total = d.Data.Total;
      args.isLoading = false;
      this.loadResults(args);
    });
  }

  deleteSearch(s) {
    this.api.delete('searches/' + s.Id).subscribe(() => {
      var i = this.searches.indexOf(s);
      this.searches.splice(i, 1);
    });

  }

  import() {

    this.isImporting = true;

    this.api.post('searches/' + this.searchToImport.Id + '/import', null).subscribe(
      () => {
      this.isImporting = false;
      this.importModal.navigateTo(this.successPage);
    },
  (e) => {
    this.isImporting = false;
    this.importError = JSON.stringify(e);
    this.importModal.navigateTo(this.errorPage);
  });

  }

  copySearch(s) {
    this.newSearch = structuredClone(s);    
    if (!this.newSearch.Data.Levels) this.newSearch.Data.Levels = {}
    this.newSearch.Id = 0;
    this.searchModal.show(this.startPage);
  }

  add(item, collection) {
    collection.push(item);
  }

  remove(item, collection) {
    var i = collection.indexOf(item);
    collection.splice(i, 1);
  }

  addSkill(skill) {
    this.newSearch.Data.Skills.push(skill);
  }

  addNiceToHaveSkill(skill) {
    this.newSearch.Data.NiceToHaveSkills.push(skill);
  }

  industryIdToName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry?.name;
  };

  industryIdToIdWithName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry.id + ' - ' + industry.name;
  };

  searchIndustries: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return industries
          .filter(i => i.id % 1000 == 0 && i.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort(i => i.name.toLowerCase().indexOf(term.toLowerCase()))
          .slice(0, 10)
          .map(i => i.id);
      }));
}
