<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content" *ngIf="project">

    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col">
          </div>
          <div class="col-auto">
            <ul class="nav nav-tabs nav-overflow">
              <li class="nav-item">
                <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/imports" routerLinkActive="active">
                  Import
                </a>
              </li>
  
              <li class="nav-item">
                <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/screening" routerLinkActive="active">
                  Screening
                </a>
              </li>
  
              <li class="nav-item">
                <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/templates" routerLinkActive="active">
                  Templates
                </a>
              </li>
  
              <li class="nav-item">
                <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/listing" routerLinkActive="active">
                  Bewerbungen
                </a>
              </li>
  
            </ul>
          </div>
        </div>
  
      </div>
    </div>
</div>
