import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScuiModalComponent, ScApiService } from '@sc-ui';
import * as _ from 'lodash'
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from 'src/services/project.service';

const utils = require('../../../utils/diff');

@Component({
  selector: 'app-project-profile',
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss']
})
export class ProjectProfileComponent implements OnInit {

  loadTimeout: any;

  project: any;

  tags: any = [];

  keywordInput: string;
  skillHaveInput: string;
  newSkillName: string;
  openModal: NgbModalRef;
  manualTag: string;
  id: number;

  selectedSkill: any;

  isScoringInProgress: boolean;
  candidatesToScore: number;
  scoringProgress: number;
  scoringOffset: number;

  @ViewChild("blacklistModal")
  blacklistModal: ScuiModalComponent;

  @ViewChild("nameBlacklistModal")
  nameBlacklistModal: ScuiModalComponent;

  haves: any;

  results: any;

  selectedResult: any;

  resultCount: any = 0;

  constructor(
    private api: ScApiService,
    private projectService: ProjectService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void 
  {
    this.route.params
      .subscribe(params => {
        this.id = params['id'];
        var cached = this.projectService.getFromCache(this.id);
        if (cached) this.project = cached;
        this.projectService.load(this.id).subscribe(p => {
          this.project = p;
        });
      });
  }

  saveSoon() 
  {
    if (this['saveTimeout']) clearTimeout(this['saveTimeout']);
    this['saveTimeout'] = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    this.projectService.save(this.project).subscribe();
  }

  scoreNext() {
    if (this.scoringOffset > 0 && this.scoringOffset >= this.candidatesToScore) {
      this.isScoringInProgress = false;
      return;
    }

    const url = `projects/${this.id}/profiling/score?skip=${this.scoringOffset}`;

    this.api.get(url).subscribe((data: any) => {
      this.scoringOffset += 200;
      this.scoringProgress = this.scoringOffset / this.candidatesToScore * 100.0;
      this.scoreNext();
    });
  }

  score() {
    this.isScoringInProgress = true;
    this.scoringOffset = 0;
    this.candidatesToScore = 0;

    var url = "projects/" + this.id + "/profiling/score/warmup";
    this.api.get(url).subscribe((data: any) => {
      this.candidatesToScore = data.ProfilesToScore;
      this.scoreNext();
    });
  }

  addSkill(name: string) {

    if (!name) return;

    if (this.project.Profile.Skills == null) this.project.Profile.Skills = [];

    var skill = {
      Name: name,
      Haves: [],
      Weight: 0.8
    };

    this.project.Profile.Skills.push(skill);
    this.save();
  }

  deleteSkill(skill) {
    var index = this.project.Profile.Skills.indexOf(skill);
    if (index < 0) return;
    this.project.Profile.Skills.splice(index, 1);
    this.save();
  }

  editBlacklist() {
    this.blacklistModal.show().subscribe();
  }

  editNameBlacklist() {
    this.nameBlacklistModal.show().subscribe();
  }

  addTagToSkill(skill: any, key: string) {

    if (!skill.Haves) skill.Haves = [];

    key = key.toLowerCase().trim();
    if (!key) return;

    for (let i = 0; i < skill.Haves.length; i++) {
      const h = skill.Haves[i];
      if (h.Key === key) return;
    }

    var have = {
      Key: key,
      Count: 0
    };

    skill.Haves.push(have);
    this.save();
  }

  deleteTagFromSkill(skill: any, key: string) {

    if (!skill.Haves) skill.Haves = [];

    key = key.toLowerCase();

    var index = -1;

    for (let i = 0; i < skill.Haves.length; i++) {
      const h = skill.Haves[i];
      if (h.Key === key) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    skill.Haves.splice(index, 1);
    this.save();
  }

  addTo(array, item : string, allowDuplicate = false) {
    item = item.toLowerCase()?.trim();
    if (!item) return;
    if (!array) return;
    if (!allowDuplicate) {
      var exists = array.indexOf(item) >= 0;
      if (exists) return;
    }

    array.push(item);
    this.save();
  }

  deleteFrom(array, item) {
    if (!array) return;
    var index = array.indexOf(item);
    if (index === -1) return;
    array.splice(index, 1);
    this.save();
  }

  truncate(text: string, length) {
    if (!text) return '';
    if (text.length <= length) return text;
    text = text.substring(0, length) + "...";
    return text;
  }

  toBool(s: any) {
    if (s == true) return true;
    if (s == false) return false;
    if (s == null) return null;
    if (s == "true") return true;
    if (s == "false") return false;
  }
}
