<div class="mb-5">

  <h6 class="h6">
    <span class="pretitle oneline" *ngIf="group.preTitle">{{ group.preTitle }}</span>
    <span class="d-block oneline">{{ group.name }}</span>
  </h6>

  <div class="list-group box-shadow-sm">
    <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center clickable" *ngFor="let subGroup of group.subGroups" (click)="select(subGroup)">
      <div class="avatar-user-item">
        <div class="row align-items-center lh-1">
          <div class="col-auto">
            <sc-avatar [user]="subGroup.items[0].Contact"></sc-avatar>
          </div>
          <div class="col ms-n2">
            <h6 class="mb-0">{{ subGroup.items[0] | task }}</h6>
            <span class="small d-inline-block text-muted mb-0">{{ subGroup.items[0].Due | humanizedDate }}</span>
          </div>
        </div>
      </div>
      <span class="badge badge-pill badge-light" [class.status-danger]="subGroup.hasDue">{{ subGroup.items.length }}</span>
    </a>
  </div>
</div>

<!-- <a>
  <div>
    <div class="card-body p-0">
      <div class="task-container clickable" *ngFor="let subGroup of group.subGroups" (click)="select(subGroup)">
        <div class="col-icon">
          <sc-avatar [user]="subGroup.items[0].Contact"></sc-avatar>
        </div>
        <div class="col-text">
          <h6 class="oneline mb-0">{{ subGroup.items[0] | task }}</h6>
          <div class="text-muted oneline">{{ subGroup.items[0].Due | humanizedDate }}</div>
        </div>

        <div class="col-badge">
          <span class="badge badge-pill badge-light">{{ subGroup.items.length }}</span>
        </div>
      </div>
    </div>
  </div>
</a> -->
