<a>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">        
        <div class="col">
            <span class="mb-0">
              <ng-content></ng-content>
            </span>
        </div>
        <div class="col-auto">
          <i class="fa-regular fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</a>
