<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Angebote
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body card-body-list">
            <ul class="list-group">
              <a class="list-group-item list-group-item-action" [href]="quote.Uri" target="_blank" *ngFor="let quote of quotes">
                <div class="row">
                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        <span *ngIf="quote.Project">Einzelprojekt - {{ quote.Project.Name }} <span class="small ms-2">({{ quote.Project.Id }})</span> </span>
                        <span *ngIf="quote.Package">Kontingent - {{ quote.Package.Title }} <span class="small ms-2">({{ quote.Package.Id }})</span> </span>
                        <span *ngIf="!quote.Package && !quote.Project">Keine Zuordnung</span>
                      </h6>
                    </span>

                    <p class="card-text text-muted small mb-0 oneline">
                      {{ quote.QuoteNumber }} <span *ngIf="quote.Company">- {{quote.Company?.Name }}</span> - {{quote.Date | date : 'dd.MM.yy'}}
                    </p>
                  </div>
                  <div class="col-auto">
                    <div class="d-inline-block" style="width: 110px;">
                      <span class="oneline">{{ (quote.Amount - quote.Discount) | number : '1.2-2'}} €</span>
                    </div>

                    <span class="badge badge-pill badge-light">{{ quote.Status }}</span>
                  </div>
                </div>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>