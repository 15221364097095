
<sc-modal size="lg" #modal>
	<sc-modal-header>
	  Sales-Status anpassen
	</sc-modal-header>
	<sc-modal-body>
  
	  <sc-form form="horizontal">
		<sc-form-row label="Ereignis">
		  <select class="custom-select" [(ngModel)]="eventType">
			<option ngValue="None">Keine Angabe</option>
			<option ngValue="DemoCall">Demo-Call</option>
			<option ngValue="Webinar">Webinar</option>
			<option ngValue="EMail">E-Mail</option>
			<option ngValue="Call">Call</option>
			<option ngValue="Other">Sonstiges</option>
		  </select>
		</sc-form-row>
		<sc-form-row label="Neuer Status">
		  <select class="custom-select" [(ngModel)]="newStatus">
			<option ngValue="None">Kein Status</option>
			<option ngValue="New">Qualified</option>
			<option ngValue="WarmDirect">Warm</option>
			<option ngValue="Scheduled">Terminiert</option>
			<option ngValue="Closing">Closing</option>
			<option ngValue="Upselling">Upselling</option>
			<option ngValue="Disqualified">Disqualified</option>
		  </select>
		</sc-form-row>
  
		<sc-form-row label="Kommentar">
		  <textarea class="form-control" rows="4" [(ngModel)]="comment" placeholder="Zusatzinfos"></textarea>
		</sc-form-row>
		<sc-form-row label="Wiedervorlage">
		  <sc-date-picker [(date)]="onHoldDate"></sc-date-picker>
		</sc-form-row>
	  </sc-form>
	</sc-modal-body>
	<sc-modal-footer>
	  <button class="btn btn-dark btn-round" (click)="updateStatus()">Ok</button>
	</sc-modal-footer>
  </sc-modal>
  