<div *ngIf="candidate">

  <div class="row align-items-center">
    <div class="col"></div>
    <div class="col-auto links">
      <a class="btn btn-sm btn-dark btn-circle me-2" href="https://backend.skillconomy.com/candidates/{{ candidate.Guid }}" target="_blank">
        <fa class="fa-regular fa-arrow-up-right"></fa>
      </a>
      <a *ngIf="candidate.XingId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.xing.com/xtm/profiles/{{candidate.XingId}}" target="_blank">
        <fa class="fa-brands fa-xing"></fa>
      </a>
      <a *ngIf="candidate.LinkedInId" class="btn btn-sm btn-dark btn-circle" href="https://www.linkedin.com/talent/profile/{{candidate.LinkedInId}}" target="_blank">
        <fa class="fa-brands fa-linkedin-in"></fa>
      </a>
    </div>
  </div>

  <div class="row mb-5 mt-5">
    <div class="col-12">
      <app-sidebar-candidate-header [candidate]="candidate"></app-sidebar-candidate-header>
    </div>
  </div>

  <div class="row mb-2" *ngIf="blacklistInfo?.Pass == false">
    <div class="col-12">
      <div class="alert alert-error">
        <strong>Auf Blacklist</strong><br />
        <span *ngFor="let message of blacklistInfo.Messages">
          {{ message }} <br>
        </span>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <app-candidate-projects [candidate]="candidate"></app-candidate-projects>
    </div>
  </div>

  <div class="row mb-2">

    <div class="col-12">
      <ul class="list-unstyled">
        <li>
          <app-sidebar-candidate-exact-item icon="fa-regular fa-cloud-arrow-up" (click)="updateModal.show()">Profil aktualisieren</app-sidebar-candidate-exact-item>
        </li>
        <li *ngIf="!isAssignedToProject">
          <app-sidebar-candidate-exact-item icon="fa-solid fa-user-plus" (click)="assignProject()">Zu Projekt hinzufügen</app-sidebar-candidate-exact-item>
        </li>
      </ul>
      <ul class="list-unstyled" *ngIf="isAssignedToProject">
        
        <li>
          <app-sidebar-candidate-exact-item (click)="subject(498)">Erstnachricht (Betreff)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="message(498)">Erstnachricht (Inhalt)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="subject(508)">Zweitnachricht (Betreff)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="message(508)">Zweitnachricht (Inhalt)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="subject(507)">Link-Fix (Betreff)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="message(507)">Link-Fix (Inhalt)</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="notInterested(521)">Hat kein Interesse</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="notInterested(522)">Hat kein Interesse, Zusatzinfos</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="notInterested(525)">Entschuldigung für Falschansprache</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="interested(526)">Hat Interesse</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="message(524)">Einverständnis Weitergabe</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="message(523)">Infos zum Prozess</app-sidebar-candidate-exact-item>
        </li>
        <li>
          <app-sidebar-candidate-exact-item (click)="ebner()">Ebner Ansprache</app-sidebar-candidate-exact-item>
        </li>
      </ul>
    </div>
  </div>

</div>

<sc-modal #updateModal>
  <sc-modal-header>
    Profil synchronisieren
  </sc-modal-header>
  <sc-modal-body>
    <div class="checklist">
      <div class="custom-control custom-checkbox checklist-control">
        <input class="custom-control-input" id="s1" type="checkbox" [(ngModel)]="updateSettings.Experience" />
        <label class="custom-control-label" for="s1"></label>
        <span class="custom-control-caption">
          Berufserfahrung, Job-Titel, Aktueller Arbeitgeber
        </span>
      </div>
      <div class="custom-control custom-checkbox checklist-control">
        <input class="custom-control-input" id="s2" type="checkbox" [(ngModel)]="updateSettings.Haves" />
        <label class="custom-control-label" for="s2"></label>
        <span class="custom-control-caption">
          Skills
        </span>
      </div>
      <div class="custom-control custom-checkbox checklist-control">
        <input class="custom-control-input" id="s3" type="checkbox" [(ngModel)]="updateSettings.Avatar" />
        <label class="custom-control-label" for="s3"></label>
        <span class="custom-control-caption">
          Profilbild
        </span>
      </div>
    </div>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-primary" (click)="updateProfile()" [disabled]="isUpdatingProfile || (!updateSettings.Experience && !updateSettings.Haves && !updateSettings.Avatar)">Synchronisieren</button>
  </sc-modal-footer>
</sc-modal>

<sc-modal size="lg" #baseDataModal>
  <div class="modal-header">
    Stammdaten bearbeiten
  </div>
  <div class="modal-body" *ngIf="candidate">
    <div class="row mb-2">
      <div class="col-3 oneline">
        Titel
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.Title" name="title" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Vorname
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.FirstName" name="firstName" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Nachname
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.LastName" name="lastName" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Job-Titel
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.JobTitle" name="jobTitle" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Tags
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.Tags" name="lastName" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        Bild
      </div>
      <div class="col-2">
        <sc-image-select [(image)]="candidate.Image1024" (imageChange)="onChange();"></sc-image-select>
        <a class="d-block small mt-1 text-muted clickable text-decoration-underline" *ngIf="candidate.Image1024" (click)="candidate.Image1024 = null; candidate.XingImage64 = null; candidate.Image = null; onChange();">Bild entfernen</a>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        E-Mail
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.EMail" name="eMail" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        XING-Profil
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.XingProfileUri" name="eMail" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        XING-ID
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.XingId" name="XingId" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3 oneline">
        LinkedIn-Profil
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.LinkedInProfileUri" name="eMail" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        Telefon
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.Phone" name="phone" class="form-control form-control-sm" (ngModelChange)="onChange();" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        Ort
      </div>
      <div class="col-9">
        <input [(ngModel)]="candidate.City" class="form-control form-control-sm" scGmapsAutocomplete (selected)="setLocation($event);" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        Kommentar
      </div>
      <div class="col-9">
        <autosize-textarea [(text)]="candidate.Profile.Comment" (textChange)="onChange();" placeholder="Kommentar"></autosize-textarea>
      </div>
    </div>
  </div>
</sc-modal>
