<div *ngIf="group">


<div class="row">

    <div class="col section-title">
        <h3>Kandidat:innen</h3>        
    </div>

</div>

<div class="row align-items-end mb-5">
    <div class="col">
        <input class="form-control form-control-search candidate-search" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
    </div>
    <div class="col-auto">
        <button class="btn btn-sm btn-secondary" (click)="modal.show()">Kandidat:in hinzufügen</button>
    </div>
</div>

<div class="row">

    <div class="col-12">
        <div class="card">
            <div class="list-group list-group-lg list-group-flush list">

                <a *ngFor="let candidate of candidates" class="list-group-item list-group-item-action candidate-list-item">

                    <div class="candidate-row clickable" routerLink="/candidates/{{candidate.Id}}">
                        <div class="avatar-col">
                            <sc-avatar [user]="candidate"></sc-avatar>
                        </div>
                        <div class="body-col">

                            <h5 class="mb-1 oneline">
                                {{candidate.FirstName}} {{ candidate.LastName }}
                            </h5>

                            <p class="card-text small mb-0 oneline">
                                <span *ngIf="candidate.JobTitle">{{candidate.JobTitle}}</span>
                                <span *ngIf="candidate.JobTitle && candidate.Company">, </span>
                                <span *ngIf="candidate.Company">{{ candidate.Company}}</span>
                                <span *ngIf="!candidate.JobTitle && !candidate.Company" class="text-muted">keine Angaben zur aktuellen Position</span>
                            </p>

                        </div>

                        <div class="status-col">
                            <span class="badge badge-status rounded-pill badge-small badge-pastel-green" *ngIf="candidate.Assignment.IsQualified">
                                <svg aria-hidden="true" focusable="false" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
                                Qualifiziert 
                            </span>

                            <span class="badge badge-status rounded-pill badge-small badge-pastel-blue" *ngIf="!candidate.Assignment.IsQualified">
                                <svg aria-hidden="true" focusable="false" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
                                Vorgestellt 
                            </span>
                        </div>

                        <div class="actions-col" (click)="$event.stopPropagation()">
                            <div ngbDropdown >
                                <div ngbDropdownToggle>
                                    <div class="file me-2 ms-2">
                                        <i class="fa-regular fa-ellipsis"></i>
                                    </div>
                                </div>
                                <div ngbDropdownMenu> 
                                    <a ngbDropdownItem (click)="removeUser(candidate)">
                                        Entfernen
                                    </a>                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </a>

            </div>

            <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
                <div class="col-auto">
                  <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
                </div>
              </div>
        </div>
    </div>

</div>

</div>

<app-pool-group-candidates-add-modal [group]="group" (userAdded)="onUserAdded($event)" (userRemoved)="onUserRemoved($event)" #modal></app-pool-group-candidates-add-modal>