import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  quotes : any = [];

  constructor(private api : ScApiService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params : any) => {          
      this.api.get(`quotes?companyId=${params?.companyId}`).subscribe(i => this.quotes = i);
    })
    
  }

}
