import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ModalSelectOrganizationComponent } from 'src/components/modal-select-organization/modal-select-organization.component';

import industries from 'refs/sc-ui/resources/industries';

const utils = require('../../utils/diff');
const LOCAL_STORAGE_KEY = 'companies_view';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  companies: any = [];

  _saved = {};

  searchTimer: any;

  q: string = "";

  skip: number = 0;

  hasMoreItems = false;

  isLoading = false;

  ownAccountsOnly = false;

  @ViewChild('addModal')
  addModal : ModalSelectOrganizationComponent;

  constructor(public api: ScApiService, private router : Router) { }

  ngOnInit() {   
    this.restoreState();
    this.loadItems();
  }

  reloadItemsSoon() {
    if (this.searchTimer) clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => this.reloadItems(), 1000);
  }

  reloadItems() {
    this.skip = 0;
    this.companies = [];
    this.loadItems();
  }

  loadItems() {
    this.isLoading = true;

    this.api.get('companies?take=100&q=' + encodeURIComponent(this.q) + '&skip=' + this.skip + "&ownAccountsOnly=" + this.ownAccountsOnly).subscribe((d: any) => {

      if (!this.companies) this.companies = [];
      this.companies = this.companies.concat(d);
      d.forEach(c => this._saved[c.Id] = _.cloneDeep(c));
      this.skip += 100;
      this.hasMoreItems = d.length == 100;
      this.persistState();
      this.isLoading = false;

    });
  }

  persistState() {
    var state = {
      Version: 1,
      Q: this.q
    };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }

  restoreState()
  {
    var stateJson = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (stateJson) {
      var state = JSON.parse(stateJson);
      if (state.Version == 1) {
        this.q = state.Q;
      }
    }
  }

  addOrganization() {
    this.addModal.show().subscribe((d: any) => {
      this.router.navigateByUrl('companies/' + d.Id);
    })
  }

  industryIdToName = (id) => {
    if (!id) return '';
    var industry = industries.find(i => i.id == id);
    return industry.name;
  };

  industryIdToIdWithName = (id) => {
    var industry = industries.find(i => i.id == id);
    return industry.id + ' - ' + industry.name;
  };

  searchIndustries = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        industries
          .filter(i => i.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort(i => i.name.toLowerCase().indexOf(term.toLowerCase()))
          .slice(0, 10)
          .map(i => i.id)
      )
    )

  saveSoon(company: any) {
    var saveSoonTimerId = 'saveSoon' + company.Id;
    if (this[saveSoonTimerId]) clearTimeout(this[saveSoonTimerId]);
    this[saveSoonTimerId] = setTimeout(() => {
      this.save(company);
    }, 500);
  }

  save(company: any) {
    var _saved = this._saved[company.Id];
    var diff = utils.diff(_saved, company);
    if (_.isEmpty(diff)) return;
    return this.api.put("companies/" + company.Id + "/merge", diff).subscribe(c => {
      this._saved[company.Id] = _.cloneDeep(company);
      this.persistState();
    });
  }
}
