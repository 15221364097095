<div class="app-nav-container">
  <div class="app-nav-primary">

    <div class="app-nav-item app-icon">
      <img src="assets/icons/sc-icon.svg" />
    </div>

    <a class="app-nav-item app-nav-link" routerLink="/projects">
      <i class="fa-solid fa-rectangle-history"></i>
    </a>

    <a class="app-nav-item app-nav-link" routerLink="/companies">
      <i class="fa-solid fa-building"></i>
    </a>

    <a class="app-nav-item app-nav-link" routerLink="/candidates">
      <i class="fa-solid fa-user-group"></i>
    </a>

    <div class="app-nav-item app-nav-link" (click)="showTasks()">
      <i class="fa-solid fa-bell"></i>
      <span *ngIf="notifications.items?.length" class="badge badge-pill badge-danger">{{ notifications.tasks.dueCount }}</span>
    </div>

    <div class="app-nav-item app-nav-link" (click)="showSecondaryNav()">
      <i class="fa-solid fa-ellipsis"></i>
    </div>

    <div class="app-nav-item app-user">
      <sc-avatar [user]="userService.me"></sc-avatar>
    </div>
  </div>

  <div class="app-nav-secondary box-shadow" [class.show]="secondaryNavVisible" (mouseleave)="hideSecondaryNavSoon()" (mouseenter)="showSecondaryNav()">
    <div class="container">
      <div class="row py-4">

        <div class="col-12 mb-4">
          <strong class="d-block mb-4 pretitle">Projektmanagement</strong>
          <a class="d-block h6" *ngIf="userService?.me?.IsManagement" routerLink="/tam-dashboard" (click)="hideSecondaryNav()">Dashboard</a>
          <a class="d-block h6" routerLink="/projects" (click)="hideSecondaryNav()">Projekte</a>
          <a class="d-block h6" routerLink="/pools" (click)="hideSecondaryNav()">Pools</a>
          <a class="d-block h6" routerLink="/chatbots" (click)="hideSecondaryNav()">Chatbots</a>
        </div>

        <div class="col-12 mb-4">
          <strong class="d-block mb-4 pretitle">Customer Retention</strong>
          <a class="d-block h6" routerLink="/dashboard-retention" (click)="hideSecondaryNav()">Dashboard</a>
          <a class="d-block h6" routerLink="/board-retention" (click)="hideSecondaryNav()">Accounts</a>          
        </div>

        <div class="col-12 mb-4">
          <strong class="d-block mb-4 pretitle">Lead Generation</strong>
          <a class="d-block h6" routerLink="/marketing-dashboard" (click)="hideSecondaryNav()">Dashboard</a>
          <a class="d-block h6" routerLink="/mailings" (click)="hideSecondaryNav()">Mailings</a>
          <a class="d-block h6" routerLink="/cadences" (click)="hideSecondaryNav()">Kadenzen</a>
        </div>

        <div class="col-12">
          <strong class="d-block mb-4 pretitle">Lead Conversion</strong>
          <a class="d-block h6" *ngIf="userService?.me?.IsManagement" routerLink="/sales-dashboard" (click)="hideSecondaryNav()">Dashboard</a>
          <a class="d-block h6" routerLink="/leads" (click)="hideSecondaryNav()">Leads</a>
          <a class="d-block h6" routerLink="/companies" (click)="hideSecondaryNav()">Unternehmen</a>          
          <a class="d-block h6" *ngIf="userService?.me?.IsManagement" routerLink="/packages" (click)="hideSecondaryNav()">Kontingente</a>
          <a class="d-block h6" *ngIf="userService?.me?.IsManagement" routerLink="/quotes" (click)="hideSecondaryNav()">Angebote</a>
          <a class="d-block h6" *ngIf="userService?.me?.IsManagement" routerLink="/invoices" (click)="hideSecondaryNav()">Rechnungen</a>
        </div>
        
      </div>
    </div>
  </div>

  <div class="app-nav-tasks box-shadow clickable" [class.show]="tasksVisible" (mouseleave)="hideTasksSoon()" (mouseenter)="showTasks()">
    <app-tasks (modalOpenChange)="handleTaskModalOpenChange($event)"></app-tasks>
  </div>
</div>
