<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div *ngIf="project" class="container">

    <div class="row">

      <div class="col-8" scAnchorTarget="Angebot">

        <div class="card shadow-lg mb-5">
          <div class="card-header">
            <h6 class="card-header-title">
              Abrechnung
            </h6>
          </div>
          <div class="card-body">

            <div class="alert alert-success" *ngIf="packagesAvailable">
              <i class="fa-sharp fa-regular fa-circle-info me-2"></i> Es sind freie Kontingente verfügbar.
            </div>

            <sc-form class="mb-2" (change)="saveSoon()">

              <sc-form-row label="Abrechnungsart">
                <select [(ngModel)]="project.BillingType">
                  <option value="SingleProject">Einzelprojekt</option>
                  <option value="PackageProject">Kontingent</option>
                  <option value="CustomBilling">Manuelle Rechnungsstellung</option>
                </select>
              </sc-form-row>

              <sc-form-row label="Rechnungsempfänger">
                <app-inline-select-organization [(organizationId)]="project.BillingCompanyId" (organizationIdChange)="billingCompanyChange();"></app-inline-select-organization>
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'PackageProject'" label="Rechnungsadresse">
                <app-inline-select-billing-address [organizationId]="project.BillingCompanyId" [(addressId)]="project.BillingAddressId" (addressIdChange)="saveSoon()"></app-inline-select-billing-address>
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'PackageProject'" label="Bestellnummer">
                <input [(ngModel)]="project.OrderNumber" placeholder="Keine">
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType != 'CustomBilling'" label="Rechnungstext">
                <input [(ngModel)]="project.BillingText" placeholder="">
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'PackageProject'" label="Grundpreis">
                <input [(ngModel)]="project.Price" type="number" placeholder="€">
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'PackageProject'" label="Rabatt">
                <input [(ngModel)]="project.Discount" type="number" placeholder="€">
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'PackageProject'" label="Steuersatz">
                <input [(ngModel)]="project.TaxRate" type="number" placeholder="%">
              </sc-form-row>

              <sc-form-row [class.d-none]="project.BillingType == 'SingleProject'" label="Kontingent">
                <app-inline-select-project-package [organizationId]="project.BillingCompanyId" [(packageId)]="project.PackageId" (packageIdChange)="saveSoon()" [company]="project.BillingCompany"></app-inline-select-project-package>
              </sc-form-row>
            </sc-form>
          </div>
        </div>
        
        <app-project-quote-recipients [project]="project"></app-project-quote-recipients>

        <div class="card shadow-lg">
          <div class="card-header">
            <h6 class="card-header-title">
              Angebote
            </h6>
          </div>
          <div class="card-body card-body-list">
            <app-list-quotes [quotes]="quotes"></app-list-quotes>
          </div>
        </div>

      </div>
    </div>
  </div>
