<app-topbar-company [company]="company"></app-topbar-company>

<div class="main-content" *ngIf="company">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card" scAnchorTarget="Rechnungsinfos">

                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-header-title">
                                    Rechnungsinformationen
                                </h4>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-secondary btn-circle btn-sm lift" (click)="addModal.show()"><i class="fa-regular fa-plus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-4">
                                Lieferantennr.
                            </div>
                            <div class="col-8">
                                <input class="form-control form-control-sm" [(ngModel)]="company.SupplierId" (ngModelChange)="saveSoon()" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4">
                                Standard-Rabatt
                            </div>
                            <div class="col-8">
                                <input class="form-control form-control-sm" [(ngModel)]="company.DefaultDiscount" (ngModelChange)="saveSoon()" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4">
                                Anmerkungen
                            </div>
                            <div class="col-8">
                                <autosize-textarea [(text)]="company.Conditions" (textChange)="saveSoon()"></autosize-textarea>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-4">
                                Rechnungsadressen
                            </div>
                            <div class="col-8 mt-2">
                                <div class="row" *ngFor="let billingAddress of billingAddresses; let i = index;">
                                    <div [class.fw-bold]="company.DefaultBillingAddressId == billingAddress.Id" class="col-10 small" (click)="selectedBillingAddress = billingAddress; editModal.show();">
                                        <p class="mb-0" style="white-space: pre;">{{ billingAddress.Address }}</p>
                                        <p class="mb-0" *ngIf="billingAddress.EMail">{{ billingAddress.EMail }}</p>
                                    </div>
                                    <div class="col-2">
                                        <button class="btn btn-secondary btn-circle btn-sm lift" (click)="selectedBillingAddress = billingAddress; editModal.show();"><i class="fa-regular fa-pen-to-square"></i></button>
                                    </div>
                                    <div class="col-12" *ngIf="i < billingAddresses.length - 1">
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                      <div class="chart">
                        <canvas id="turnoverChart" class="chart-canvas"></canvas>
                      </div>
                    </div>
                  </div>

                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-header-title">
                                    Rechnungen
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <ul class="list-group list-group-lg list-group-flush list">
                            <li class="list-group-item py-1" *ngFor="let invoice of invoices">
                                <div class="row small">
                                    <div class="col-1">
                                        <a routerLink="/invoices/{{invoice.Id}}" class="oneline"><strong>{{invoice.InvoiceNumber}}</strong></a>
                                    </div>
                                    <div class="col-2">
                                        <a routerLink="/invoices/{{invoice.Id}}" class="oneline">{{invoice.Date | date : 'dd.MM.yyyy'}}</a>
                                    </div>
                                    <div class="col-7">
                                        <div>
                                            <a class="text-primary" routerLink="/projects/{{invoice.Project.Id}}" *ngIf="invoice.Project">Einzelprojekt - {{ invoice.Project.Name }} ({{ invoice.Project.Id }})</a>
                                            <a class="text-primary" routerLink="/packages/{{invoice.Package.Id}}" *ngIf="invoice.Package">Kontingent - {{ invoice.Package.Title }} ({{ invoice.Package.Id }})</a>
                                            <span *ngIf="!invoice.Package && !invoice.Project">Keine Zuordnung</span>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <a class="oneline">{{ (invoice.Amount - invoice.Discount) | number : '1.2-2'}} €</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<sc-modal #addModal size="lg">
    <div class="modal-header">
        <h4 class="modal-title">Neue Rechnungsadresse</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="addModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="addModal.isOpen">
        <div class="row mb-2">
            <div class="col-3">
                E-Mail
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="newBillingAddress.EMail" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <label>Adresse</label>
            </div>
            <div class="col-9">
                <autosize-textarea [(text)]="newBillingAddress.Address"></autosize-textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark" [disabled]="!newBillingAddress.Address" (click)="addBillingAddress();addModal.dismiss();">Hinzufügen</button>
    </div>
</sc-modal>

<sc-modal #editModal size="lg">
    <div class="modal-header">
        <h4 class="modal-title">Rechnungsadresse bearbeiten</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="addModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="editModal.isOpen">
        <div class="row mb-2">
            <div class="col-3">
                E-Mail
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="selectedBillingAddress.EMail" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <label>Adresse</label>
            </div>
            <div class="col-9">
                <autosize-textarea [(text)]="selectedBillingAddress.Address" rows="1"></autosize-textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="company?.DefaultBillingAddressId == selectedBillingAddress?.Id" class="btn btn-secondary" disabled><i class="fa-regular fa-check"></i> Standard</button>
        <button *ngIf="company?.DefaultBillingAddressId != selectedBillingAddress?.Id" class="btn btn-secondary" (click)="company.DefaultBillingAddressId = selectedBillingAddress.Id; save();">Als Standard verwenden</button>
        <button class="btn btn-danger" (click)="deleteSelectedBillingAddress();">Löschen</button>
        <button class="btn btn-dark" (click)="saveSelectedBillingAddress();editModal.dismiss();">Speichern</button>
    </div>
</sc-modal>