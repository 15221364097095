import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-pool-person-assignment',
  templateUrl: './modal-pool-person-assignment.component.html',
  styleUrls: ['./modal-pool-person-assignment.component.scss']
})
export class ModalPoolPersonAssignmentComponent {

}
