<sc-modal #modal>
	<sc-modal-header>
		Profile importieren
	</sc-modal-header>
	<sc-modal-body>
		<div class="row">
		  <div class="col-12">
			<sc-form>
			  <sc-form-row label="Batch-Id">
				<input class="form-control" [(ngModel)]="batchId" />
			  </sc-form-row>
			  <sc-form-row label="Skip">
				<input class="form-control" [(ngModel)]="skip" />
			  </sc-form-row>
			  <sc-form-row label="Take">
				<input class="form-control" [(ngModel)]="take" />
			  </sc-form-row>
			</sc-form>
		  </div>
		</div>
	  </sc-modal-body>
  
	  <sc-modal-footer>
		<button class="btn btn-secondary btn-round" (click)="modal.dismiss()">Abbrechen</button>
		<button class="btn btn-dark btn-round" [class.loading]="isImporting" (click)="import();">Importieren</button>
	  </sc-modal-footer>
</sc-modal>