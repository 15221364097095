import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-cadence',
  templateUrl: './cadence.component.html',
  styleUrls: ['./cadence.component.scss']
})
export class CadenceComponent implements OnInit {

  id : any;

  cadence : any;

  constructor(private route : ActivatedRoute, private api : ScApiService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params : any) => {
        if (params.id)
        {
          this.id = params.id;
          this.api.get('cadences/' + this.id).subscribe(d => {
            this.cadence = d;
          });
        }
    })
  }

}
