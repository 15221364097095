import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  packages: any = [];

  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit(): void {
    this.api.get('packages').subscribe(d => {
      this.packages = d;
    });
  }

  navigate(p)
  {
    this.router.navigateByUrl('packages/' + p.Id);
  }
}