<div class="card shadow-lg">
  <div class="card-header">
    <h6 class="card-header-title">
      Angebot
    </h6>
  </div>
  <div class="card-body">
    <sc-form (change)="onChange()">

      <sc-form-row label="Abrechnungsart">
        <select [(ngModel)]="package.BillingType">
          <option value="PerProject">Pro Projekt</option>
          <option value="Flatrate">Pauschal</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Rechnungsempfänger">
        <app-inline-select-organization [(organizationId)]="package.BillingCompanyId" (organizationIdChange)="package.BillingAddressId = 0; onChange();"></app-inline-select-organization>
      </sc-form-row>

      <sc-form-row label="Rechnungsadresse" [disabled]="!package.BillingCompanyId">
        <app-inline-select-billing-address [organizationId]="package.BillingCompanyId" [(addressId)]="package.BillingAddressId" (addressIdChange)="onChange()"></app-inline-select-billing-address>
      </sc-form-row>

      <sc-form-row label="Angebotsempfänger" [disabled]="!package.BillingCompanyId">
        <app-inline-select-person [organizationId]="package.BillingCompanyId" [allowCreate]="false" [allowSearch]="false" [(personId)]="package.ContactId" (personIdChange)="onChange()"></app-inline-select-person>
      </sc-form-row>

      <sc-form-row label="Bestellnummer">
        <input [(ngModel)]="package.OrderNumber" placeholder="Keine">
      </sc-form-row>

      <sc-form-row label="Grundpreis">
        <input [(ngModel)]="package.Price" type="number" placeholder="€">
      </sc-form-row>

      <sc-form-row label="Rabatt">
        <input [(ngModel)]="package.Discount" type="number" placeholder="€">
      </sc-form-row>

      <sc-form-row label="Steuersatz">
        <input [(ngModel)]="package.TaxRate" type="number" placeholder="%">
      </sc-form-row>

    </sc-form>

  </div>
</div>
