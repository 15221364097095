<app-topbar-candidate [candidate]="candidate"></app-topbar-candidate>

<div class="main-content">

  <div class="container" *ngIf="candidate">

    <div class="row">

      <div class="col-8 candidate-content">
		<app-candidate-projects [candidate]="candidate"></app-candidate-projects>
		<app-candidate-chats [candidate]="candidate"></app-candidate-chats>
      </div>

      <div class="col-4 candidate-content">
      </div>

    </div>
  </div>
</div>