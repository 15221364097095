import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {


  mappings : any = {};

  ambiguousOnly = false;

  suggestionsOnly = false;

  unapprovedOnly = false;

  query = '';

  constructor(private api : ScApiService)
  {}

  ngOnInit()
  {
    this.load();
  }

  load()
  {
    this.api.get('mappings?ambiguousOnly=' + this.ambiguousOnly + '&suggestionsOnly=' + this.suggestionsOnly + '&unapprovedOnly=' + this.unapprovedOnly + '&query=' + this.query).subscribe(d => {
      this.mappings = d;
    });
  }

  loadSoon()
  {
    if (this["loadTimeout"]) clearTimeout(this["loadTimeout"]);
    this["loadTimeout"] = setTimeout(() => {
      this.load();
    }, 500);
  }
}
