import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sc-layout-empty',
  templateUrl: './sc-layout-empty.component.html',
  styleUrls: ['./sc-layout-empty.component.scss']
})
export class ScLayoutEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
