import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-topbar-company',
  templateUrl: './topbar-company.component.html',
  styleUrls: ['./topbar-company.component.scss']
})
export class TopbarCompanyComponent implements OnInit {

  deleteConfirmation : string;

  @Input()
  company : any;
  
  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit(): void {
  }  

  delete()
  {
    this.api.delete("companies/" + this.company.Id).subscribe(d => {
      this.router.navigateByUrl('/companies');
    });
  }
}
