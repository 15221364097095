<ng-template [appTopbarTemplate]>
  <div class="title h4">Kadenzen</div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="card">
          <div class="card-body card-body-list">
            <div class="list-group">
              <a class="list-group-item list-group-item-action" routerLink="/cadences/{{cadence.Id}}" *ngFor="let cadence of cadences">
                <div class="row align-items-center">
                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        {{cadence.Name}}
                      </h6>
                    </span>
                    <p class="card-text text-muted small mb-0 oneline">
                    {{ cadence.Description }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
