import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-card-invoices',
  templateUrl: './company-card-invoices.component.html',
  styleUrls: ['./company-card-invoices.component.scss']
})
export class CompanyCardInvoicesComponent implements OnChanges {

  @Input()
  company : any;

  invoices : any = [];

  constructor(private api : ScApiService) { }

  ngOnChanges()
  {
    this.api.get("invoices?companyId=" + this.company.Id).subscribe((d: any) => {
      this.invoices = d;         
    });
  }

}
