<div class="sidebar-container">

  <div class="topbar">
    <app-topbar-sidebar></app-topbar-sidebar>
  </div>

  <div class="content">
    <main>
      <router-outlet #outlet></router-outlet>
    </main>
  </div>

  <div class="footer">
    <app-sidebar-footer></app-sidebar-footer>
  </div>
  
</div>
