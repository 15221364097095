import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import Chart from 'chart.js/auto';
import { ChartColorsService } from 'src/services/chart-colors.service';
import industries from 'refs/sc-ui/resources/industries';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss']
})
export class SalesDashboardComponent implements OnInit {

  turnoverData: any;

  acquisitionData: any;

  industriesData: any;

  companyData: any;

  industryNames = {};

  clvData: any;

  months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]

  constructor(private api: ScApiService, private colors : ChartColorsService) { }

  ngOnInit() {

    industries.forEach(i => this.industryNames[i.id] = i.name);
    this.industryNames[0] = "Keine Angabe";

    this.api.get(`dashboard/acquisition-lead-conversion`).subscribe((d: any) => {

      new Chart('conversionsChart', {
        type: 'line',
        data: {
          labels: d.Scheduled.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.Scheduled.map(i => i.Change),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.Scheduled.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(0,98,215,0.2)',
              borderColor: 'rgba(0,98,215,0.2)',
            }
          ]
        }
      });
      
      new Chart('warmedChart', {
        type: 'line',
        data: {
          labels: d.Warmed.map(i => i.Month),

          datasets: [
            {
              label: 'Wert',
              type: 'bar',
              data: d.Warmed.map(i => i.Change),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            },
            {
              label: 'Wert Ø3M',
              type: 'line',
              data: d.Warmed.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(0,98,215,0.2)',
              borderColor: 'rgba(0,98,215,0.2)',
            }
          ]
        }
      });

    });

    this.api.get(`dashboard/acquisition-account-conversion`).subscribe((d: any) => {
      this.acquisitionData = d.Acquisition;
      this.industriesData = d.Industries;
      this.companyData = d.Companies;
      this.turnoverData = d.Turnover;
      this.clvData = d;
      var labels = d.Timelines.map(i => i.PeriodMonth);         
      

      new Chart('statusChart', {
        type: 'line',
        data: {
          labels: d.Closed.map(i => i.Month),

          datasets: [
            {
              label: 'Gesamt',
              type: 'bar',
              data: d.Closed.map(i => i.Change),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            },
            {
              label: 'Gesamt Ø3M',
              data: d.Closed.map(i => i.ChangeAverage3M),
              backgroundColor: 'rgba(0,98,215,0.2)',
              borderColor: 'rgba(0,98,215,0.2)',
            }
          ]
        }
      });


      new Chart('basketSizeChart', {
        type: 'bar',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Umsatz in ersten 6M',
              data: d.Timelines.map(i => i.ClosingBasketSize),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            }
          ]
        }
      });

      new Chart('turnoverChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Umsatz Ø3M',
              data: d.Timelines.map(i => i.Turnover3M),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            },
            {
              type: 'bar',
              label: 'Umsatz',
              data: d.Timelines.map(i => i.Turnover),
              backgroundColor: 'rgba(0,98,215,0.2)',
              borderColor: 'rgba(0,98,215,0.2)',
            },
            {
              label: 'Erstkundenumsatz Ø3M',
              data: d.Timelines.map(i => i.TurnoverFirstTime3M),
              backgroundColor: 'rgb(243,150,129)',
              borderColor: 'rgb(243,150,129)'
            },
            {
              type: 'bar',
              label: 'Erstkundenumsatz',
              data: d.Timelines.map(i => i.TurnoverFirstTime),
              backgroundColor: 'rgba(243,150,129,.4)',
              borderColor: 'rgba(243,150,129,.4)'
            },
            {
              label: 'Bestandskundenumsatz Ø3M',
              data: d.Timelines.map(i => i.TurnoverRetention3M),
              backgroundColor: 'rgb(172,173,239)',
              borderColor: 'rgb(172,173,239)'
            },
            {
              type: 'bar',
              label: 'Bestandskundenumsatz',
              data: d.Timelines.map(i => i.TurnoverRetention),
              backgroundColor: 'rgba(172,173,239,.3)',
              borderColor: 'rgba(172,173,239,.3)'
            },
            {
              type: 'bar',
              label: 'Vorjahres-Umsatz',
              data: d.Timelines.map(i => i.TurnoverYearBefore),
              backgroundColor: this.colors.getColor(0),
              borderColor: this.colors.getColor(0)
            },
            {
              type: 'line',
              label: 'Vorjahres-Umsatz Ø3M',
              data: d.Timelines.map(i => i.TurnoverYearBefore3M),
              backgroundColor: this.colors.getColor(0),
              borderColor: this.colors.getColor(0)
            }
          ]
        }
      });

      new Chart('salesActivitiesChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Calls Ø3M',
              data: d.Timelines.map(i => i.SalesCalls3M),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            },
            {
              type: 'bar',
              label: 'Calls',
              data: d.Timelines.map(i => i.SalesCalls),
              backgroundColor: 'rgba(0,98,215,0.2)',
              borderColor: 'rgba(0,98,215,0.2)',
            },            
            {
              label: 'Angebote Ø3M',
              data: d.Timelines.map(i => i.QuotesFromSalesCalls3M),
              backgroundColor: 'rgb(243,150,129)',
              borderColor: 'rgb(243,150,129)'
            },
            {
              type: 'bar',
              label: 'Angebote',
              data: d.Timelines.map(i => i.QuotesFromSalesCalls),
              backgroundColor: 'rgba(243,150,129,.4)',
              borderColor: 'rgba(243,150,129,.4)'
            },
            {
              label: 'Aufträge Ø3M',
              data: d.Timelines.map(i => i.OrdersFromSalesCalls3M),
              backgroundColor: 'rgb(172,173,239)',
              borderColor: 'rgb(172,173,239)'
            },
            {
              type: 'bar',
              label: 'Aufträge',
              data: d.Timelines.map(i => i.OrdersFromSalesCalls),
              backgroundColor: 'rgba(172,173,239,.3)',
              borderColor: 'rgba(172,173,239,.3)'
            }
          ]
        }
      });

      new Chart('salesConversionsChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Angebotsquote Ø3M',
              data: d.Timelines.map(i => i.QuotesFromSalesCallsRatio3M),
              backgroundColor: 'rgb(243,150,129)',
              borderColor: 'rgb(243,150,129)'
            },
            {
              type: 'bar',
              label: 'Angebotsquote',
              data: d.Timelines.map(i => i.QuotesFromSalesCallsRatio),
              backgroundColor: 'rgba(243,150,129,.4)',
              borderColor: 'rgba(243,150,129,.4)'
            },
            {
              label: 'Beauftragungsquote Ø3M',
              data: d.Timelines.map(i => i.OrdersFromSalesCallsRatio3M),
              backgroundColor: 'rgb(172,173,239)',
              borderColor: 'rgb(172,173,239)'
            },
            {
              type: 'bar',
              label: 'Beauftragungsquote',
              data: d.Timelines.map(i => i.OrdersFromSalesCallsRatio),
              backgroundColor: 'rgba(172,173,239,.3)',
              borderColor: 'rgba(172,173,239,.3)'
            }
          ]
        }
      });

      var channelResults = d.Timelines[0].ChannelResults;
      var datasets = [];

      for (var index = 0; index < channelResults.length; index++) {
        var name = channelResults[index].Channel;
        var dataset = {
          label: name,
          data: d.Timelines.map(i => i.ChannelResults[index].TurnoverFirstTime),
          backgroundColor: this.colors.getColor(index),
          borderColor: this.colors.getColor(index)
        };

        datasets.push(dataset);
      }

      new Chart('channelChart', {
        type: 'bar',
        data: {
          labels: labels.map(i => this.months[i - 1]),
          datasets: datasets
        },
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        }
      });

    });
  }

  



}
