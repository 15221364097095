<ng-template [appTopbarTemplate]>
  <div class="title h4" *ngIf="mailing">
    Mailing: {{ mailing.Title }}
  </div>

  <div class="actions">
    <button class="btn btn-round btn-dark" (click)="sendTest()" [class.loading]="isSendingTest">
      Test senden
    </button>
    <button class="btn btn-round btn-dark ms-2" (click)="sendModal.show()">
      Starten
    </button>
  </div>
</ng-template>

<div class="main-content">
  <div class="container" *ngIf="mailing">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-body">
            <sc-form>
            <sc-form-row label="Bezeichnung (UTM-Source)">
              <input [(ngModel)]="mailing.Title" (ngModelChange)="saveSoon()" />
            </sc-form-row>
            </sc-form>
          </div>
        </div>

      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ngx-monaco-editor style="height: 60vh;" id="editor" [options]="editorOptions" [(ngModel)]="mailing.Content" (ngModelChange)="saveSoon()"></ngx-monaco-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal #sendModal>
  <sc-modal-header>
    Mailing versenden
  </sc-modal-header>
  <sc-modal-body>
    <sc-form>
      <sc-form-row label="Absender (E-Mail)">
        <input [(ngModel)]="senderEMail" />
      </sc-form-row>
      <sc-form-row label="Empfänger (Kadenz-ID)">
        <input [(ngModel)]="cadenceId" />
      </sc-form-row>
    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-round btn-dark" (click)="send()" [class.loading]="isSending">Senden</button>
  </sc-modal-footer>
</sc-modal>
