import { Component, Input, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-project-person-assignment',
  templateUrl: './modal-project-person-assignment.component.html',
  styleUrls: ['./modal-project-person-assignment.component.scss']
})
export class ModalProjectPersonAssignmentComponent {

  assignment: any;

  @ViewChild('modal')
  modal: ModalWizardComponent;

  @ViewChild('start')
  start: ModalWizardPageComponent;

  stati: any = [
    {
      DisplayName: 'Keine Angabe',
      Name: 'None',
      Description: 'Unbestimmter oder nicht zugewiesener Status'
    },
    {
      DisplayName: 'Angesprochen',
      Name: 'Contacted',
      Description: 'Kandidat:in wurde für das Projekt angesprochen'
    },
    {
      DisplayName: 'Interessiert',
      Name: 'Interested',
      Description: 'Kandidat:in hat Interesse an der Stelle'
    },
    {
      DisplayName: 'Vorgestellt',
      Name: 'Listed',
      Description: 'Kandidat:in wurde vorgestellt'
    },
    {
      DisplayName: 'Vorgestellt (unverbindlich)',
      Name: 'ListedNonCommittal',
      Description: 'Kandidat:in wurde ohne Wirksamkeit für die Garantie vorgestellt'
    },
    {
      DisplayName: 'Eingeladen',
      Name: 'Accepted',
      Description: 'Kandidat:in wurde von Kunde angenommen'
    },
    {
      DisplayName: 'Eingestellt',
      Name: 'Hired',
      Description: 'Kandidat:in wurde von Kunde eingestellt'
    },
    {
      DisplayName: 'Abgelehnt',
      Name: 'Rejected',
      Description: 'Kandidat:in ist vom Kunden abgelehnt worden'
    },
    {
      DisplayName: 'Zurückgezogen',
      Name: 'Revoked',
      Description: 'Kandidat:in hat Interesse zurückgezogen'
    },
    {
      DisplayName: 'Nicht interessiert',
      Name: 'NotInterested',
      Description: 'Kandidat:in ist nicht interessiert'
    }
  ]

  intendedStati: any = [];

  unintendedStati: any = [];

  selectedStatus: any;

  constructor(private api: ScApiService) { }

  getStatus(name) {
    return this.stati.find(s => s.Name == name);
  }

  public show(assignment) {
    this.modal.navigateTo(this.start, 'none');

    this.assignment = assignment;

    this.stati.forEach(s => {
      s.ConfirmationMessage = "Ein Wechsel in diesen Status ist nicht vorgesehen. Es werden keine Benachrichtigungen versendet. Es kann im weiteren Verlauf zu inkonsistenten Benachrichtigungen kommen!";
      s.IsIntended = false;
    });

    if (this.assignment.Status == 'Contacted') {
      var interested = this.getStatus('Interested');
      interested.IsIntended = true;
      interested.ConfirmationMessage = "Die Kandidat:in wird als interessiert markiert.";

      var notInterested = this.getStatus('NotInterested');
      notInterested.IsIntended = true;
      notInterested.ConfirmationMessage = "Die Kandidat:in wird als nicht interessiert markiert.";
    }

    if (this.assignment.Status == 'Interested') {
      var rejected = this.getStatus('Rejected');
      rejected.IsIntended = true;
      rejected.ConfirmationMessage = "Die Kandidat:in wird benachrichtigt, dass ein Fit leider wider Erwarten nicht vorliegt";

      var listed = this.getStatus('Listed');
      listed.IsIntended = true;
      listed.ConfirmationMessage = "Die Kandidat:in wird ins Portal gepusht. Die Projektteilnehmer:innen werden hierüber <strong>nicht</strong> benachrichtigt.";

      var listed = this.getStatus('ListedNonCommittal');
      listed.IsIntended = true;
      listed.ConfirmationMessage = "Die Kandidat:in wird ins Portal gepusht, ohne dass er auf die Garantie angerechnet wird. Die Projektteilnehmer:innen werden hierüber <strong>nicht</strong> benachrichtigt.";
    }

    if (this.assignment.Status == 'Listed' || this.assignment.Status == 'ListedNonCommittal') {
      var rejected = this.getStatus('Rejected');
      rejected.IsIntended = true;
      rejected.ConfirmationMessage = "Die Kandidat:in wird als abgelehnt markiert. Die Absage muss durch den Kunden erfolgen.";

      var revoked = this.getStatus('Revoked');
      revoked.IsIntended = true;
      revoked.ConfirmationMessage = "Die Kandidat:in wird als nicht mehr interessiert markiert.";

      var accepted = this.getStatus('Accepted');
      accepted.IsIntended = true;
      accepted.ConfirmationMessage = "Die Kandidat:in wird als eingeladen markiert und erhält hierüber eine Benachrichtigung per E-Mail. Die Projektteilnehmer:inne werden ebenfalls benachrichtigt";
    }

    if (this.assignment.Status == 'Accepted') {

      var rejected = this.getStatus('Rejected');
      rejected.IsIntended = true;
      rejected.ConfirmationMessage = "Die Kandidat:in wird als abgelehnt markiert. Die Absage muss durch den Kunden erfolgen.";

      var revoked = this.getStatus('Revoked');
      revoked.IsIntended = true;
      revoked.ConfirmationMessage = "Die Kandidat:in wird als nicht mehr interessiert markiert.";

      var hired = this.getStatus('Hired');
      hired.IsIntended = true;
      hired.ConfirmationMessage = "Die Kandidat:in wird als eingestellt markiert und die Projektteilnehmer:inne werden benachrichtigt";
    }

    var currentStatus = this.getStatus(this.assignment.Status);
    this.intendedStati = this.stati.filter(i => i.IsIntended && i != currentStatus);
    this.unintendedStati = this.stati.filter(i => !i.IsIntended && i != currentStatus);

    this.modal.show();
  }

  setStatus(status) {

    this.assignment.Status = status;

    if (status == 'Rejected') {
      this.action('reject');
    }

    else if (status == 'Accepted') {
      this.action('accept');
    }

    else if (status == 'Listed') {
      this.action('add-to-shortlist');
    }

    else if (status == 'ListedNonCommittal') {
      this.action('add-to-shortlist-non-committal');
    }

    else if (status == 'Hired') {
      this.action('hired');
    }

    else {
      this.api.put('candidates/' + this.assignment.CandidateId + "/assignments/" + this.assignment.Id, this.assignment).subscribe();
    }
  }

  private action(actionName) {
    var uri = `projects/${this.assignment.ProjectId}/candidates/${this.assignment.CandidateId}/actions/${actionName}`;
    this.api.post(uri, null).subscribe();
  }
}
