<div class="card" scAnchorTarget="Rechnung">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <div class="card-header-title h6">
          Rechnung
          <span *ngIf="invoice" class="badge badge-{{ statusColors[invoice?.Status] }} badge-pill ms-4">{{ statusLabels[invoice?.Status] }}</span>
          <span *ngIf="invoice?.IsFirstReminderSent && !invoice?.IsSecondReminderSent" class="badge badge-pill badge-warning-soft ms-2">Erste Mahnung</span>
          <span *ngIf="invoice?.IsSecondReminderSent" class="badge badge-pill badge-danger-soft ms-2">Zweite Mahnung</span>
        </div>
      </div>
      <div class="col-auto">
        <button *ngIf="invoice?.Status == 'Billed'" class="btn btn-sm btn-dark" (click)="cancellationModal.show()">Stornieren</button>
        <button *ngIf="invoice?.Status == 'Billed' && !invoice?.IsFirstReminderSent" class="btn btn-sm btn-dark ms-2" (click)="firstReminderModal.show()">Erste Mahnung</button>
        <button *ngIf="invoice?.Status == 'Billed' && invoice?.IsFirstReminderSent && !invoice.IsSecondReminderSent" class="btn btn-sm btn-dark ms-2" (click)="secondReminderModal.show()">Zweite Mahnung</button>
        <button *ngIf="invoice?.Status == 'Draft'" class="btn btn-sm btn-secondary" (click)="sendModal.show(sendModalProceedPage)">Versenden</button>
        <button *ngIf="invoice?.Status == 'Draft'" class="btn btn-sm btn-secondary ms-2" (click)="generateInvoiceDocument()">
          <span class="spinner-border spinner-border-sm" *ngIf="isUpdating"></span> PDF generieren
        </button>
      </div>
    </div>
  </div>

  <div class="card-body" *ngIf="invoice">
    <div class="row">
      <div class="col-8">

        <sc-form [disabled]="invoice?.Status == 'Billed'" (change)="saveSoon()">

          <sc-form-row label="Nummer">
            <input [ngModel]="invoice?.InvoiceNumber" readonly>
          </sc-form-row>

          <sc-form-row label="Rechnungsempfänger">
            <app-inline-select-organization [canSetNull]="true" [(organizationId)]="invoice.CompanyId" (organizationIdChange)="billingCompanyChanged()"></app-inline-select-organization>
          </sc-form-row>

          <sc-form-row label="Rechnungsadresse" [disabled]="!invoice.CompanyId">
            <div class="clickable form-control" style="height: auto" (click)="selectAddress()">
              <p style="white-space: pre;" class="mb-0">{{ invoice.Address }}</p>
              <p class="mb-0 mt-2" *ngIf="invoice.AddressEMail">
                <strong>{{ invoice.AddressEMail }}</strong>
              </p>
            </div>
          </sc-form-row>

          <!-- <sc-form-row label="E-Mail" [disabled]="!invoice.CompanyId">
            <input [(ngModel)]="invoice.AddressEMail" placeholder="Keine">            
          </sc-form-row> -->

          <sc-form-row label="Bestellnr.">
            <input [(ngModel)]="invoice.OrderNumber" placeholder="Keine">
          </sc-form-row>

          <sc-form-row label="Rechnungstext">
            <autosize-textarea [(text)]="invoice.InvoiceText"></autosize-textarea>
          </sc-form-row>

          <sc-form-row label="Grundpreis">
            <div class="input-group">
              <input [(ngModel)]="invoice.Amount" type="number" class="form-control" placeholder="Basispreis in €">
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </sc-form-row>

          <sc-form-row label="Rabatt">
            <div class="input-group">
              <input [(ngModel)]="invoice.Discount" type="number" class="form-control" placeholder="Rabatt in €">
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </sc-form-row>

          <sc-form-row label="Umsatzsteuersatz">

            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <input [(ngModel)]="invoice.TaxRate" type="number" class="form-control" placeholder="Steuersatz">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="invoice.TaxRate != 19.0">
                <div class="alert alert-warning mt-2">
                  <i class="fa-regular fa-triangle-exclamation me-2"></i> Der angegebene Steuersatz beträgt nicht 19%
                </div>
              </div>
            </div>

          </sc-form-row>

        </sc-form>
      </div>
      <div class="col-3 offset-1">
        <pdf-viewer class="clickable" (click)="previewModal.show()" *ngIf="invoice.Uri && !isUpdating" [src]="invoice.Uri" [render-text]="true" [fit-to-page]="true" [zoom]=".9" style="display: block; width: 100%;">
        </pdf-viewer>
      </div>
    </div>

  </div>
</div>

<app-modal-select-billing-address #addressModal></app-modal-select-billing-address>

<sc-modal #previewModal size="lg">
  <div class="modal-body p-0">
    <pdf-viewer *ngIf="invoice?.Uri && !isUpdating" [src]="invoice.Uri" [render-text]="true" [fit-to-page]="true" [zoom]=".9" style="display: block; width: 100%; min-height: 1040px;">
    </pdf-viewer>
  </div>
  <div class="modal-footer">
    <a class="btn btn-secondary" [href]="invoice?.Uri" target="_blank"><i class="fa-regular fa-cloud-arrow-down"></i> Herunterladen</a>
    <button class="btn btn-secondary" (click)="previewModal.dismiss()">Schließen</button>
  </div>
</sc-modal>

<sc-modal-wizard #sendModal size="md">
  <div class="modal-wizard-body">
    <sc-modal-wizard-page #sendModalProceedPage>
      <sc-modal-header>
        Forfahren?
      </sc-modal-header>
      <div class="modal-body" style="min-height: 20vhpx;" *ngIf="invoice">
        <div class="row align-items-center">
          <div class="col" *ngIf="invoice.AddressEMail">
            Die Rechnung wird an <i>{{ invoice.AddressEMail }}</i>, <i>rechnungen@skillconomy.com</i> und
            an das Buchhaltungssystem gesendet.
          </div>

          <div class="col" *ngIf="!invoice.AddressEMail">
            Die Rechnung wird an <i>rechnungen@skillconomy.com</i> und an das
            Buchhaltungssystem gesendet.
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="sendModal.dismiss();">Abbrechen</button>
        <button [disabled]="isSending" class="btn btn-dark" (click)="sendInvoice()">
          <span class="spinner-border spinner-border-sm" *ngIf="isSending"></span>
          Fortfahren
        </button>
      </div>
    </sc-modal-wizard-page>

    <sc-modal-wizard-page #confirmation>
      <sc-modal-header>
        Erledigt
      </sc-modal-header>
      <div class="modal-body">
        <div class="alert alert-success mb-0">
          <div class="row align-items-center">
            <div class="col">
              Die Rechnung wurde verschickt!
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="sendModal.dismiss();">Schließen</button>
      </div>
    </sc-modal-wizard-page>

    <sc-modal-wizard-page #sendModalErrorPage>
      <sc-modal-header>
          Fehler
      </sc-modal-header>
      <div class="modal-body">
          <div class="alert alert-error mb-0">
              <div class="row align-items-center">
                  <div class="col">
                      {{ errorMessage }}                         
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
          <button class="btn btn-secondary" (click)="sendModal.dismiss();">Ok</button>
      </div>
  </sc-modal-wizard-page>
  </div>
</sc-modal-wizard>

<sc-modal #cancellationModal size="md">
  <sc-modal-header>
    Rechnung stornieren
  </sc-modal-header>
  <div class="modal-body">
    Möchtest du die Rechnung wirklich stornieren? Wenn du fortfährst, wird eine Stornorechnung generiert. Bitte versende diese dann manuell! Erst dann wird sie auch verbucht.
  </div>
  <div class="modal-footer">
    <button [disabled]="isSending" class="btn btn-dark" (click)="cancelInvoice(); cancellationModal.dismiss();">
      Ok
    </button>
  </div>
</sc-modal>

<sc-modal #firstReminderModal size="md">
  <sc-modal-header>
    Erste Mahnung versenden
  </sc-modal-header>
  <div class="modal-body">
    Möchtest du die Rechnung wirklich anmahnen?
  </div>
  <div class="modal-footer">
    <button [disabled]="isSending" class="btn btn-dark" (click)="sendFirstReminder(); firstReminderModal.dismiss();">
      Ok
    </button>
  </div>
</sc-modal>

<sc-modal #secondReminderModal size="md">
  <sc-modal-header>
    Zweite Mahnung versenden
  </sc-modal-header>
  <div class="modal-body">
    Möchtest du die Rechnung wirklich letztmalig anmahnen?
  </div>
  <div class="modal-footer">
    <button [disabled]="isSending" class="btn btn-dark" (click)="sendSecondReminder(); secondReminderModal.dismiss();">
      Ok
    </button>
  </div>
</sc-modal>
