<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card" *ngIf="blacklist">
          <div class="card-header">
            <h6 class="card-header-title">
              Blacklist
            </h6>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-12">
                <autosize-textarea [(text)]="blacklist.Content" placeholder="Blacklist"></autosize-textarea>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <button class="btn btn-dark" (click)="save()">Speichern</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>