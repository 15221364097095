<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Pools
  </div>
</ng-template>

<div class="scroll-dummy" *ngIf="state == 'out'"></div>

<div class="content" [@fadeIn]="state">

  <div class="container">

    <div class="row mb-3">
      <div class="col-4">
        <input type="search" name="q" [(ngModel)]="q" (ngModelChange)="searchSoon()" class="form-control form-control-prepended search" placeholder="Suchen">
      </div>
    </div>

    <div class="row">

      <div class="col-12">

        <div class="list-group">

          <a *ngFor="let item of pools" routerLink="/pools/{{item.Id}}" class="list-group-item list-group-item-action">

            <div class="row align-items-center">

              <div class="col-auto">
                <div class="image-container">
                  <img *ngIf="item.Image" [src]="item.Image | imageSize : 80 : 50" />
                  <img *ngIf="!item.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{item.Id % 3 + 1}}.jpg" />
                </div>
              </div>

              <div class="col">
                <h6 class="mb-1">
                  {{item.Name}}
                </h6>
                <p class="card-text text-muted small mb-0 oneline">
                  {{item.Company?.Name }}
                </p>
              </div>

              <div class="col-auto">
                <h6 class="mb-1">
                  <i class="fa-regular fa-user fa-xs me-2"></i> {{ item.CandidateCount }}
                </h6>
              </div>

              <div class="col-auto">
                <h6 class="mb-1">
                  <i class="fa-regular fa-champagne-glasses fa-xs me-2"></i> {{ item.SuccessCount }}
                </h6>
              </div>

            </div>
          </a>
        </div>

        <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
          <div class="col-auto">
            <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<app-footer-blob></app-footer-blob>
