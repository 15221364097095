import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-base',
  templateUrl: './pool-base.component.html',
  styleUrls: ['./pool-base.component.scss']
})
export class PoolBaseComponent implements OnInit, OnChanges {

  @Input()
  pool : any;

  users : any = [];
  
  constructor(private api : ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    if (!this.pool) return;

    this.api.get(`pools/${this.pool.Id}/users`).subscribe(d => {
      this.users = d;
      this.users.forEach(u => u.allowRemove = !u.IsManager && !u.IsDecider && !u.IsSelf);
    })
  }

  save()
  {
    this.api.put('pools/' + this.pool.Id, this.pool).subscribe();
  }

}
