import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectPersonComponent, ModalSelectPersonOptions } from '../modal-select-person/modal-select-person.component';
import { ModalSelectBillingAddressComponent } from '../modal-select-billing-address/modal-select-billing-address.component';

@Component({
  selector: 'app-inline-select-billing-address',
  templateUrl: './inline-select-billing-address.component.html',
  styleUrls: ['./inline-select-billing-address.component.scss']
})
export class InlineSelectBillingAddressComponent {


  @Input()
  organizationId = 0;

  @Input()
  addressId : any;

  @Output()
  addressIdChange = new EventEmitter<any>();

  @ViewChild(ModalSelectBillingAddressComponent)
  modal : ModalSelectBillingAddressComponent;

  address : any;

  constructor(private api : ScApiService)
  {}

  ngOnChanges()
  {
    if(!this.addressId)
    {
      this.address = null;
      return;
    }
    if (this.addressId != this.address?.Id)
    {
      this.api.get('billing-addresses/' + this.addressId).subscribe(d => {
        this.address = d;
      })
    }
  }

  selectAddress()
  {
    this.modal.show(this.organizationId).subscribe((d : any) => {
      this.address = d;
      this.addressId = d.Id;
      this.addressIdChange.next(this.addressId);
    });
  }
}
