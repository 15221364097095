<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content" *ngIf="project">

  <div class="container mb-5">
    <div class="row">
      <div class="col">
        <app-nav-project-sourcing [project]="project" active="listing"></app-nav-project-sourcing>
      </div>
      <div class="col-auto">
        <button class="btn btn-sm btn-secondary oneline me-2" (click)="newCandidatesModal.show()"><i class="fa-regular fa-bell me-2"></i> Neue Bewerbungen</button>    
        <button class="btn btn-sm btn-secondary oneline" (click)="revokedCandidatesModal.show()"><i class="fa-regular fa-bell me-2"></i> Bewerbungen zurückgezogen</button>
      </div>
    </div>

  </div>

  <div class="container">

    <div class="row">

      <div class="col-7">

        <div class="card mb-3" *ngFor="let group of groups">

          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  {{ group.name }}
                </h6>
              </div>
            </div>
          </div>

          <div class="card-body card-body-list">

            <div class="list-group">
              <a class="list-group-item list-group-item-action" routerLink="/candidates/{{person.Id}}" *ngFor="let person of group.items">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <sc-avatar class="d-inline-block" [user]="person" size="sm"></sc-avatar>
                  </div>

                  <div class="col">
                    <span>
                      <h6 class="mb-1 oneline d-inline">
                        {{person.FirstName}} {{ person.LastName}}
                      </h6>
                    </span>

                    <p class="card-text text-muted small mb-0 oneline">
                      {{ person.JobTitle }}
                    </p>
                  </div>

                  <div class="col-auto">
                    <app-status-badge-project-person-assignment [assignment]="person.Assignment"></app-status-badge-project-person-assignment>
                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>

      </div>

      <div class="col-5">

        <div class="card">
          <div class="card-header">
            <div class="card-header-title h6">Statistik</div>
          </div>
          <div class="card-body">
            <table class="w-100" style="width: 100%">
              <tr>
                <th style="width: auto;"></th>
                <th style="text-align: right;">gesamt</th>
                <th style="text-align: right;">%</th>
                <th style="text-align: right;">gesamt %</th>
              </tr>
              <tr>
                <td> Ansprachen </td>
                <td style="text-align: right;"> {{ stats?.Contacted | number : '1.0-0'}} </td>
                <td style="text-align: right;"> 100% </td>
                <td style="text-align: right;"> 100% </td>
              </tr>
              <tr>
                <td> Clicks </td>
                <td style="text-align: right;"> {{ stats?.Clicked | number : '1.0-0'}} </td>
                <td style="text-align: right;"> {{ stats?.Clicked / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
                <td style="text-align: right;"> {{ stats?.Clicked / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
              </tr>
              <tr>
                <td> Vorstellungen </td>
                <td style="text-align: right;"> {{ stats?.Shortlisted | number : '1.0-0'}} </td>
                <td style="text-align: right;"> {{ stats?.Shortlisted / stats?.Clicked * 100 | number : '1.0-0'}} % </td>
                <td style="text-align: right;"> {{ stats?.Shortlisted / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
              </tr>
              <tr>
                <td> Einladungen </td>
                <td style="text-align: right;"> {{ stats?.Accepted | number : '1.0-0'}} </td>
                <td style="text-align: right;"> {{ stats?.Accepted / stats?.Shortlisted * 100 | number : '1.0-0'}} % </td>
                <td style="text-align: right;"> {{ stats?.Accepted / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
              </tr>
              <tr>
                <td> Ablehnungen </td>
                <td style="text-align: right;"> {{ stats?.Rejected | number : '1.0-0'}} </td>
                <td style="text-align: right;"> {{ stats?.Rejected / stats?.Shortlisted * 100 | number : '1.0-0'}} % </td>
                <td style="text-align: right;"> {{ stats?.Rejected / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
              </tr>
              <tr>
                <td> Vorstellungen </td>
                <td style="text-align: right;"> {{ stats?.Hired | number : '1.0-0'}} </td>
                <td style="text-align: right;"> {{ stats?.Hired / stats?.Accepted * 100 | number : '1.0-0'}} % </td>
                <td style="text-align: right;"> {{ stats?.Hired / stats?.Contacted * 100 | number : '1.0-0'}} % </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <div class="card-header-title h6">Rückmeldungen</div>
          </div>
          <div class="card-body">
            <autosize-textarea [(text)]="project.Profile.CandidateFeedback" (textChange)="saveSoon()"></autosize-textarea>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<sc-modal #errorModal>
  <div class="modal-header">
    Fehler
  </div>
  <div class="modal-body">
    <div class="alert alert-danger mb-0">
      <div class="row align-items-center">
        <div class="col">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="errorModal.dismiss();">Schließen</button>
  </div>
</sc-modal>

<app-project-process-action-modal #newCandidatesModal [project]="project" title="Über neue Bewerbungen benachrichtigen?" [showMailOption]="false" actionName="new-candidates">
  <div class="body">
    Der Process Owner und die Entscheider werden informiert, dass neue Bewerber:innen auf der Shortlist sind.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>

<app-project-process-action-modal #revokedCandidatesModal [project]="project" title="Über zurückgezogene Bewerbungen benachrichtigen?" [showMailOption]="false" actionName="revoked-candidates">
  <div class="body">
    Der Process Owner und die Entscheider werden informiert, Bewerberungen zurückgezogen wurden.
  </div>
  <div class="success">
    Vielen Dank!
  </div>
</app-project-process-action-modal>
