import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ModalSelectManagerComponent } from 'src/components/modal-select-manager/modal-select-manager.component';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-board-customer-retention',
  templateUrl: './board-customer-retention.component.html',
  styleUrls: ['./board-customer-retention.component.scss']
})
export class BoardCustomerRetentionComponent {

  accounts: any = [];

  searchTimer: any;
  
  salesStatus = 'Closed';

  companyName = '';

  tags = '';

  ownerId = 0;

  skip = 0;

  take = 25;

  isLoading = false;

  hasMoreItems = false;

  total = 0;

  stats: any;

  selectedManager: any;

  @ViewChild('userModal')
  userModal: ModalSelectManagerComponent;

  constructor(private api: ScApiService, private route: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: any) => {
      this.salesStatus = params['status'] ?? 'Closed';
      this.companyName = params['companyName'] ?? '';
      this.ownerId = params['ownerId'] ?? 0;
      this.tags = params['tags'] ?? '';

      if (params['ownerId'] == undefined) {
        if (this.userService.me) {
          this.selectedManager = this.userService.me;
          this.ownerId = this.userService.me?.Id ?? 0;
          this.load();
          return;
        }
        else {
          this.userService.meChange.subscribe(i => {
            this.ownerId = this.userService.me?.Id ?? 0;
            this.load();
          });

          return;
        }
      }

      this.skip = 0;
      this.accounts = [];
      this.loadMore();
      this.loadStats();
    });
  }

  loadStats() {

    var url = 'account-stats?';
    if (this.companyName) url += 'companyName=' + this.companyName + '&';
    if (this.ownerId != undefined) url += 'ownerId=' + this.ownerId + '&';
    if (this.tags) url += 'tags=' +   encodeURIComponent(this.tags) + '&';

    this.api.get(url).subscribe(d => {
      this.stats = d;
    });

    if (this.ownerId && this.ownerId != this.selectedManager?.Id) {
      this.api.get('contacts/' + this.ownerId).subscribe(c => {
        this.selectedManager = c;
      });
    }
  }

  selectManager() {

    this.userModal.show().subscribe(
      (m: any) => {
        this.selectedManager = m;
        this.ownerId = m ? m.Id : 0;
        this.load();
      });
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.load();
    }, 500);
  }

  load() {
    var url = '/board-retention?';
    if (this.companyName) url += 'companyName=' + this.companyName + '&';
    if (this.salesStatus) url += 'status=' + this.salesStatus + '&';
    if (this.ownerId != undefined) url += 'ownerId=' + this.ownerId + '&';
    if (this.tags) url += 'tags=' + encodeURIComponent(this.tags) + '&';
    this.router.navigateByUrl(url);
  }

  loadMore() {
    
    this.isLoading = true;

    this.api.get(`accounts?companyName=${this.companyName}&ownerId=${this.ownerId}&salesStatus=${this.salesStatus}&tags=${encodeURIComponent(this.tags)}&skip=${this.skip}&take=${this.take}`).subscribe((d: any) => {
      this.skip += this.take;
      this.accounts.push.apply(this.accounts, d.Items);
      this.hasMoreItems = this.accounts.length < d.Total;
      this.total = d.Total;
      this.isLoading = false;
    });
  }

}
