import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {

  blacklist : any;
  
  constructor(private api : ScApiService) { }

  ngOnInit() {
    this.api.get('blacklist').subscribe(d => {
      this.blacklist = d;
    })
  }

  save()
  {
    this.api.put('blacklist', this.blacklist).subscribe();
  }

}
