<span *ngIf="result.SkillScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Skills
  </span>
  <span *ngIf="result.DistanceScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Entfernung
  </span>
  <span *ngIf="result.JobTitleScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Job-Titel
  </span>
  <span *ngIf="result.BlacklistScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Blacklist
  </span>
  <span *ngIf="result.LanguageScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Sprachkenntnisse
  </span>
  <span *ngIf="result.EducationTimeScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Beginn/Ende der Ausbildung
  </span>
  <span *ngIf="result.EducationScore === 0" class="badge badge-pill badge-danger me-1 mb-1">
	<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;Ausbildungsbezeichnung
  </span>