import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { forEach } from 'lodash';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent {

  id: any;

  mapping: any = {};

  ambiguities: any = [];

  suggestions: any = [];

  nearestNeighbours : any = [];

  constructor(private api: ScApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params.id;

      this.api.get('mappings/' + params.id).subscribe((d: any) => {
        this.mapping = d.Item;
        this.ambiguities = d.Ambiguities;
        this.nearestNeighbours = d.NearestNeighbours;
      });
    })
  }

  getSuggestions() {
    this.api.get('mappings/' + this.id + '/suggestions').subscribe((d: any) => {
      this.suggestions = d;
    });
  }

  save() {
    this.api.put('mappings/' + this.id, this.mapping).subscribe((d: any) => { });
  }

  saveMapping(mapping) {
    this.api.put('mappings/' + mapping.Id, mapping).subscribe((d: any) => { });
  }


  merge(ambiguity) {

    var i = this.ambiguities.indexOf(ambiguity);
    this.ambiguities.splice(i, 1);
    this.api.delete('mappings/' + ambiguity.Mapping.Id, null).subscribe((d: any) => { });

    ambiguity.Mapping.Tags.forEach(t => {
      var isContained = this.mapping.Tags.some(t2 => t.Tag == t2.Tag);
      if (isContained) return;
      this.mapping.Tags.push(t);
    })

    this.save();
  }

  approve() {
    this.mapping.IsApproved = true;
    this.save();
  }

  blacklist() {
    this.mapping.IsBlacklisted = true;
    this.save();
  }

  delete() {
    this.api.delete('mappings/' + this.mapping.Id).subscribe((d: any) => { });
  }

  createNew() {
    var selection = this.mapping.Tags.filter(t => t.isSelected);

    selection.forEach(s => {
      var i = this.mapping.Tags.indexOf(s);
      if (i == -1) return;
      this.mapping.Tags.splice(i, 1);
    });

    this.save();

    var newMapping = {
      Tags: selection
    };

    this.api.post('mappings/', newMapping).subscribe((d: any) => { });
  }

  claimAmbiguities() {
    this.ambiguities.forEach((a: any) => {

      a.Tags.forEach(t => {
        var ambiguousTag = a.Mapping.Tags.find(t2 => t2.Tag == t);
        var i = a.Mapping.Tags.indexOf(ambiguousTag);
        if (i == -1) return;
        a.Mapping.Tags.splice(i, 1);

        var tag = this.mapping.Tags.find(t2 => t2.Tag == t);
        tag.IsAmbiguous = false;
      });

      this.saveMapping(a.Mapping);
    });
  }

  removeAmbiguities() {
    var ambiguities = this.mapping.Tags.filter(t => t.IsAmbiguous);

    ambiguities.forEach(s => {
      var i = this.mapping.Tags.indexOf(s);
      if (i == -1) return;
      this.mapping.Tags.splice(i, 1);
    });

    this.save();
  }

  removeSuggestions() {
    this.mapping.Suggestions.length = 0;
    this.save();
  }

  addSuggestion(s) {
    var i = this.mapping.Suggestions.indexOf(s);
    if (i == -1) return;
    this.mapping.Suggestions.splice(i, 1);
    this.mapping.Tags.push(s);
    this.save();
  }

  addTag(mapping, tag) {
    mapping.Tags.push({
      Tag: tag
    });
    this.api.put('mappings/' + mapping.Id, mapping).subscribe((d: any) => { });
  }

  addNeighbour(n)
  {
    this.mapping.Tags.push({
      Tag: n.Tag
    });
    this.api.put('mappings/' + this.mapping.Id, this.mapping).subscribe((d: any) => { });
  }

  deleteTag(mapping, tag) {
    var i = mapping.Tags.indexOf(tag);
    if (i == -1) return;
    mapping.Tags.splice(i, 1);
    this.api.put('mappings/' + mapping.Id, mapping).subscribe((d: any) => { });
  }

  deleteSuggestion(mapping, tag) {
    var i = mapping.Suggestions.indexOf(tag);
    if (i == -1) return;
    mapping.Suggestions.splice(i, 1);
    this.api.put('mappings/' + mapping.Id, mapping).subscribe((d: any) => { });
  }
}
