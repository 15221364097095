import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-chrome-extension-auth',
  templateUrl: './chrome-extension-auth.component.html',
  styleUrls: ['./chrome-extension-auth.component.scss']
})
export class ChromeExtensionAuthComponent implements OnInit {

  constructor(public api : ScApiService) {  }

  ngOnInit(): void {
  }

}
