import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { ScUiModule, ApiConfig } from '@sc-ui';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ScLayoutTopnavigationComponent } from './layouts/sc-layout-topnavigation/sc-layout-topnavigation.component';
import { ScLayoutEmptyComponent } from './layouts/sc-layout-empty/sc-layout-empty.component';
import { LoginComponent } from './views/login/login.component';
import { ProjectBriefingComponent } from './views/project/project-briefing/project-briefing.component';
import { ProjectDashboardComponent } from './views/project/project-dashboard/project-dashboard.component';
import { ScSwitchComponent } from './components/sc-switch/sc-switch.component';
import { ProjectSearchComponent } from './views/project/project-search/project-search.component';
import { AgmCoreModule } from '@agm/core';
import { ProjectTemplatesComponent } from './views/project/project-templates/project-templates.component';
import { CandidateComponent } from './views/candidate/candidate.component';
import { ProjectDashboardPeopleComponent } from './views/project/project-dashboard/project-dashboard-people/project-dashboard-people.component';
import { CompaniesComponent } from './views/companies/companies.component';
import { CompanyComponent } from './views/company/company.component';
import { ProjectBriefingFilesComponent } from './views/project/project-briefing/project-briefing-files/project-briefing-files.component';
import { FilterBadgeComponent } from './components/filter-badge/filter-badge.component';
import { MonthYearDateInputComponent } from './components/month-year-date-input/month-year-date-input.component';
import { BlacklistComponent } from './views/blacklist/blacklist.component';
import { SalesDashboardComponent } from './views/dashboard-sales/sales-dashboard.component';
import { ChatbotsComponent } from './views/chatbots/chatbots.component';
import { ChatbotComponent } from './views/chatbot/chatbot.component';
import { CandidatesComponent } from './views/candidates/candidates.component';
import { LocationListEditorComponent } from './components/location-list-editor/location-list-editor.component';
import { ScChatbotModule } from '@sc-ui-chatbot';
import { ScLayoutSidebarComponent } from './layouts/sc-layout-sidebar/sc-layout-sidebar.component';
import { BenefitSelectionComponent } from './components/benefit-selection/benefit-selection.component';
import { ChatbotSelectionComponent } from './components/chatbot-selection/chatbot-selection.component';
import { ModalProjectSearchPostChatComponent } from './components/modal-project-search-post-chat/modal-project-search-post-chat.component';
import { ChatbotHistoryComponent } from './views/chatbot-history/chatbot-history.component';
import { ChatComponent } from './views/chat/chat.component';
import { CandidateChatsComponent } from './views/candidate/candidate-sourcing/candidate-chats/candidate-chats.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { ModalEditLocationsComponent } from './views/candidate/candidate-overview/modal-edit-locations/modal-edit-locations.component';
import { ModalEditSkillsComponent } from './views/candidate/candidate-overview/modal-edit-skills/modal-edit-skills.component';
import { CandidateModalEditSkillComponent } from './views/candidate/candidate-overview/candidate-modal-edit-skill/candidate-modal-edit-skill.component';
import { ChatbotPrintComponent } from './views/chatbot-print/chatbot-print.component';
import { environment } from './environment.prod';
import { ProjectImportsComponent } from './views/project/project-imports/project-imports.component';
import { DatepickerInlineComponent } from '../refs/sc-ui/components/date-picker-inline/date-picker-inline.component';

import { ActivityItemCandidateCommentedComponent } from './components/activity-item/activity-item-candidate-commented/activity-item-candidate-commented.component';
import { ActivityItemCandidateShortlistedComponent } from './components/activity-item/activity-item-candidate-shortlisted/activity-item-candidate-shortlisted.component';
import { ActivityItemCandidateAcceptedComponent } from './components/activity-item/activity-item-candidate-accepted/activity-item-candidate-accepted.component';
import { ActivityItemCandidateRejectedComponent } from './components/activity-item/activity-item-candidate-rejected/activity-item-candidate-rejected.component';
import { ActivityItemCandidateHiredComponent } from './components/activity-item/activity-item-candidate-hired/activity-item-candidate-hired.component';
import { ActivityItemCandidateFeedbackPostedComponent } from './components/activity-item/activity-item-candidate-feedback-posted/activity-item-candidate-feedback-posted.component';
import { ActivityItemProjectJobsiteReadyComponent } from './components/activity-item/activity-item-project-jobsite-ready/activity-item-project-jobsite-ready.component';
import { ActivityItemProjectJobsiteCommentedComponent } from './components/activity-item/activity-item-project-jobsite-commented/activity-item-project-jobsite-commented.component';
import { ActivityItemProjectJobsiteWorkItemCommentedComponent } from './components/activity-item/activity-item-project-jobsite-work-item-commented/activity-item-project-jobsite-work-item-commented.component';
import { ActivityItemProjectJobsiteConfirmedComponent } from './components/activity-item/activity-item-project-jobsite-confirmed/activity-item-project-jobsite-confirmed.component';
import { ActivityItemProjectMessagingDoneComponent } from './components/activity-item/activity-item-project-messaging-done/activity-item-project-messaging-done.component';
import { ActivityItemProjectBriefingCompletedComponent } from './components/activity-item/activity-item-project-briefing-completed/activity-item-project-briefing-completed.component';
import { ActivityItemComponent } from './components/activity-item/activity-item.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { ProjectStatusPipe } from './pipes/project-status.pipe';
import { CandidateActivitiesComponent } from './views/candidate/_shared/candidate-activities/candidate-activities.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { ProjectBillingComponent } from './views/project/project-billing/project-billing.component';
import { NavProjectComponent } from './components/nav-project/nav-project.component';
import { ProjectAnalysisComponent } from './views/project/project-analysis/project-analysis.component';
import { ProjectQuoteComponent } from './views/project/project-quote/project-quote.component';
import { ProjectJobsiteComponent } from './views/project/project-jobsite/project-jobsite.component';
import { ProjectListingComponent } from './views/project/project-listing/project-listing.component';
import { ProjectDebriefingComponent } from './views/project/project-debriefing/project-debriefing.component';
import { CompanyCardPackagesComponent } from './views/company/company-card-packages/company-card-packages.component';
import { PackageComponent } from './views/package/package.component';
import { ProjectPackageSelectionComponent } from './components/inline-select-project-package/inline-select-project-package.component';
import { TopbarTemplateDirective } from './directives/topbar-template.directive';
import { TopbarProjectComponent } from './views/project/topbar-project/topbar-project.component';
import { InvoiceEditorComponent } from './components/invoice-editor/invoice-editor.component';
import { ProjectProcessActionModalComponent } from './components/project-process-action-modal/project-process-action-modal.component';
import { InvoicesComponent } from './views/invoices/invoices.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { CompanyBillingComponent } from './views/company-billing/company-billing.component';
import { NavCompanyComponent } from './components/nav-company/nav-company.component';
import { TopbarCompanyComponent } from './components/topbar-company/topbar-company.component';
import { PackagesComponent } from './views/packages/packages.component';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ImpressionEditorComponent } from './components/impression-editor/impression-editor.component';
import { CompanyAssetEditorComponent } from './views/company/company-asset-editor/company-asset-editor.component';
import { QuotesComponent } from './views/quotes/quotes.component';
import { ProjectProfileComponent } from './views/project/project-profile/project-profile.component';
import { NavProjectSourcingComponent } from './components/nav-project-sourcing/nav-project-sourcing.component';
import { ModalContactEditComponent } from './components/modal-contact-edit/modal-contact-edit.component';
import { ChromeExtensionAuthComponent } from './views/chrome-extension-auth/chrome-extension-auth.component';
import { StringListEditorComponent } from './components/string-list-editor/string-list-editor.component';
import { ProjectSaveModalErrorComponent } from './components/project-save-modal-error/project-save-modal-error.component';
import { ProjectSaveModalConflictComponent } from './components/project-save-modal-conflict/project-save-modal-conflict.component';
import { DashboardTamComponent } from './views/dashboard-tam/dashboard-tam.component';
import { ModalActionComponent } from './components/modal-action/modal-action.component';
import { CompanyCardInvoicesComponent } from './views/company/company-card-invoices/company-card-invoices.component';
import { CompanyCardBillingInformationComponent } from './views/company/company-card-billing-information/company-card-billing-information.component';
import { PoolsComponent } from './views/pools/pools.component';
import { PoolComponent } from './views/pool/pool.component';
import { PoolActivitiesComponent } from './views/pool/pool-activities/pool-activities.component';
import { PoolBaseComponent } from './views/pool/pool-base/pool-base.component';
import { PoolCandidatesComponent } from './views/pool/pool-candidates/pool-candidates.component';
import { PoolGroupsComponent } from './views/pool/pool-groups/pool-groups.component';
import { PoolGroupComponent } from './views/pool-group/pool-group.component';
import { PoolGroupBaseComponent } from './views/pool-group/pool-group-base/pool-group-base.component';
import { PoolGroupCandidatesComponent } from './views/pool-group/pool-group-candidates/pool-group-candidates.component';
import { PoolBaseUserModalComponent } from './views/pool/pool-base/pool-base-user-modal/pool-base-user-modal.component';
import { PoolGroupCandidatesAddModalComponent } from './views/pool-group/pool-group-candidates/pool-group-candidates-add-modal/pool-group-candidates-add-modal.component';
import { CandidateBaseComponent } from './views/candidate/candidate-overview/candidate-base/candidate-base.component';
import { StatusBadgePoolPersonAssignmentComponent } from './components/status-badge-pool-person-assignment/status-badge-pool-person-assignment.component';
import { StatusBadgeProjectPersonAssignmentComponent } from './components/status-badge-project-person-assignment/status-badge-project-person-assignment.component';
import { CandidateSkillsComponent } from './views/candidate/candidate-overview/candidate-skills/candidate-skills.component';
import { CandidateCvComponent } from './views/candidate/candidate-overview/candidate-cv/candidate-cv.component';
import { ProjectPackageActionModalComponent } from './components/project-package-action-modal/project-package-action-modal.component';
import { StatusBadgeProjectComponent } from './components/status-badge-project/status-badge-project.component';
import { ProjectsFilterBadgesComponent } from './components/projects-filter-badges/projects-filter-badges.component';
import { ProjectsFilterModalComponent } from './components/projects-filter-modal/projects-filter-modal.component';
import { ActivityItemProjectUserInvitedComponent } from './components/activity-item/activity-item-project-user-invited/activity-item-project-user-invited.component';
import { ActivityItemProjectQuoteSentComponent } from './components/activity-item/activity-item-project-quote-sent/activity-item-project-quote-sent.component';
import { ActivityItemProjectAnalysisCompletedComponent } from './components/activity-item/activity-item-project-analysis-completed/activity-item-project-analysis-completed.component';
import { ActivityItemProjectCreatedComponent } from './components/activity-item/activity-item-project-created/activity-item-project-created.component';
import { SidebarComponent } from './views/sidebar/sidebar.component';
import { SidebarCandidateExactComponent } from './views/sidebar/sidebar-candidate-exact/sidebar-candidate-exact.component';
import { TopbarSidebarComponent } from './components/topbar-sidebar/topbar-sidebar.component';
import { SidebarCandidateExactItemComponent } from './views/sidebar/sidebar-candidate-exact/sidebar-candidate-exact-item/sidebar-candidate-exact-item.component';
import { SidebarProjectsComponent } from './views/sidebar/sidebar-projects/sidebar-projects.component';
import { SidebarCandidateNewComponent } from './views/sidebar/sidebar-candidate-new/sidebar-candidate-new.component';
import { SidebarCandidateHeaderComponent } from './views/sidebar/sidebar-candidate-exact/sidebar-candidate-header/sidebar-candidate-header.component';
import { SidebarLoginComponent } from './views/sidebar/sidebar-login/sidebar-login.component';
import { SidebarFooterComponent } from './components/sidebar-footer/sidebar-footer.component';
import { SidebarSearchComponent } from './views/sidebar/sidebar-search/sidebar-search.component';
import { SidebarCandidateMergeComponent } from './views/sidebar/sidebar-candidate-merge/sidebar-candidate-merge.component';
import { CadenceEditComponent } from './views/cadence-edit/cadence-edit.component';
import { CadencesComponent } from './views/cadences/cadences.component';
import { CadenceComponent } from './views/cadence/cadence.component';
import { CadencePeopleComponent } from './views/cadence-people/cadence-people.component';
import { PoolCadencesComponent } from './views/pool/pool-cadences/pool-cadences.component';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';
import { NavPrimaryComponent } from './components/nav-primary/nav-primary.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { SettingsComponent } from './views/settings/settings.component';
import { TaskGroupComponent } from './components/task-group/task-group.component';
import { TaskComponent } from './components/task/task.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { TaskDetailProjectPrognosisComponent } from './components/task-detail/task-detail-project-prognosis/task-detail-project-prognosis.component';
import { CandidateProjectsComponent } from './views/candidate/candidate-sourcing/candidate-projects/candidate-projects.component';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { CompanyProjectsComponent } from './views/company/company-projects/company-projects.component';
import { CompanyPeopleComponent } from './views/company/company-people/company-people.component';
import { CandidateCadencesComponent } from './views/candidate/candidate-cadences/candidate-cadences.component';
import { ModalProjectPersonAssignmentComponent } from './components/modal-project-person-assignment/modal-project-person-assignment.component';
import { ModalPoolPersonAssignmentComponent } from './components/modal-pool-person-assignment/modal-pool-person-assignment.component';
import { ModalCadencePersonAssignmentComponent } from './components/modal-cadence-person-assignment/modal-cadence-person-assignment.component';
import { StatusBadgeCadencePersonAssignmentComponent } from './components/status-badge-cadence-person-assignment/status-badge-cadence-person-assignment.component';
import { ActivityItemMailComponent } from './components/activity-item/activity-item-mail/activity-item-mail.component';
import { TaskDetailCadenceMailReplyComponent } from './components/task-detail/task-detail-cadence-mail-reply/task-detail-cadence-mail-reply.component';
import { CandidateOrganizationsComponent } from './views/candidate/_shared/candidate-organizations/candidate-organizations.component';
import { TaskDetailCustomComponent } from './components/task-detail/task-detail-custom/task-detail-custom.component';
import { CandidateTasksComponent } from './views/candidate/_shared/candidate-tasks/candidate-tasks.component';
import { CompanyTasksComponent } from './views/company/company-tasks/company-tasks.component';
import { ModalTaskEditComponent } from './components/modal-task-edit/modal-task-edit.component';
import { ModalTaskAddComponent } from './components/modal-task-add/modal-task-add.component';
import { TaskPipe } from './pipes/task-title.pipe';
import { ModalSelectOrganizationComponent } from './components/modal-select-organization/modal-select-organization.component';
import { CompanyAssociatedCompaniesComponent } from './views/company/company-associated-companies/company-associated-companies.component';
import { ModalSelectPersonComponent } from './components/modal-select-person/modal-select-person.component';
import { ListPeopleComponent } from './components/list-people/list-people.component';
import { ModalSelectCadenceComponent } from './components/modal-select-cadence/modal-select-cadence.component';
import { ProjectSearchResultErrorBadgesComponent } from './views/project/project-search/project-search-result-error-badges/project-search-result-error-badges.component';
import { ActivityItemCandidateInterestedComponent } from './components/activity-item/activity-item-candidate-interested/activity-item-candidate-interested.component';
import { ListOrganizationsComponent } from './components/list-organizations/list-organizations.component';
import { CardKpiComponent } from './components/card-kpi/card-kpi.component';
import { TaskDetailDefaultComponent } from './components/task-detail/task-detail-default/task-detail-default.component';
import { ListInvoicesComponent } from './components/list-invoices/list-invoices.component';
import { ListPackagesComponent } from './components/list-packages/list-packages.component';
import { CompanyBaseComponent } from './views/company/company-base/company-base.component';
import { StatusBadgePersonComponent } from './components/status-badge-person/status-badge-person.component';
import { ActivityItemIntroductoryCallComponent } from './components/activity-item/activity-item-introductory-call/activity-item-introductory-call.component';
import { ModalSelectChatbotComponent } from './components/modal-select-chatbot/modal-select-chatbot.component';
import { ProjectDashboardTasksComponent } from './views/project/project-dashboard/project-dashboard-tasks/project-dashboard-tasks.component';
import { ModalSelectPoolComponent } from './components/modal-select-pool/modal-select-pool.component';
import { ActivityItemCandidateStatusUpdateComponent } from './components/activity-item/activity-item-candidate-status-update/activity-item-candidate-status-update.component';
import { ReportsComponent } from './views/reports/reports.component';
import { ActivityItemProjectReviewCompletedComponent } from './components/activity-item/activity-item-project-review-completed/activity-item-project-review-completed.component';
import { ActivityItemProjectSourcingCompletedComponent } from './components/activity-item/activity-item-project-sourcing-completed/activity-item-project-sourcing-completed.component';
import { ActivityItemProjectSourcingStartedComponent } from './components/activity-item/activity-item-project-sourcing-started/activity-item-project-sourcing-started.component';
import { ActivityItemProjectOrderedComponent } from './components/activity-item/activity-item-project-ordered/activity-item-project-ordered.component';
import { ActivityItemProjectJobsiteRevisionRequestedComponent } from './components/activity-item/activity-item-project-jobsite-revision-requested/activity-item-project-jobsite-revision-requested.component';
import { ActivityItemProjectBilledComponent } from './components/activity-item/activity-item-project-billed/activity-item-project-billed.component';
import { ActivityItemProjectReviewBookedComponent } from './components/activity-item/activity-item-project-review-booked/activity-item-project-review-booked.component';
import { ActivityItemProjectBriefingBookedComponent } from './components/activity-item/activity-item-project-briefing-booked/activity-item-project-briefing-booked.component';
import { TaskDetailProjectSendFollowupMessagesComponent } from './components/task-detail/task-detail-project-send-followup-messages/task-detail-project-send-followup-messages.component';
import { ActivityItemCallComponent } from './components/activity-item/activity-item-call/activity-item-call.component';
import { LeadsComponent } from './views/board-customer-acquisition/board-customer-acquisition.component';
import { ModalSelectManagerComponent } from './components/modal-select-manager/modal-select-manager.component';
import { SelectorPersonComponent } from './components/inline-select-person/inline-select-person.component';
import { InlineSelectContactComponent } from './components/inline-select-contact/inline-select-contact.component';
import { InlineSelectOrganizationComponent } from './components/inline-select-organization/inline-select-organization.component';
import { InlineSelectBillingAddressComponent } from './components/inline-select-billing-address/inline-select-billing-address.component';
import { ModalSelectBillingAddressComponent } from './components/modal-select-billing-address/modal-select-billing-address.component';
import { ListBillingAddressComponent } from './components/list-billing-address/list-billing-address.component';
import { InlineSelectManagerComponent } from './components/inline-select-manager/inline-select-manager.component';
import { ProjectQuoteRecipientsComponent } from './views/project/project-quote/project-quote-recipients/project-quote-recipients.component';
import { ModalSelectProjectPackageComponent } from './components/modal-select-project-package/modal-select-project-package.component';
import { CompanyAssetsComponent } from './views/company/company-assets/company-assets.component';
import { PackageQuoteComponent } from './views/package/package-quote/package-quote.component';
import { PackageInvoicesComponent } from './views/package/package-invoices/package-invoices.component';
import { PackageProjectsComponent } from './views/package/package-projects/package-projects.component';
import { ListQuotesComponent } from './components/list-quotes/list-quotes.component';
import { SkillsComponent } from './views/skills/skills.component';
import { SkillComponent } from './views/skill/skill.component';
import { ActivityItemProjectRatedComponent } from './components/activity-item/activity-item-project-rated/activity-item-project-rated.component';
import { FeedbackRatingPipe } from './pipes/feedback-rating.pipe';
import { ActivityItemProjectCompletedComponent } from './components/activity-item/activity-item-project-completed/activity-item-project-completed.component';
import { TrainingBatchesComponent } from './views/training-batches/training-batches.component';
import { TopbarCandidateComponent } from './views/candidate/_shared/topbar-candidate/topbar-candidate.component';
import { CandidateOverviewComponent } from './views/candidate/candidate-overview/candidate-overview.component';
import { CandidateAccountComponent } from './views/candidate/candidate-account/candidate-account.component';
import { CandidateSourcingComponent } from './views/candidate/candidate-sourcing/candidate-sourcing.component';
import { CandidateSalesComponent } from './views/candidate/candidate-sales/candidate-sales.component';
import { SalesLeadBaseComponent } from './views/candidate/candidate-sales/candidate-sales-base/candidate-sales-base.component';
import { SalesLeadLogActivityComponent } from './views/candidate/candidate-sales/candidate-sales-log-activity/candidate-sales-log-activity.component';
import { SalesLeadProjectsComponent } from './views/candidate/candidate-sales/candidate-sales-projects/candidate-sales-projects.component';
import { CandidateErrorModalComponent } from './views/candidate/_shared/candidate-error-modal/candidate-error-modal.component';
import { ModalBriefingComponent } from './views/project/_shared/modal-briefing/modal-briefing.component';
import { ProjectBriefingModalSkillsComponent } from './views/project/project-briefing/project-briefing-modal-skills/project-briefing-modal-skills.component';
import { ProjectBriefingModalIndustriesComponent } from './views/project/project-briefing/project-briefing-modal-industries/project-briefing-modal-industries.component';
import { ProjectBriefingModalImportComponent } from './views/project/project-briefing/project-briefing-modal-import/project-briefing-modal-import.component';
import { IndustryNamePipe } from './pipes/industry-name.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DashboardMarketingComponent } from './views/dashboard-marketing/dashboard-marketing.component';
import { MailingsComponent } from './views/mailings/mailings.component';
import { MailingComponent } from './views/mailing/mailing.component';
import { ProjectScreeningComponent } from './views/project/project-screening/project-screening.component';
import { InlineSelectSalesStatusComponent } from './components/inline-select-sales-status/inline-select-sales-status.component';
import { ModalSelectSalesStatusComponent } from './components/modal-select-sales-status/modal-select-sales-status.component';
import { BoardCustomerRetentionComponent } from './views/board-customer-retention/board-customer-retention.component';
import { ActivityItemSalesOrganizationStatusChangedComponent } from './components/activity-item/activity-item-sales-organization-status-changed/activity-item-sales-organization-status-changed.component';
import { DashboardRetentionComponent } from './views/dashboard-retention/dashboard-retention.component';

registerLocaleData(localeDe);



@NgModule({
  declarations: [
    AppComponent,
    ScLayoutTopnavigationComponent,
    ScLayoutEmptyComponent,
    LoginComponent,
    ProjectBriefingComponent,
    ProjectDashboardComponent,
    ScSwitchComponent,
    ProjectSearchComponent,
    ProjectTemplatesComponent,
    CandidateComponent,
    ProjectDashboardPeopleComponent,
    CompaniesComponent,
    CompanyComponent,
    ProjectBriefingFilesComponent,
    FilterBadgeComponent,
    MonthYearDateInputComponent,
    BlacklistComponent,
    SalesDashboardComponent,
    ChatbotsComponent,
    ChatbotComponent,
    CandidatesComponent,
    LocationListEditorComponent,
    ScLayoutSidebarComponent,
    BenefitSelectionComponent,
    ChatbotSelectionComponent,
    ModalProjectSearchPostChatComponent,
    ChatbotHistoryComponent,
    ChatComponent,
    CandidateChatsComponent,
    SortByPipe,
    ProjectStatusPipe,
    ModalEditLocationsComponent,
    ModalEditSkillsComponent,
    CandidateModalEditSkillComponent,
    ChatbotPrintComponent,
    ProjectImportsComponent,
    ActivityItemComponent,
    ActivityItemCandidateCommentedComponent,
    ActivityItemCandidateShortlistedComponent,
    ActivityItemCandidateAcceptedComponent,
    ActivityItemCandidateRejectedComponent,
    ActivityItemCandidateHiredComponent,
    ActivityItemCandidateFeedbackPostedComponent,
    ActivityItemProjectJobsiteReadyComponent,
    ActivityItemProjectJobsiteCommentedComponent,
    ActivityItemProjectJobsiteWorkItemCommentedComponent,
    ActivityItemProjectJobsiteConfirmedComponent,
    ActivityItemProjectMessagingDoneComponent,
    ActivityItemProjectBriefingCompletedComponent,
    ActivityItemProjectCompletedComponent,
    TopbarComponent,
    NavTabsComponent,
    ProjectsComponent,
    CandidateActivitiesComponent,
    ProjectBillingComponent,
    NavProjectComponent,
    ProjectAnalysisComponent,
    ProjectQuoteComponent,
    ProjectJobsiteComponent,
    ProjectListingComponent,
    ProjectDebriefingComponent,
    CompanyCardPackagesComponent,
    PackageComponent,
    ProjectPackageSelectionComponent,
    TopbarTemplateDirective,
    TopbarProjectComponent,
    InvoiceEditorComponent,
    ProjectProcessActionModalComponent,
    InvoicesComponent,
    InvoiceComponent,
    CompanyBillingComponent,
    NavCompanyComponent,
    TopbarCompanyComponent,
    PackagesComponent,
    ImpressionEditorComponent,
    CompanyAssetEditorComponent,
    QuotesComponent,
    ProjectProfileComponent,
    NavProjectSourcingComponent,
    ModalContactEditComponent,
    ChromeExtensionAuthComponent,
    StringListEditorComponent,
    ProjectSaveModalErrorComponent,
    ProjectSaveModalConflictComponent,
    DashboardTamComponent,
    ModalActionComponent,
    CompanyCardInvoicesComponent,
    CompanyCardBillingInformationComponent,
    PoolsComponent,
    PoolComponent,
    PoolActivitiesComponent,
    PoolBaseComponent,
    PoolBaseUserModalComponent,
    PoolCandidatesComponent,
    PoolGroupsComponent,
    PoolGroupComponent,
    PoolGroupBaseComponent,
    PoolGroupCandidatesComponent,
    PoolGroupCandidatesAddModalComponent,
    CandidateBaseComponent,
    StatusBadgePoolPersonAssignmentComponent,
    StatusBadgeProjectPersonAssignmentComponent,
    CandidateSkillsComponent,
    CandidateCvComponent,
    ProjectPackageActionModalComponent,
    StatusBadgeProjectComponent,
    ProjectsFilterBadgesComponent,
    ProjectsFilterModalComponent,
    ActivityItemProjectUserInvitedComponent,
    ActivityItemProjectQuoteSentComponent,
    ActivityItemProjectAnalysisCompletedComponent,
    ActivityItemProjectCreatedComponent,
    SidebarComponent,
    SidebarCandidateExactComponent,
    TopbarSidebarComponent,
    SidebarCandidateExactItemComponent,
    SidebarProjectsComponent,
    SidebarCandidateNewComponent,
    SidebarCandidateHeaderComponent,
    SidebarLoginComponent,
    SidebarFooterComponent,
    SidebarSearchComponent,
    SidebarCandidateMergeComponent,
    CadenceEditComponent,
    CadencesComponent,
    CadenceComponent,
    CadencePeopleComponent,
    PoolCadencesComponent,
    LayoutDefaultComponent,
    NavPrimaryComponent,
    TasksComponent,
    SettingsComponent,
    TaskGroupComponent,
    TaskComponent,
    TaskDetailComponent,
    TaskDetailProjectPrognosisComponent,
    CandidateProjectsComponent,
    ListProjectsComponent,
    CompanyProjectsComponent,
    CompanyPeopleComponent,
    CandidateCadencesComponent,
    ModalProjectPersonAssignmentComponent,
    ModalPoolPersonAssignmentComponent,
    ModalCadencePersonAssignmentComponent,
    StatusBadgeCadencePersonAssignmentComponent,
    ActivityItemMailComponent,
    TaskDetailCadenceMailReplyComponent,
    CandidateOrganizationsComponent,
    TaskDetailCustomComponent,
    CandidateTasksComponent,
    CompanyTasksComponent,
    ModalTaskEditComponent,
    ModalTaskAddComponent,
    TaskPipe,
    ModalSelectOrganizationComponent,
    CompanyAssociatedCompaniesComponent,
    ModalSelectPersonComponent,
    ListPeopleComponent,
    ModalSelectCadenceComponent,
    ProjectSearchResultErrorBadgesComponent,
    ActivityItemCandidateInterestedComponent,
    ListOrganizationsComponent,
    CardKpiComponent,
    TaskDetailDefaultComponent,
    ListInvoicesComponent,
    ListPackagesComponent,
    CompanyBaseComponent,
    StatusBadgePersonComponent,
    ActivityItemIntroductoryCallComponent,
    ModalSelectChatbotComponent,
    ProjectDashboardTasksComponent,
    ModalSelectPoolComponent,
    ActivityItemCandidateStatusUpdateComponent,
    ReportsComponent,
    ActivityItemProjectReviewCompletedComponent,
    ActivityItemProjectSourcingCompletedComponent,
    ActivityItemProjectSourcingStartedComponent,
    ActivityItemProjectOrderedComponent,
    ActivityItemProjectJobsiteRevisionRequestedComponent,
    ActivityItemProjectBilledComponent,
    ActivityItemProjectReviewBookedComponent,
    ActivityItemProjectBriefingBookedComponent,
    TaskDetailProjectSendFollowupMessagesComponent,
    ActivityItemCallComponent,
    LeadsComponent,
    CandidateSalesComponent,
    SalesLeadBaseComponent,
    SalesLeadLogActivityComponent,
    ModalSelectManagerComponent,
    SelectorPersonComponent,
    InlineSelectContactComponent,
    InlineSelectOrganizationComponent,
    InlineSelectBillingAddressComponent,
    ModalSelectBillingAddressComponent,
    ListBillingAddressComponent,
    InlineSelectManagerComponent,
    ProjectQuoteRecipientsComponent,
    ModalSelectProjectPackageComponent,
    CompanyAssetsComponent,
    PackageQuoteComponent,
    PackageInvoicesComponent,
    PackageProjectsComponent,
    ListQuotesComponent,
    SalesLeadProjectsComponent,
    SkillsComponent,
    SkillComponent,
    ActivityItemProjectRatedComponent,
    FeedbackRatingPipe,
    ActivityItemProjectCompletedComponent,
    TrainingBatchesComponent,
    TopbarCandidateComponent,
    CandidateOverviewComponent,
    CandidateAccountComponent,
    CandidateSourcingComponent,
    CandidateErrorModalComponent,
    ModalBriefingComponent,
    ProjectBriefingModalSkillsComponent,
    ProjectBriefingModalIndustriesComponent,
    ProjectBriefingModalImportComponent,
    IndustryNamePipe,
    DashboardMarketingComponent,
    MailingsComponent,
    MailingComponent,
    ProjectScreeningComponent,
    InlineSelectSalesStatusComponent,
    ModalSelectSalesStatusComponent,
    BoardCustomerRetentionComponent,
    ActivityItemSalesOrganizationStatusChangedComponent,
    DashboardRetentionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    CommonModule,
    ScUiModule,
    ScChatbotModule,
    MonacoEditorModule.forRoot(),
    ClipboardModule,
    DragDropModule,
    InlineSVGModule,
    NgJsonEditorModule,
    ImageCropperModule,
    PdfViewerModule,
    NgxSliderModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de-DE" },
    {
      provide: ApiConfig,
      useValue:
      {
        host: environment.api
      }
    },
    {
      provide : NgbModalConfig,
      useValue : {   
        animation: true,     
        centered: true
      }
    }
  ],
  bootstrap: [AppComponent], 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {

  constructor()
  {
  }
 }
