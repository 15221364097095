<div class="row">
    <div class="col" *ngIf="selectedChatbot">
        <button class="btn btn-block btn-sm btn-secondary w-100"
            style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
            (click)="select();">{{ selectedChatbot.Name }}</button>
    </div>
    <div class="col" *ngIf="!selectedChatbot">
        <button class="btn btn-block btn-sm btn-secondary w-100"
            style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; opacity: .5;"
            (click)="select();">Auswählen</button>
    </div>
</div>


<app-modal-select-chatbot #selectModal></app-modal-select-chatbot>