import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';
import benefits from 'refs/sc-ui/resources/benefits';

@Component({
  selector: 'benefit-selection',
  templateUrl: './benefit-selection.component.html',
  styleUrls: ['./benefit-selection.component.scss']
})
export class BenefitSelectionComponent implements OnChanges {

  selectedStates: any = {};

  allBenefits = benefits;

  filteredBenefits = [];

  q = '';

  @Input()
  benefitIds: any = [];

  @Output()
  benefitIdsChange = new EventEmitter<any>();

  ngOnChanges() {
    this.updateBenefits();
    this.q = '';
    this.filterBenefits();
  }

  filterBenefits()
  {
    this.filteredBenefits = benefits.filter(b => b.name_de.toLowerCase().indexOf(this.q.toLowerCase()) >= 0);
  }

  updateBenefits() {

    benefits.forEach(i => {
      this.selectedStates[i.id] = false;
    });

    if (!this.benefitIds) return;

    this.benefitIds.forEach(b => {
      this.selectedStates[b] = true;
    });
  }

  setSelected(e, b) {

    if (e) {
      this.addBenefit(b);
    }
    else {
      this.removeBenefit(b);
    }

  }

  addBenefit(b) {
    var existingBenefit = this.benefitIds.find(b2 => b2 == b.id);
    if (existingBenefit) return;
    b.isActive = true;
    this.benefitIds.push(b.id);
    this.benefitIds.sort((a, b) => a - b);
    this.benefitIdsChange.emit();
  }

  removeBenefit(b) {

    var index = this.benefitIds.indexOf(b.id);
    if (index >= 0) {
      this.benefitIds.splice(index, 1);
      b.isActive = false;
    }
    this.benefitIdsChange.emit();
  }

}
