<div class="row" [scAnchorTarget]="'Kadenzen'" *ngIf="cadenceAssignments?.length">

  <div class="col-12 section-title">
    <h3>Kadenzen</h3>
  </div>
  <div class="col-12">
    <ul class="list-group list-group-lg list-group-flush list">
      <li class="list-group-item py-2" *ngFor="let cadenceAssignment of cadenceAssignments">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-5">
              <div class="mb-1 name oneline">
                <a routerLink="/cadences/{{cadenceAssignment.Cadence.Id}}" style="white-space: pre; text-overflow:ellipsis; cursor: pointer;">
                  <strong>{{cadenceAssignment.Cadence.Name}}</strong>
                </a>
              </div>
            </div>
            <div class="col-2">
              {{ cadenceAssignment.Cadence.Stats.Pending }}
            </div>
            <div class="col-2">
              {{ cadenceAssignment.Cadence.Stats.Completed }}
            </div>
            <div class="col-2">
              {{ cadenceAssignment.Cadence.Stats.Failed }}
            </div>
            <div class="col-1" (click)="$event.stopPropagation()">
              <div ngbDropdown>
                <div ngbDropdownToggle>
                  <div class="file me-2 ms-2 clickable">
                    <i class="fa-regular fa-ellipsis"></i>
                  </div>
                </div>
                <div ngbDropdownMenu>
                  <a ngbDropdownItem (click)="selectedCadenceAssignment = cadenceAssignment; removePeopleFrowCadence.show()">
                    Alle Pool-Kandidat:innen entfernen
                  </a>
                  <a ngbDropdownItem (click)="selectedCadenceAssignment = cadenceAssignment; addPeopleToCadence.show()">
                    Pool-Kandidat:innen hinzufügen
                  </a>
                  <a ngbDropdownItem (click)="selectedCadenceAssignment = cadenceAssignment; editModal.show()">
                    Bearbeiten
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<sc-modal #addPeopleToCadence>
  <sc-modal-header>
    Personen bearbeiten
  </sc-modal-header>
  <sc-modal-body>
    <sc-form>
      <sc-form-row label="Anzahl">
        <input [(ngModel)]="addCount" />
      </sc-form-row>
    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-primary" [disabled]="isExecuting" (click)="addCandidatesToCadence();">
      <span class="spinner-border spinner-border-sm" *ngIf="isExecuting"></span>
      Hinzufügen
    </button>
  </sc-modal-footer>
</sc-modal>

<sc-modal #removePeopleFrowCadence>
  <sc-modal-header>
    Personen entfernen
  </sc-modal-header>
  <sc-modal-body>

  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-primary">Fortfahren</button>
  </sc-modal-footer>
</sc-modal>

<sc-modal #editModal>
  <sc-modal-header>
    {{ selectedCadenceAssignment?.Cadence.Name }}
  </sc-modal-header>
  <sc-modal-body>
    <sc-form *ngIf="selectedCadenceAssignment">
      <sc-form-row label="Neue automatisch hinzufügen">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="AutomaticallyAddNewPersons" [(ngModel)]="selectedCadenceAssignment.AutomaticallyAddNewPersons">
          <label class="custom-control-label" for="AutomaticallyAddNewPersons"></label>
        </div>
      </sc-form-row>
      <sc-form-row label="Erfolge automatisch entfernen">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="AutomaticallyDeactivateSucceededPersons" [(ngModel)]="selectedCadenceAssignment.AutomaticallyDeactivateSucceededPersons">
          <label class="custom-control-label" for="AutomaticallyDeactivateSucceededPersons"></label>
        </div>
      </sc-form-row>
    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-dark" (click)="saveCadenceAssignment(selectedCadenceAssignment); editModal.dismiss()">
      Speichern
    </button>
  </sc-modal-footer>
</sc-modal>