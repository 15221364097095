import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-project-templates',
  templateUrl: './project-templates.component.html',
  styleUrls: ['./project-templates.component.scss']
})
export class ProjectTemplatesComponent implements OnInit {

  $destroy = new Subject();

  id: any;
  
  project: any; 
   
  saveTimeout: any;

  constructor(
    private api: ScApiService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.$destroy)).subscribe(params => {
      
      this.id = params['id'];

      this.api.get('jobs/' + this.id).subscribe(p => {
        this.project = p;
      });
    });
  }

  save() {
    this.api.put("jobs/" + this.id, this.project).subscribe(data => {
      console.log(data);
    });
  }

  saveSoon() {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => { this.save(); }, 1000);
  }

}
