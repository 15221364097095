<app-topbar-project [project]="project"></app-topbar-project>

<div class="header mb-3">
  <div class="container">
    <div class="row">

      <div class="col">
        <app-nav-project-sourcing [project]="project" active="screening"></app-nav-project-sourcing>
      </div>

      <div class="col-auto">
        <div ngbDropdown>
          <button ngbDropdownToggle class="btn btn-sm btn-secondary btn-circle">
            <i class="fa-regular fa-ellipsis"></i>
          </button>

          <div ngbDropdownMenu class="dropdown-menu">

            <a ngbDropdownItem class="dropdown-item">
              <i class="fa-solid fa-play me-2"></i> Neu matchen
            </a>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-md-4" *ngIf="project">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Suchen
                </h6>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-circle btn-secondary" (click)="requirementsModal.show()">
                  <i class="fa-regular fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body card-body-list">
            <ul class="list-group">
              <li class="list-group-item list-group-item-action" *ngFor="let search of searches" (click)="loadResults(search)">
                <!-- <div class="mb-2">
                  <span class="text-muted small fw-normal">{{ search.Created | humanizedDate }}</span>
                </div> -->

                <div class="row">
                  <div class="col">
                    <div *ngIf="search.Data">
                      <div class="tag-list mb-n2">
                        <div class="tag me-2 mb-2" *ngFor="let s of search.Data.Skills">{{ s }}</div>
                        <div class="tag me-2 mb-2" *ngFor="let s of search.Data.NiceToHaveSkills" style="opacity: .5;">{{ s }}</div>
                        <div class="tag me-2 mb-2" *ngFor="let s of search.Data.Industries"> Branche: {{ s | industryName }} </div>
                        <div class="tag me-2 mb-2" *ngFor="let s of search.Data.Locations">{{ s.City }}</div>
                        <div class="tag me-2 mb-2" *ngIf="search.Data.MinYearsOfExperience || search.Data.MaxYearsOfExperience">Berufserfahrung: {{ search.Data.MinYearsOfExperience }} - {{ search.Data.MaxYearsOfExperience }} Jahre</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div ngbDropdown (click)="$event.preventDefault(); $event.stopPropagation();">
                      <div class="me-n2" ngbDropdownToggle>
                        <i class="fa-sharp fa-solid fa-ellipsis-vertical me-2 ms-2"></i>
                      </div>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem (click)="copySearch(search)"><i class="fa-sharp fa-regular fa-copy me-2"></i> Kopieren</a>
                        <a ngbDropdownItem (click)="deleteSearch(search)"><i class="fa-sharp fa-regular fa-trash me-2"></i> Löschen</a>
                        <a ngbDropdownItem [disabled]="isImporting" (click)="importModal.show(confirmPage); searchToImport = search;"><i class="fa-sharp fa-regular fa-file-import me-2"></i> Import</a>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="row justify-content-end mt-2">
                  <div class="col-auto">
                    <div>
                      <span class="text-muted small fw-normal">{{ search.Data.Total }}</span>
                    </div>
                  </div>
                </div>

              </li>
            </ul>

          </div>
        </div>
      </div>
      <div class="col-md-8">
        <app-project-screening-profile *ngFor="let result of results" [profile]="result"></app-project-screening-profile>
      </div>
    </div>
  </div>
</div>

<sc-modal-wizard size="xl" #searchModal>

  <sc-modal-header>
    Neue Suche
  </sc-modal-header>
  <sc-modal-wizard-page #startPage>

    <sc-modal-body>

      <div class="card-body card-body-list" style="height: 70vh;">
        <div class="row">
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item fw-normal">
                <div class="row mb-2 align-items-center">
                  <div class="col">
                    <h6 class="mb-0">Skills</h6>
                  </div>
                  <!-- <div class="col-auto">
                <button class="btn btn-secondary btn-sm btn-circle" (click)="searchModal.navigateTo(addSkillPage)"><i class="fa-sharp fa-regular fa-pen-to-square"></i></button>
              </div> -->
                </div>
                <div class="row align-items-center">
                  <div class="col-12">
                    <div class="tag-list mb-2" *ngIf="newSearch.Data.Skills?.length">
                      <div class="tag me-2 mb-2" *ngFor="let s of newSearch.Data.Skills">
                        {{ s }}
                        <i (click)="remove(s, newSearch.Data.Skills)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <input class="form-control" #skillAddInput (scOnEnter)="add(skillAddInput.value, newSearch.Data.Skills); skillAddInput.value = '';" />
                  </div>
                </div>
              </li>
              <li class="list-group-item fw-normal">
                <div class="row mb-2 align-items-center">
                  <div class="col">
                    <h6 class="mb-0">Nice-To-Have-Skills</h6>
                  </div>
                  <!-- <div class="col-auto">
                <button class="btn btn-secondary btn-sm btn-circle" (click)="searchModal.navigateTo(addNiceToHaveSkillPage)"><i class="fa-sharp fa-regular fa-pen-to-square"></i></button>
              </div> -->
                </div>
                <div class="row align-items-center">
                  <div class="col-12">
                    <div class="tag-list mb-2" *ngIf="newSearch.Data.NiceToHaveSkills?.length">
                      <div class="tag me-2 mb-2" *ngFor="let s of newSearch.Data.NiceToHaveSkills">
                        {{ s }}
                        <i (click)="remove(s, newSearch.Data.NiceToHaveSkills)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <input class="form-control" #nthSkillAddInput (scOnEnter)="add(nthSkillAddInput.value, newSearch.Data.NiceToHaveSkills); nthSkillAddInput.value = '';" />
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <h6 class="mb-2">Aktuelle Branche</h6>
                <div class="row">
                  <div class="col-12">
                    <input class="form-control" [editable]="false" [resultFormatter]="industryIdToIdWithName" [inputFormatter]="industryIdToName" [ngbTypeahead]="searchIndustries" [(ngModel)]="newSearch.Data.CurrentIndustry" />
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <h6 class="mb-2">Branchen</h6>
                <div class="row">
                  <div class="col-12">
                    <div class="tag-list">
                      <div class="tag me-2 mb-2" *ngFor="let s of newSearch.Data.Industries">
                        {{ s | industryName }}
                        <span *ngIf="s.Type == 'NiceToHave'" class="ms-2 text-muted">(optional)</span>
                        <span *ngIf="s.Type == 'Alternative'" class="ms-2 text-muted">(alt. zu {{ s.AlternativeTo | industryName  }})</span>
                        <i (click)="remove(s, newSearch.Data.Industries)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <input class="form-control" [editable]="false" [resultFormatter]="industryIdToIdWithName" [inputFormatter]="industryIdToName" [ngbTypeahead]="searchIndustries" (selectItem)="add($event.item, newSearch.Data.Industries)" />
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <h6 class="mb-2">Orte</h6>
                <app-location-list-editor [locations]="newSearch.Data.Locations">
                </app-location-list-editor>
              </li>
              <li class="list-group-item">
                <h6 class="mb-2">Radius</h6>
                <input class="form-control" [(ngModel)]="newSearch.Data.Radius">
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row mb-2 align-items-center">
                  <div class="col">
                    <h6 class="mb-0">Berufserfahrung</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label class="fw-normal">Von</label>
                    <select class="custom-select" [(ngModel)]="newSearch.Data.MinYearsOfExperience">
                      <option *ngFor="let i of [1,2,3,5,7,10,15,20,30]" [ngValue]="i">{{i}} Jahre</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <label class="fw-normal">Bis</label>
                    <select class="custom-select" [(ngModel)]="newSearch.Data.MaxYearsOfExperience">
                      <option *ngFor="let i of [1,2,3,5,7,10,15,20,30]" [ngValue]="i">{{i}} Jahre</option>
                    </select>
                  </div>
                </div>

              </li>
              <li class="list-group-item">
                <h6 class="mb-2">Abschlüsse</h6>

                <div>
                  <sc-checkbox label="Hochschulabschluss" [hasNullState]="true"></sc-checkbox>
                  <sc-checkbox label="Bachelor" [hasNullState]="true"></sc-checkbox>
                  <sc-checkbox label="Master" [hasNullState]="true"></sc-checkbox>
                  <sc-checkbox label="Ausbildung" [hasNullState]="true"></sc-checkbox>
                </div>
              </li>
              <li class="list-group-item">
                <h6 class="mb-2">Sprachen</h6>

                <div class="row">
                  <div class="col-12">
                    <label class="fw-normal">Deutsch</label>
                    <select class="custom-select" [(ngModel)]="newSearch.Data.GermanLevel">
                      <option [ngValue]="null">keine Angabe</option>
                      <option value="basic">Grundkenntnisse</option>
                      <option value="good">Gut</option>
                      <option value="fluent">Fließend</option>
                    </select>
                  </div>
                  <div class="col-12 mt-2">
                    <label class="fw-normal">Englisch</label>
                    <select class="custom-select" [(ngModel)]="newSearch.Data.EnglishLevel">
                      <option [ngValue]="null">keine Angabe</option>
                      <option value="basic">Grundkenntnisse</option>
                      <option value="good">Gut</option>
                      <option value="fluent">Fließend</option>
                    </select>
                  </div>
                </div>

              </li>
              <li class="list-group-item">
                <h6 class="mb-2">Level</h6>
                <div *ngIf="newSearch.Data.Levels">
                  <sc-checkbox label="Absolvent" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Graduate"></sc-checkbox>
                  <sc-checkbox label="Junior" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Junior"></sc-checkbox>
                  <sc-checkbox label="Professional" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Professional"></sc-checkbox>
                  <sc-checkbox label="Senior" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Senior"></sc-checkbox>
				  <sc-checkbox label="Lead" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Lead"></sc-checkbox>
				  <sc-checkbox label="Executive" [hasNullState]="true" [(isChecked)]="newSearch.Data.Levels.Executive"></sc-checkbox>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark btn-round" (click)="createSearch()">Suche ausführen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #addSkillPage>
    <sc-modal-body>
      <input #addSkillName class="form-control" />
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark btn-round" (click)="addSkill(addSkillName.value); searchModal.navigateTo(startPage)">Hinzufügen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #addNiceToHaveSkillPage>
    <sc-modal-body>
      <input #addNiceToHaveSkillName class="form-control" />
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark btn-round" (click)="addNiceToHaveSkill(addNiceToHaveSkillName.value); searchModal.navigateTo(startPage)">Hinzufügen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>
</sc-modal-wizard>

<sc-modal-wizard #importModal>
  <sc-modal-header>
    Ergebnisse als Batch importieren
  </sc-modal-header>

  <sc-modal-wizard-page #confirmPage>
    <sc-modal-body>
      <p>
        Sollen die Suchergebnisse als neue Batch importiert werden?
      </p>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-round btn-dark" [class.loading]="isImporting" (click)="import()">Ok</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #successPage>
    <sc-modal-body>
		<div class="alert alert-success">
			<h5 class="alert-heading">Import erfolgreich</h5>
			<p>Die Suchergebnisse wurden als neue Batch hinzugefügt.</p>
		  </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-round btn-dark" (click)="importModal.dismiss()">Ok</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #errorPage>
    <sc-modal-body>
		<div class="alert alert-error">
			<h5 class="alert-heading">Fehler</h5>
			<p>{{ importError }}</p>
		  </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-round btn-dark" (click)="importModal.dismiss()">Ok</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

</sc-modal-wizard>


<sc-modal-wizard size="xl" #requirementsModal>
  <sc-modal-header>
    Anforderungen
  </sc-modal-header>
  <sc-modal-wizard-page>
    <sc-modal-body style="height: 70vh;">

      <ul class="list-group">
        <li class="list-group-item" *ngFor="let r of requirements">
          <div>
            <div class="row">
              <!-- <div class="col-0">
                Skill
              </div> -->
              <div class="col-12">
                <div class="tag-list mb-2" *ngIf="newSearch.Data.Skills?.length">
                  <div class="tag me-2 mb-2" *ngFor="let s of newSearch.Data.Skills">
                    {{ s }}
                    <i (click)="remove(s, newSearch.Data.Skills)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fa-sharp fa-solid fa-arrow-turn-down-right"></i>
              </div>
              <div class="col">
                <div class="tag-list mb-2" *ngIf="newSearch.Data.Skills?.length">
                  <div class="tag me-2 mb-2" *ngFor="let s of newSearch.Data.Skills">
                    {{ s }} <span class="small ms-2">+500</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        
      </ul>

      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-round btn-secondary me-2 mb-2" (click)="requirementsModal.navigateTo(addSkillRequirement)">Skill</button>
          <button class="btn btn-round btn-secondary me-2 mb-2" (click)="addRequirement()">Berufserfahrung</button>
          <button class="btn btn-round btn-secondary me-2 mb-2" (click)="addRequirement()">Branche</button>
          <button class="btn btn-round btn-secondary me-2 mb-2" (click)="addRequirement()">Ort</button>
        </div>
      </div>
    </sc-modal-body>
    <!-- <sc-modal-footer>
      <button class="btn btn-secondary btn-round" (click)="requirementsModal.navigateTo()">Neue Anforderung</button>
    </sc-modal-footer> -->
  </sc-modal-wizard-page>

  <sc-modal-wizard-page>
    <sc-modal-body style="height: 70vh;">

      <ul class="list-group">
        <li class="list-group-item list-group-item-action" (click)="requirementsModal.navigateTo(addSkillRequirement)">
          Skill
        </li>
        <li class="list-group-item list-group-item-action">
          Branche
        </li>
        <li class="list-group-item list-group-item-action">
          Berufserfahrung
        </li>
        <li class="list-group-item list-group-item-action">
          Abschluss
        </li>
        <li class="list-group-item list-group-item-action">
          Level
        </li>
      </ul>

    </sc-modal-body>

    <sc-modal-footer>
      <button class="btn btn-secondary btn-round">Neues Kriterium</button>
    </sc-modal-footer>

  </sc-modal-wizard-page>

  <sc-modal-wizard-page #addSkillRequirement>
    <sc-modal-body style="height: 70vh;">
      <input #addSkillInput class="form-control form-control-block" placeholder="Skill eingeben" (scOnEnter)="addSkill(addSkillInput.value)" />
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-secondary btn-round">Zurück</button>
      <button class="btn btn-dark btn-round" (click)="addSkillRequirement()">Hinzufügen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

</sc-modal-wizard>