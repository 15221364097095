import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-card-packages',
  templateUrl: './company-card-packages.component.html',
  styleUrls: ['./company-card-packages.component.scss']
})
export class CompanyCardPackagesComponent implements OnChanges {

  @Input()
  company : any;

  packages : any = [];

  billingPackages : any = [];

  newPackage : any = {}

  constructor(private api : ScApiService, private router : Router) { }

  ngOnChanges(): void {

    this.newPackage.CompanyId = this.company.Id;
    this.newPackage.BillingCompanyId = this.company.Id;

    this.api.get('packages?organizationId=' + this.company.Id).subscribe(d => {
      this.packages = d;      
    });

    this.api.get('packages?billingOrganizationId=' + this.company.Id).subscribe(d => {
      this.billingPackages = d;      
    });
  }

  addPackage() {
    this.newPackage.CompanyId = this.company.Id;
    this.api.post('packages', this.newPackage).subscribe((d : any) => {
      this.router.navigateByUrl(`/packages/${d.Id}`);
    });
  }

  navigate(p)
  {
    this.router.navigateByUrl(`/packages/${p.Id}`);
  }
}
