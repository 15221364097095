export default [
    {
        "id": 60400,
        "name": "Abfälle und Recycling"
    },
    {
        "id": 10400,
        "name": "Architektur"
    },
    {
        "id": 10000,
        "name": "Architektur und Bauwesen"
    },
    {
        "id": 210300,
        "name": "Arztpraxen"
    },
    {
        "id": 210400,
        "name": "Alternative Medizin"
    },
    {
        "id": 30000,
        "name": "Automobil und Fahrzeugbau"
    },
    {
        "id": 30100,
        "name": "Automobile und Zweiräder"
    },
    {
        "id": 70200,
        "name": "Bahnverkehr"
    },
    {
        "id": 120000,
        "name": "Banken und Finanzdienstleistungen"
    },
    {
        "id": 120200,
        "name": "Bankwesen"
    },
    {
        "id": 10200,
        "name": "Baugewerbe"
    },
    {
        "id": 10100,
        "name": "Bauingenieurwesen"
    },
    {
        "id": 10300,
        "name": "Baustoffe"
    },
    {
        "id": 160000,
        "name": "Beratung und Consulting"
    },
    {
        "id": 230600,
        "name": "Bergbau und Metalle"
    },
    {
        "id": 220600,
        "name": "Bibliotheken"
    },
    {
        "id": 40800,
        "name": "Bio- und Nanotechnologie"
    },
    {
        "id": 40500,
        "name": "Chemie"
    },
    {
        "id": 200500,
        "name": "Coaching und Fortbildung"
    },
    {
        "id": 90500,
        "name": "Computer-Hardware"
    },
    {
        "id": 90400,
        "name": "Computernetzwerke"
    },
    {
        "id": 90800,
        "name": "Computerspiele"
    },
    {
        "id": 220100,
        "name": "Darstellende Kunst"
    },
    {
        "id": 170300,
        "name": "Design und Grafik"
    },
    {
        "id": 40300,
        "name": "Druck"
    },
    {
        "id": 200600,
        "name": "E-Learning"
    },
    {
        "id": 21200,
        "name": "Einzelhandel"
    },
    {
        "id": 41200,
        "name": "Elektrotechnik"
    },
    {
        "id": 60000,
        "name": "Energie, Wasser und Umwelt"
    },
    {
        "id": 60200,
        "name": "Energiewirtschaft"
    },
    {
        "id": 60100,
        "name": "Erneuerbare Energien"
    },
    {
        "id": 200000,
        "name": "Erziehung, Bildung und Wissenschaft"
    },
    {
        "id": 200300,
        "name": "Fach- und Hochschulen"
    },
    {
        "id": 140100,
        "name": "Facility Management"
    },
    {
        "id": 30600,
        "name": "Fahrzeugvermietung"
    },
    {
        "id": 110400,
        "name": "Film und Musik"
    },
    {
        "id": 120100,
        "name": "Finanzdienstleistungen"
    },
    {
        "id": 230400,
        "name": "Fischerei"
    },
    {
        "id": 200100,
        "name": "Forschung"
    },
    {
        "id": 230300,
        "name": "Forstwirtschaft und Jagd"
    },
    {
        "id": 220200,
        "name": "Fotografie"
    },
    {
        "id": 10500,
        "name": "Garten- und Landschaftsbau"
    },
    {
        "id": 80200,
        "name": "Gastronomie"
    },
    {
        "id": 190100,
        "name": "Gemeinnützige Einrichtungen und Vereine"
    },
    {
        "id": 230700,
        "name": "Geologie"
    },
    {
        "id": 210000,
        "name": "Gesundheit und Soziales"
    },
    {
        "id": 20200,
        "name": "Getränke"
    },
    {
        "id": 20900,
        "name": "Glas und Keramik"
    },
    {
        "id": 21100,
        "name": "Großhandel"
    },
    {
        "id": 40200,
        "name": "Halbleiter und elektronische Bauteile"
    },
    {
        "id": 80100,
        "name": "Hotelgewerbe"
    },
    {
        "id": 90200,
        "name": "IT-Dienstleister"
    },
    {
        "id": 90300,
        "name": "IT-Sicherheit"
    },
    {
        "id": 140000,
        "name": "Immobilien"
    },
    {
        "id": 140300,
        "name": "Immobilienvermittlung"
    },
    {
        "id": 140200,
        "name": "Immobilienverwaltung"
    },
    {
        "id": 21000,
        "name": "Import und Export"
    },
    {
        "id": 40000,
        "name": "Industrie und Maschinenbau"
    },
    {
        "id": 110100,
        "name": "Informationsdienste"
    },
    {
        "id": 190600,
        "name": "Internationale Angelegenheiten"
    },
    {
        "id": 90000,
        "name": "Internet und Informationstechnologie"
    },
    {
        "id": 90700,
        "name": "Internet und Onlinemedien"
    },
    {
        "id": 120300,
        "name": "Investmentbanken"
    },
    {
        "id": 110500,
        "name": "Journalismus"
    },
    {
        "id": 200400,
        "name": "Kinderbetreuung"
    },
    {
        "id": 20000,
        "name": "Konsumgüter und Handel"
    },
    {
        "id": 20800,
        "name": "Kosmetik und Körperpflege"
    },
    {
        "id": 210100,
        "name": "Krankenhäuser"
    },
    {
        "id": 220400,
        "name": "Kunst und Kunsthandwerk"
    },
    {
        "id": 220000,
        "name": "Kunst, Kultur und Sport"
    },
    {
        "id": 40900,
        "name": "Kunststoff und Gummiwaren"
    },
    {
        "id": 70500,
        "name": "Lagerhaltung"
    },
    {
        "id": 230200,
        "name": "Landwirtschaft"
    },
    {
        "id": 20100,
        "name": "Lebensmittel"
    },
    {
        "id": 30500,
        "name": "Luft- und Raumfahrzeugbau"
    },
    {
        "id": 70400,
        "name": "Luftverkehr"
    },
    {
        "id": 20500,
        "name": "Luxusgüter und Schmuck"
    },
    {
        "id": 170100,
        "name": "Marketing und Werbung"
    },
    {
        "id": 170000,
        "name": "Marketing, PR und Design"
    },
    {
        "id": 170200,
        "name": "Markt- und Meinungsforschung"
    },
    {
        "id": 41100,
        "name": "Maschinenbau und Betriebstechnik"
    },
    {
        "id": 110000,
        "name": "Medien und Verlage"
    },
    {
        "id": 210600,
        "name": "Medizinische Dienste"
    },
    {
        "id": 50100,
        "name": "Medizintechnik"
    },
    {
        "id": 40100,
        "name": "Mess- und Regeltechnik"
    },
    {
        "id": 170400,
        "name": "Messe, Ausstellungen und Kongresse"
    },
    {
        "id": 40700,
        "name": "Metallindustrie und -verarbeitung"
    },
    {
        "id": 40600,
        "name": "Mineralölverarbeitung"
    },
    {
        "id": 20400,
        "name": "Mode und Textilien"
    },
    {
        "id": 220500,
        "name": "Museen und Kultureinrichtungen"
    },
    {
        "id": 220300,
        "name": "Musik"
    },
    {
        "id": 20600,
        "name": "Möbel und Holzwaren"
    },
    {
        "id": 41300,
        "name": "Optische und fotografische Geräte"
    },
    {
        "id": 180200,
        "name": "Outsourcing und Offshoring"
    },
    {
        "id": 20700,
        "name": "Papierwaren"
    },
    {
        "id": 180000,
        "name": "Personaldienstleistungen"
    },
    {
        "id": 180100,
        "name": "Personaldienstleistungen und -beratung"
    },
    {
        "id": 70100,
        "name": "Personenverkehr"
    },
    {
        "id": 210700,
        "name": "Pflegeberufe"
    },
    {
        "id": 50000,
        "name": "Pharma und Medizintechnik"
    },
    {
        "id": 50200,
        "name": "Pharmazeutische Produkte und Arzneimittel"
    },
    {
        "id": 190400,
        "name": "Politik und Verbände"
    },
    {
        "id": 70600,
        "name": "Post und Spedition"
    },
    {
        "id": 210500,
        "name": "Psychologie und Psychotherapie"
    },
    {
        "id": 150100,
        "name": "Rechtsberatung"
    },
    {
        "id": 80300,
        "name": "Reisebüros und -veranstalter"
    },
    {
        "id": 190200,
        "name": "Religiöse Einrichtungen"
    },
    {
        "id": 120400,
        "name": "Risikokapital und Private Equity"
    },
    {
        "id": 110200,
        "name": "Rundfunk und Fernsehen"
    },
    {
        "id": 41000,
        "name": "Rüstung"
    },
    {
        "id": 30300,
        "name": "Schienenfahrzeugbau"
    },
    {
        "id": 30200,
        "name": "Schiffbau"
    },
    {
        "id": 70300,
        "name": "Schifffahrt"
    },
    {
        "id": 200200,
        "name": "Schulen und Kindergärten"
    },
    {
        "id": 230800,
        "name": "Sicherheit und Ermittlungen"
    },
    {
        "id": 90100,
        "name": "Software"
    },
    {
        "id": 230000,
        "name": "Sonstige Branchen"
    },
    {
        "id": 230500,
        "name": "Sonstige Dienstleistungen"
    },
    {
        "id": 210800,
        "name": "Sozialwesen"
    },
    {
        "id": 230100,
        "name": "Spiel-, Wett- und Lotteriewesen"
    },
    {
        "id": 220800,
        "name": "Sportler, Veranstalter und Verbände"
    },
    {
        "id": 220700,
        "name": "Sportvereine, Sportanlagen und Fitnesszentren"
    },
    {
        "id": 150300,
        "name": "Steuerberatung"
    },
    {
        "id": 20300,
        "name": "Tabakwaren"
    },
    {
        "id": 100000,
        "name": "Telekommunikation"
    },
    {
        "id": 110700,
        "name": "Text und Lektorat"
    },
    {
        "id": 210200,
        "name": "Tiermedizin"
    },
    {
        "id": 80000,
        "name": "Tourismus und Gastronomie"
    },
    {
        "id": 70000,
        "name": "Transport und Logistik"
    },
    {
        "id": 60500,
        "name": "Umweltschutz"
    },
    {
        "id": 90600,
        "name": "Unterhaltungselektronik"
    },
    {
        "id": 40400,
        "name": "Verbundwerkstoffe"
    },
    {
        "id": 80400,
        "name": "Vergnügungsparks und Freizeiteinrichtungen"
    },
    {
        "id": 30400,
        "name": "Verkehrstechnik"
    },
    {
        "id": 110300,
        "name": "Verlagswesen"
    },
    {
        "id": 130000,
        "name": "Versicherungen"
    },
    {
        "id": 190500,
        "name": "Verteidigung, Justiz und Polizei"
    },
    {
        "id": 60300,
        "name": "Wasserversorgung und -entsorgung"
    },
    {
        "id": 150200,
        "name": "Wirtschaftsprüfung"
    },
    {
        "id": 150000,
        "name": "Wirtschaftsprüfung, Steuern und Recht"
    },
    {
        "id": 190300,
        "name": "Öffentliche Verwaltung"
    },
    {
        "id": 190000,
        "name": "Öffentlicher Dienst, Verbände und Einrichtungen"
    },
    {
        "id": 170500,
        "name": "Öffentlichkeitsarbeit und Kommunikation"
    },
    {
        "id": 110600,
        "name": "Übersetzen und Dolmetschen"
    }
]