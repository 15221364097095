<div class="card task-list box-shadow-none {{ showTaskDetail ? 'hide' : 'show' }}">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-title mb-0">Aufgaben</h6>
      </div>
      <div class="col-auto" (click)="selectManager();">
        <sc-avatar *ngIf="selectedManager" [user]="selectedManager"></sc-avatar>
        <div *ngIf="!selectedManager" class="avatar avatar-sm">
          <span class="avatar-icon">
            <i class="fa-regular fa-users"></i>
          </span>
        </div>
      </div>
      <div class="col-auto clickable" *ngIf="!notifications?.isPinned" (click)="notifications.pin()">
        <i class="fa-regular fa-thumbtack"></i>
      </div>
      <div class="col-auto clickable" *ngIf="notifications?.isPinned" (click)="notifications.unpin()">
        <i class="fa-solid fa-thumbtack"></i>
      </div>
    </div>
  </div>
  <div class="card-body scrollbar-minimal" style="overflow-x: hidden;">
    <app-task-group (taskGroupSelected)="selectTask($event, group)" [group]="group" *ngFor="let group of notifications?.tasks?.groups">
    </app-task-group>
  </div>
</div>

<div class="card task-detail box-shadow-none {{ showTaskDetail ? 'show' : 'hide' }}">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col clickable" (click)="showTaskDetail = false">
        <h6 class="card-title mb-0">
          <i class="fa-regular fa-chevron-left fa-sm me-2"></i> Alle
        </h6>
      </div>
      <div class="col-auto clickable" *ngIf="!notifications?.isPinned" (click)="notifications.pin()">
        <i class="fa-regular fa-thumbtack"></i>
      </div>
      <div class="col-auto clickable" *ngIf="notifications?.isPinned" (click)="notifications.unpin()">
        <i class="fa-solid fa-thumbtack"></i>
      </div>
    </div>
  </div>

  <div class="card-header group-header" *ngIf="selectedGroup">
    <div class="row" *ngIf="selectedTask.Project">
      <div class="col">
        <span class="h6 mb-0">{{ selectedTask.Project.Name }}</span> <small class="ms-2">m|w|d</small>
      </div>
      <div class="col-auto" routerLink="/projects/{{ selectedTask.Project.Id }}">
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
      </div>
    </div>
    <h6 *ngIf="!selectedTask.Project" class="oneline mb-0">
      {{ selectedGroup.name }}
    </h6>
  </div>
  <div class="card-body scrollbar-minimal" *ngIf="selectedTask">
    <app-task-detail [task]="selectedTask" (done)="done()">
    </app-task-detail>
  </div>
  <div class="card-footer">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="clickable" (click)="selectPreviousTask()">
          <i class="fa-regular fa-chevron-left"></i>
        </div>
      </div>
      <div class="col-auto">
        <span>
          {{ selectedTaskIndex + 1 }} / {{ selectedSubGroup?.items.length }}
        </span>
      </div>
      <div class="col-auto">
        <div class="clickable" (click)="selectNextTask()">
          <i class="fa-regular fa-chevron-right"></i>
        </div>
      </div>

      <div class="col"></div>

      <div class="col-auto">
        <button class="btn btn-sm btn-dark" (click)="done()">Erledigt</button>
      </div>
    </div>

  </div>
</div>

<app-modal-select-manager #userModal></app-modal-select-manager>
