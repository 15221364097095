import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-detail-custom',
  templateUrl: './task-detail-custom.component.html',
  styleUrls: ['./task-detail-custom.component.scss']
})
export class TaskDetailCustomComponent {
  @Input()
  task : any;
}
