import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss']
})
export class FilterBadgeComponent implements OnInit {

  @Input()
  filterValue : any = null;

  @Output()
  filterValueChange = new EventEmitter<any>();

  constructor() { }

  emitChange()
  {
    this.filterValueChange.emit(this.filterValue);
  }

  ngOnInit() {
  }

}
