<div class="card">
	<div class="card-header">
	  <h6 class="card-header-title">
		Projekte
	  </h6>
	</div>

	<div class="card-body card-body-list" *ngIf="package?.Projects?.length">
	  <app-list-projects [projects]="package?.Projects"></app-list-projects>
	</div>
  </div>