<div *ngIf="candidate">

  <div class="row">
    <div class="col-auto">
      <a class="btn btn-sm btn-secondary btn-circle me-2" routerLink="/sidebar/candidate-new">
        <fa class="fa-regular fa-chevron-left"></fa>
      </a>
    </div>
    <div class="col"></div>
    <div class="col-auto links">
      <a class="btn btn-sm btn-dark btn-circle me-2" href="https://backend.skillconomy.com/candidates/{{ candidate.Guid }}" target="_blank">
        <fa class="fa-regular fa-arrow-up-right"></fa>
      </a>
      <a *ngIf="candidate.XingId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.xing.com/xtm/profiles/{{candidate.XingId}}" target="_blank">
        <fa class="fa-brands fa-xing"></fa>
      </a>
      <a *ngIf="candidate.LinkedInId" class="btn btn-sm btn-dark btn-circle" href="https://www.linkedin.com/talent/profile/{{candidate.LinkedInId}}" target="_blank">
        <fa class="fa-brands fa-linkedin-in"></fa>
      </a>
    </div>
  </div>

  <div class="row mb-6 mt-5">
    <div class="col-12">
      <app-sidebar-candidate-header [candidate]="candidate"></app-sidebar-candidate-header>
    </div>
  </div>

  <div class="row mb-2" *ngIf="blacklistInfo?.Pass == false">
    <div class="col-12">
      <div class="alert alert-error">
        <strong>Auf Blacklist</strong><br />
        <span *ngFor="let message of blacklistInfo.Messages">
          {{ message }} <br>
        </span>
      </div>
    </div>
  </div>

  <div class="row mb-4" *ngIf="assignments?.length">
    <div class="col-12" *ngFor="let a of assignments | slice:0:visibleProjectItemCount">
      <app-candidate-base-project-item [assignment]="a"></app-candidate-base-project-item>
    </div>

    <div class="col-12 mt-3 text-align-center" *ngIf="assignments.length > visibleProjectItemCount">
      <div class="d-flex justify-content-center">
        <span class="text-primary mx-auto clickable" (click)="visibleProjectItemCount = assignments.length">{{ assignments.length - visibleProjectItemCount }} weitere <fa class="fa-solid fa-chevron-down"></fa></span>
      </div>
    </div>

    <div class="col-12 mt-3" *ngIf="visibleProjectItemCount > 3">
      <div class="d-flex justify-content-center">
        <span class="text-primary mx-auto clickable" (click)="visibleProjectItemCount = 3">Weniger anzeigen <fa class="fa-solid fa-chevron-up"></fa></span>
      </div>
    </div>
  </div>

  <div class="row mb-2">

    <div class="col-12">
      <ul class="list-unstyled">
        <li>
          <app-sidebar-candidate-exact-item icon="fa-regular fa-merge" (click)="join()">Verknüpfen</app-sidebar-candidate-exact-item>
        </li>
      </ul>
    </div>
  </div>

</div>