import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScUploadService } from '@sc-ui';

@Component({
  selector: 'app-candidate-cv',
  templateUrl: './candidate-cv.component.html',
  styleUrls: ['./candidate-cv.component.scss']
})
export class CandidateCvComponent implements OnInit {

  @Input()
  candidate: any;

  @Output()
  candidateChange = new EventEmitter<any>();

  selectedExperience: any;

  selectedEducation: any;

  selectedQualification: any;

  isUploading = false;

  constructor(private uploadApi: ScUploadService) { }

  ngOnInit(): void {
  }

  onChange() {
    this.candidateChange.next(this.candidate);
  }

  addExperience() {
    var e = {
      Start: new Date().toISOString(),
      End: new Date().toISOString(),
      CompanyName: 'Unternehmen',
      Title: 'Bezeichnung'
    };

    if (!this.candidate.Profile.Experience) this.candidate.Profile.Experience = [];
    this.candidate.Profile.Experience.push(e);
    this.onChange();
  }

  setToPresent(e)
  {
    e.ToPresent = !e.End;
  }

  removeExperience(e) {
    var index = this.candidate.Profile.Experience.indexOf(e);
    if (index < 0) return;
    this.candidate.Profile.Experience.splice(index, 1);
    this.onChange();
  }

  addEducation() {
    var e = {
      Start: new Date().toISOString(),
      End: new Date().toISOString(),
      Institute: 'Institut',
      Title: 'Bezeichnung'
    };

    if (!this.candidate.Profile.Education) this.candidate.Profile.Education = [];
    this.candidate.Profile.Education.push(e);
    this.onChange();
  }

  removeEducation(e) {
    var index = this.candidate.Profile.Education.indexOf(e);
    if (index < 0) return;
    this.candidate.Profile.Education.splice(index, 1);
    this.onChange();
  }

  addQualification() {
    var e = {
      Date: new Date().toISOString(),
      Institute: 'Institut',
      Title: 'Bezeichnung'
    };

    if (!this.candidate.Profile.Qualifications) this.candidate.Profile.Qualifications = [];
    this.candidate.Profile.Qualifications.push(e);
    this.onChange();
  }

  removeQualification(e) {
    var index = this.candidate.Profile.Qualifications.indexOf(e);
    if (index < 0) return;
    this.candidate.Profile.Qualifications.splice(index, 1);
    this.onChange();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.onChange();
  }

  uploadCv(files) {

    if (files.length < 1) return;
    var file = files[0];

    this.uploadApi.upload([file],
      (response: any) => {
        this.candidate.CvFile = response.Uri;
        this.onChange();
      });
  }

  deleteCv() {
    this.candidate.CvFile = null;
    this.onChange();
    
  }

  downloadCv()
  {
    var uri = this.candidate.CvFile;
    window.open(uri, '_blank').focus();
  }

}
