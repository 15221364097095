<div class="row">
    <div class="col-auto pr-0">
        <div class="admin-manager-container">
            <sc-avatar-list (click)="modal.show(); modal.navigateTo(usersPage, 'none');" [users]="poolUsers"></sc-avatar-list>
        </div>
    </div>

    <div class="col-auto user-menu pl-2 pr-0">

        <button class="btn btn-secondary btn-sm" (click)="modal.show(); modal.navigateTo(addUserPage, 'none');">
            <i class="fa-solid fa-user-plus me-2"></i> Einladen
        </button>

    </div>
</div>

<app-modal-manage-users [users]="poolUsers" [results]="users"></app-modal-manage-users>

<sc-modal-wizard #modal>
    <div class="modal-header">
        <h6 class="modal-title mb-0">
            <i class="fa-solid fa-users me-3"></i>
            Teilnehmer
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-wizard-body">

        <sc-modal-wizard-page #usersPage>
            <div class="modal-body">
                <sc-user-list [users]="poolUsers" (removeUser)="removeUser($event);"></sc-user-list>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="modal.navigateTo(addUserPage)"><i class="fa-solid fa-user-plus me-2"></i> Teilnehmer einladen</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page [definesHeight]="true" #addUserPage>
            <div class="modal-body" style="min-height: 30vh;">
                <div class="row">
                    <div class="col-12">
                        <input class="form-control form-control-search mb-4" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Name oder E-Mail" />
                    </div>
                    <div class="col-12 user-list-container">
                        <button *ngIf="showAddEMail" (click)="addByEMail()" [disabled]="isAddingByEMail" class="btn btn-block btn-primary">
                            <i class="fa-solid fa-user-plus me-2" *ngIf="!isAddingByEMail"></i> 
                            <span *ngIf="isAddingByEMail" class="spinner-border spinner-border-sm"></span>
                            Neuen Teilnehmer einladen
                        </button>

                        <p *ngIf="!isSearching && !users?.length && !showAddEMail" class="text-muted">
                            Es wurde kein Benutzer gefunden. Bitte versuchen Sie es erneut oder geben Sie eine gültige E-Mail-Adresse an, um einen neuen Teilnehmer einzuladen.
                        </p>

                        <sc-user-list [users]="users" (removeUser)="removeUser($event);" (addUser)="addUser($event);"></sc-user-list>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" (click)="modal.navigateTo(usersPage)">Zur Übersicht</button>
            </div>
        </sc-modal-wizard-page>

    </div>
</sc-modal-wizard>