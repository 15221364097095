import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScUploadService } from '@sc-ui';
import * as _ from 'lodash';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-jobsite',
  templateUrl: './project-jobsite.component.html',
  styleUrls: ['./project-jobsite.component.scss']
})
export class ProjectJobsiteComponent implements OnInit {

  id: any;

  project: any;

  blacklist : any;

  bannerError = false;
  
  copyFromInfo: any = {
    banner: false,
    salary: false,
    benefits: false,
    properties: {}
  };

  constructor(
    private api: ScApiService,
    private uploadApi : ScUploadService,
    private route: ActivatedRoute,
    private projectService: ProjectService) {
  }

  ngOnInit(): void {

    this.api.get('blacklist').subscribe(d => {
      this.blacklist = d;
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
      
      var cached = this.projectService.getFromCache(this.id);
      if (cached)
      {
        this.project = cached;
      }

      this.projectService.load(this.id).subscribe(p => {
        this.project = p;
      });
    });
  }
  
  completeJobsite()
  {
    this.api.post('jobs/' + this.project.Id + '/actions/jobsite-ready', null).subscribe();
  }

  confirmJobsite()
  {
    this.api.post('jobs/' + this.project.Id + '/actions/jobsite-confirmed', null).subscribe();
  }

  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  save() {
    this.projectService.save(this.project).subscribe();
  }

  executeCopyFromProject() {
    this.api.get('jobs/' + this.copyFromInfo.ProjectId).subscribe((p: any) => {

      if (this.copyFromInfo.salary)
      {
        this.project.Profile.Compensation.MinSalary = p.Profile.Compensation.MinSalary;
        this.project.Profile.Compensation.MaxSalary = p.Profile.Compensation.MaxSalary;
        this.project.Profile.Compensation.SalaryCurrency = p.Profile.Compensation.SalaryCurrency;
      }

      if (this.copyFromInfo.benefits)
      {
        this.project.Profile.Compensation.Benefits = p.Profile.Compensation.Benefits;
      }

      if (this.copyFromInfo.banner)
      {
        this.project.Profile.BannerUri = p.Profile.BannerUri;
        this.project.Profile.Banner = p.Profile.Banner;
        this.project.Image = p.Image;
      }

      for (let [key, value] of Object.entries(this.copyFromInfo.properties)) {
        if (!value) continue;
        var projectValue = p.Profile[key];
        this.project.Profile[key] = projectValue;
      }

      this.save();

      this.copyFromInfo.properties = [];
      this.copyFromInfo.confirm = null;

    });
  }

  saveCompany() {
    var company = this.project.Company;
    this.api.put("companies/" + company.Id, company).subscribe(d => { });
  }

  saveGlobalBlacklist() {
    this.api.put('blacklist', this.blacklist).subscribe();
  }

  uploadSharingImage(files) {

    this.uploadApi.upload(files, (d: any) => {
      this.project.Image = d.Uri;
      this.project.Profile.BannerUri = d.Uri;
      this.save();
    });
  }

  addWorkplaceImpression() {

    var impression = {
      Title: 'Headline',
      Description: 'An impression of your future place to work. The kind of thing you love about a workplace.',
      Image: 'https://picsum.photos/640/360?q=' + (new Date()).toISOString()
    };

    this.project.Profile.Workplace.Impressions.push(impression);
    this.save();
  }

  uploadWorkplaceImpressionImage(impression, files) {
    this.uploadApi.upload(files, (d: any) => {
      impression.ImageUri = d.Uri;
      impression.Image = d.Uri;
      this.save();
    });
  }

  addProjectExample() {
    var newExample = {
      Title: 'Headline',
      Description: 'Ein beispielhaftes Projekt',
      Image: 'https://picsum.photos/640/360?q=' + (new Date()).toISOString()
    };

    if (!this.project.Profile.ProjectExamples) this.project.Profile.ProjectExamples = [];

    this.project.Profile.ProjectExamples.push(newExample);
    this.save();
  }

  uploadProjectExampleImage(example, files) {
    this.uploadApi.upload(files, (d: any) => {
      example.ImageUri = d.Uri;
      example.Image = d.Uri;
      this.save();
    });
  }

  removeProjectExample(p) {
    var index = this.project.Profile.ProjectExamples.indexOf(p);
    this.project.Profile.ProjectExamples.splice(index, 1);
    this.save();
  }  

  removeWorkplaceImpression(impression) {
    var index = this.project.Profile.Workplace.Impressions.indexOf(impression);
    this.project.Profile.Workplace.Impressions.splice(index, 1);
    this.save();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.save();
  }

  getImageIndex()
  {
    return this.project.Id % 3 + 1; 
  }

}

