import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-briefing-completed',
  templateUrl: './activity-item-project-briefing-completed.component.html',
  styleUrls: ['./activity-item-project-briefing-completed.component.scss']
})
export class ActivityItemProjectBriefingCompletedComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
