import { Component, Input, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-candidate-sales-projects',
  templateUrl: './candidate-sales-projects.component.html',
  styleUrls: ['./candidate-sales-projects.component.scss']
})
export class SalesLeadProjectsComponent implements OnChanges {

  @Input()
  organizationId: any;

  projects : any;

  hasMoreProjects = false;

  constructor(private api: ScApiService) { }

  ngOnChanges() {
    if (!this.organizationId) return;

    var uri = 'jobs?organizationId=' + this.organizationId
      + '&status=AwaitingAnalysis,AwaitingQuote,AwaitingOrder'
      + '&sort=id'
      + '&includeCancelled=false'
      + '&take=3';

    this.api.get(uri).subscribe((d : any) => {
      this.projects = d.Items;
      this.hasMoreProjects = d.Total > d.Items?.length;
    });
  }

  openOrganizationProjects()
  {
    var uri = `/companies/${this.organizationId}/base?view=projects`;
    window.open(uri, "_blank");
  }

}
