import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-project-sourcing',
  templateUrl: './nav-project-sourcing.component.html',
  styleUrls: ['./nav-project-sourcing.component.scss']
})
export class NavProjectSourcingComponent implements OnInit {

  @Input()
  project : any;

  @Input()
  active = "";
  
  constructor() { 
  }

  ngOnInit(): void {
  }

}
