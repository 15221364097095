<div class="row">
  <div class="col-4" *ngFor="let id of benefitIds">
    <div class="row mb-2">
      <div class="col text-truncate">
        <app-benefit [id]="id"></app-benefit>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <button class="btn btn-round btn-secondary" (click)="editModal.show()">
      Bearbeiten
    </button>
  </div>
</div>

<sc-modal #editModal>

  <sc-modal-header>
    Benefits bearbeiten
  </sc-modal-header>

  <sc-modal-body>
    <div class="row">
      <div class="col-12 mb-3">
        <input class="form-control" placeholder="Benefits suchen" [(ngModel)]="q" (ngModelChange)="filterBenefits()">
      </div>
      <div class="col-12 scrollbar-minimal" style="height: 50vh; overflow-y: auto;">
        <ul class="list-group">
          <li *ngFor="let benefit of filteredBenefits" class="list-group-item">

            <div class="row w-100">
              <div class="col">
                <app-benefit [id]="benefit.id"></app-benefit>
              </div>
              <div class="col-auto">
                <sc-switch [ngModel]="selectedStates[benefit.id]" (ngModelChange)="setSelected($event, benefit)"></sc-switch>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>
  </sc-modal-body>

</sc-modal>
