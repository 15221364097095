<ng-template [appTopbarTemplate]>
    <div class="title">
      <h4 class="mb-0">Kontingente</h4>
    </div>
    <div class="actions">
      <a class="btn btn-sm btn-secondary btn-circle">
        <i class="fa-solid fa-plus"></i>
      </a>
    </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body card-body-list">
            <app-list-packages [packages]="packages" [navigateOnClick]="true"></app-list-packages>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
