import { Component, Input, OnChanges } from '@angular/core';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-company-tasks',
  templateUrl: './company-tasks.component.html',
  styleUrls: ['./company-tasks.component.scss']
})
export class CompanyTasksComponent implements OnChanges {

  @Input()
  company : any;

  tasks : any = [];

  constructor(public notifications: NotificationsService) { }

  ngOnChanges() {
      this.tasks = this.notifications.tasks.getTasksOfOrganization(this.company.Id);
  }
}
