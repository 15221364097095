import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';
import { Observable } from 'rxjs';

export class ModalActionArgs
{
  success(params:string) {}
  error(params:string) {}
}

@Component({
  selector: 'app-modal-action',
  templateUrl: './modal-action.component.html',
  styleUrls: ['./modal-action.component.scss']
})
export class ModalActionComponent implements OnInit {

  @ViewChild(ModalWizardComponent)
  modal: ModalWizardComponent;

  @ViewChild('confirmation')
  confirmation: ModalWizardPageComponent;

  @ViewChild('error')
  error: ModalWizardPageComponent;

  @Input()
  confirmBtnClass = "btn-success";

  @Output()
  action = new EventEmitter<ModalActionArgs>();

  successMessage: any;

  errorMessage: any;

  isExecuting = false;

  ngOnInit(): void {
  }

  execute() {
    if (this.isExecuting) return;
    this.isExecuting = true;

    var args : ModalActionArgs = {
      success: (message) => {
        this.modal.navigateTo(this.confirmation);
        this.isExecuting = false;
        this.successMessage = message;
      },
      error: (message) => {
        this.modal.navigateTo(this.error);
        this.isExecuting = false;
        this.errorMessage = message;
      }
    }

    this.action.next(args);
  }

  show() {
    this.modal.show().subscribe();
  }
}
