import { Component, Input } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-package-invoices',
  templateUrl: './package-invoices.component.html',
  styleUrls: ['./package-invoices.component.scss']
})
export class PackageInvoicesComponent {

  @Input()
  package : any;

  invoices : any = [];

  constructor(private api : ScApiService) { }

  ngOnChanges()
  {
    this.api.get(`packages/${this.package.Id}/invoices`).subscribe((d: any) => {
      this.invoices = d;         
    });
  }
}
