<sc-modal #modal>
	<sc-modal-header>
	  Neue Aufgabe
	</sc-modal-header>
	<sc-modal-body>
	  <sc-form form="default" *ngIf="task">
		<sc-form-row label="Beschreibung" *ngIf="task.Payload">
		  <autosize-textarea [(text)]="task.Payload.Description"></autosize-textarea>
		</sc-form-row>
  
		<sc-form-row label="Fälligkeit">
		  <div class="row">
			<div class="col-12">
			  <sc-date-picker [(date)]="task.Due"></sc-date-picker>
			</div>
			<div class="col-12 mt-1">
			  <button class="btn btn-sm btn-secondary me-1" (click)="setDue(task, 2)">3M</button>
			  <button class="btn btn-sm btn-secondary me-1" (click)="setDue(task, 6)">6M</button>
			  <button class="btn btn-sm btn-secondary me-1" (click)="setDue(task, 12)">12M</button>
			  <button class="btn btn-sm btn-secondary me-1" (click)="setDue(task, 24)">24M</button>
			</div>
		  </div>
  
		</sc-form-row>
	  </sc-form>
	</sc-modal-body>
	<sc-modal-footer>
	  <button class="btn btn-dark" (click)="createTask();">Erstellen</button>
	</sc-modal-footer>
  </sc-modal>
  