import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-candidate-new',
  templateUrl: './sidebar-candidate-new.component.html',
  styleUrls: ['./sidebar-candidate-new.component.scss']
})
export class SidebarCandidateNewComponent implements OnInit {

   
  constructor(public sidebarService: SidebarService, private api: ScApiService, private router: Router) { }

  ngOnInit(): void {
  }

  createCandidate() {
    this.api.post('candidates', this.sidebarService.profile).subscribe((d: any) => {
      this.router.navigateByUrl('/sidebar/candidate/' + d.Guid);
    });
  }

  goToMerge(candidate)
  {
    this.router.navigateByUrl('/sidebar/candidate-merge/' + candidate.Guid);
  }

  

}
