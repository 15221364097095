import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-debriefing',
  templateUrl: './project-debriefing.component.html',
  styleUrls: ['./project-debriefing.component.scss']
})
export class ProjectDebriefingComponent implements OnInit {

  id: any;

  project: any;

  isUpdating = false;

  constructor(
    private api: ScApiService,
    private route: ActivatedRoute,
    private projectService: ProjectService) {
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      
      var cached = this.projectService.getFromCache(this.id);
      if (cached)
      {
        this.project = cached;
      }

      this.projectService.load(this.id).subscribe(p => {
        this.project = p;
      });
    });
  }

  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  save() {
    this.projectService.save(this.project).subscribe();
  }

}
