import { Component, OnInit, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-save-modal-error',
  templateUrl: './project-save-modal-error.component.html',
  styleUrls: ['./project-save-modal-error.component.scss']
})
export class ProjectSaveModalErrorComponent implements OnInit {

  @ViewChild('modal')
  modal: ScuiModalComponent;
  
  constructor(private projectService : ProjectService) { }

  ngOnInit(): void {
    this.projectService.onError.subscribe(() => {
      this.modal.show().subscribe();
    });
  }

}
