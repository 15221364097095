<sc-modal-wizard #modal>

  <sc-modal-wizard-page #start>
    <sc-modal-header>
      Gefundene Unternehmen
    </sc-modal-header>
    <sc-modal-body>

      <div>
        <input class="form-control search mb-2" placeholder="Suche" [(ngModel)]="name" (ngModelChange)="searchSoon()" autocomplete="off" />
      </div>

      <div style="height: 40vh; overflow-y: auto;" class="scrollbar-minimal">
        <p class="p-3" *ngIf="organizations?.length == 0">
          Keine Unternehmen gefunden
        </p>
        <app-list-organizations [organizations]="organizations" (selected)="modal.complete($event);"></app-list-organizations>
      </div>

    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark" (click)="modal.navigateTo(create)">Neues Unternehmen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

  <sc-modal-wizard-page #create>
    <sc-modal-header>
      Unternehmen erstellen
    </sc-modal-header>
    <sc-modal-body>
      <sc-form>
        <sc-form-row label="Name">
          <input [(ngModel)]="name" autocomplete="off" />
        </sc-form-row>
        <sc-form-row label="Domain">
          <input [(ngModel)]="domain" autocomplete="off" />
        </sc-form-row>
      </sc-form>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark" (click)="createOrganization()">Erstellen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>

</sc-modal-wizard>
