<div [scAnchorTarget]="'Projekte'" *ngIf="candidate">

  <div class="card mb-3">
    <div class="card-header">
      <h6 class="card-header-title">
        Screening & Ansprache
      </h6>
    </div>

    <div class="card-body card-body-list" *ngIf="projects?.length">
      <div class="list-group">
        <div class="list-group-item list-group-item-action" (click)="modal.show(project.Assignment)" *ngFor="let project of projects">
          <div class="row align-items-center flex-nowrap">

            <div class="col-auto">
              <sc-avatar class="d-inline-block" [user]="project.Manager" size="sm"></sc-avatar>
            </div>

            <div class="col">

              <h6 class="mb-1 oneline w-100">
                {{project.Name}}
              </h6>

              <p class="card-text text-muted small mb-0 oneline">
                {{ project.Id }} <span *ngIf="project.Company">- {{project.Company?.Name }}</span>
              </p>

            </div>

            <div class="col-auto">
              <app-status-badge-project-person-assignment class="d-block" [assignment]="project.Assignment"></app-status-badge-project-person-assignment>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-project-person-assignment #modal></app-modal-project-person-assignment>
