<sc-activity-item-highlight icon="fa-solid fa-check" [activity]="activity" [context]="context" *ngIf="condensed">
    hat das Projekt bewertet
</sc-activity-item-highlight>

<sc-activity-item-comment [activity]="activity" [context]="context" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat das Projekt bewertet
        </p>
    </div>
    <div class="comment-body">        
        <p>
			<strong>Das Briefing wurde effizient geführt und der Prozess klar erklärt?</strong><br>
			{{ activity.Payload?.Step1 | feedbackRating }} 
		</p>
		<p>
			<strong>Das gesuchte Profil wurde im Briefing verstanden?</strong><br>
			{{ activity.Payload?.Step2 | feedbackRating  }} 
		</p>
		<p>
			<strong>Die Bewerbungen waren aussagekräftig und passend?</strong> <br>
			{{ activity.Payload?.Step3 | feedbackRating }} 
		</p>
		<p>
			<strong>Der Gesamtprozess war insgesamt einfach und transparent</strong> <br>
			{{ activity.Payload?.Step4 | feedbackRating  }} 
		</p>
		<p *ngIf="activity.Payload?.Comment">
			<strong>Nachricht:</strong> <br>
			{{ activity.Payload?.Comment }} 
		</p>
    </div>
</sc-activity-item-comment>