import { Injectable } from '@angular/core';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { elementAt, tap } from 'rxjs/operators';

const utils = require('../utils/diff');

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private _savedVersions = {};

  private _cachedProjects = {};

  private _saveTimeouts = {};

  public onError = new Subject<any>();

  public onConflict = new Subject<any>();

  public onSave = new Subject<any>();

  constructor(private api : ScApiService) { }

  public load(id) : Observable<any>
  {
    return this.api.get('jobs/' + id).pipe(tap(p => {
      this._savedVersions[id] = _.cloneDeep(p);
      this._cachedProjects[id] = p;
    }));
  }

  public getFromCache(id)
  {
    var cached = this._cachedProjects[id];
    if (cached) console.log('Returning cached version of project ' + id);
    return cached;
  }

  saveSoon(project : any) {
    if (this._saveTimeouts[project.Id]) {
      clearTimeout(this._saveTimeouts[project.Id]);
    }
    this._saveTimeouts[project.Id] = setTimeout(() => { this.save(project).subscribe(); }, 1000);
  }

  save(project : any, force = false) {

    var _projectSaved = this._savedVersions[project.Id];

    var diff = utils.diff(_projectSaved, project);
    
    diff.Version = project.Version;

    return this.api.put("jobs/" + project.Id + "/merge?force=" + force, diff).pipe(
      tap((data : any) => {
        if (data.VersionError)
        {
            var args = {
              Error: data,
              Project: project
            };

            this.onConflict.next(args)
        }
        else
        {
          project.Version = data.Version;
          _projectSaved = _.cloneDeep(project);
        }
      },
      e => {
        this.onError.next(e);
      }));
  }
}
