import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ScApiService } from '@sc-ui';
import * as _ from 'lodash';
const utils = require('../../../utils/diff');

@Component({
  selector: 'app-candidate-sales',
  templateUrl: './candidate-sales.component.html',
  styleUrls: ['./candidate-sales.component.scss'],
  providers: [NgbDropdown]
})
export class CandidateSalesComponent implements OnInit {

  id: any;

  ownerId: any = 0;

  salesStatus: any = null;

  candidate: any;

  _savedCandidate: any;

  isSaving = false;

  pageInfo: any;

  constructor(private api: ScApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params.id;
      this.loadCandidate();
    });
  }

  loadCandidate() {
    this.api.get('candidates/' + this.id).subscribe((d: any) => {
      this.candidate = d;
      this.ownerId = d.Organization?.ManagerId;
      this.salesStatus = d.SalesStatus;
      this._savedCandidate = _.cloneDeep(d);
    });
  }

  saveSoon() {
    if (this["saveTimer"]) clearTimeout(this["saveTimer"]);

    this["saveTimer"] = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    if (this.isSaving) return;
    this.isSaving = true;

    var diff = utils.diff(this._savedCandidate, this.candidate);

    this.api.put('candidates/' + this.candidate.Guid, diff).subscribe(
      (d: any) => {
        this.candidate.PortalAccount = d.PortalAccount;
        this._savedCandidate = _.cloneDeep(this.candidate);
        this.isSaving = false;
      },
      (e) => {
        this.isSaving = false;
      }
    );
  }

  goToNextLead() {
    this.api.get('leads?take=1&salesStatus=' + this.salesStatus + '&ownerId=' + this.ownerId).subscribe((d : any ) => {
      if (d.Items.length == 0)
      {
        this.router.navigateByUrl('/leads?ownerId=' + this.ownerId);
      }
      else
      {
        this.router.navigateByUrl('/candidates/' + d.Items[0].Leads[0].Guid + '/sales');
      }
      
    });    
  }

  goToList() {
    this.router.navigateByUrl('/leads?status=' + this.salesStatus + '&ownerId=' + this.ownerId);
  }

}
