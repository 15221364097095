import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectOrganizationComponent } from 'src/components/modal-select-organization/modal-select-organization.component';

@Component({
  selector: 'app-candidate-organizations',
  templateUrl: './candidate-organizations.component.html',
  styleUrls: ['./candidate-organizations.component.scss']
})
export class CandidateOrganizationsComponent {
  
  @Input()
  organizationId: any;

  @Output()
  organizationIdChange = new EventEmitter<any>();

  @HostBinding("class.hide")
  hide = false;

  @ViewChild(ModalSelectOrganizationComponent)
  selectOrganizationModal : ModalSelectOrganizationComponent;

  organization : any;

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.loadOrganization();
  }

  loadOrganization()
  {
    if (!this.organizationId)
    {
      this.organization = null;
      return;
    }

    if (this.organizationId != this.organization?.Id)
    {
      this.api.get('companies/' + this.organizationId).subscribe(d => {
        this.organization = d;
      });
    }
  }

  onOrganizationIdChange()
  {
    this.loadOrganization();
    this.organizationIdChange.next(this.organizationId);
  }

  saveOrganization()
  {
    if (!this.organization) return;
    if (this["saveOrganizationTimeout"]) clearTimeout(this["saveOrganizationTimeout"]);
    this["saveOrganizationTimeout"] = setTimeout(() => {
      this.api.put('companies/' + this.organization.Id + '/merge', this.organization).subscribe();
    }, 500);
  }



}
