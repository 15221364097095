import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'sc-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss']
})
export class ActivityItemComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  context : any;

  @Input()
  condensed = false;

  constructor() { }

  ngOnInit()
  {
  }
}
