<sc-modal #modal>

  <sc-modal-header>
    Kontingent auswählen
  </sc-modal-header>

  <sc-modal-body>
    <div class="row">
      <div class="col-12">
		<p *ngIf="packages?.length == 0">
			Keine Kontingente gefunden
		</p>
        <app-list-packages [packages]="packages" (selected)="modal.complete($event);"></app-list-packages>
      </div>
    </div>
  </sc-modal-body>

</sc-modal>
