import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-dashboard-tam',
  templateUrl: './dashboard-tam.component.html',
  styleUrls: ['./dashboard-tam.component.scss']
})
export class DashboardTamComponent implements OnInit {

  data: any;

  months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
    this.api.get(`dashboard/tam`).subscribe((d: any) => {
      this.data = d;

      var labels = d.Timelines.map(i => i.PeriodMonth);

      new Chart('averagesChart', {
        type: 'line',
        options: {
          scales: {
            y: {
              min: 0
            }
          }
        },
        data: {
          labels: labels.map(i => this.months[i - 1]),
          datasets: [
            {
              label: 'Ansprachen',
              data: d.Timelines.map(i => i.AverageContacts),
              backgroundColor: this.getColor(0),
              borderColor: this.getColor(0)
            },
            {
              label: 'Clicks',
              data: d.Timelines.map(i => i.AverageClicks),
              backgroundColor: this.getColor(1),
              borderColor: this.getColor(1)
            },
            {
              label: 'Bewerbungen',
              data: d.Timelines.map(i => i.AverageInterested),
              backgroundColor: this.getColor(2),
              borderColor: this.getColor(2)
            },
            {
              label: 'Vorstellungen',
              data: d.Timelines.map(i => i.AverageShortlisted),
              backgroundColor: this.getColor(3),
              borderColor: this.getColor(3)
            },
            {
              label: 'Einladungen',
              data: d.Timelines.map(i => i.AverageAccepted),
              backgroundColor: this.getColor(4),
              borderColor: this.getColor(4)
            },
            {
              label: 'Einstellungen',
              data: d.Timelines.map(i => i.AverageHired),
              backgroundColor: this.getColor(5),
              borderColor: this.getColor(5)
            }
          ]
        }
      });

      new Chart('conversionsChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Clicks',
              data: d.Timelines.map(i => i.ClickedRate),
              backgroundColor: this.getColor(0),
              borderColor: this.getColor(0)
            },
            {
              label: 'Interessenten',
              data: d.Timelines.map(i => i.InterestedRate),
              backgroundColor: this.getColor(1),
              borderColor: this.getColor(1)
            },
            {
              label: 'Vorstellungen',
              data: d.Timelines.map(i => i.ShortlistedRate),
              backgroundColor: this.getColor(2),
              borderColor: this.getColor(2)
            },
            {
              label: 'Einladungen',
              data: d.Timelines.map(i => i.AcceptedRate),
              backgroundColor: this.getColor(3),
              borderColor: this.getColor(3)
            },
            {
              label: 'Einstellungen',
              data: d.Timelines.map(i => i.HiredRate),
              backgroundColor: this.getColor(4),
              borderColor: this.getColor(4)
            }
          ]
        }
      });

      new Chart('qualityChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Garantiequote',
              data: d.Timelines.map(i => i.CancelledRate),
              backgroundColor: this.getColor(0),
              borderColor: this.getColor(0)
            }
          ]
        }
      });

      new Chart('volumeChart', {
        type: 'line',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Potentialanalysen',
              data: d.Timelines.map(i => i.ProjectAnalysisCompletedCount),
              backgroundColor: this.getColor(0),
              borderColor: this.getColor(0)
            },
            {
              label: 'Briefings',
              data: d.Timelines.map(i => i.ProjectBriefingCompletedCount),
              backgroundColor: this.getColor(1),
              borderColor: this.getColor(1)
            },
            {
              label: 'Ansprachen abgeschlossen',
              data: d.Timelines.map(i => i.ProjectSourcingCompletedCount),
              backgroundColor: this.getColor(2),
              borderColor: this.getColor(2)
            },
            {
              label: 'Projekte abgeschlossen',
              data: d.Timelines.map(i => i.ProjectCompletedCount),
              backgroundColor: this.getColor(3),
              borderColor: this.getColor(3)
            },
            {
              label: 'Projekte abgerechnet',
              data: d.Timelines.map(i => i.ProjectBilledCount),
              backgroundColor: this.getColor(4),
              borderColor: this.getColor(4)
            },
            {
              label: 'Abgebrochen',
              data: d.Timelines.map(i => i.CancelledCount),
              backgroundColor: this.getColor(5),
              borderColor: this.getColor(5)
            }
          ]
        }
      });

      new Chart('utilizationChart', {
        type: 'bar',
        data: {
          labels: labels.map(i => this.months[i - 1]),

          datasets: [
            {
              label: 'Auslastung [%]',
              data: d.Timelines.map(i => i.Utilization),
              backgroundColor: this.getColor(0),
              borderColor: this.getColor(0)
            }
          ]
        }
      });


    });
  }

  getColor(i) {
    var colors = [
      "#93DDEA", "#EFD6AC", "#ACADEF", "#F2C5CB", "#BCF1D4", "#E2EFAC", "#EFE8AC"
    ];

    var colorIndex = i % colors.length;
    var value = colors[colorIndex];
    return value;
  }

}
