import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';

export class ModalSelectPersonOptions {

  public suggestions: any = [];

  public organizationId = 0;

  public allowCreate = true;

  public allowSearch = true;
}

@Component({
  selector: 'app-modal-select-person',
  templateUrl: './modal-select-person.component.html',
  styleUrls: ['./modal-select-person.component.scss']
})
export class ModalSelectPersonComponent {

  firstName = '';

  lastName = '';

  eMail = '';

  organizationId = 0;

  isLoading = false;

  skip = 0;

  take = 20;

  hasMoreItems = false;

  suggestions: any;

  filteredSuggestions: any;

  qSuggestions = '';

  error = '';

  people: any;

  allowCreate = true;

  allowSearch = true;

  @ViewChild('modal')
  modal: ModalWizardComponent;

  @ViewChild('start')
  startPage: ModalWizardPageComponent;

  @ViewChild('results')
  resultsPage: ModalWizardPageComponent;

  @ViewChild('suggestionsPage')
  suggestionsPage: ModalWizardPageComponent;

  @ViewChild('errorPage')
  errorPage: ModalWizardPageComponent;

  constructor(private api: ScApiService) { }

  show(suggestions = null, options: ModalSelectPersonOptions = null) {

    var targetPage = this.startPage;

    if (suggestions) {
      this.suggestions = suggestions;
      this.filteredSuggestions = suggestions.map(i => i);
      targetPage = this.suggestionsPage;
    }

    if (options?.organizationId) {
      this.organizationId = options.organizationId;
      this.search();
      targetPage = this.resultsPage;
    }

    if (options?.allowCreate != undefined) {
      this.allowCreate = options.allowCreate;
    }

    if (options?.allowSearch != undefined) {
      this.allowSearch = options.allowSearch;
    }

    this.firstName = '';
    this.lastName = '';
    this.eMail = '';

    return this.modal.show(targetPage);
  }

  search() {
    this.skip = 0;
    this.people = [];
    this.loadMoreItems();
  }

  loadMoreItems() {
    this.isLoading = true;
    this.api.get(`candidates?lastName=${this.lastName}&firstName=${this.firstName}&eMail=${this.eMail}&organizationId=${this.organizationId}&skip=${this.skip}&take=${this.take}`).subscribe((d: any) => {
      this.skip += this.take;
      this.people.push.apply(this.people, d.Items);
      this.hasMoreItems = this.people.length < d.TotalCount;
      debugger;
      this.isLoading = false;
    });
  }

  searchSuggestions() {
    this.filteredSuggestions = this.suggestions.filter(i => (i.FirstName + ' ' + i.LastName).toLowerCase().indexOf(this.qSuggestions.toLowerCase()) >= 0);
  }

  createPerson() {
    var args = {
      FirstName: this.firstName,
      LastName: this.lastName,
      EMail: this.eMail
    };

    this.api.post('candidates', args).subscribe(d => {
      this.modal.complete(d);
    },
      e => {
        this.modal.navigateTo(this.errorPage, "rightToLeft");
        this.error = e.error;
        console.log(e);
      });
  }
}
