<div *ngIf="candidate">

  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Stammdaten
          </h6>
        </div>
        <div class="col-auto me-n2">
          <a *ngIf="candidate.XingId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.xing.com/xtm/profiles/{{candidate.XingId}}" target="_blank">
            <fa class="fa-brands fa-xing"></fa>
          </a>
          <a *ngIf="!candidate.XingId" [href]="xingSearchUri" target="_blank" class="btn btn-sm btn-secondary btn-circle me-2">
            <fa class="fa-brands fa-xing"></fa>
          </a>
          <a *ngIf="candidate.LinkedInId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.linkedin.com/talent/profile/{{candidate.LinkedInId}}" target="_blank">
            <fa class="fa-brands fa-linkedin-in"></fa>
          </a>
          <a *ngIf="!candidate.LinkedInId" [href]="linkedInSearchUri" target="_blank" class="btn btn-sm btn-secondary btn-circle me-2">
            <fa class="fa-brands fa-linkedin-in"></fa>
          </a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">

        <div class="col-12 order-2 col-lg order-lg-1">

          <sc-form>
            <sc-form-row label="Titel">
              <input [(ngModel)]="candidate.Title" (ngModelChange)="onChange();" />
            </sc-form-row>

            <sc-form-row label="Gender">
              <select [(ngModel)]="candidate.Gender" (ngModelChange)="onChange()">
                <option [ngValue]="null">Unbestimmt</option>
                <option ngValue="m">Männlich</option>
                <option ngValue="f">Weiblich</option>
              </select>
            </sc-form-row>

            <sc-form-row label="Vorname">
              <input [(ngModel)]="candidate.FirstName" (ngModelChange)="onChange();" />
            </sc-form-row>

            <sc-form-row label="Nachname">
              <input [(ngModel)]="candidate.LastName" (ngModelChange)="onChange();" />
            </sc-form-row>

            <sc-form-row label="XING">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">.../xtm/profiles/</span>
                </div>
                <input [(ngModel)]="candidate.XingId" (ngModelChange)="onChange();" />
                <div class="input-group-append">
                  <a *ngIf="candidate.XingId" href="https://www.xing.com/xtm/profiles/{{candidate.XingId}}" target="_blank" class="btn btn-secondary">
                    <fa class="fa-solid fa-arrow-up-right-from-square"></fa>
                  </a>
                  <a *ngIf="!candidate.XingId" [href]="xingSearchUri" target="_blank" class="btn btn-secondary">
                    <fa class="fa-solid fa-magnifying-glass"></fa>
                  </a>
                </div>
              </div>
            </sc-form-row>

            <sc-form-row label="LinkedIn">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">.../in/</span>
                </div>
                <input [(ngModel)]="candidate.LinkedInId" (ngModelChange)="onChange();" />
                <div class="input-group-append">
                  <div ngbDropdown>
                    <button ngbDropdownToggle *ngIf="candidate.LinkedInId" class="btn btn-secondary">
                      <fa class="fa-solid fa-arrow-up-right-from-square"></fa>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem href="https://www.linkedin.com/in/{{candidate.LinkedInId}}" target="_blank">Öffentliches Profil</a>
                      <a ngbDropdownItem href="https://www.linkedin.com/talent/profile/{{candidate.LinkedInId}}" target="_blank">LinkedIn Recruiter</a>
                    </div>
                  </div>
                  <a *ngIf="!candidate.LinkedInId" [href]="linkedInSearchUri" target="_blank" class="btn btn-secondary">
                    <fa class="fa-solid fa-magnifying-glass"></fa>
                  </a>
                </div>
              </div>
            </sc-form-row>

            <sc-form-row label="Job-Titel">
              <input [(ngModel)]="candidate.JobTitle" (ngModelChange)="onChange();" />
            </sc-form-row>

            <sc-form-row label="E-Mail">
              <input [(ngModel)]="candidate.EMail" (ngModelChange)="onChange();" />
            </sc-form-row>

            <sc-form-row label="Telefon">
              <div class="input-group">
                <input [(ngModel)]="candidate.Phone" (ngModelChange)="onChange();" />
                <div class="input-group-append">
                  <a *ngIf="candidate.Phone" (click)="callCandidate()" class="btn btn-secondary">
                    <i class="fa-solid fa-phone"></i>
                  </a>
                </div>
              </div>
            </sc-form-row>

            <sc-form-row label="Mobil">
              <div class="input-group">
                <input [(ngModel)]="candidate.Mobile" (ngModelChange)="onChange();" />
                <div class="input-group-append">
                  <a *ngIf="candidate.Mobile" href="tel:{{candidate.Mobile}}" target="_blank" class="btn btn-secondary">
                    <i class="fa-solid fa-phone"></i>
                  </a>
                </div>
              </div>
            </sc-form-row>           

            <sc-form-row label="Kontakt-Status">
              <select [(ngModel)]="candidate.ContactStatus" (ngModelChange)="onChange()">
                <option ngValue="None">-</option>
                <option ngValue="User">User</option>
                <option ngValue="Contact">Contact</option>
                <option ngValue="Promoter">Promoter</option>
              </select>
            </sc-form-row>

            <sc-form-row label="Marketing-Status">
              <select [(ngModel)]="candidate.MarketingStatus" (ngModelChange)="onChange()">
                <option ngValue="None">Kein Status</option>
                <option ngValue="Active">Aktiv</option>
                <option ngValue="Unsubscribed">Unsubscribed</option>
              </select>
            </sc-form-row>

            <sc-form-row label="Kommentar">
              <autosize-textarea [(text)]="candidate.Profile.Comment" (textChange)="onChange();"></autosize-textarea>
            </sc-form-row>
          </sc-form>
        </div>

        <div class="col-12 order-1 col-lg-auto order-lg-2">
          <sc-image-select-crop aspectRatio="1" minWidth="512" (uploaded)="setImage($event);">
            <sc-avatar size="xxl" [user]="candidate"></sc-avatar>
          </sc-image-select-crop>
        </div>
      </div>
    </div>
  </div>

</div>

<app-candidate-modal-select-pool #poolModal></app-candidate-modal-select-pool>
