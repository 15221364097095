<div class="card">

  <sc-image-select-crop uploadUri="assets" fileType="jpeg" [minWidth]="1000" (uploaded)="company.Profile.Banner = $event.Uri; companyChange.next(company);">
    <img *ngIf="company.Profile.Banner" [src]="company.Profile.Banner | imageSize : 1200 : 395" (error)="bannerError = true;" class="card-img-top" />
    <img *ngIf="!company.Profile.Banner" src="/assets/project-hero-placeholder/hero-placeholder-{{getImageIndex()}}.jpg" class="card-img-top" />
  </sc-image-select-crop>

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Assets
        </h6>
      </div>
      <div class="col-auto">
        <div class="btn btn-round btn-secondary me-2" [class.loading]="isCopyingAssets" (click)="copyAssets()">
          Aus Projekt
        </div>
        <div class="btn btn-round btn-dark" [class.loading]="isGeneratingAssets" (click)="scrapeModal.show(startPage)">
          <i class="fa-sharp fa-solid fa-stars me-2"></i> Generieren
        </div>
      </div>
      <div class="col-auto">
        <select class="custom-select" [(ngModel)]="assetLanguage">
          <option value="de">Deutsch</option>
          <option value="en">Englisch</option>
        </select>
      </div>
    </div>

  </div>
  <div class="card-body">

    <app-company-asset-editor *ngIf="assetLanguage == 'de'" [company]="company" [assets]="company.Profile" (changed)="companyChange.next(company)"></app-company-asset-editor>
    <app-company-asset-editor *ngIf="assetLanguage == 'en'" [company]="company" [assets]="company.Profile.EnglishAssets" (changed)="companyChange.next(company)"></app-company-asset-editor>

  </div>
</div>

<div class="card mt-5">

  <div class="card-header">
    <h6 class="card-header-title">
      Benefits
    </h6>
  </div>

  <div class="card-body">
    <benefit-selection [benefitIds]="company.Profile.BenefitIds" (benefitIdsChange)="companyChange.next(company)"></benefit-selection>
  </div>

</div>

<sc-modal-wizard #scrapeModal>

  <sc-modal-wizard-page #startPage>
    <sc-modal-header>
      Assets generieren
    </sc-modal-header>
    <sc-modal-body style="height: 60vh;">
      <div class="row">
        <div class="col-12">
          <input class="form-control" [(ngModel)]="q" (ngModelChange)="searchScrapeSources()" />
        </div>
        <div class="col-12 mt-3">
          <div class="list-group">
            <div class="list-group-item list-group-item-action" *ngFor="let r of scrapeSources" (click)="selectSource(r); scrapeModal.navigateTo(generatePage)">
              <div class="row align-items-center">
                <div class="col-2">
                  <div class="image-container">
                    <img [src]="r.logo" class="img-fluid" />
                  </div>

                </div>
                <div class="col-8">
                  <div style="max-width: 100%;" class="oneline">{{ r.name }}</div>
                  <div style="max-width: 100%;" class="small clickable oneline d-inline-block" (click)="openSourceUrl($event, r)"><span> <i class="fa-sharp fa-solid fa-arrow-up-right-from-square me-2"></i>{{ r.Uri }}</span></div>
                </div>
                <div class="col-2 justify-content-end d-flex">
                  <i class="fa-sharp fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </sc-modal-body>
  </sc-modal-wizard-page>
  <sc-modal-wizard-page #generatePage>
    <sc-modal-header>
      Die Assets werden generiert...
    </sc-modal-header>
    <sc-modal-body>
      Bitte warte kurz...
    </sc-modal-body>
  </sc-modal-wizard-page>
  <sc-modal-wizard-page #scrapeResultPage>
    <sc-modal-header>
      Die Assets wurden generiert
    </sc-modal-header>
    <sc-modal-body>
      <div class="list-group" *ngIf="scrapeResult">
        <div class="list-group-item py-2" [class.not-found]="!scrapeResult.LogoUri">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Logo</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeLogo" *ngIf="scrapeResult.LogoUri"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.Banner">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Banner</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeBanner" *ngIf="scrapeResult.Banner"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.Description">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Beschreibung</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeDescription" *ngIf="scrapeResult.Description"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.Highlights">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Highlights: Arbeitgeber</span>
            </div>
            <div class="col-auto">
              <sc-switch  [(ngModel)]="includeHighlights" *ngIf="scrapeResult.Highlights"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.WorkplaceHighlights">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Highlights: Arbeitsumgebung</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeWorkplaceHighlights" *ngIf="scrapeResult.WorkplaceHighlights"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.VideoUri">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Video</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeVideo" *ngIf="scrapeResult.VideoUri"></sc-switch>
            </div>
          </div>
        </div>
        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.Impressions?.length">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Impressionen</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeImpressions" *ngIf="scrapeResult.Impressions?.length"></sc-switch>
            </div>
          </div>
        </div>

        <div class="list-group-item py-2"[class.not-found]="!scrapeResult.BenefitIds?.length">
          <div class="row">
            <div class="col">
              <span class="fw-normal">Benefits</span>
            </div>
            <div class="col-auto">
              <sc-switch [(ngModel)]="includeBenefits" *ngIf="scrapeResult.BenefitIds?.length"></sc-switch>
            </div>
          </div>
        </div>
      </div>
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-round btn-secondary" (click)="scrapeModal.dismiss()">Abbrechen</button>
      <button class="btn btn-round btn-dark" (click)="confirmResults(); scrapeModal.dismiss();">Übernehmen</button>
    </sc-modal-footer>
  </sc-modal-wizard-page>
</sc-modal-wizard>
