import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ModalSelectPersonComponent } from 'src/components/modal-select-person/modal-select-person.component';

@Component({
  selector: 'app-company-people',
  templateUrl: './company-people.component.html',
  styleUrls: ['./company-people.component.scss']
})
export class CompanyPeopleComponent implements OnChanges {

  @Input() 
  company : any;

  @ViewChild('selectPersonModal')
  selectPersonModal : ModalSelectPersonComponent;

  people : any = [];

  constructor(private api : ScApiService, private router : Router)
  {}

  ngOnChanges()
  {
    if (!this.company) 
    {
      this.people = [];
      return;
    }

    this.api.get('companies/' + this.company.Id + '/people').subscribe(d => {
      this.people = d;
    });
  }

  addPerson()
  { 
    this.selectPersonModal.show().subscribe((d : any) => {
      this.people.push(d);
      d.OrganizationId = this.company.Id;
      this.api.put('candidates/' + d.Guid + '/merge', { OrganizationId : d.OrganizationId  }).subscribe();
    });

  }

}
