<div class="card">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Vorinformationen
        </h6>
      </div>
      <div class="col-auto">
        <sc-file-button (filesChanged)="onDocumentsSelected($event)" [disabled]="isUploading">
          <div class="btn btn-sm btn-secondary btn-circle lift">
            <i class="fa-regular fa-cloud-arrow-up"></i>
          </div>
        </sc-file-button>
      </div>
    </div>
  </div>

  <div class="card-body" *ngIf="filesToUpload.length > 0 || project.Files?.length">

    <div *ngIf="filesToUpload.length > 0">

      <ul class="list-group list-group-sm list-group-flush list">

        <li class="list-group-item px-0" *ngFor="let file of filesToUpload">

          <div class="row align-items-start">
            <div class="col-auto">
              <i class="fa-regular fa-file-pdf fa-lg"></i>
            </div>
            <div class="col ms--2">
              <!-- Title -->
              <h6 class="card-title mb-1 name">
                <a>{{file.name}} <span class="small text-muted">{{file.Type}}</span></a>
              </h6>
              <!-- Time -->
              <p class="card-text small text-muted mb-0" [hidden]="file.isUploading || file.hasError">
                Noch nicht hochgeladen
              </p>
              <p class="card-text small text-danger mb-0" [hidden]="file.isUploading || !file.hasError">
                {{file.error}}
              </p>
              <div class="progress progress-sm mb-1">
                <div class="progress-bar" role="progressbar" [style.width]="file.uploadProgress+ '%'"></div>
              </div>
              <p class="card-text small text-muted mb-0" [hidden]="!file.isUploading">
                Wird hochgeladen ({{file.uploadProgress}}%)
              </p>
            </div>
            <div class="col-auto">
              <!-- Button -->
              <button [disabled]="file.isUploading" (click)="removeFileToUpload(file)" class="btn btn-sm btn-white d-none d-md-inline-block">
                <i class="fa-regular fa-trash"></i>
              </button>
            </div>
          </div> <!-- / .row -->
        </li>
      </ul>
    </div>

    <!-- List -->
    <ul class="list-group list-group-sm list-group-flush list">

      <li class="list-group-item px-0" *ngFor="let file of project.Files">

        <div class="row align-items-start">
          <div class="col-auto">
            <a [href]="file.Uri" target="_blank"><img [scFileIcon]="file.Extension" /></a>
          </div>
          <div class="col ms--2">
            <!-- Title -->
            <h6 class="card-title mb-1 name">
              <a [href]="file.Uri" target="_blank">{{file.Name}} <span class="small text-muted">{{file.Type}}</span></a>
            </h6>
            <!-- Time -->
            <p class="card-text small text-muted mb-0">
              Hochgeladen <time title="{{file.Created | date : 'dd.MM.yy'}}">{{file.Created | humanizedDate}}</time>
            </p>

          </div>
          <div class="col-auto">
            <!-- Button -->
            <a [href]="file.Uri" target="_blank" class="btn btn-sm btn-white d-none d-md-inline-block me-2">
              <i class="fa-regular fa-cloud-arrow-down"></i>
            </a>

            <button (click)="deleteFile(file)" class="btn btn-sm btn-white d-none d-md-inline-block">
              <i class="fa-regular fa-trash"></i>
            </button>
          </div>
        </div> <!-- / .row -->
      </li>
    </ul>
  </div>
</div>

<sc-modal #deleteFileModal>
  <div class="modal-header">
    <h4 class="modal-title">Datei wirklich löschen?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="deleteFileModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Möchtest du die Datei wirklich unwiderruflich löschen?
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="deleteFileModal.dismiss()">Abbrechen</button>
    <button class="btn btn-danger" (click)="deleteFileModal.complete()">Löschen</button>
  </div>
</sc-modal>