import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-topbar-project',
  templateUrl: './topbar-project.component.html',
  styleUrls: ['./topbar-project.component.scss']
})
export class TopbarProjectComponent implements OnInit {

  @Input()
  project : any;

  @ViewChild('deleteModal')
  deleteModal : ScuiModalComponent;
  
  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit(): void {
  }

  delete()
  {
    this.api.delete('jobs/' + this.project.Id).subscribe(d => {
      this.deleteModal.dismiss();
      this.router.navigateByUrl("/projects");
    });
  }

}
