import { Component, OnInit, ViewChild } from "@angular/core";
import { Subject, interval } from "rxjs";
import { debounce } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ScuiModalComponent } from '@sc-ui';
import { ScApiService } from '@sc-ui';
import { QueryParamsService } from "src/services/query-params.service";
import { ProjectService } from "src/services/project.service";
import { ModalSelectPersonComponent } from "src/components/modal-select-person/modal-select-person.component";

const utils = require('../../../utils/diff');
const _ = require('lodash');

@Component({
  selector: "app-project-search",
  templateUrl: "./project-search.component.html",
  styleUrls: ["./project-search.component.scss"],
  providers: [QueryParamsService]
})
export class ProjectSearchComponent implements OnInit {

  @ViewChild('proceedModal')
  proceedModal: ScuiModalComponent;

  proceedMessage: string;

  loadTimeout: any;

  project: any;

  stats: any;

  tags: any = [];

  keywordInput: string;
  skillHaveInput: string;
  newSkillName: string;
  openModal: NgbModalRef;
  manualTag: string;
  id: number;

  selectedSkill: any;
  skip: number;
  take: number = 20;
  itemsLoading: boolean;
  hasItemsToLoad: boolean = true;
  itemsLoaded = false;
  hasInconsistentScores = false;

  isScoringInProgress: boolean;
  candidatesToScore: number;
  scoringProgress: number;
  scoringOffset: number;

  isSearchingInProgress: boolean;
  lastSearchResponse: any;
  isSearchingAborted: boolean;
  showFilter: boolean = false;
  showSearch: boolean = false;

  isImporting = false;

  importArgs = {
    SourceProjectId: 0,
    SourceBatchId: -1,
    TargetBatchId: 0,
    IncludeContacted: false,
    CopyIncludedExcluded: false
  };

  defaultFilter = {
    ProjectId: 0,
    BatchId: "-1",
    IsMatch: true,
    IsShortlisted: null,
    IsInterested: null,
    IsContacted: null,
    IsRejected: null,
    IsAccepted: null,
    IsHired: null,
    IsEcluded: null,
    IsEcludedSecondary: null,
    IsIncluded: null,
    IsBlacklisted: null,
    QueryName: null,
    QueryJobTitle: null,
    QueryHaves: null,
    QueryEmployer: null,
    Tags: null
  };

  sort = 'score';

  defaultViewSettings: any = {
    showHaves: false,
    showBio: false,
    showFunnel: false,
    showDetails: false
  }

  filter: any = {};

  viewSettings: any = {};

  addCandidateError: string;

  private cancelRunningRequests = new Subject<any>();

  @ViewChild("tagModal")
  tagModal: ScuiModalComponent;

  @ViewChild("blacklistModal")
  blacklistModal: ScuiModalComponent;

  @ViewChild("nameBlacklistModal")
  nameBlacklistModal: ScuiModalComponent;

  @ViewChild("importCandidatesModal")
  importCandidatesModal: ScuiModalComponent;

  @ViewChild(ModalSelectPersonComponent)
  selectPersonModal: ModalSelectPersonComponent;

  haves: any;

  results: any;

  selectedResult: any;

  resultCount: any = 0;

  constructor(
    private api: ScApiService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService) {
  }

  ngOnInit(): void {

    this.route.params
      .subscribe(params => {

        this.id = params['id'];
        this.filter.ProjectId = this.id;
        this.defaultFilter.ProjectId = this.id;

        var cached = this.projectService.getFromCache(this.id);
        if (cached) this.project = cached;
        this.projectService.load(this.id).subscribe(p => {
          this.project = p;
        });
      });

    this.queryParamsService.externalStateChange
      .pipe(debounce(() => interval(500)))
      .subscribe(params => {

        var resetQueryParams = false;

        if (!params.viewSettings) {
          resetQueryParams = true;
          params.viewSettings = Object.assign({}, this.defaultViewSettings);
        }

        if (!params.filter) {
          resetQueryParams = true;
          params.filter = Object.assign({}, this.defaultFilter);
        }

        if (resetQueryParams) {
          this.queryParamsService.setState(params, false);
        }

        this.viewSettings = params.viewSettings;
        var hasFilterChanged = JSON.stringify(params.filter) != JSON.stringify(this.filter);

        if (!hasFilterChanged) return;
        this.filter = params.filter;

        this.loadCandidates();
        this.loadStats();
        this.loadTags();
      });
  }

  onStateChanged() {

    var data = {
      filter: this.filter,
      viewSettings: this.viewSettings
    };

    this.queryParamsService.setState(data, true);
  }

  loadCandidatesTimer: any = null;

  loadCandidatesSoon() {

    if (this.loadCandidatesTimer) clearTimeout(this.loadCandidatesTimer);

    this.onStateChanged();

    setTimeout(() => {
      this.loadCandidates();
      this.loadStats();
      this.loadTags();
    }, 500);
  }

  addCandidate() {
    this.selectPersonModal.show().subscribe((d: any) => {

      var batchId = this.filter.BatchId;
      if (batchId == -1) batchId = 0;

      var args = {
        Guid: d.Guid,
      };

      this.api.post(`jobs/${this.id}/candidates?BatchId=${batchId}`, args).subscribe((d: any) => {
        setTimeout(() => {
          this.router.navigateByUrl('/candidates/' + d.Id);
        }, 100);
      });
    });
  }

  loadCandidates() {

    this.skip = 0;
    this.results = [];
    this.loadMoreCandidates();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }

  loadMoreCandidates() {

    if (!this.id) return;

    var cancelled = false;
    this.cancelRunningRequests.next();
    this.cancelRunningRequests.subscribe(() => { cancelled = true });

    this.itemsLoading = true;

    var uri = 'jobs/' + this.id + '/candidates/search?take=' + this.take + '&skip=' + this.skip + '&sort=' + this.sort;

    this.api.post(uri, this.filter).subscribe(
      (r: any) => {
        if (cancelled) {
          console.log('Request was cancelled, not handling results');
          return;
        }

        r.Results.forEach(r => {

          if (r.Haves) {
            var split = r.Haves.split(',')
              .filter(s => s?.length)
              .map(s => s.trim());

            r.havesList = split;
          }
          else {
            r.havesList = [];
          }
        });

        this.results = this.results.concat(r.Results);
        this.resultCount = r.ResultCount;
        this.skip += this.take;
        this.hasItemsToLoad = r.length == this.take;
        this.hasInconsistentScores = r.HasInconsistentScores;
      },
      error => {
        console.log(error);
      },
      () => {
        this.itemsLoading = false;
      }
    );
  }

  loadStats() {
    this.api.get(`projects/${this.id}/stats?BatchId=${this.filter.BatchId}`).subscribe(d => {
      this.stats = d;
    });
  }

  screen() {
    this.api.post(`projects/${this.id}/screening`, null).subscribe(d => {

      this.refresh();
      
    });
  }



  loadTags() {
    this.api.get(`jobs/${this.id}/tags`).subscribe(d => {
      this.tags = d;
      this.tags.forEach(t => {
        t.isExcluded = this.filter.tags && this.filter.tags.indexOf('-' + t.Tag) > -1;
        if (t.isExcluded) {
          t.isIncluded = false;
          return;
        }
        t.isSelected = this.filter.tags && this.filter.tags.indexOf(t.Tag) > -1;
      });
    });
  }

  save() {
    this.projectService.save(this.project).subscribe();
  }

  refresh() {
    this.loadCandidatesSoon();
  }

  setFiltersForScreening() {
    this.filter = Object.assign({}, this.defaultFilter);
    this.filter.IsContacted = false;
    this.filter.IsExcluded = false;
    this.filter.IsIncluded = false;
    this.filter.IsIncludedSecondary = false;
    this.filter.IsBlacklisted = false;

    this.sort = 'score';

    this.viewSettings = {
      showHaves: true,
      showBio: true,
      showFunnel: true,
      showDetails: true
    };

    this.loadCandidatesSoon();
  }

  clearFilters() {
    this.filter = Object.assign({}, this.defaultFilter);
    this.loadCandidatesSoon();
  }

  toggleTag(tag) {

    if (tag.isSelected) {
      tag.isSelected = false;
      tag.isExcluded = true;
    }
    else if (tag.isExcluded) {
      tag.isSelected = false;
      tag.isExcluded = false;
    }
    else {
      tag.isSelected = true;
      tag.isExcluded = false;
    }

    var tagsParam = '';

    if (this.tags) {
      this.tags.forEach(t => {
        if (t.isSelected) tagsParam += ' ' + t.Tag;
        if (t.isExcluded) tagsParam += ' -' + t.Tag;
      });

      tagsParam = tagsParam.trim();
    }

    this.filter.tags = tagsParam;

    this.loadCandidatesSoon();
  }

  excludeAll() {
    var candidateIds = [];

    this.results.forEach(c => {
      if (c.Assignment.IsContacted) return;
      if (c.Assignment.IsExcluded) return;
      if (c.Assignment.IsIncluded) return;
      candidateIds.push(c.Id);
    });

    this.api.post("projects/" + this.id + "/candidates/exclude", candidateIds).subscribe(d => {
      this.refresh();
      window.scrollTo(0, 0);
    });
  }

  toggleExcluded(item) {

    var isExcluded = !item.Assignment.IsExcluded;
    this.setExcluded(item, isExcluded);
  }

  toggleIncluded(item) {

    var isIncluded = !item.Assignment.IsIncluded;
    this.setIncluded(item, isIncluded);
  }

  toggleIncludedSecondary(item) {

    item.Assignment.IsIncludedSecondary = !item.Assignment.IsIncludedSecondary;
    this.api.put(`candidates/${item.Assignment.CandidateId}/assignments/${item.Assignment.Id}`, item.Assignment).subscribe();
  }

  getAIScore(item) {
    var url = "projects/" + this.id + "/candidates/" + item.Assignment.CandidateId + "/ai-score";
    this.api.get(url).subscribe(data => {
      console.log(data);
    });
  }

  setExcluded(item, isExcluded: boolean) {

    var wasIncluded = item.Assignment.IsIncluded;
    var wasExcluded = item.Assignment.IsExcluded;
    if (wasExcluded === isExcluded) return;
    var apiParam = isExcluded ? "false" : "auto";
    var increment = isExcluded ? 1 : -1;

    item.Assignment.IsExcluded = isExcluded;
    if (isExcluded) item.Assignment.IsIncluded = false;
    this.project.Profile.ExcludedCount += increment;

    var arg = { Value: apiParam };
    var url = "projects/" + this.id + "/candidates/" + item.Assignment.CandidateId + "/included";
    this.api.put(url, arg).subscribe(data => {
      console.log(data);
      if (this.countActiveItems() < 15) this.loadMoreCandidates();
    },
      e => {
        item.Assignment.IsExcluded = wasExcluded;
        item.Assignment.IsIncluded = wasIncluded;
        this.project.Profile.ExcludedCount -= increment;
      });
  }

  setIncluded(item, isIncluded: boolean) {

    var wasIncluded = item.Assignment.IsIncluded;
    var wasExcluded = item.Assignment.IsExcluded;
    if (wasIncluded === isIncluded) return;
    var apiParam = isIncluded ? "true" : "auto";
    var increment = wasExcluded ? -1 : 0;

    item.Assignment.IsIncluded = isIncluded;
    if (isIncluded) item.Assignment.IsExcluded = false;

    this.project.Profile.ExcludedCount += increment;

    var arg = { Value: apiParam };
    var url = "projects/" + this.id + "/candidates/" + item.Assignment.CandidateId + "/included";
    this.api.put(url, arg).subscribe(data => {
      console.log(data);
      if (this.countActiveItems() < 15) this.loadMoreCandidates();
    },
      e => {
        item.Assignment.IsIncluded = wasIncluded;
        item.Assignment.IsExcluded = wasExcluded;
        this.project.Profile.ExcludedCount -= increment;
      });
  }

  countActiveItems(): number {
    var count = 0;

    this.results.forEach(i => {
      if (i.Assignment.IsContaced) return;
      if (i.Assignment.IsExcluded) return;
      count++;
    });

    return count;
  }

  scoreNext() {
    if (this.scoringOffset > 0 && this.scoringOffset >= this.candidatesToScore) {
      this.isScoringInProgress = false;
      this.refresh();
      return;
    }

    const url = `projects/${this.id}/profiling/score?skip=${this.scoringOffset}`;

    this.api.get(url).subscribe((data: any) => {
      this.scoringOffset += 200;
      this.scoringProgress = this.scoringOffset / this.candidatesToScore * 100.0;
      this.scoreNext();
    });

  }

  score() {
    this.isScoringInProgress = true;
    this.scoringOffset = 0;
    this.candidatesToScore = 0;

    var url = "projects/" + this.id + "/profiling/score/warmup";
    this.api.get(url).subscribe((data: any) => {
      this.candidatesToScore = data.ProfilesToScore;
      this.scoreNext();
    });
  }

  getTags(q: string) {
    var url = "projects/" + this.id + "/profiling/haves?q=" + q;
    this.api.get(url).subscribe((data: any) => {
      console.log(data);
    });
  }

  clearItems() {
    this.results = [];
    this.skip = 0;
  }

  setBatchState(state: string, value: boolean) {
    var proceedMessage = 'Möchtest du wirklich alle nicht markierte Profile einschließen?';
    if (state == 'excluded') proceedMessage = 'Möchtest du wirklich alle nicht markierten Profile ausschließen?';

    this.askProceed(proceedMessage, () => {
      var args = { State: state, Value: value };
      this.api.put(`jobs/${this.project.Id}/candidates/rest/status?BatchId=${this.filter.BatchId}`, args).subscribe(d => {
        this.refresh();
      });
    });
  }

  markBAsAProfiles() {
    this.askProceed('Möchtest du wirklich alle B-Profile zu A-Profilen machen?', () => {
      var args = { BatchId: this.filter.BatchId };
      this.api.post(`jobs/${this.project.Id}/candidates/set-b-as-a-profiles`, args).subscribe(d => {
        this.results.forEach(i => {
          if (i.Assignment.IsIncludedSecondary) i.Assignment.IsIncluded = true;
        });
        this.loadStats();
      });
    });
  }

  deleteCandidates() {
    this.askProceed('Möchtest du wirklich alle nicht markierte und nicht kontaktierte Profile löschen?', () => {
      this.api.delete(`jobs/${this.project.Id}/candidates/rest?BatchId=${this.filter.BatchId}`).subscribe();
    });
  }

  moveRestToBatch() {
    this.askProceed('Möchtest du wirklich alle nicht markierte Kandidat:innen in eine neue Batch verschieben?', () => {
      this.api.post(`jobs/${this.project.Id}/candidates/rest/tonewbatch?BatchId=${this.filter.BatchId}`, null).subscribe(d => {
        this.refresh();
      });
    });
  }

  importCandidates() {
    this.isImporting = true;

    this.api.post(`jobs/${this.project.Id}/candidates/import`, this.importArgs).subscribe(d => {
      this.filter.BatchId = this.importArgs.TargetBatchId;
      this.importCandidatesModal.dismiss();
      this.refresh();
      this.isImporting = false;
    });
  }

  createTrainingBatch() {
    this.askProceed('Möchtest du wirklich eine Training-Batch erzeugen?', () => {

      var arg = {
        BatchId: this.filter.BatchId,
        ProjectId: this.project.Id
      }

      this.api.post(`training-batches/`, arg).subscribe(d => {
        this.refresh();
      });

    });
  }

  addKeyword(key: string) {
    if (!key) return;
    if (!this.project.Profile.Keywords) this.project.Profile.Keywords = [];
    var exists = this.project.Profile.Keywords.indexOf(key) >= 0;
    if (exists) return;
    this.project.Profile.Keywords.push(key);
  }

  deleteKeyword(key: string) {
    if (!this.project.Profile.Keywords) this.project.Profile.Keywords = [];
    var index = this.project.Profile.Keywords.indexOf(key);
    if (index < 0) return;
    this.project.Profile.Keywords.splice(index, 1);
  }

  postChat(chatbotId) {
    var args = {
      ChatbotId: chatbotId,
      ProjectId: this.id,
      BatchId: this.filter.BatchId,
      Tags: this.filter.tags,
      SendNotifications: true,
      CreateIfCompleted: false
    };

    this.api.post('chats/batch', args).subscribe(d => {
      console.log(d);

    });
    console.log(chatbotId);
  }

  addTo(array, item, allowDuplicate = false) {
    if (!array) return;
    if (!allowDuplicate) {
      var exists = array.indexOf(item) >= 0;
      if (exists) return;
    }

    array.push(item);
  }

  deleteFrom(array, item) {
    if (!array) return;
    var index = array.indexOf(item);
    if (index === -1) return;
    array.splice(index, 1);
  }

  truncate(text: string, length) {
    if (!text) return '';
    if (text.length <= length) return text;
    text = text.substring(0, length) + "...";
    return text;
  }

  toBool(s: any) {
    if (s == true) return true;
    if (s == false) return false;
    if (s == null) return null;
    if (s == "true") return true;
    if (s == "false") return false;
  }

  askProceed(message, action) {
    this.proceedMessage = message;
    this.proceedModal.show().subscribe(() => {
      action();
    })
  }
}
