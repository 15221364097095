<div class="main-content">

  <div class="container" *ngIf="projects">

    <div class="row mb-3">
      <div class="col-12">
        <input type="search" [(ngModel)]="q" (ngModelChange)="loadResultsSoon()" class="form-control search" placeholder="Suchen">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="list-group">
          <div class="list-group-item" *ngIf="!projects?.length">
            Keine Projekte
          </div>
          <a class="list-group-item list-group-item-action" *ngFor="let project of projects" [class.archived]="project.IsArchived" (click)="selectProject(project)">
            <div class="row align-items-center flex-nowrap">

              <div class="col-auto">
                <sc-avatar class="d-inline-block" [user]="project.Manager" size="sm"></sc-avatar>
              </div>

              <div class="col">
                <span>
                  <h6 class="mb-1 oneline d-inline">
                    {{project.Name}}
                  </h6>
                  <small class="font-weight-normal d-inline">m|w|d</small>
                </span>

                <p class="card-text text-muted small mb-0 oneline">
                  {{ project.Id }} <span *ngIf="project.Company">- {{project.Company?.Name }}</span>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
