<div *ngIf="candidate">

  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Kadenzen
          </h6>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-circle btn-secondary" (click)="addCadence()">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card-body card-body-list" *ngIf="cadences?.length">
      <div class="list-group">
        <div class="list-group-item list-group-item-action" (click)="modal.show(cadence.Assignment)" *ngFor="let cadence of cadences">
          <div class="row align-items-center flex-nowrap">

            <div class="col">
              <span>
                <h6 class="mb-1 oneline d-inline">
                  {{cadence.Name}}
                </h6>
              </span>

              <p class="card-text text-muted small mb-0 oneline">
                {{ cadence.Id }} <span *ngIf="cadence.Description">- {{cadence.Description }}</span>
              </p>
            </div>  
			
			<div class="col-auto">
				<app-status-badge-cadence-person-assignment [assignment]="cadence.Assignment"></app-status-badge-cadence-person-assignment>
			</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-cadence-person-assignment  [showGoToCadence]="true" (change)="load()" #modal></app-modal-cadence-person-assignment>
<app-modal-select-cadence #cadenceModal></app-modal-select-cadence>