<div class="list-group">
  <a class="list-group-item list-group-item-action" [routerLink]="navigateOnClick ? '/candidates/' + person.Guid : null" (click)="onClick(person)" *ngFor="let person of people">
    <div class="row align-items-center flex-nowrap">
      <div class="col-auto">
        <sc-avatar class="d-inline-block" [user]="person" size="sm"></sc-avatar>
      </div>

      <div class="col">
        <h6 class="mb-1 oneline d-inline">
          {{person.FirstName}} {{ person.LastName}}
        </h6>
        <p class="card-text text-muted small mb-0 oneline">
          {{ person.EMail }}
        </p>
      </div>

      <div class="col-auto">
        <app-status-badge-person [person]="person"></app-status-badge-person>
      </div>
    </div>
  </a>
  <div class="list-group-item justify-content-center d-flex" *ngIf="showMore">
    <button class="btn btn-secondary btn-round btn-sm" (click)="moreClick.next()" [disabled]="isLoadingMore">Mehr</button>
  </div>
</div>
