<ng-template [appTopbarTemplate]>
  <div class="title">
    <h4 class="mb-0">Operations Dashboard</h4>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row mb-5">
      <div class="col-12">
        <h5>Projektbestand nach Status</h5>
      </div>
      <div class="col-3 mb-3" *ngFor="let s of data?.CountByStatus">
        <div class="card px-2">
          <div class="card-body">
            <div class="mb-1">
              <div class="h3 mb-0 d-inline-block">
                {{ s.Count | number : '1.0-0'}} {{ unit }}
              </div>

              <div class="d-inline-block ms-3">
                {{ s.CountPerFte | number : '1.2-2'}} / FTE
              </div>

            </div>
            <div class="oneline small">
              <span>{{ s.Status | projectStatus }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <h5>Kontingentbestand</h5>
      </div>
      <div class="col-3 mb-3">
        <app-card-kpi label="Kontingente" [value]="data?.Packages?.Count" unit="">
        </app-card-kpi>
      </div>
      <div class="col-3 mb-3">
        <app-card-kpi label="Aktives Kontingentvolumen" [value]="data?.Packages?.Volume " unit="">
        </app-card-kpi>
      </div>
      <div class="col-3 mb-3">
        <app-card-kpi label="Offenes Kontingentvolumen" [value]="data?.Packages?.Due" unit="">
        </app-card-kpi>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5>Zeitreihen</h5>
      </div>
      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Conversions
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="conversionsChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Durchschnittswerte
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="averagesChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Qualität
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="qualityChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Volumen
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="volumeChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Auslastung
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="utilizationChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
