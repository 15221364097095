
<sc-modal-wizard #actionModal size="md">
    <div class="modal-wizard-body">
        <sc-modal-wizard-page #proceed>
            <div class="modal-header">
                Fortfahren?
            </div>
            <div class="modal-body" style="min-height: 150px;">
               
                <div class="row align-items-center">
                    <div class="col">
                        <ng-content select=".body"></ng-content>                            
                    </div>
                </div>
            </div>
            <div
             class="modal-footer">
                <button class="btn btn-secondary" (click)="actionModal.dismiss();">Abbrechen</button>
                <button [disabled]="isExecuting" class="btn {{ confirmBtnClass }}" (click)="execute()">
                    <span class="spinner-border spinner-border-sm" *ngIf="isExecuting"></span>
                    Fortfahren
                </button>
            </div>
        </sc-modal-wizard-page>
  
        <sc-modal-wizard-page #confirmation>
            <div class="modal-header">
                Erledigt
            </div>
            <div class="modal-body">
                <div class="alert alert-success mb-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <ng-content select=".success"></ng-content>   
                            <div *ngIf="successMessage">{{ successMessage }}</div>                         
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="actionModal.dismiss();">Schließen</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page #error>
            <div class="modal-header">
                Fehler
            </div>
            <div class="modal-body">
                <div class="alert alert-danger mb-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <ng-content select=".error"></ng-content>   
                            <div *ngIf="errorMessage">{{ errorMessage }}</div>                  
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="actionModal.dismiss();">Schließen</button>
            </div>
        </sc-modal-wizard-page>
    </div>
  </sc-modal-wizard>