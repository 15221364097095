import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-inline-select-sales-status',
  templateUrl: './inline-select-sales-status.component.html',
  styleUrls: ['./inline-select-sales-status.component.scss']
})
export class InlineSelectSalesStatusComponent {

  @Input()
  candidate : any;

  @Output()
  changed = new EventEmitter<any>();

  statusDisplayNames = {
    None: "Kein Status",
    New: "Qualified",
    WarmDirect: "WarmDirect",
    WarmIndirect: "WarmIndirect",
    Scheduling: "Scheduling",
    Scheduled: "Scheduled",
    Negotiating: "Negotiating",
    Closing: "Closing",
    Upselling: "Upselling",
    Closed: "Closed",
    Reapproach: "Reapproach",
    Disqualified: "Disqualified",
    Blacklisted: "Blacklisted"
  };
}
