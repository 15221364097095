<div class="form-control clickable" (click)="selectOrganization()">
  <div class="person-container" *ngIf="organization">
    <span class="name-container oneline">{{ organization.Name }}</span>
  </div>

  <div class="button-container" *ngIf="organization">
	<button *ngIf="canSetNull" class="btn btn-sm btn-circle btn-secondary me-2" (click)="setNull(); $event.stopPropagation()">
		<i class="fa-sharp fa-solid fa-xmark"></i>
	</button>
	<button class="btn btn-sm btn-circle btn-secondary" (click)="navigate(); $event.stopPropagation()">
		<i class="fa-sharp fa-solid fa-arrow-up-right-from-square"></i>
	</button>
  </div>
</div>
<app-modal-select-organization #modal></app-modal-select-organization>
