<div class="comment">

    <div class="row">
        <div class="col-auto">
            <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
        </div>

        <div class="col ms-n2">
            <div class="comment-header">
                <div class="row">
                    <div class="col">
                        <sc-activity-item-header [context]="context" [activity]="activity"></sc-activity-item-header>
                        <div class="comment-label">
							<p>Status geändert:</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comment-body-container">
                <div class="comment-body">
                    <div class="row mb-1 align-items-center no-gutters" *ngIf="activity.Payload?.NewStatus && activity.Payload?.NewStatus != activity.Payload?.OldStatus">
                        <div class="col-auto pe-2" *ngIf="activity.Payload?.OldStatus && activity.Payload?.OldStatus != 'None' && activity.Payload?.OldStatus != ''">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.OldStatus }}</span>
                        </div>
                        <div class="col-auto pe-2">
                            <span><i class="fa-sharp fa-solid fa-arrow-right"></i></span>
                        </div>
                        <div class="col-auto">
                            <span class="badge badge-pill badge-light d-block">{{ activity.Payload?.NewStatus }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>