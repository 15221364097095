import { Component, ViewChild } from '@angular/core';
import { ModalWizardComponent, ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-select-billing-address',
  templateUrl: './modal-select-billing-address.component.html',
  styleUrls: ['./modal-select-billing-address.component.scss']
})
export class ModalSelectBillingAddressComponent {

  @ViewChild(ScuiModalComponent)
  modal: ScuiModalComponent;

  addresses : any = [];

  constructor(private api: ScApiService) { }

  ngOnInit() {
  }
  
  public show(organizationId) {

    this.api.get('companies/' + organizationId + '/billing-addresses').subscribe(d => {
      this.addresses = d;
    })

    return this.modal.show();
  }

}
