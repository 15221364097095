import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectCadenceComponent } from 'src/components/modal-select-cadence/modal-select-cadence.component';

@Component({
  selector: 'app-candidate-cadences',
  templateUrl: './candidate-cadences.component.html',
  styleUrls: ['./candidate-cadences.component.scss']
})
export class CandidateCadencesComponent {

  @Input()
  candidate: any;

  cadences: any = [];

  @Output()
  candidateChange = new EventEmitter<any>();

  @ViewChild(ModalSelectCadenceComponent)
  cadenceModal : ModalSelectCadenceComponent;

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {  
    this.load();
  }

  load()
  {
    this.api.get('candidates/' + this.candidate.Id + '/cadences').subscribe((d : any) => {
      this.cadences = d;
    });
  }

  addCadence()
  {
    this.cadenceModal.show().subscribe((c: any) => {
      if (!c) return;
      var args = { PersonId : this.candidate.Guid };
      this.api.post('cadences/' + c.Id + '/people', args).subscribe((d : any) => {
        this.cadences.push(d);
      });
    });
  }
}
