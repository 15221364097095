<div class="row">
	<div class="col-12">
	  <p>
		Bitte stelle sicher, dass alle Rückmeldungen von Kandidat:innen bearbeitet sind und versende dann die Zweitnachrichten. 
	  </p>	  
	</div>
	<div class="col-12" *ngIf="isTaskCreated">
		<div class="alert alert-success">
			Die Zweitnachrichten werden zum nächstmöglichen Zeitpunkt vom Assistenten versendet.
		</div>
	</div>
	<div class="col-12">
		<button class="btn btn-dark" (click)="sendFollowupMessages()" [disabled]="isTaskCreated || isCreatingTask">Zweitnachrichten versenden</button>
	</div>
  </div>
  