import { Component, Input } from '@angular/core';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-project-dashboard-tasks',
  templateUrl: './project-dashboard-tasks.component.html',
  styleUrls: ['./project-dashboard-tasks.component.scss']
})
export class ProjectDashboardTasksComponent {
  
  @Input()
  project: any;

  tasks : any = [];

  constructor(public notifications: NotificationsService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
      this.tasks = this.notifications.tasks.getTasksOfProject(this.project.Id);
  }
}
