import { Component, Input, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-company-projects',
  templateUrl: './company-projects.component.html',
  styleUrls: ['./company-projects.component.scss']
})
export class CompanyProjectsComponent implements OnChanges {

  @Input()
  company : any;

  projects : any = [];

  constructor(private api : ScApiService)
  {   }

  ngOnChanges()
  {
    this.api.get('companies/' + this.company.Id + '/projects').subscribe(d => {
      this.projects = d;
    });
  }


}
