<ng-template [appTopbarTemplate]>
  <div class="title h4">
    Skill: {{mapping?.Key}}
  </div>
  <div class="actions">
    <button class="btn btn-dark" (click)="createNew()">Neu</button>
  </div>
</ng-template>

<div class="main-content">

  <div class="container">

    <div class="row">
      <div class="col-12">
        <div class="card mt-3">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="card-header-title">
                  {{ mapping.Key }}
                </h5>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="removeSuggestions()">
                  Remove Suggestions
                </button>
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="removeAmbiguities()">
                  Remove Ambiguities
                </button>
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="claimAmbiguities()">
                  Claim Ambiguities
                </button>               
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="blacklist()">
                  <i class="fa-sharp fa-regular fa-ban"></i> Blacklist
                </button>
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="delete()">
                  <i class="fa-sharp fa-regular fa-ban"></i> Delete
                </button> 
				<button class="btn btn-sm btn-round btn-secondary" (click)="approve()">
                  <i class="fa-sharp fa-regular fa-badge-check"></i> Approve
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="tag-list list-inline mb-0">
              <li class="list-inline-item" *ngFor="let tag of mapping.Tags">
                <span class="tag tag-clip-text tag-light" [class.text-danger]="tag.IsAmbiguous"><span class="clickable" [class.text-primary]="tag.isSelected" (click)="tag.isSelected = !tag.isSelected">{{tag.Tag}} </span><i (click)="deleteTag(mapping, tag)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i></span>
              </li>
              <li class="list-inline-item suggestion-item" *ngFor="let tag of mapping.Suggestions">
                <span class="tag tag-clip-text tag-light" [class.text-danger]="tag.IsAmbiguous"><span class="clickable" (click)="addSuggestion(tag)">{{tag.Tag}} </span><i (click)="deleteSuggestion(mapping, tag)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i></span>
              </li>

			  <li class="list-inline-item suggestion-item" *ngFor="let tag of nearestNeighbours">
                <span class="tag tag-clip-text tag-light"><span class="clickable" (click)="addNeighbour(tag)">{{tag.Tag}} </span></span>
              </li>
            </ul>
            <input #newTagInput class="form-control" placeholder="Neuen Tag eingeben" (scOnEnter)="addTag(mapping, newTagInput.value); newTagInput.value ='';" />
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card mt-3" *ngFor="let a of ambiguities">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="card-header-title">
                  {{ a.Mapping.Key }}
                </h5>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-round btn-secondary me-2" (click)="merge(a)">
                  <i class="fa-regular fa-merge"></i> Merge
                </button>
                <button class="btn btn-sm btn-round btn-secondary" (click)="merge(a)">
                  <i class="fa-regular fa-merge"></i> Remove Ambiguities
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="tag-list list-inline mb-0">
              <li class="list-inline-item" *ngFor="let tag of a.Mapping.Tags">
                <span class="tag tag-clip-text tag-light" [class.text-danger]="tag.IsAmbiguous">{{tag.Tag}} <i (click)="deleteTag(mapping, tag)" class="fa-sharp fa-regular fa-xmark ms-2 clickable"></i></span>
              </li>
            </ul>
          </div>

        </div>

      </div>

    </div>

  </div>
