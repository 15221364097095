import { Component } from '@angular/core';
import { ScApiService } from '@sc-ui';
import Chart from 'chart.js/auto';
import { ChartColorsService } from 'src/services/chart-colors.service';

@Component({
  selector: 'app-dashboard-marketing',
  templateUrl: './dashboard-marketing.component.html',
  styleUrls: ['./dashboard-marketing.component.scss']
})
export class DashboardMarketingComponent {

  period = '14d';

  type = 'page_view';

  utmSource = '';

  utmCampaign = '';

  eventsChart : any;

  conversionChart : any;

  utmCampaigns : any = []

  constructor(private api: ScApiService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.api.get('dashboard/marketing/events').subscribe((d: any) => {

      this.utmCampaigns = d.Campaigns;

      this.eventsChart = new Chart('eventsChart', {
        type: 'bar',
        data: {
          labels: d.Data.map(i => i.Label),

          datasets: [
            {
              label: 'Ereignisse',
              data: d.Data.map(i => i.Count),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            }
          ]
        }
      });
    });

    this.api.get('dashboard/marketing/conversion').subscribe((d: any) => {

      this.conversionChart = new Chart('conversionChart', {
        type: 'bar',
        data: {
          labels: d.Data.map(i => i.Label),

          datasets: [
            {
              label: 'Conversion: Demo-Buchungen [%]',
              data: d.Data.map(i => i.Count),
              backgroundColor: 'rgba(0,98,215,0.8)',
              borderColor: 'rgba(0,98,215,0.8)',
            }
          ]
        }
      });
    });
  }

  reload()
  {
    this.api.get('dashboard/marketing/events?period=' + this.period + '&type=' + this.type + '&utmSource=' + this.utmSource + '&utmCampaign=' + this.utmCampaign).subscribe((d: any) => {
      
      if (!this.utmCampaign)
      {
        this.utmCampaigns = d.Campaigns;
      }
      
      this.eventsChart.data.labels = d.Data.map(i => i.Label);
      this.eventsChart.data.datasets[0].data = d.Data.map(i => i.Count);
      this.eventsChart.update();
      
    });

    this.api.get('dashboard/marketing/conversion?period=' + this.period + '&type=' + this.type + '&utmSource=' + this.utmSource + '&utmCampaign=' + this.utmCampaign).subscribe((d: any) => {
      
      
      this.conversionChart.data.labels = d.Data.map(i => i.Label);
      this.conversionChart.data.datasets[0].data = d.Data.map(i => i.Count);
      this.conversionChart.update();
      
    });
  }

}
