import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScUploadService, ScuiModalComponent } from '@sc-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScApiService } from '@sc-ui';

import * as _ from 'lodash';
const utils = require('../../utils/diff');

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  saveTimer: any;

  id: any;

  isSaving = false;

  private _savedCompany: any;

  company: any;

  locations: any = [];

  tags: any;

  isCustomer = false;

  assetLanguage = 'de';

  deleteConfirmation = '';

  view = 'base';

  @ViewChild('convertToHotLeadModal')
  convertToHotLeadModal: ScuiModalComponent;

  @ViewChild('convertToCustomerModal')
  convertToCustomerModal: ScuiModalComponent;

  constructor(private api: ScApiService,
    private uploadApi: ScUploadService,
    private route: ActivatedRoute,
    private router: Router,
    private modal: NgbModal) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params : any) => {
      this.view = params.view ?? 'base';
    });   

    this.api.get('companies/tags').subscribe(d => {
      this.tags = d;
    });

    this.route.params.subscribe(
      params => {
        this.id = params["id"];

        this.api.get("companies/" + this.id).subscribe((d: any) => {
          this.company = d;
          this._savedCompany = _.cloneDeep(d);
        });

        this.api.get("companies/" + this.id + '/locations').subscribe(d => {
          this.locations = d;
        });
      });
  }

  addDomain(name) {
    var arg = {
      Host: name
    };

    this.company.Domains.push(arg);
    this.save();
  }

  removeDomain(d)
  {
    var index = this.company.Domains.indexOf(d);
    this.company.Domains.splice(index, 1);
    this.save();
  }

  addName(name) {
    var arg = {
      Name: name
    };

    this.company.Names.push(arg);    
    this.save();
  }

  removeName(d)
  {
    var index = this.company.Names.indexOf(d);
    this.company.Names.splice(index, 1);
    this.save();
  }

  uploadLogo(files) {
    this.uploadApi.upload(files, (d: any) => {
      this.company.Profile.LogoUri = d.Uri;
      this.save();
    });
  }

  save() {
    if (this.isSaving) return;
    this.isSaving = true;

    var diff = utils.diff(this._savedCompany, this.company);
    this.api.put("companies/" + this.id + "/merge", diff).subscribe((d : any) => {
      this.company.Names = d.Names;
      this.company.Domains = d.Domains;
      this._savedCompany = _.cloneDeep(this.company);
      this.isSaving = false;
    });
  }

  saveSoon() {
    console.log('soon');
    if (this.saveTimer) clearTimeout(this.saveTimer);
    this.saveTimer = setTimeout(() => { this.save(); }, 500);
  }

  addTag(tag) {
    if (!this.company.Tags) this.company.Tags = "";
    var contains = this.company.Tags.indexOf(tag) >= 0;
    if (contains) return;
    this.company.Tags = this.company.Tags.trim() + ' ' + tag;
    this.saveSoon();
  }

  delete() {
    this.api.delete("companies/" + this.company.Id).subscribe(d => {
      this.router.navigateByUrl('/companies');
    });
  }

  activateView(v)
  {
    this.router.navigateByUrl('/companies/' + this.id + '?view=' + v);
  }



  

}
