<div id="login" class="d-flex align-items-center" style="height: 100vh;">
  <div class="container">
    <div class="row">

      <div class="col-10 offset-1">

        <form>

          <div class="form-group">
            <label>E-Mail</label>
            <input type="email" name="eMail" [(ngModel)]="eMail" class="form-control">
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col">
                <label>Passwort</label>
              </div>

            </div>
            <div class="input-group input-group-merge">
              <input type="password" name="password" [(ngModel)]="password" class="form-control">
            </div>
          </div>

          <button class="btn btn-lg btn-block btn-primary mb-3" type="button" (click)="login();">
            Anmelden
          </button>

        </form>
      </div>
    </div>
  </div>
</div>