<div class="card" scAnchorTarget="Stammdaten">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Stammdaten
        </h6>
      </div>
    </div>
  </div>

  <div class="card-body">
    <sc-form (change)="saveSoon()">

      <sc-form-row label="Name">
        <div class="row g-0">
          <div class="col-2 pe-2">
            <select [(ngModel)]="company.Gender">
              <option value="f">die</option>
              <option value="m">der</option>
              <option value="n">das</option>
            </select>
          </div>
          <div class="col-10">
            <input [(ngModel)]="company.Name" />
          </div>
        </div>
      </sc-form-row>

      <sc-form-row label="Account-Manager:in">
        <app-inline-select-contact organizationId="1" [allowCreate]="false" [canSetNull]="true" [allowSearch]="false" [(contactId)]="company.ManagerId" (contactIdChange)="saveSoon()">
        </app-inline-select-contact>
        <!-- <app-inline-select-person [organizationId]="1" [(personId)]="company.ManagerId" (personIdChange)="saveSoon()"></app-inline-select-person> -->
      </sc-form-row>

      <sc-form-row label="Form">
        <select [(ngModel)]="company.IsGroup">
          <option [ngValue]="false">Gesellschaft</option>
          <option [ngValue]="true">Unternehmensgruppe</option>
        </select>
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Gruppe">
        <app-inline-select-organization [canSetNull]="true" [(organizationId)]="company.ParentOrganizationId" (organizationIdChange)="saveSoon()"></app-inline-select-organization>
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Sales-Status">
        <select [(ngModel)]="company.SalesStatus">
          <option ngValue="None"></option>
          <option ngValue="Opportunity">Opportunity</option>
          <option ngValue="Closed">Aktiv</option>
          <option ngValue="Inactive">Inaktiv</option>
          <option ngValue="Lost">Verloren</option>
          <option ngValue="Blacklisted">Blacklisted</option>
        </select>
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Marketing-Status">
        <select [(ngModel)]="company.MarketingStatus">
          <option ngValue="None">Keine Angabe</option>
          <option ngValue="Active">Active</option>
          <option ngValue="Unsubscribed">Unsubscribed</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Akquisekanal">
        <select [(ngModel)]="company.AcquisitionChannel">
          <option ngValue="Undefined">Keine Angabe</option>
          <option ngValue="Direct">Eigene Recherche</option>
          <option ngValue="Referral">Empfehlung</option>
          <option ngValue="Event">Event</option>
          <option ngValue="ColdCall">Kaltakquise - Telefon</option>
          <option ngValue="LinkedIn">LinkedIn</option>
          <option ngValue="LinkedInDirect">LinkedIn - Direktnachricht</option>
          <option ngValue="XingDirect">XING - Direktnachricht</option>
          <option ngValue="SalesPartner">Partner</option>
          <option ngValue="Podcast">Podcast</option>
          <option ngValue="Mailing">Mailing</option>
          <option ngValue="MailingNewsletter">Mailing - Newsletter</option>
        </select>
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Branche">
        <input [(ngModel)]="company.IndustryId" [editable]="false" [resultFormatter]="industryIdToIdWithName" [inputFormatter]="industryIdToName" [ngbTypeahead]="searchIndustries" />
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Größe">
        <select [(ngModel)]="company.Size">
          <option [ngValue]="-1">keine Angabe</option>
          <option [ngValue]="0">= 1</option>
          <option [ngValue]="1">> 1</option>
          <option [ngValue]="10">> 10</option>
          <option [ngValue]="50">> 50</option>
          <option [ngValue]="200">> 200</option>
          <option [ngValue]="500">> 500</option>
          <option [ngValue]="1000">> 1000</option>
          <option [ngValue]="5000">> 5000</option>
          <option [ngValue]="10000">> 10000</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Karriereseite">
        <div class="input-group">
          <input class="form-control" [(ngModel)]="company.CareerSite" />
          <div class="input-group-append">
            <a *ngIf="company.CareerSite" [href]="company.CareerSite" target="_blank" class="btn btn-secondary">
              <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
            <a *ngIf="!company.CareerSite" href="https://www.google.com/search?q={{company.Name}}+karriere" target="_blank" class="btn btn-secondary">
              <i class="fa-solid fa-magnifying-glass"></i>
            </a>
          </div>
        </div>
      </sc-form-row>

      <sc-form-row label="Tags">
        <input [(ngModel)]="company.Tags">
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Stadt">
        <input [(ngModel)]="company.City" />
      </sc-form-row>

      <sc-form-row *ngIf="!company.IsGroup" label="Land">
        <input [(ngModel)]="company.Country" />
      </sc-form-row>

      <sc-form-row label="Anmerkungen">
        <autosize-textarea [(text)]="company.Description"></autosize-textarea>
      </sc-form-row>

    </sc-form>

  </div>
</div>


<div class="card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Aktivitäten
        </h6>
      </div>
    </div>
  </div>

  <div class="card-body">

    <!-- <div class="row mb-5">

      <div class="col">
        <autosize-textarea [(text)]="callComment" placeholder="Kommentar"></autosize-textarea>
      </div>

      <div class="col-auto ps-0">
        <button class="btn btn-dark btn-round btn-circle" [disabled]="!callComment?.length" (click)="postCall()">
          <i class="fa-sharp fa-solid fa-paper-plane"></i>          
        </button>
      </div>
    </div> -->

    <sc-activity-feed #feed [scAnchorTarget]="'Aktivitäten'" uri="companies/{{company.Id}}/activities" headline="" readOnly="true" pageSize="40">
      <ng-template let-activity="activity">
        <sc-activity-item [activity]="activity" [condensed]="false"></sc-activity-item>
      </ng-template>
    </sc-activity-feed>
  </div>
</div>