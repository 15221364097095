import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-badge-cadence-person-assignment',
  templateUrl: './status-badge-cadence-person-assignment.component.html',
  styleUrls: ['./status-badge-cadence-person-assignment.component.scss']
})
export class StatusBadgeCadencePersonAssignmentComponent {

  @Input()
  assignment : any;
  
}
