<!-- <span class="badge badge-pill badge-light" *ngIf="assignment.Status == 'None'">
    Importiert
</span> -->

<span class="badge badge-pill badge-primary-soft" *ngIf="assignment.Status == 'Contacted'">
    Kontaktiert
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Interested'">
    Interessiert
</span>
<span class="badge badge-pill badge-danger-soft" *ngIf="assignment.Status == 'NotInterested'">
    Nicht Interessiert
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Listed'">
    Vorgestellt
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'ListedNonCommittal'">
    Vorgestellt (unverbindlich)
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Accepted'">
    Eingeladen
</span>
<span class="badge badge-pill badge-success-soft" *ngIf="assignment.Status == 'Hired'">
    Eingestellt
</span>
<span class="badge badge-pill badge-danger-soft" *ngIf="assignment.Status == 'Rejected'">
    Abgelehnt
</span>
<span class="badge badge-pill badge-danger-soft" *ngIf="assignment.Status == 'Revoked'">
    Zurückgezogen
</span>