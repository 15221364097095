import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent } from '@sc-ui';

@Component({
  selector: 'app-candidate-modal-edit-skill',
  templateUrl: './candidate-modal-edit-skill.component.html',
  styleUrls: ['./candidate-modal-edit-skill.component.scss']
})
export class CandidateModalEditSkillComponent implements OnInit {

  @ViewChild(ModalWizardComponent)
  modal : ModalWizardComponent;

  @Input()
  skill : any = null;

  @Output()
  skillChanged = new EventEmitter<any>();

  @Output()
  skillDeleted = new EventEmitter<any>();

  @ViewChild(ModalWizardPageComponent)
  editPage : ModalWizardPageComponent;

  constructor() { }

  ngOnInit() {
  }

  show()
  {
    this.modal.show();
    this.modal.navigateTo(this.editPage);
  }

  deleteSkill()
  {
    this.skillDeleted.emit();
  }

  onChange()
  {
    this.skillChanged.emit(this.skill);
  }

}
