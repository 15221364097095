import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/services/notifications.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-nav-primary',
  templateUrl: './nav-primary.component.html',
  styleUrls: ['./nav-primary.component.scss']
})
export class NavPrimaryComponent implements OnInit {

  secondaryNavVisible = false;

  tasksVisible = false;

  isModalOpen = false;

  @HostBinding('class.tasks-pinned')
  pinTasks = false;

  constructor(
    private router: Router,
    public userService: UserService,
    public notifications: NotificationsService) {
  }

  ngOnInit() {

    this.notifications.onPin.subscribe(() => {
      this.pinTasks = true;
      this.tasksVisible = true;
    });

    this.notifications.onUnpin.subscribe(() => {
      this.pinTasks = false;
      this.tasksVisible = false;
    });

    this.pinTasks = this.notifications.isPinned;
    this.tasksVisible = this.notifications.isPinned;

  }

  handleTaskModalOpenChange(isOpen) {
    if (isOpen) {
      if (this["hideTasksTimeout"]) clearTimeout(this["hideTasksTimeout"]);
      this.isModalOpen = true;
    }
    else {
      this.isModalOpen = false;
      this["hideTasksTimeout"] = setTimeout(() => this.hideTasks(), 1500);
    }
  }

  showTasks() {
    this.hideSecondaryNav();
    if (this["hideTasksTimeout"]) clearTimeout(this["hideTasksTimeout"]);
    this.tasksVisible = true;
  }

  hideTasks() {
    if (this.isModalOpen) return;
    this.pinTasks = false;
    this.tasksVisible = false;
  }

  hideTasksSoon() {
    if (this.pinTasks) return;
    if (this.isModalOpen) return;
    if (this["hideTasksTimeout"]) clearTimeout(this["hideTasksTimeout"]);
    this["hideTasksTimeout"] = setTimeout(() => this.hideTasks(), 500);
  }

  showSecondaryNav() {
    if (!this.pinTasks) this.hideTasks();
    if (this["hideSecondaryNavTimeout"]) clearTimeout(this["hideSecondaryNavTimeout"]);
    this.secondaryNavVisible = true;
  }

  hideSecondaryNav() {
    this.secondaryNavVisible = false;
  }

  hideSecondaryNavSoon() {
    if (this["hideSecondaryNavTimeout"]) clearTimeout(this["hideSecondaryNavTimeout"]);
    this["hideSecondaryNavTimeout"] = setTimeout(() => this.hideSecondaryNav(), 500);
  }

  navigate(url) {
    this.hideSecondaryNav();
    this.router.navigateByUrl(url);
  }
}
