import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-badge-person',
  templateUrl: './status-badge-person.component.html',
  styleUrls: ['./status-badge-person.component.scss']
})
export class StatusBadgePersonComponent {

  @Input()
  person : any;

}
