<sc-modal #modal size="lg">
    <div class="modal-header">
        <h4 class="modal-title">Chatbot ausspielen</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mb-2" *ngIf="selectedTags()?.length > 0">
            <div class="col-3">
                <label>Tags</label>
            </div>
            <div class="col-9">
                <span *ngFor="let tag of selectedTags()" class="badge ms-1 mb-2 me-1" [class.badge-primary]="tag.isSelected" [class.bg-danger]="tag.isExcluded" [title]="tag.Tag">{{truncate(tag.Tag, 50)}}</span>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Batch</label>
            </div>
            <div class="col-9">
                <input class="form-control" type="text" readonly [(ngModel)]="filter.batchId" name="batchId" />
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-3">
                <label>ID</label>
            </div>
            <div class="col-9">
                <input #chatbotId class="form-control" type="text" name="guid" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                CreateIfCompleted
            </div>
            <div class="col">
                <div class="custom-control custom-switch mb-3">
                    <input type="checkbox" class="custom-control-input" id="createIfCompleted" [(ngModel)]="createIfCompleted">
                    <label class="custom-control-label" for="createIfCompleted"></label>
                </div>

                <div class="alert alert-light" role="alert">
                    <h4 class="alert-heading"><i class="fa-regular fa-triangle-exclamation me-2"></i> CreateIfCompleted</h4>
                    <i>CreateIfCompleted</i> legt fest, ob ein Chat ausgespielt werden soll, wenn der Kandidat den gleichen Chat schon einmal vollständig beendet hat.
                    <br><br> 
                    Chatbots werden grundsätzlich <strong>nicht</strong> ausgespielt, wenn der gleiche Chat schon an den Kandidaten ausgespielt und von diesem noch nicht vollständig durchlaufen wurde.
                  </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                Notifications
            </div>
            <div class="col">
                <div class="custom-control custom-switch mb-3">
                    <input type="checkbox" class="custom-control-input" id="sendNotifications" [(ngModel)]="sendNotifications">
                    <label class="custom-control-label" for="sendNotifications"></label>
                </div>
                <div class="alert alert-light" role="alert">
                    <h4 class="alert-heading"><i class="fa-regular fa-triangle-exclamation me-2"></i> Notifications</h4>
                    Notifications werden wie folgt versendet:<br><br>
                    - Wenn im Chatbot kein Notification-Title oder kein Notification-Inhalt angegeben wurde, passiert nichts<br>
                    - Wenn der Kandidat die App installiert hat, wird eine Push-Notification versendet (auf alle Devices mit installierter App)<br>
                    - Wenn eine E-Mail-Adresse angegeben ist, wird eine E-Mail versendet, ggf. auch zusätzlich zur Push-Notification<br><br>
                    Welche Notifications versendet wurden, ist im Activity-Feed des Kandidatenprofils zu sehen.
                  </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-dark" [disabled]="isPosting" (click)="postChat(chatbotId.value);">
            <i class="fa fa-play"></i>&nbsp;{{isPosting ? 'Wird ausgespielt' : 'Ausspielen'}}
        </button>
    </div>
</sc-modal>