import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ProjectService } from 'src/services/project.service';

@Component({
  selector: 'app-project-imports',
  templateUrl: './project-imports.component.html',
  styleUrls: ['./project-imports.component.scss']
})
export class ProjectImportsComponent implements OnInit {

  id : any;

  project : any;

  imports : any = [];

  selectedImport : any;

  firstMessageArgs = {
    BatchId: -1,
    TemplateId: 498
  }

  secondMessageArgs = {
    BatchId: -1,
    TemplateId: 508
  }

  typeNames = {
    FirstMessage : "Erstansprache",
    SecondMessage : "Zweitansprache"
  }

  constructor(private route : ActivatedRoute, private api : ScApiService, private projectService : ProjectService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.id = params['id'];
        var cached = this.projectService.getFromCache(this.id);
        if (cached) this.project = cached;    
        this.projectService.load(this.id).subscribe(p => { 
          this.project = p;
        });

        this.api.get('projects/' + this.id + '/imports').subscribe(d => {
          this.imports = d;
          this.imports.forEach(i => {
            if (i.Type != 'Import') return;
            var cleanUrl = decodeURI(i.Payload.Url);
            i.cleanUrl = cleanUrl;
          });
        });
      });
  }

  

  addFirstMessage()
  {
    var newImport = {
      ProjectId : this.id,
      Type: 'FirstMessage',
      Status : 'Pending',
      Payload : {
        BatchId: this.firstMessageArgs.BatchId,
        TemplateId: this.firstMessageArgs.TemplateId
      }
    };

    this.api.post('projects/' + this.id + '/imports', newImport).subscribe(d => {
      this.imports.push(d);
      this.firstMessageArgs = {
        BatchId: -1,
        TemplateId: 498
      }
    });
  }

  

  addSecondMessage()
  {
    var newImport = {
      ProjectId : this.id,
      Type: 'SecondMessage',
      Status : 'Pending',
      Payload : {
        BatchId: this.secondMessageArgs.BatchId,
        TemplateId: this.secondMessageArgs.TemplateId
      }
    };

    this.api.post('projects/' + this.id + '/imports', newImport).subscribe(d => {
      this.imports.push(d);
      this.secondMessageArgs = {
        BatchId: -1,
        TemplateId: 508
      }
    });
  }

  deleteImport(i)
  {
    this.api.delete('projects/' + this.id + '/imports/' + i.Id).subscribe(d => {
      var index = this.imports.indexOf(i);
      this.imports.splice(index, 1);
    });
  }

  restartImport(i)
  {
    if (i.isRestarting) return;
    i.isRestarting = true;
    i.Status = 'Pending';
    this.api.put('imports', i).subscribe(d => {  i.isRestarting = false; });
  }

}
