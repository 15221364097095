<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content">

  <div *ngIf="project" class="container">

    <div class="row">

      <!-- <div class="col-md-3">
        <div class="status-col">
          <app-project-process [project]="project"></app-project-process>
        </div>
      </div> -->

      <div class="col-12">

        <div class="card mb-5">
          <sc-image-select-crop uploadUri="assets" fileType="jpeg" [minWidth]="1000" (uploaded)="project.Image = $event.Uri; save();">
            <img *ngIf="project.Image && !bannerError" [src]="project.Image | imageSize : 1200 : 395" (error)="bannerError = true;" class="card-img-top" />
            <img *ngIf="!project.Image || bannerError" src="/assets/project-hero-placeholder/hero-placeholder-{{getImageIndex()}}.jpg" class="card-img-top" />
          </sc-image-select-crop>

          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Aufgabe
                </h6>
              </div>
              <div class="col-auto pr-0">
                <div class="btn btn-sm btn-secondary" (click)="briefingModal.show()">Briefing-Infos</div>
              </div>
              <div class="col-auto pr-0">
                <a class="btn btn-sm btn-secondary d-block" href="https://jobs.skillconomy.com/job/{{project.ShortlistSecret}}" target="_blank"><i class="fa-regular fa-arrow-up-right-from-square"></i> Jobsite</a>
              </div>
              <div class="col-auto pr-0">
                <div class="dropdown-more" ngbDropdown>
                  <div ngbDropdownToggle class="btn btn-sm btn-secondary btn-circle lift me-2"><i class="fa-regular fa-ellipsis"></i></div>
                  <div ngbDropdownMenu>
                    <a class="dropdown-item" (click)="copyFromProjectModal.show()"><i class="fa-regular fa-copy"></i> Inhalte übernehmen</a>
                    <a class="dropdown-item" href="https://jobs.skillconomy.com/review/{{project.ManagerAccess?.Secret}}" target="_blank"><i class="fa-regular fa-arrow-up-right-from-square"></i> Jobsite-Review</a>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="card-body">

            <div class="row mb-2">
              <div class="col-3">
                Bezeichnung
              </div>
              <div class="col-9">
                <autosize-textarea [(text)]="project.Name" (textChange)="saveSoon()"></autosize-textarea>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3">
                Tags
              </div>
              <div class="col-9">
                <autosize-textarea [(text)]="project.Profile.Tags" (textChange)="saveSoon()" placeholder="Jeder Tag eine Zeile. Vorangestelltes '+' für Hervorhebung."></autosize-textarea>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3">
                Beschreibung
              </div>
              <div class="col-9">
                <autosize-textarea [(text)]="project.Profile.Description" (textChange)="saveSoon()" placeholder="Aufgabenbeschreibung"></autosize-textarea>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3">
                Higlights
              </div>
              <div class="col-9">
                <autosize-textarea [(text)]="project.Profile.Highlights" (textChange)="saveSoon()" placeholder="Higlights als Liste: Eine Zeile ist ein Eintrag"></autosize-textarea>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3">
                Video
              </div>
              <div class="col-9">
                <input class="form-control" name="input" rows="1" [(ngModel)]="project.Profile.VideoUri" (ngModelChange)="saveSoon()" placeholder="z.B.: https://www.youtube.com/embed/5HlbV1wKBmo" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Sprache
              </div>
              <div class="col-9">
                <select class="custom-select" [(ngModel)]="project.Profile.JobsiteLanguage" (ngModelChange)="saveSoon()">
                  <option value="de">Deutsch</option>
                  <option value="en">Englisch</option>
                </select>

                <div class="alert alert-danger mt-2" *ngIf="project.Profile.JobsiteLanguage == 'en'">
                  <div class="row">
                    <div class="col-auto">
                      <i class="fa-regular fa-triangle-exclamation"></i>
                    </div>
                    <div class="col">
                      <strong>Achtung: </strong> Die Jobsite ist weiterhin auf Deutsch! Es wird lediglich die englischsprachige Unternehmensbeschreibung verwendet! Bitte nur verwenden, wenn es nicht anders geht!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Karte ausblenden
              </div>
              <div class="col-9">
                <sc-switch [(ngModel)]="project.Profile.IsMapHidden" (ngModelChange)="saveSoon()">
                </sc-switch>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                Archiviert
              </div>
              <div class="col-9">
                <sc-switch [(ngModel)]="project.IsArchived" (ngModelChange)="saveSoon()">
                </sc-switch>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <div class="col-3">
                Öffentlich
              </div>
              <div class="col-auto pr-0">
                <sc-switch [(ngModel)]="project.IsPublic" (ngModelChange)="saveSoon()">
                </sc-switch>
              </div>
              <div class="col">
                <input placeholder="URL z.B.: skillconomy-gmbh-data-analyst" class="form-control" [disabled]="!project.IsPublic" [(ngModel)]="project.PublicUri" (ngModelChange)="saveSoon()" />
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">

          <div class="card-header">
            <h6 class="card-header-title">
              Gehalt
            </h6>
          </div>

          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-3 oneline">
                Gehalt
              </div>
              <div class="col-2">
                <input name="MinSalary" [(ngModel)]="project.Profile.Compensation.MinSalary" (ngModelChange)="saveSoon()" type="text" class="form-control" placeholder="Min. Fixgehalt">
              </div>
              <div class="col-1">
                bis
              </div>
              <div class="col-2">
                <input name="MaxSalary" [(ngModel)]="project.Profile.Compensation.MaxSalary" (ngModelChange)="saveSoon()" type="text" class="form-control" placeholder="Max. Fixgehalt">
              </div>
              <div class="col-2">
                <input name="SalaryCurrency" [(ngModel)]="project.Profile.Compensation.SalaryCurrency" (ngModelChange)="saveSoon()" type="text" class="form-control" placeholder="Währung">
              </div>
            </div>
          </div>

        </div>

        <div class="card mb-5">

          <div class="card-header">
            <h6 class="card-header-title">
              Benefits
            </h6>
          </div>

          <div class="card-body">
            <benefit-selection [benefitIds]="project.Profile.BenefitIds" (benefitIdsChange)="saveSoon()"></benefit-selection>
          </div>

        </div>

        <div class="card mb-5">
          <!-- <div class="card-header">

                        <h4 class="card-header-title">
                            Jobsite-Medien
                        </h4>

                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <sc-file-button (filesChanged)="uploadSharingImage($event)" *ngIf="project?.Profile?.SharingImageUri">
                                    <img *ngIf="project?.Profile?.SharingImageUri" [src]="project.Profile.SharingImageUri" class="img-fluid mb-2" />
                                </sc-file-button>
                                <sc-file-button (filesChanged)="uploadSharingImage($event)" *ngIf="!project?.Profile?.SharingImageUri">
                                    <div class="btn btn-sm btn-secondary">Kein Social Media Bild ausgewählt</div>
                                </sc-file-button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                            Facebook Pixel ID
                            </div>
                            <div class="col-9 mb-2">
                            <input class="form-control" name="FacebookPixelId" [(ngModel)]="project.Profile.FacebookPixelId" (ngModelChange)="saveSoon()" placeholder="Facebook Pixel ID" />
                            </div>
                        </div>

                    </div> -->

          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Impressionen
                </h6>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-secondary btn-circle" (click)="impressionEditor.addImpression()"><i class="fa-regular fa-plus"></i></button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <app-impression-editor #impressionEditor [(impressions)]="project.Profile.Workplace.Impressions" (changed)="saveSoon()"></app-impression-editor>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Projektbeispiele
                </h6>
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-secondary btn-circle" (click)="addProjectExample();"><i class="fa-regular fa-plus"></i></button>
              </div>
            </div>

          </div>

          <div class="card-body">
            <app-impression-editor #impressionEditor [(impressions)]="project.Profile.ProjectExamples" (changed)="saveSoon()"></app-impression-editor>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Chatbots
                </h6>
              </div>
            </div>

          </div>

          <div class="card-body" cdkDropList [cdkDropListData]="project.Profile.Workplace.ProjectExamples" (cdkDropListDropped)="onDrop($event); saveSoon();">

            <div class="row mb-2">
              <div class="col-3 oneline">
                Chatbot
              </div>
              <div class="col-4">
                <app-chatbot-selection [(selectedChatbotId)]="project.Profile.MainChatbot.ChatbotId" (selectedChatbotIdChange)="saveSoon()"></app-chatbot-selection>
              </div>
              <div class="col-5">
                <input class="form-control" name="input" rows="1" [(ngModel)]="project.Profile.MainChatbot.Label" (ngModelChange)="saveSoon()" placeholder="Label des Chatbots" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3 oneline">
                Fallback Chatbot
              </div>
              <div class="col-4">
                <app-chatbot-selection [(selectedChatbotId)]="project.Profile.MainFallbackChatbot.ChatbotId" (selectedChatbotIdChange)="saveSoon()"></app-chatbot-selection>
              </div>
              <div class="col-5">
                <input class="form-control" name="input" rows="1" [(ngModel)]="project.Profile.MainFallbackChatbot.Label" (ngModelChange)="saveSoon()" placeholder="Label des Chatbots" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3 oneline">
                Aufgaben-Chatbot
              </div>
              <div class="col-4">
                <app-chatbot-selection [(selectedChatbotId)]="project.Profile.TaskChatbot.ChatbotId" (selectedChatbotIdChange)="saveSoon()"></app-chatbot-selection>
              </div>
              <div class="col-5">
                <input class="form-control" name="input" rows="1" [(ngModel)]="project.Profile.TaskChatbot.Label" (ngModelChange)="saveSoon()" placeholder="Label des Chatbots" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-3 oneline">
                Jobfragen-Chatbot
              </div>
              <div class="col-4">
                <app-chatbot-selection [(selectedChatbotId)]="project.Profile.CustomQuestionsChatbotId" (selectedChatbotIdChange)="saveSoon()"></app-chatbot-selection>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<sc-modal #briefingModal size="xl">
  <div class="modal-header">
    <h4 class="modal-title">Briefing</h4>
    <button type="button" class="close" aria-label="Close" (click)="briefingModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="briefingModal.isOpen">

    <div class="row mb-2">
      <div class="col-2 oneline">
        Must-Haves
      </div>
      <div class="col-9">
        <p class="fw-bold" style="white-space: pre-wrap;">{{ project.Profile.MustHaves }}</p>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-2 oneline">
        Nice-to-Haves
      </div>
      <div class="col-9">
        <p class="fw-bold" style="white-space: pre-wrap;">{{ project.Profile.NiceToHaves }}</p>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-2 oneline">
        Selling Points
      </div>
      <div class="col-9">
        <p class="fw-bold" style="white-space: pre-wrap;">{{ project.Profile.SellingPoints }}</p>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-2 oneline">
        Protokoll
      </div>
      <div class="col-9">
        <p style="white-space: pre-wrap;">{{ project.Profile.Comment }}</p>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="briefingModal.dismiss()">Schließen</button>
  </div>
</sc-modal>

<sc-modal #notificationsSuccessModal>
  <div class="modal-header">
    <h4 class="modal-title">Notifications versendet</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="notificationsSuccessModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    Die Benachrichtigungen wurden erfolgreich versendet.
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="notificationsSuccessModal.dismiss()">OK</button>
  </div>
</sc-modal>

<sc-modal #notificationsErrorModal>
  <div class="modal-header">
    <h4 class="modal-title">Fehler</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="notificationsErrorModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    Die Benachrichtigungen konnten nicht vollständig gesendet werden.
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="notificationsErrorModal.dismiss()">OK</button>
  </div>
</sc-modal>

<sc-modal #validationErrorsModal>
  <div class="modal-header">
    <h4 class="modal-title">Fehler</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="validationErrorsModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    <ul>
      <li *ngFor="let e of validationErrors">{{e}}</li>
    </ul>
  </div>
</sc-modal>

<sc-modal #copyFromProjectModal>
  <div class="modal-header">
    <h6 class="modal-title">Inhalte von anderem Projekt kopieren</h6>
    <button type="button" class="close" aria-label="Close" (click)="copyFromProjectModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="project">
    <div class="row">
      <div class="col-3">
        Projekt-ID
      </div>
      <div class="col-9">
        <input class="form-control" name="copyFromInfo.ProjectId" [(ngModel)]="copyFromInfo.ProjectId" />
      </div>
    </div>
    <hr />
    <div class="row ">
      <div class="col-12">
        <div>
          <h6>Suche</h6>
        </div>
        <div class="checklist">
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="s1" type="checkbox" [(ngModel)]="copyFromInfo.properties.Blacklist" />
            <label class="custom-control-label" for="s1"></label>
            <span class="custom-control-caption">
              Blacklist - Unternehmen
            </span>
          </div>

          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="s2" type="checkbox" [(ngModel)]="copyFromInfo.properties.NameBlacklist" />
            <label class="custom-control-label" for="s2"></label>
            <span class="custom-control-caption">
              Blacklist - Kandidaten
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="s3" type="checkbox" [(ngModel)]="copyFromInfo.properties.Skills" />
            <label class="custom-control-label" for="s3"></label>
            <span class="custom-control-caption">
              Skills
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="s4" type="checkbox" [(ngModel)]="copyFromInfo.properties.JobTitleInclude" />
            <label class="custom-control-label" for="s4"></label>
            <span class="custom-control-caption">
              Job-Titel muss entsprechen
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="s5" type="checkbox" [(ngModel)]="copyFromInfo.properties.JobTitleExclude" />
            <label class="custom-control-label" for="s5"></label>
            <span class="custom-control-caption">
              Job-Titel darf nicht entsprechen
            </span>
          </div>
        </div>
        <hr>
        <div>
          <h6>Jobsite</h6>
        </div>
        <div class="checklist">
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c1" type="checkbox" [(ngModel)]="copyFromInfo.banner" />
            <label class="custom-control-label" for="c1"></label>
            <span class="custom-control-caption">
              Header-Bild
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c2" type="checkbox" [(ngModel)]="copyFromInfo.properties.Description" />
            <label class="custom-control-label" for="c2"></label>
            <span class="custom-control-caption">
              Aufgabenbeschreibung
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c3" type="checkbox" [(ngModel)]="copyFromInfo.properties.Tags" />
            <label class="custom-control-label" for="c3"></label>
            <span class="custom-control-caption">
              Tags
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c4" type="checkbox" [(ngModel)]="copyFromInfo.properties.Highlights" />
            <label class="custom-control-label" for="c4"></label>
            <span class="custom-control-caption">
              Highlights
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c5" type="checkbox" [(ngModel)]="copyFromInfo.salary" />
            <label class="custom-control-label" for="c5"></label>
            <span class="custom-control-caption">
              Gehalt
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c51" type="checkbox" [(ngModel)]="copyFromInfo.benefits" />
            <label class="custom-control-label" for="c51"></label>
            <span class="custom-control-caption">
              Benefits
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c6" type="checkbox" [(ngModel)]="copyFromInfo.properties.Locations" />
            <label class="custom-control-label" for="c6"></label>
            <span class="custom-control-caption">
              Locations
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c7" type="checkbox" [(ngModel)]="copyFromInfo.properties.Workplace" />
            <label class="custom-control-label" for="c7"></label>
            <span class="custom-control-caption">
              Arbeitsumgebung
            </span>
          </div>
          <div class="custom-control custom-checkbox checklist-control">
            <input class="custom-control-input" id="c8" type="checkbox" [(ngModel)]="copyFromInfo.properties.ProjectExamples" />
            <label class="custom-control-label" for="c8"></label>
            <span class="custom-control-caption">
              Projektbeispiele
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-3">
      <div class="col-12">
        <p>Willst du die Felder wirklich unwiderruflich überschreiben? Bitte gib in das Textfeld <i>ja</i> (klein
          geschrieben) ein um fortzufahren.</p>
      </div>
      <div class="col-12">
        <input class="form-control" [(ngModel)]="copyFromInfo.confirm" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="copyFromProjectModal.dismiss()">Abbrechen</button>
    <button [disabled]="copyFromInfo.confirm != 'ja' || !copyFromInfo.ProjectId" class="btn btn-dark" (click)="executeCopyFromProject(); copyFromProjectModal.dismiss()">OK</button>
  </div>
</sc-modal>
