import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { SidebarService } from 'src/services/sidebar.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-topbar-sidebar',
  templateUrl: './topbar-sidebar.component.html',
  styleUrls: ['./topbar-sidebar.component.scss']
})
export class TopbarSidebarComponent implements OnInit {

  constructor(public userService : UserService,
    public sidebarService : SidebarService,
    private api : ScApiService) { }

  ngOnInit(): void {
  }

  logout()
  {
    this.api.logout().subscribe();
  }

}
