import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-package-quote',
  templateUrl: './package-quote.component.html',
  styleUrls: ['./package-quote.component.scss']
})
export class PackageQuoteComponent {

  @Input()
  package : any;

  @Output()
  packageChange = new EventEmitter<any>();

  onChange()
  {
    this.packageChange.next(this.package);
  }

}
