<ng-template [appTopbarTemplate]>
  <div class="title">
    <h4 class="mb-0">Sales Dashboard</h4>
  </div>
</ng-template>

<div class="main-content">
  <div class="container">
    <div class="row mb-4">
      <div class="col-3">
        <app-card-kpi 
          label="Umsatz"
          [value]="turnoverData?.CurrentYear" 
          [trend]="turnoverData?.Trend">
        </app-card-kpi>
      </div>

      <div class="col-3">
        <app-card-kpi 
          label="Umsatz im Vergleichszeitraum"
          [value]="turnoverData?.LastYearUntilCurrentDate" >
        </app-card-kpi>
      </div>

      <div class="col-3">
        <app-card-kpi 
          label="Umsatz Vorjahr"
          [value]="turnoverData?.LastYear" >
        </app-card-kpi>
      </div>

      <div class="col-3">
        <app-card-kpi 
          label="Forecast"
          [value]="turnoverData?.Forecast" >
        </app-card-kpi>        
      </div>

    </div>
    <div class="row">

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Erstumsatz 6M
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="basketSizeChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Closed
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="statusChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Warmed
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="warmedChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Scheduled
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="conversionsChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Calls
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="callsChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Umsatz
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="turnoverChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Kunden
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="customerCountChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>    

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Kennenlerncalls
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="salesActivitiesChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Kennenlerncall-Conversions
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="salesConversionsChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Neukundenumsatz je Kanal Ø3M
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="channelChart" style="height: 40vh;" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Erstkunden
            </h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr class="fw-bold">
                        <td class="py-1" style="width: auto;">Kanal</td>
                        <td class="py-1" style="width: 180px;">Kunden</td>
                        <td class="py-1" style="width: 180px;">Umsatz</td>
                        <td class="py-1" style="width: 180px;">Umsatzanteil</td>
                        <td class="py-1" style="width: 180px;">Trend</td>
                      </tr>
                      <tr *ngFor="let a of acquisitionData; let i = index;" class="px-0">
                        <td class="py-1 col oneline">
                          <div [title]="a.Channel" class="oneline">{{ a.Channel }}</div>
                        </td>
                        <td class="py-1 col oneline">{{ a.CustomerCount }}</td>
                        <td class="py-1 col oneline">{{ a.Total / 1000.0 | number : '0.1-1' }} k€</td>
                        <td class="py-1 col oneline">{{ a.TotalRelative | number : '0.1-1' }} %</td>
                        <td class="py-1 col oneline">
                          <i *ngIf="a.Trend > 0" class="fa-solid fa-arrow-trend-up text-success me-3"></i>
                          <i *ngIf="a.Trend <= 0" class="fa-solid fa-arrow-trend-down text-danger me-3"></i>
                          {{ a.Trend | number : '0.1-1' }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Branchen
            </h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr class="fw-bold">
                        <td class="py-1" style="width: auto;">Branche</td>
                        <td class="py-1" style="width: 180px;">Kunden</td>
                        <td class="py-1" style="width: 180px;">Umsatz</td>
                        <td class="py-1" style="width: 180px;">Umsatzanteil</td>
                        <td class="py-1" style="width: 180px;">Trend</td>
                      </tr>
                      <tr *ngFor="let a of industriesData; let i = index;" class="px-0">
                        <td class="py-1 col oneline">
                          <div [title]="industryNames[a.IndustryId]" class="oneline">{{ industryNames[a.IndustryId] }}</div>
                        </td>
                        <td class="py-1 col oneline">{{ a.CustomerCount }}</td>
                        <td class="py-1 col oneline">{{ a.Total / 1000.0 | number : '0.1-1' }} k€</td>
                        <td class="py-1 col oneline">{{ a.RelativeTotal | number : '0.1-1' }} %</td>
                        <td class="py-1 col oneline">
                          <i *ngIf="a.Trend > 0" class="fa-solid fa-arrow-trend-up text-success me-3"></i>
                          <i *ngIf="a.Trend <= 0" class="fa-solid fa-arrow-trend-down text-danger me-3"></i>
                          {{ a.Trend | number : '0.1-1' }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h6 class="card-header-title">
              Topkunden
            </h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr class="fw-bold">
                    <td class="py-1" style="width: auto;">Kunde</td>
                    <td class="py-1" style="width: 180px;">Umsatz</td>
                    <td class="py-1" style="width: 180px;">Umsatzanteil</td>
                    <td class="py-1" style="width: 180px;">Trend</td>
                  </tr>
                  <tr *ngFor="let a of companyData; let i = index;" class="px-0">
                    <td class="py-1 col"><a routerLink="/companies/{{a.Id}}">{{a.Name}}</a></td>
                    <td class="py-1 col oneline">{{ a.Total / 1000.0 | number : '0.1-1' }} k€</td>
                    <td class="py-1 col oneline">{{ a.RelativeTotal | number : '0.1-1' }} %</td>
                    <td class="py-1 col oneline">
                      <i *ngIf="a.Trend > 0" class="fa-solid fa-arrow-trend-up text-success me-3"></i>
                      <i *ngIf="a.Trend <= 0" class="fa-solid fa-arrow-trend-down text-danger me-3"></i>
                      {{ a.Trend | number : '0.1-1' }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
