<div class="card">

    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h6 class="card-header-title">
                    Kontingente
                </h6>
            </div>
            <div class="col-auto">
                <button class="btn btn-dark btn-circle btn-sm" (click)="addModal.show()"><i class="fa-regular fa-plus"></i></button>
            </div>
        </div>
    </div>

    <div class="card-body card-body-list" *ngIf="packages.length > 0">
        <app-list-packages [packages]="packages" (selected)="navigate($event)"></app-list-packages>
    </div>
</div>

<div class="card">

    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h6 class="card-header-title">
                    Kontingente (nur buchhalterisch)
                </h6>
            </div>
            <div class="col-auto">
                <button class="btn btn-dark btn-circle btn-sm" (click)="addModal.show()"><i class="fa-regular fa-plus"></i></button>
            </div>
        </div>
    </div>

    <div class="card-body card-body-list" *ngIf="billingPackages.length > 0">
        <app-list-packages [packages]="billingPackages" (selected)="navigate($event)"></app-list-packages>
    </div>
</div>

<sc-modal #addModal size="lg">
    <sc-modal-header>
        Neues Kontingent
    </sc-modal-header>
    
    <div class="modal-body" *ngIf="addModal.isOpen">
        <div class="row mb-2">
            <div class="col-3">
                Bezeichnung
            </div>
            <div class="col-9">
                <input class="form-control form-control-sm" [(ngModel)]="newPackage.Title" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <label>Beschreibung</label>
            </div>
            <div class="col-9">
                <autosize-textarea [(text)]="newPackage.Comment"></autosize-textarea>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                Volumen
            </div>
            <div class="col-9">
                <input type="number" class="form-control form-control-sm" [(ngModel)]="newPackage.Volume" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="addModal.dismiss()">Abbrechen</button>
        <button class="btn btn-dark" (click)="addModal.dismiss(); addPackage();">Hinzufügen</button>
    </div>
</sc-modal>