<div *ngIf="impressions?.length" cdkDropList [cdkDropListData]="impressions" (cdkDropListDropped)="onDrop($event);">
    <div class="row mb-4" *ngFor="let impression of impressions" cdkDrag>
        <div class="col-4">
            <sc-image-select-crop [aspectRatio]="3 / 2" [minWidth]="600" [resizeToWidth]="600" fileType="webp" (uploaded)="onUpload(impression, $event)">
                <img *ngIf="!impression.Image || impression.imageError" src="/assets/project-hero-placeholder/hero-placeholder-1.jpg" class="img-fluid w-100" />
                <img *ngIf="impression.Image && !impression.imageError" [src]="impression.Image" (error)="impression.imageError = true;" class="img-fluid w-100">
            </sc-image-select-crop>
        </div>
        <div class="col">
            <input class="form-control mb-2 fw-bold" [(ngModel)]="impression.Title" (ngModelChange)="changed.next();" />
            <textarea class="form-control" rows="5" [(ngModel)]="impression.Description" (ngModelChange)="changed.next();"></textarea>
        </div>
        <div class="col-auto">
            <button class="btn btn-sm btn-secondary btn-circle" (click)="removeImpression(impression)">
                <i class="fa-regular fa-trash me-1"></i>
            </button>
        </div>
    </div>
</div>