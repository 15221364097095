import { Component, OnInit, Input, OnChanges, ViewChild, HostBinding } from '@angular/core';
import { environment } from 'src/environment.prod';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { ModalSelectChatbotComponent } from 'src/components/modal-select-chatbot/modal-select-chatbot.component';

@Component({
  selector: 'app-candidate-chats',
  templateUrl: './candidate-chats.component.html',
  styleUrls: ['./candidate-chats.component.scss']
})
export class CandidateChatsComponent implements OnChanges, OnInit {

  @Input()
  candidate;

  @HostBinding('class.hidden')
  hide = false;

  chats : any = [];  

  chatbots : any = [];

  selectedChatbot : any = null;

  searchInput = '';

  searchBotsInput = '';

  overViewScrolledToTop = true;

  @ViewChild('remindModal', {static : true})
  remindModal : ScuiModalComponent;

  @ViewChild('selectModal', {static : true})
  selectModal : ModalSelectChatbotComponent;

  constructor(private api : ScApiService, private chatsService : ChatsService) { }

  ngOnInit()
  {}

  ngOnChanges() {
    this.loadChats();
  }

  loadChats()
  {
    this.chatsService.getChats(`candidates/${this.candidate.Guid}/chats`).subscribe(d => {
      this.chats.length = 0;
      this.chats.push.apply(this.chats, d);
    })
  }

  startChatbot() {
    this.selectModal.show().subscribe((d: any) => {

      var args = { 
        ChatbotId: d.Id
      };
  
      this.api.post(`candidates/${this.candidate.Guid}/chats`, args).subscribe(() => {
        this.loadChats();
      });
    })
  }

  reminder : any = {};

  selectedChat : any;

  select(chat) {
    this.selectedChat = chat;
  }

  showRemindModal()
  {
    this.reminder = {
      SendPushNotification: true,
      SendEMailNotification: true
    };  
    
    this.remindModal.show();  
  }

  remindChat()
  {
    this.api.post('chats/' + this.selectedChat.Id + '/reminder', this.reminder, environment.chatApi).subscribe();
  }
}
