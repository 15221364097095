<sc-modal size="lg" #modal>
  <sc-modal-header>
    Chatbot auswählen
  </sc-modal-header>
  <sc-modal-body>
    <div>
      <input class="form-control search mb-2" placeholder="Suche" [(ngModel)]="q" (ngModelChange)="searchSoon()" />
    </div>
    <div style="height: 50vh; overflow-y: auto; overflow-x: hidden;" class="scrollbar-minimal">
      <div class="list-group">
        <div (click)="modal.complete(chatbot)" class="list-group-item list-group-item-action" *ngFor="let chatbot of chatbots">
          <div class="row align-items-center flex-nowrap">
            <div class="col-auto">
              <div class="avatar avatar-sm" *ngIf="chatbot.Image">
                <img [src]="chatbot.Image" alt="Chat Image" class="avatar-img rounded">
              </div>

              <div class="avatar avatar-sm" *ngIf="!chatbot.Image">
                <img src="/assets/img/illustrations/bot-illu.png" alt="Chat Image" class="avatar-img rounded">
              </div>
            </div>

            <div class="col">
              <h6 class="mb-1 oneline d-inline">
                {{chatbot.Name}}
              </h6>
              <p class="card-text text-muted small mb-0 oneline">
                {{ chatbot.Id }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sc-modal-body>
</sc-modal>
