<sc-modal-wizard #modal size="lg">

    <div class="modal-header">
        <h4 class="modal-title">Fachskills</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-wizard-body">

        <sc-modal-wizard-page #edit>

            <div class="modal-body" *ngIf="skill">
                <div class="row">
        
                    <div class="col small">
                        <strong>Skill</strong>
                    </div>
                    <div class="col-auto">
                        {{ skill.Skill.Name }}
                    </div>
                </div>
        
                <hr>
        
                <div class="row">
        
                    <div class="col small">
                        <strong>Top-Skill</strong>
                    </div>
                    <div class="col-auto">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="IsTopSkill" [(ngModel)]="skill.IsTopSkill" (ngModelChange)="onChange()">
                            <label class="custom-control-label" for="IsTopSkill"></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="small mt-3 mb-0">
                            Top-Skills sind Skills, in denen du im Vergleich zu deinen anderen Skills besonders gut bist und die meiste Erfahrung gesammelt hast. Achte darauf, dass du nicht viel mehr als ein Viertel deiner Skills als Top-Skill markierst.
                        </p>
                    </div>
                </div>
        
                <hr>
        
                <div class="row">
        
                    <div class="col small">
                        <strong>Favorit</strong>
                    </div>
                    <div class="col-auto">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="IsFavorite" [(ngModel)]="skill.IsFavorite" (ngModelChange)="onChange()">
                            <label class="custom-control-label" for="IsFavorite"></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="small mt-3 mb-0">
                            Favoriten sind Skills, die dich besonders interessieren und die du gerne in deinem Arbeitsalltag zur Anwendung bringen möchtest. Du musst keine weitreichenden Expertenkenntnisse in einem Skill haben, um es als Favorit zu markieren.
                        </p>
                    </div>
                </div>
            </div>
        
            <div class="modal-footer">
        
                <button class="btn btn-rounded btn-sm btn-secondary" (click)="modal.dismiss()">Fertig</button>
                <button class="btn btn-rounded btn-sm btn-outline-danger" (click)="modal.navigateTo(delete)">Löschen</button>
        
            </div>

        </sc-modal-wizard-page>

        <sc-modal-wizard-page #delete>

            <div class="modal-body" *ngIf="skill">

                <p>
                    Bist du dir sicher, dass du diesen Eintrag löschen möchtest?
                </p>

                <div class="row">
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col small">
                        <div class="text-truncate"> <strong>{{ skill.Skill.Name }}</strong></div>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>

                </div>

            </div>

            <div class="modal-footer">
                <button class="btn btn-rounded btn-sm btn-secondary" (click)="modal.navigateTo(edit, 'leftToRight')">Abbrechen</button>
                <button class="btn btn-rounded btn-sm btn-danger" (click)="deleteSkill(); modal.dismiss();">Löschen</button>
            </div>
        </sc-modal-wizard-page>
    </div>
</sc-modal-wizard>