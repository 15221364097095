<ul class="nav nav-tabs nav-overflow">

    <li class="nav-item">
        <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/profile" [class.active]="active == 'profile'">
            Suchprofil
        </a>
    </li>

    <li class="nav-item">
        <a class="nav-link active" routerLink="/projects/{{project?.Id}}/sourcing/screening" [class.active]="active == 'screening'">
            Screening
        </a>
    </li>
    
    <li class="nav-item">
        <a class="nav-link" routerLink="/projects/{{project?.Id}}/sourcing/imports" [class.active]="active == 'imports'">
            Ansprachen
        </a>
    </li>

    <li class="nav-item">
        <a class="nav-link active" routerLink="/projects/{{project?.Id}}/sourcing/listing" [class.active]="active == 'listing'">
            Bewerber
        </a>
    </li>

</ul>