import { Component, Input, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-select-manager',
  templateUrl: './modal-select-manager.component.html',
  styleUrls: ['./modal-select-manager.component.scss']
})
export class ModalSelectManagerComponent {

  @ViewChild('modal')
  modal : ScuiModalComponent;

  @Input()
  headline = 'Manager:in auswählen';

  @Input()
  canSelectAll = true;

  managers : any = [];

  constructor(private api : ScApiService)
  {}

  public show()
  {
    return this.modal.show();
  }

  ngOnInit()
  {
    this.api.get('companies/1/contacts').subscribe((d: any) => {
      this.managers = d;
    });
  }

}
