<div class="card mb-3">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Teilnehmer
        </h6>
      </div>
      <div class="col-auto">
        <div class="btn btn-sm btn-dark btn-circle lift" (click)="addUser()">
          <i class="fa-regular fa-plus"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body card-body-list">
    <div class="list-group" *ngIf="project">
      <div class="list-group-item list-group-item-action" [class.muted]="user.Access.IsMuted" (click)="selectedUser=user; editUsersModal.show();" *ngFor="let user of users">
        <div class="row align-items-center">
          <div class="col-auto">
            <sc-avatar [user]="user" size="sm"></sc-avatar>
          </div>

          <div class="col">

            <h6 class="mb-0 name">
              <span *ngIf="user.LastName || user.FirstName">{{ user.LastName }}, {{ user.FirstName }} <span class="text-muted small me-3">{{ user.Gender }}</span></span>
              <span class="text-muted" *ngIf="!user.LastName && !user.FirstName">Keine Namensangabe</span>
            </h6>

            <span class="small text-muted">
              {{ user.EMail }}
            </span>

          </div>

          <div class="col-auto" *ngIf="user.Access.IsMuted">
            <span class="badge badge-pill badge-light"><i class="fa-solid fa-volume-slash me-2"></i>Stumm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-select-person #addUserModal></app-modal-select-person>

<app-modal-contact-edit #contactModal (saved)="load()"></app-modal-contact-edit>

<sc-modal #editUsersModal>
  <sc-modal-header>
    Teilnehmer bearbeiten
  </sc-modal-header>
  <sc-modal-body>
    <sc-form *ngIf="selectedUser" (change)="saveAccess(selectedUser.Access)">    
      <sc-form-row label="Angebotsempfänger:in">
        <sc-switch [(ngModel)]="selectedUser.Access.IsQuoteRecipient"></sc-switch>
      </sc-form-row>
      <sc-form-row label="Stumm">
        <sc-switch [(ngModel)]="selectedUser.Access.IsMuted"></sc-switch>
      </sc-form-row>
    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-secondary" (click)="navigateToProfile(selectedUser); editUsersModal.dismiss()">Zum Profil</button>
    <button class="btn btn-secondary" (click)="removeUser(selectedUser)">Aus Projekt entfernen</button>
  </sc-modal-footer>
</sc-modal>
