<sc-modal #modal>
  <sc-modal-header>
    Kadenz auswählen
  </sc-modal-header>
  <sc-modal-body>

    <div>
      <input class="form-control search mb-2" [(ngModel)]="q" (ngModelChange)="searchSoon()" />
    </div>
    <div class="list-group">
      <div (click)="modal.complete(cadence)" class="list-group-item list-group-item-action" *ngFor="let cadence of cadences">
        {{ cadence.Name }}
      </div>
    </div>
  </sc-modal-body>
</sc-modal>
