import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, ScuiModalComponent, ScUploadService } from '@sc-ui';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  invoices : any = [];

  newInvoice : any = {};

  @ViewChild('addModal')
  addModal : ScuiModalComponent;

  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit(): void {
    this.api.get(`invoices`).subscribe(i => this.invoices = i);    
  }

  addInvoice() {

    this.api.post(`invoices`, this.newInvoice).subscribe((i : any) => {
        this.router.navigateByUrl('invoices/' + i.Id);
        this.addModal.dismiss();
    })
  }
}
