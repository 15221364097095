import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

import * as _ from 'lodash';
const utils = require('../../../utils/diff');

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CandidateErrorModalComponent } from '../_shared/candidate-error-modal/candidate-error-modal.component';

@Component({
  selector: 'app-candidate-overview',
  templateUrl: './candidate-overview.component.html',
  styleUrls: ['./candidate-overview.component.scss'],
  providers: [NgbDropdown]
})
export class CandidateOverviewComponent {

  @ViewChild(CandidateErrorModalComponent)
  errorModal : CandidateErrorModalComponent;

  candidate: any;
  
  private _savedCandidate: any;

  saveTimer: any;
  isSaving: any;
  unsavedChanges: boolean;

  constructor(private api: ScApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.api.get('candidates/' + params.id).subscribe((d: any) => {
        this.candidate = d;
        this._savedCandidate = _.cloneDeep(d);
      });
    });
  }

  saveSoon() {
    if (this.saveTimer) clearTimeout(this.saveTimer);

    this.saveTimer = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    if (this.isSaving) return;
    this.isSaving = true;

    var diff = utils.diff(this._savedCandidate, this.candidate);

    this.api.put('candidates/' + this.candidate.Guid, diff).subscribe(
      (d: any) => {
        this.unsavedChanges = false;
        this.candidate.PortalAccount = d.PortalAccount;
        this._savedCandidate = _.cloneDeep(this.candidate);
      },
      (e) => {
        console.log(e);
        this.isSaving = false;
        this.errorModal.show(e.error);        
      },
      () => {
        this.isSaving = false;
      });
  }
}
