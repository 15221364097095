<sc-modal #briefingModal size="lg">

  <sc-modal-header>
    <i class="fa-sharp fa-solid fa-stars me-2"></i> KI-Briefing
  </sc-modal-header>

  <sc-modal-body class="briefing-modal-body">
    <div style="height: 75vh;">
      <div id="sc-chatbot-container" style="width: 100%;height: 100%;" *ngIf="chat">
        <sc-chatbot-stream *ngIf="chat" #chatbotStream style="z-index: 1000;height: 100%;" theme="light" q delayInitialServerMessage="0" [showHeader]="true" [showInitialLoader]="false" [chat]="chat" (onEndOfChat)="onBriefingComplete($event)">
        </sc-chatbot-stream>
      </div>
    </div>
  </sc-modal-body>

  <sc-modal-footer *ngIf="!chatStarted">
    <button class="btn btn-secondary btn-round" (click)="restart()"><i class="fa-sharp fa-solid fa-stars me-2"></i> Neu starten</button>
  </sc-modal-footer>

</sc-modal>
