import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-search',
  templateUrl: './sidebar-search.component.html',
  styleUrls: ['./sidebar-search.component.scss']
})
export class SidebarSearchComponent implements OnInit {

  uri;

  batchId = 0;

  constructor(public sidebarService : SidebarService, private api : ScApiService) { }

  ngOnInit(): void {
    this.uri = this.sidebarService.search?.uri;
    this.sidebarService.searchChange.subscribe(d => this.uri = d?.uri);
  }

  importSearch()
  {
    var newImport = {
      ProjectId : this.sidebarService.projectId,
      Status : 'Pending',
      Type: 'Import',
      Payload : {
        Type: 'xtm',
        Url : this.uri,
        BatchId: this.batchId
      }
    };

    this.api.post('projects/' + this.sidebarService.projectId + '/imports', newImport).subscribe(d => {
      // this.imports.push(d);
      this.sidebarService.success("Import wurde angelegt");
    });
  }
}
