import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-billing-address',
  templateUrl: './list-billing-address.component.html',
  styleUrls: ['./list-billing-address.component.scss']
})
export class ListBillingAddressComponent {

  @Input()
  billingAddresses : any = [];

  @Output()
  selected = new EventEmitter<any>();
}
