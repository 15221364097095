import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent {

  @Input()
  task : any;

  @Output()
  done = new EventEmitter<any>();
}
