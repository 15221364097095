<sc-modal-wizard #modal size="lg">

    <div class="modal-header">
        <h4 class="modal-title">Arbeitsorte</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-wizard-body">

        <sc-modal-wizard-page #overview>

            <div class="modal-body" style="height: 60vh;">
                <div class="row mb-4">
                    <div class="col small">
                        <strong>Umzugsbereit weltweit</strong>
                    </div>
                    <div class="col-auto">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="IsWillingToMoveWorldwide" [(ngModel)]="profile.IsWillingToMoveWorldwide" (ngModelChange)="emitChange()">
                            <label class="custom-control-label" for="IsWillingToMoveWorldwide"></label>
                        </div>
                    </div>                 
                </div>

                <div class="row mb-4" *ngIf="!profile.IsWillingToMoveWorldwide">
                    <div class="col small">
                        <strong>Umzugsbereit in DE</strong>
                    </div>
                    <div class="col-auto">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="IsWillingToMove" [(ngModel)]="profile.IsWillingToMove" (ngModelChange)="emitChange()">
                            <label class="custom-control-label" for="IsWillingToMove"></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                </div>

                <div *ngIf="!profile.IsWillingToMoveWorldwide && !profile.IsWillingToMove">
                    <div class="row clickable" *ngFor="let e of profile.DesiredLocations; let i = index;" (click)="selectedValue=e; modal.navigateTo(edit);">
                        <div class="col small">
                            <div class="text-truncate"> <strong>{{ e.City }} + {{ e.Radius }} km</strong></div>
                        </div>
                        <div class="col-auto">
                            <i class="fa-regular fa-chevron-right"></i>
                        </div>
                        <div class="col-12" *ngIf="i != profile.DesiredLocations.length - 1">
                            <hr>
                        </div>
                    </div>
                </div>

            </div>

            <div class="modal-footer">

                <button class="btn btn-rounded btn-sm btn-secondary lift" (click)="modal.dismiss()">
                    Fertig
                </button>
                <button class="btn btn-rounded btn-sm btn-dark lift" (click)="modal.navigateTo(add)">
                    <i class="fa-regular fa-plus"></i> Ort ergänzen
                </button>

            </div>

        </sc-modal-wizard-page>

        <sc-modal-wizard-page #edit>

            <div class="modal-body" *ngIf="selectedValue">
                <div class="row mb-4">
                    <div class="col small">
                        <strong>Ort</strong>
                    </div>
                    <div class="col-auto">
                        {{ selectedValue.City }}
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col small">
                        <strong>Radius</strong>
                    </div>
                    <div class="col-auto">
                        <select class="custom-select custom-select-sm" [(ngModel)]="selectedValue.Radius">
                            <option *ngFor="let v of [10, 20, 50, 100, 200]" [ngValue]="v">+ {{v}} km</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button class="btn btn-rounded btn-sm btn-secondary" (click)="modal.navigateTo(overview, 'leftToRight')">Fertig</button>
                <button class="btn btn-rounded btn-sm btn-outline-danger" (click)="modal.navigateTo(delete)">Löschen</button>
            </div>

        </sc-modal-wizard-page>

        <sc-modal-wizard-page #add>

            <div class="modal-body">

                <div class="row mb-4">
                    <div class="col-12">
                        <div style="position: relative;">
                            <input autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control form-control-block form-control-sm" #placeInput>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col small">
                        <strong>Radius</strong>
                    </div>
                    <div class="col-auto">
                        <select class="custom-select custom-select-sm" [(ngModel)]="newLocation.Radius">
                            <option *ngFor="let v of [10, 20, 50, 100, 200]" [ngValue]="v">+ {{v}} km</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button class="btn btn-rounded btn-sm btn-secondary" (click)="modal.navigateTo(overview, 'leftToRight')">Abbrechen</button>
                <button class="btn btn-rounded btn-sm btn-dark" [disabled]="!canAdd()" (click)="addNewItem(); modal.navigateTo(overview, 'leftToRight')">Hinzufügen</button>

            </div>

        </sc-modal-wizard-page>

        <sc-modal-wizard-page #delete>

            <div class="modal-body" *ngIf="selectedValue">

                <p>
                    Bist du dir sicher, dass du diesen Eintrag aus der Liste deiner Orte löschen möchtest?
                </p>

                <div class="row">
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col small">
                        <div class="text-truncate"> <strong>{{ selectedValue.City }}</strong></div>

                    </div>
                    <div class="col-12">
                        <hr>
                    </div>

                </div>

            </div>

            <div class="modal-footer">
                <button class="btn btn-rounded btn-sm btn-secondary" (click)="modal.navigateTo(overview, 'leftToRight')">Abbrechen</button>
                <button class="btn btn-rounded btn-sm btn-danger" (click)="deleteLocation(selectedValue); modal.navigateTo(overview, 'leftToRight');">Löschen</button>
            </div>

        </sc-modal-wizard-page>


    </div>
</sc-modal-wizard>