<div class="card" scAnchorTarget="Personen">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Untergeordnete Unternehmen
        </h6>
      </div>
    </div>
  </div>

  <div class="card-body card-body-list">
    <app-list-organizations [organizations]="companies" [navigateOnClick]="true"></app-list-organizations>
  </div>
</div>
