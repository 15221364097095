import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-badge-pool-person-assignment',
  templateUrl: './status-badge-pool-person-assignment.component.html',
  styleUrls: ['./status-badge-pool-person-assignment.component.scss']
})
export class StatusBadgePoolPersonAssignmentComponent implements OnInit {

  @Input()
  status: any;

  _status : any;

  stati = {
    Added: {
      label: 'Vorgestellt',
      color: 'light',
      icon: 'fa-solid fa-question-circle'
    },
    Qualified: {
      label: 'Qualifiziert',
      color: 'light',
      icon: 'fa-solid fa-user'
    },
    Removed: {
      label: 'Abgelehnt',
      color: 'danger-soft',
      icon: 'fa-solid fa-times'
    },
    Resigned: {
      label: 'Ausgetreten',
      color: 'danger-soft',
      icon: 'fa-solid fa-times'
    },
    Hired: {
      label: 'Eingestellt',
      color: 'success-soft',
      icon: 'fa-solid fa-check'
    },
    Success: {
      label: 'Erfolg',
      color: 'success-soft',
      icon: 'fa-solid fa-check'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {    
    if (!this.status) return;
    this._status = this.stati[this.status];
  }

}
