import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-candidate-skills',
  templateUrl: './candidate-skills.component.html',
  styleUrls: ['./candidate-skills.component.scss']
})
export class CandidateSkillsComponent implements OnInit, OnChanges {

  @Input()
  candidate : any;

  skills : any = [];

  @Output()
  candidateChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    this.updateSkills();
  }

  onChange()
  {
    this.candidateChange.next(this.candidate);
  }

  updateSkills() {
    var haves = this.candidate?.Haves;

    if (!haves) {
      this.skills = [];
      return;
    }
    
    var split = haves.split(',').filter(s => s?.length);
    var result = [];
    split.forEach(s => result.push(s.trim()));
    this.skills = result;
  }

}
