<div class="card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Aktivitäten
        </h6>
      </div>
      <div class="col-auto">
        <button class="btn btn-sm btn-round btn-secondary" (click)="modal.show()">
          <i class="fa-regular fa-plus me-2"></i> Sales-Aktivität
        </button>
      </div>
    </div>
  </div>

  <div class="card-body">

    <!-- <div class="row mb-5">

      <div class="col">
        <autosize-textarea [(text)]="callComment" placeholder="Kommentar"></autosize-textarea>
      </div>

      <div class="col-auto ps-0">
        <button class="btn btn-dark btn-round btn-circle" [disabled]="!callComment?.length" (click)="postCall()">
          <i class="fa-sharp fa-solid fa-paper-plane"></i>          
        </button>
      </div>
    </div> -->

    <sc-activity-feed #feed [scAnchorTarget]="'Aktivitäten'" uri="candidates/{{candidate.Guid}}/activities" headline="" readOnly="true" pageSize="40">
      <ng-template let-activity="activity">
        <sc-activity-item [activity]="activity" [condensed]="false"></sc-activity-item>
      </ng-template>
    </sc-activity-feed>
  </div>
</div>


<app-modal-select-sales-status [candidate]="candidate" (changed)="feed.reloadItems()" #modal></app-modal-select-sales-status>