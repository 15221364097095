import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { ModalActionArgs } from 'src/components/modal-action/modal-action.component';
import { ModalSelectChatbotComponent } from 'src/components/modal-select-chatbot/modal-select-chatbot.component';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.scss'],
  animations: [fadeIn]
})
export class PoolComponent implements OnInit {

  id: any;

  pool: any;

  state = 'out';

  isPostingChatbot = false;

  @ViewChild('chatbotModal')
  chatbotModal: ScuiModalComponent;

  @ViewChild('selectModal', { static: true })
  selectModal: ModalSelectChatbotComponent;

  constructor(private api: ScApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (!params.id) return;
      this.id = params.id;
      this.api.get(`pools/${params.id}`).subscribe((d: any) => {
        this.pool = d;
        this.state = 'in';
      }, e => this.api.redirectAnonymousToLogin(e))
    })
  }

  notifyNewCandidates(args: ModalActionArgs) {
    this.api.post(`pools/${this.id}/actions/new-candidates`, null).subscribe(
      () => {
        args.success("Die Benachrichtigungen wurden erfolgreich versendet");
      },
      e => {
        args.error("Fehler");
      }
    );

  }

  broadcastChatbot() {
    this.selectModal.show().subscribe((d: any) => {

      this.chatbotModal.show();
      this.isPostingChatbot = true;

      var args = {
        ChatbotId: d.Id
      };

      this.api.post(`pools/${this.id}/chatbots`, args).subscribe(d => {
        console.log(d);
        this.isPostingChatbot = false;
        this.chatbotModal.dismiss();
      });
    })
  }

}
