import { EventEmitter, Injectable } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  LOCAL_STORAGE_KEY = 'sidebarservice';

  projectId: number = 0;

  project: any = null;

  profile: any = null;

  search: any = null;

  searchChange = new EventEmitter<any>();

  potentialMatches: any = [];

  blacklistInfo: any;

  projectChange = new EventEmitter<any>();

  onSuccess = new Subject<any>();

  onDevToolsMessage = new Subject<any>();

  xtmLockInfo: any = null;

  constructor(private api: ScApiService) {
    var json = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (json) {
      var storedState = JSON.parse(json);
      this.projectId = storedState.ProjectId;
      this.profile = storedState.NewProfile;
      this.potentialMatches = storedState.PotentialMatches;
      this.blacklistInfo = storedState.BlacklistInfo;
      this.search = storedState.Search;
      this.loadProject();
    }

    window.onmessage = (e) => {
      var message = e.data;
      this.onDevToolsMessage.next(message);
      this.getXtmLockIfApplicable(message);
    };
  }

  setProjectId(id: number) {
    this.projectId = id;
    this.loadProject();
    this.saveState();
  }

  setProfile(profile: any) {
    this.profile = profile;
    this.saveState();

    if (!this.profile) return;

    this.api.post('sidebar/blacklist-info-new-candidate?projectId=' + this.projectId, this.profile).subscribe((b: any) => {
      this.blacklistInfo = b;
      this.saveState();
    });
  }

  setSearch(search: any) {
    this.search = search;
    this.saveState();
    this.searchChange.emit(search);
  }

  setPotentialMatches(matches: any) {
    this.potentialMatches = matches;
    this.saveState();
  }

  saveState() {
    var json = JSON.stringify({
      ProjectId: this.projectId,
      NewProfile: this.profile,
      PotentialMatches: this.potentialMatches,
      BlacklistInfo: this.blacklistInfo,
      Search: this.search
    });

    localStorage.setItem(this.LOCAL_STORAGE_KEY, json);
  }

  loadProject() {
    if (!this.projectId) {
      this.project == null;
    }
    else {
      this.api.get(`jobs/${this.projectId}`).subscribe(d => this.project = d);
    }
  }

  success(message) {
    var args =
    {
      type: 'success',
      body: message
    };

    this.onSuccess.next(args);
  }

  sendDevToolsMessage(message) {
    window.parent.postMessage(message, '*');
  }

  getXtmLockIfApplicable(message) {
    if (this['xtmLockTimeout']) clearTimeout(this['xtmLockTimeout']);

    this['xtmLockTimeout'] = setTimeout(() => {
      if (message.type == 'chrome.tabs.onUpdated' && message.url.startsWith('https://www.xing.com/xtm/')) {
        this.api.post('sidebar/xtm-lock', null).subscribe((d: any) => {
          this.xtmLockInfo = d;
        });
      }
    }, 1000);
  }
}
