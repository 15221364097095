import { Pipe, PipeTransform } from '@angular/core';
import industries from 'refs/sc-ui/resources/industries';

@Pipe({
  name: 'industryName'
})
export class IndustryNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    var match = industries.find(i => i.id == value);
    if (!match) return '?';

    return match.name;
  }

}
