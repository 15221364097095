import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartColorsService {

  constructor() { }

  public getColor(i) {

    var colors = {

      pastelblue: {
        textColor: "light",
        bgColor: "#93DDEA"
      },

      pastelorange: {
        textColor: "light",
        bgColor: "#EFD6AC"
      },

      pastelpurple: {
        textColor: "light",
        bgColor: "#ACADEF"
      },

      pastelrose: {
        textColor: "light",
        bgColor: "#F2C5CB"
      },

      pastelgreen: {
        textColor: "light",
        bgColor: "#BCF1D4"
      },

      pastellime: {
        textColor: "light",
        bgColor: "#E2EFAC"
      },

      pastelyellow: {
        textColor: "light",
        bgColor: "#EFE8AC"
      }

    }

    var keys = Object.keys(colors).reverse();
    var colorIndex = i % keys.length;
    var key = keys[colorIndex];
    var value = colors[key];
    return value.bgColor;
  }
}
