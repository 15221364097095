<div class="footer-container">

	<div class="row">
		<div class="col-12 small muted">
			<span style="opacity: .6" *ngIf="sidebarService.xtmLockInfo?.Permitted">
				XTM-Account geblockt bis {{ sidebarService.xtmLockInfo.Lock.Expiry | date : 'HH:mm' }} Uhr
			</span>
			<span class="text-danger" *ngIf="sidebarService.xtmLockInfo?.Permitted == false">
				Der XTM-Account konnte nicht geblockt werden!
			</span>
		</div>
	</div>
  <div class="message-container">
    <div class="success mt-1" *ngFor="let message of messages">
      <div class="row">
        <div class="col-12 small">
          {{ message.body }}
        </div>
      </div>
    </div>
  </div>
</div>
