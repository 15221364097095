import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-project-briefing-modal-import',
  templateUrl: './project-briefing-modal-import.component.html',
  styleUrls: ['./project-briefing-modal-import.component.scss']
})
export class ProjectBriefingModalImportComponent {

  @ViewChild('modal')
  modal: ScuiModalComponent;

  @Input()
  project: any;

  batchId = 0;

  skip = 0;

  take = 1000;

  isImporting = false;

  constructor(private api: ScApiService, private router : Router) { }

  show() {
    return this.modal.show();
  }

  import() {

    if (this.isImporting) return;
    this.isImporting = true;

    var locations = [];

    this.project.Profile.Locations.forEach(l => {
      var loc = {
        City: l.City,
        Lat: l.Latitude,
        Lon: l.Longitude
      };

      locations.push(loc)
    })

    var args = {

      Requirements: {
        skills: this.project.Profile.SkillRequirements,
        industries: this.project.Profile.IndustryRequirements,
        min_experience:  parseInt(this.project.Profile.MinYearsOfExperience),
        max_experience:  parseInt(this.project.Profile.MaxYearsOfExperience),
        max_remote: parseInt(this.project.Profile.MaxRemote),
        locations: locations
      },
      BatchId: this.batchId,
      Take: this.take,
      Skip: this.skip

    };

    this.api.post('projects/' + this.project.Id + '/screening', args).subscribe(() => 
    {
      this.isImporting = false;
      this.modal.dismiss();
      this.router.navigateByUrl('/projects/'  + this.project.Id + `/sourcing?filter=%7B"BatchId":"${ this.batchId }","IsMatch":true%7D`);
    });

  }



}
