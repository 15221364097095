import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-topbar-candidate',
  templateUrl: './topbar-candidate.component.html',
  styleUrls: ['./topbar-candidate.component.scss'],
  providers: [NgbDropdown]
})
export class TopbarCandidateComponent {

  @Input()
  candidate : any;

  constructor(private api : ScApiService, private router : Router) {}

  delete() {
    this.api.delete("candidates/" + this.candidate.Guid).subscribe(() => {
      this.router.navigateByUrl('/candidates');
    });
  }
}
