import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { ProjectService } from 'src/services/project.service';


@Component({
  selector: 'app-project-briefing',
  templateUrl: './project-briefing.component.html',
  styleUrls: ['./project-briefing.component.scss']
})
export class ProjectBriefingComponent implements OnInit { 

  id: any;

  project: any;

  benefits: any = [];

  blacklist: any = {};

  zoom: number = 11;

  saveTimeout: any;

  qCompany: string = '';

  companies: any = [];

  contacts: any = [];

  admins: any = [];

  isPostingNotification = false;

  deleteConfirmation = '';

  validationErrors = [];

  copyFromInfo: any = {
    banner: false,
    properties: {}
  };

  private chat : any;

  isSaving = false;

  matchCount = -1;

  @ViewChild('notificationsSuccessModal')
  notificationsSuccessModal: ScuiModalComponent;

  @ViewChild('notificationsErrorModal')
  notificationsErrorModal: ScuiModalComponent;

  @ViewChild('validationErrorsModal')
  validationErrorsModal: ScuiModalComponent;

  @ViewChild('addSkillModal')
  addSkillModal : ScuiModalComponent;

  @ViewChild('addIndustryModal')
  addIndustryModal : ScuiModalComponent;

  constructor(
    private api: ScApiService,
    private projectService : ProjectService,
    private route: ActivatedRoute,
    private chatService : ChatsService) {
  }

  ngOnInit(): void {

    this.api.get("companies/1/contacts").subscribe((d: any) => {
      this.admins = d;
    });

    this.api.get('blacklist').subscribe(d => {
      this.blacklist = d;
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
      var cached = this.projectService.getFromCache(this.id);
      if (cached) this.project = cached;    
      this.projectService.load(this.id).subscribe(p => { this.project = p });
    });
  }
  
  saveSoon() {
    this.projectService.saveSoon(this.project);
  }

  save() {
    this.projectService.save(this.project).subscribe();
  } 

  saveCompany() {
    var company = this.project.Company;
    this.api.put("companies/" + company.Id, company).subscribe(d => { });
  }

  saveGlobalBlacklist() {
    this.api.put('blacklist', this.blacklist).subscribe();
  }

  executeCopyFromProject() {
    console.log(this.copyFromInfo);

    this.api.get('jobs/' + this.copyFromInfo.ProjectId).subscribe((p: any) => {

      if (this.copyFromInfo.banner)
      {
        this.project.Profile.BannerUri = p.Profile.BannerUri;
        this.project.Profile.Banner = p.Profile.Banner;
      }

      for (let [key, value] of Object.entries(this.copyFromInfo.properties)) {
        if (!value) continue;
        var projectValue = p.Profile[key];
        this.project.Profile[key] = projectValue;
      }

      this.save();

      this.copyFromInfo.properties = [];
      this.copyFromInfo.confirm = null;

    });
  }

  briefingChat() {

    this.chatService.getChat('projects/' + this.id + '/briefing-chat').subscribe((c : any) => {
      this.chat = c;
    });

  }

  removeIndustry(i)
  {
    var index = this.project.Profile.IndustryRequirements.indexOf(i);
    this.project.Profile.IndustryRequirements.splice(index, 1);
    this.save();
  }

  removeSkill(i)
  {
    var index = this.project.Profile.SkillRequirements.indexOf(i);
    this.project.Profile.SkillRequirements.splice(index, 1);
    this.save();
  }

  addIndustry()
  {
    this.addIndustryModal.show().subscribe((d : any) => {
      if (!this.project.Profile.IndustryRequirements) this.project.Profile.IndustryRequirements = [];
      this.project.Profile.IndustryRequirements.push(d);
      this.save();
    });
  }

  addSkill()
  {
    this.addSkillModal.show().subscribe((d : any) => {
      if (!this.project.Profile.SkillRequirements) this.project.Profile.SkillRequirements = [];
      this.project.Profile.SkillRequirements.push(d);
      this.save();
    });
  }

  updateMatchCount()
  {
    var locations = [];

    this.project.Profile.Locations.forEach(l => {
      var loc = {
        City: l.City,
        Lat: l.Latitude,
        Lon: l.Longitude
      };

      locations.push(loc)
    })

    var args = {
      Requirements: {
        skills: this.project.Profile.SkillRequirements,
        industries: this.project.Profile.IndustryRequirements,
        min_experience:  parseInt(this.project.Profile.MinYearsOfExperience),
        max_experience:  parseInt(this.project.Profile.MaxYearsOfExperience),
        max_remote: parseInt(this.project.Profile.MaxRemote),
        locations: locations
      }
    };

    this.api.post('projects/' + this.project.Id + '/screening-total', args).subscribe((d : any) => {
      this.matchCount = d.Total;
    });
  }
}
