<app-topbar-project [project]="project"></app-topbar-project>

<div class="main-content" *ngIf="project">

  <div class="container mb-3">
    <div class="row align-items-center">
      <div class="col">
        <app-nav-project-sourcing [project]="project" active="imports"></app-nav-project-sourcing>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="card-header-title">
                  Ansprachen
                </h6>
              </div>
              <div class="col-auto">
                <div ngbDropdown>
                  <button ngbDropdownToggle class="btn btn-circle btn-dark btn-sm">
                    <i class="fa-sharp fa-solid fa-plus"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem (click)="addFirstMessageModal.show()">
                      Erstansprache
                    </a>
                    <a ngbDropdownItem (click)="addSecondMessageModal.show()">
                      Zweitansprache
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body card-body-list">
            <div class="list-group">
              <div *ngFor="let import of imports" class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="mb-1 oneline d-inline">
                      {{ typeNames[import.Type] }} <span *ngIf="import.Type == 'Import'" class="small ms-3 clickable" (click)="selectedImport = import; importDetailsModal.show();"><i class="fa-solid fa-arrow-up-right-from-square"></i></span>
                    </h6>
                    <p class="card-text text-muted small mb-0 oneline">
                      Erstellt: {{ import.Created | humanizedDate }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <span *ngIf="import.Status == 'Pending'" class="badge badge-pill badge-light">Warte auf Ausführung</span>
                    <span *ngIf="import.Status == 'Running'" class="badge badge-pill badge-light">In Ausführung</span>
                    <span *ngIf="import.Status == 'Completed'" class="badge badge-pill badge-success-soft">Abgeschlossen</span>
                    <span *ngIf="import.Status == 'Failed'" class="badge badge-pill badge-danger-soft">Fehler</span>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-sm btn-secondary btn-circle" *ngIf="import.Status == 'Pending'" (click)="deleteImport(import);"><i class="fa-regular fa-trash"></i></button>
                    <button class="btn btn-sm btn-secondary btn-circle" *ngIf="import.Status != 'Pending' && import.Status != 'Running'" (click)="restartImport(import);"><i class="fa-regular fa-repeat"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <sc-modal #addFirstMessageModal>
    <sc-modal-header>
      Erstansprache hinzufügen    
    </sc-modal-header>
    <sc-modal-body>
      <label>Batch-ID</label>
      <input class="form-control" [(ngModel)]="firstMessageArgs.BatchId" />
      <label>Template-ID</label>
      <input class="form-control" [(ngModel)]="firstMessageArgs.TemplateId" />
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark" (click)="addFirstMessage(); modal.dismiss();">OK</button>
    </sc-modal-footer>
  </sc-modal>

  <sc-modal #addSecondMessageModal>
    <sc-modal-header>
      Zweitansprache hinzufügen    
    </sc-modal-header>
    <sc-modal-body>
      <label>Batch-ID</label>
      <input class="form-control" [(ngModel)]="secondMessageArgs.BatchId" />
      <label>Template-ID</label>
      <input class="form-control" [(ngModel)]="secondMessageArgs.TemplateId" />
    </sc-modal-body>
    <sc-modal-footer>
      <button class="btn btn-dark" (click)="addSecondMessage(); modal.dismiss();">OK</button>
    </sc-modal-footer>
  </sc-modal>
