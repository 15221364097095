<div class="row mb-2">
  <div class="col-3">
    Logo
  </div>
  <div class="col-3">
    <sc-file-button class="mx-auto" (filesChanged)="uploadLogo($event)">

      <div class="card" *ngIf="company.Profile?.LogoUri">
        <div class="card-body">
          <img class="logo" [src]="company.Profile?.LogoUri" />
        </div>
      </div>

      <div *ngIf="!company.Profile?.LogoUri" class="logo-placeholder">
        <i class="fa-regular fa-image fa-3x mx-auto"></i>
      </div>
    </sc-file-button>
  </div>
</div>
<div class="row mb-2">
  <div class="col-3">
    Beschreibung
  </div>
  <div class="col-9">
    <autosize-textarea [(text)]="assets.Description" (textChange)="changed.next()" placeholder="Unternehmensbeschreibung"></autosize-textarea>
  </div>
</div>
<div class="row mb-2">
  <div class="col-3">
    Highlights: Arbeitgeber
  </div>
  <div class="col-9">
    <autosize-textarea [(text)]="assets.Highlights" (textChange)="changed.next()" placeholder="Higlights als Liste: Eine Zeile ist ein Eintrag"></autosize-textarea>
  </div>
</div>
<div class="row mb-2">
  <div class="col-3">
    Highlights: Arbeitsumgebung
  </div>
  <div class="col-9">
    <autosize-textarea [(text)]="assets.WorkplaceHighlights" (textChange)="changed.next()" placeholder="Higlights als Liste: Eine Zeile ist ein Eintrag"></autosize-textarea>
  </div>
</div>
<div class="row mb-4">
  <div class="col-3">
    Video-URL
  </div>
  <div class="col-9">
    <input class="form-control" type="text" name="VideoUri" [(ngModel)]="assets.VideoUri" (ngModelChange)="changed.next()" />
  </div>
</div>

<div class="row align-items-center">
  <div class="col">
    <h6 class="card-header-title">
      Impressionen
    </h6>
  </div>
  <div class="col-auto">
    <button class="btn btn-sm btn-secondary btn-circle lift" (click)="impressionEditor.addImpression()"><i class="fa-regular fa-plus"></i></button>
  </div>

</div>

<hr>

<app-impression-editor #impressionEditor [(impressions)]="assets.Impressions" (changed)="changed.next()"></app-impression-editor>
