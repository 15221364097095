import { Pipe, PipeTransform } from '@angular/core';

export const taskTypes = {
  "#projectrequested": {
    title: 'Erfolgsprognose erstellen',
    description: 'Das Projekt wurde angefragt',
    route: (i: any) => `/projects/${i.Project.Id}/analysis`
  },
  "#jobsiteconfirmed": {
    title: 'Ansprache beginnen',
    description: 'Die Jobsite wurde freigegeben',
    route: (i: any) => `/projects/${i.Project.Id}/sourcing/imports`
  },
  "#projectsendquote": {
    title: 'Angebot versenden',
    description: 'Die Prognose wurde abgeschlossen',
    route: (i: any) => `/projects/${i.Project.Id}/quote`
  },
  "#projectquotefollowup": {
    title: 'Angebot nachfassen',
    description: 'Keine Rückmeldung zum Angebot',
    route: (i: any) => `/projects/${i.Project.Id}/quote`
  },
  "#projectsendinvoice": {
    title: 'Rechnung versenden',
    description: 'Das Projekt wurde abgeschlossen',
    route: (i: any) => `/projects/${i.Project.Id}/quote`
  },
  "#jobsiterevisionrequested": {
    title: 'Jobsite überarbeiten',
    description: 'Überarbeitung der Jobsite wurde angefordert',
    route: (i: any) => `/projects/${i.Project.Id}/jobsite`
  },
  "#projectcompletejobsite": {
    title: 'Jobsite erstellen',
    description: 'Bitte erstelle die Jobsite',
    route: (i: any) => `/projects/${i.Project.Id}/jobsite`
  },
  "#projectcheckstatus": {
    title: 'Status prüfen',
    description: 'Der zwischenstand muss versendet werden',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectsendfollowupmessages": {
    title: 'Zweitnachrichten versenden',
    description: 'Zweitnachrichten können versendet werden.',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectcompletesourcing": {
    title: 'Ansprache abschließen',
    description: 'Die Ansprache muss beendet werden',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectcompletereview": {
    title: 'Review abschließen',
    description: 'Das Review muss abgeschlossen werden',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectstartsecondsourcing": {
    title: 'Zweite Ansprache beginnen',
    description: 'Die zweite Ansprache muss beginnen',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectcompletesecondsourcing": {
    title: 'Zweite Ansprache abschließen',
    description: 'Die zweite Ansprache muss abgeschlossen werden',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#projectrated": {
    title: 'Projekt wurde bewertet',
    description: 'Bitte prüfe die Bewertung und reagiere bei Bedarf darauf',
    route: (i: any) => `/projects/${i.Project.Id}`
  },
  "#candidateinterested": {
    title: 'Bewerbung prüfen',
    description: 'Eine Kandidat:in hat sich beworben',
    route: (i: any) => `/candidates/${i.Candidate.Id}`
  },
  "#cadencemailresearchfailed": {
    title: 'E-Mail recherchieren',
    description: 'Kein Ergebnis der automatischen Recherche',
    route: (i: any) => `/candidates/${i.Candidate.Id}`
  },
  "#cadencemailreply": {
    title: 'Antwort bearbeiten',
    description: 'Ein Empfänger hat per Mail geantwortet',
    route: (i: any) => `/candidates/${i.Candidate.Id}`
  },
  "#custom": {
    title: 'Wiedervorlage',
    description: 'Manuell erstellte Aufgabe',
    route: (i: any) => {
      if (i.Candidate) return `/candidates/${i.Candidate.Id}`;
      if (i.Organization) return `/companies/${i.Organization.Id}`;
      if (i.Project) return `/projects/${i.Projects.Id}`;
    }
  },
  "#organizationreview": {
    title: 'Organisation prüfen',
    description: 'Organisation prüfen',
    route: (i: any) => {
      if (i.Candidate) return `/candidates/${i.Candidate.Id}`;
      if (i.Organization) return `/companies/${i.Organization.Id}`;
    }
  },
  "#verifytrainingbatch": {
    title: 'Training-Batch verifizieren',
    description: 'Bitte gib das Screening für das KI-Training frei',
    route: (i: any) => {
      return '/training-batches'
      // return `/projects/${ i.Payload.ProjectId }/sourcing/screening?filter=%7B%22ProjectId%22:%22${ i.Payload.ProjectId }%22,%22BatchId%22:%22${ i.Payload.BatchId }%22%7D`;
    }
  },
  "#unknown": {
    title: 'Unbekannte Aufgabe',
    description: '',
    route: (i: any) => {
      if (i.Candidate) return `/candidates/${i.Candidate.Id}`;
      if (i.Organization) return `/companies/${i.Organization.Id}`;
      if (i.Project) return `/projects/${i.Projects.Id}`;
    }
  }
}

@Pipe({
  name: 'task'
})
export class TaskPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    
    if (!value) return '';
    var knownType = taskTypes[value.Content];
    var property : string = args[0]?.toString();
    if (!property) property = 'title';

    if (knownType)
    {
      return knownType[property];
    }
    else
    {
      var result = taskTypes["#unknown"];
      result.description = 'Es ist leider nicht hinterlegt, was der Inhalt dieser Aufgabe ist: ' + value.Content;
      return result[property];
    }
  }

}
