import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-jobsite-confirmed',
  templateUrl: './activity-item-project-jobsite-confirmed.component.html',
  styleUrls: ['./activity-item-project-jobsite-confirmed.component.scss']
})
export class ActivityItemProjectJobsiteConfirmedComponent implements OnInit {
  
  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;

  constructor() { }

  ngOnInit(): void {
  }

}
