import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss']
})
export class SidebarFooterComponent implements OnInit {

  success = false;

  messages = [];


  constructor(public sidebarService : SidebarService) { }

  ngOnInit(): void {
    this.sidebarService.onSuccess.subscribe(m => {
      this.doSuccess(m);
    })
  }

  doSuccess(message)
  {
    this.messages.push(message);
    setTimeout(() => {
      var i = this.messages.indexOf(message);
      this.messages.splice(i, 1);
    }, 10000);
  }

}
