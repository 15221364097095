export default [
    {
        "id": 1001,
        "name": "Analyse und Statistik"
    },
    {
        "id": 1002,
        "name": "Administration, Sachbearbeitung und Verwaltung"
    },
    {
        "id": 1003,
        "name": "Beratung / Consulting"
    },
    {
        "id": 1004,
        "name": "Kundenbetreuung"
    },
    {
        "id": 1005,
        "name": "Einkauf, Materialwirtschaft und Logistik"
    },
    {
        "id": 1006,
        "name": "Finanzen, Rechnungswesen und Controlling"
    },
    {
        "id": 1007,
        "name": "Forschung, Lehre und Entwicklung"
    },
    {
        "id": 1008,
        "name": "Gesundheit, Medizin und Soziales"
    },
    {
        "id": 1009,
        "name": "Grafik, Design und Architektur"
    },
    {
        "id": 1010,
        "name": "Ingenieurwesen und technische Berufe"
    },
    {
        "id": 1011,
        "name": "IT und Softwareentwicklung"
    },
    {
        "id": 1012,
        "name": "Management und Unternehmensentwicklung"
    },
    {
        "id": 1013,
        "name": "Marketing und Werbung"
    },
    {
        "id": 1014,
        "name": "Personalwesen und HR"
    },
    {
        "id": 1015,
        "name": "PR, Öffentlichkeitsarbeit und Journalismus"
    },
    {
        "id": 1016,
        "name": "Produktion und Handwerk"
    },
    {
        "id": 1017,
        "name": "Produktmanagement"
    },
    {
        "id": 1018,
        "name": "Projektmanagement"
    },
    {
        "id": 1019,
        "name": "Prozessplanung und Qualitätssicherung"
    },
    {
        "id": 1020,
        "name": "Recht"
    },
    {
        "id": 1021,
        "name": "Vertrieb und Handel"
    },
    {
        "id": 1022,
        "name": "Sonstige Tätigkeitsfelder"
    }
]