import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss']
})
export class MailingComponent {

  mailing : any;

  id : any;

  isSendingTest = false;

  isSending = false;

  editorOptions = {
    theme: 'vs-light',
    language: 'html',
    automaticLayout: true,
    wordWrap: 'on',
    codeLense: false,
    minimap: {
      enabled: false
    }
  };

  private saveRequests = new Subject<any>();

  cadenceId = '3';

  senderEMail = this.user.me?.EMail;

  @ViewChild('sendModal')
  sendModal : ScuiModalComponent;

  constructor(private route : ActivatedRoute, private api : ScApiService, private user : UserService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params : any) => {
        if (params.id)
        {
          this.id = params.id;
          this.api.get('mailings/' + this.id).subscribe(d => {
            this.mailing = d;
          });
        }
    });


    this.saveRequests.pipe(debounceTime(500)).subscribe(() => {
      this.api.put('mailings', this.mailing).subscribe();
    });

  }

  sendTest()
  {
    if (this.isSendingTest) return;
    this.isSendingTest = true;

    this.api.post('mailings/' + this.id + '/test', null).subscribe(() => {
      this.isSendingTest = false;
    });
  }

  send()
  {
    if (this.isSending) return;
    this.isSending = true;

    var args = {
      EMail: this.senderEMail,
      CadenceId: parseInt(this.cadenceId)
    };

    this.api.post('mailings/' + this.id + '/send', args).subscribe(() => {
      this.isSending = false;
      this.sendModal.dismiss();
    });
  }

  saveSoon()
  {
    this.saveRequests.next();

  }

}
