import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScUploadService } from '@sc-ui';

@Component({
  selector: 'app-company-asset-editor',
  templateUrl: './company-asset-editor.component.html',
  styleUrls: ['./company-asset-editor.component.scss']
})
export class CompanyAssetEditorComponent implements OnInit {

  @Input()
  company : any = {};

  @Input()
  assets : any = {};

  @Output()
  changed = new EventEmitter<any>();

  constructor(private uploadApi : ScUploadService) { }

  ngOnInit(): void {
  }

  uploadLogo(files) {
    this.uploadApi.upload(files, (d: any) => {
      this.company.Profile.LogoUri = d.Uri;
      this.changed.next();
    });
  }

}
