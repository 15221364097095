import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWizardPageComponent, ScApiService, WizardComponent } from '@sc-ui';
import { ModalSelectPersonComponent } from 'src/components/modal-select-person/modal-select-person.component';
import { CandidateActivitiesComponent } from 'src/views/candidate/_shared/candidate-activities/candidate-activities.component';

@Component({
  selector: 'app-candidate-sales-log-activity',
  templateUrl: './candidate-sales-log-activity.component.html',
  styleUrls: ['./candidate-sales-log-activity.component.scss']
})
export class SalesLeadLogActivityComponent implements OnChanges {

  oldStatus = '';

  activity: any = {};

  comment = '';

  newStatus = '';

  outcome = '';

  onHoldDate: any = null;

  redirectPersonId = 0;

  @Input()
  candidate: any;

  @Output()
  candidateChange = new EventEmitter<any>();

  @Input()
  activityFeed: CandidateActivitiesComponent;

  @Output()
  nextLead = new EventEmitter<any>();

  @ViewChild(ModalSelectPersonComponent)
  selectPersonModal: ModalSelectPersonComponent;

  @ViewChild(WizardComponent)
  wizard: WizardComponent;

  @ViewChild('startPage')
  startPage: ModalWizardPageComponent;

  @ViewChild('logPage')
  logPage: ModalWizardPageComponent;

  @ViewChild('confirmationPage')
  confirmationPage: ModalWizardPageComponent;  

  constructor(private api: ScApiService, private router: Router) { }

  ngOnChanges() {
    this.wizard?.navigateTo(this.startPage, 'none');
    this.comment = '';
    this.oldStatus = this.candidate?.SalesStatus;
    this.newStatus = this.candidate?.SalesStatus;
    this.redirectPersonId = 0;
    var date = new Date();
    date.setHours(date.getHours() + 24);
    this.onHoldDate = date.toISOString();
    this.selectOutcome('NotReached')
  }

  log() {

    var args: any = {
      Content: '#sales',
      CandidateId: this.candidate.Guid,
      CompanyId: this.candidate.Organization?.Id,
      Payload: {
        Type: 'Call',
        Comment: this.comment,
        OldStatus: this.candidate.SalesStatus,
        NewStatus: this.newStatus,
        Outcome: this.outcome,
        OldSalesOnHoldUntil: this.candidate.SalesOnHoldUntil,
        NewSalesOnHoldUntil: this.onHoldDate
      }
    };

    console.log(args);

    if (this.onHoldDate) {
      this.candidate.SalesOnHoldUntil = this.onHoldDate;
    }

    if (this.newStatus) {
      this.candidate.SalesStatus = this.newStatus;
    }

    if (this.newStatus || this.onHoldDate) {
      this.candidateChange.next(this.candidate);
    }

    if (this.redirectPersonId && this.outcome == 'Redirected') {
      var diff: any = {
        OrganizationId: this.candidate.Organization.Id,
        SalesStatus: 'WarmIndirect'
      }

      this.api.put('people/' + this.redirectPersonId, diff).subscribe();
    }

    this.api.post('activities', args).subscribe(() => {
      this.activityFeed.feed.reloadItems();
      this.wizard.navigateTo(this.confirmationPage);
    });
  }

  goToNextLead() {
    this.nextLead.next();
    this.wizard.navigateTo(this.startPage);
  }

  goToRedirectLead() {
    this.router.navigateByUrl('/candidate-saless/' + this.redirectPersonId);
  }

  callPhone() {
    var phoneLink = "tel:" + this.candidate.Phone;
    window.open(phoneLink, '_blank');
    this.wizard.navigateTo(this.logPage);
  }

  callMobile() {
    var phoneLink = "tel:" + this.candidate.Mobile;
    window.open(phoneLink, '_blank');
    this.wizard.navigateTo(this.logPage);
  }

  selectOutcome(o) {

    this.outcome = o;

    if (this.outcome == 'NotReached') {
      this.newStatus = this.oldStatus;
      var date = new Date();
      date.setHours(date.getHours() + 24);
      this.onHoldDate = date.toISOString();
    }

    if (this.outcome == 'Success') {
      this.newStatus = '';

      if (this.candidate.SalesStatus == 'WarmDirect' || this.candidate.SalesStatus == 'WarmIndirect')
        this.newStatus = 'Scheduling';

      if (this.candidate.SalesStatus == 'Scheduling')
        this.newStatus = 'Scheduled';

      if (this.candidate.SalesStatus == 'Scheduled')
        this.newStatus = 'Negotiating';

      if (this.candidate.SalesStatus == 'Negotiating')
        this.newStatus = 'Closing';

      if (this.candidate.SalesStatus == 'Closing')
        this.newStatus = 'Closed';

      if (this.newStatus == '') {
        this.newStatus = this.candidate.SalesStatus;
      }

      var date = new Date();
      date.setHours(date.getHours() + 24);
      this.onHoldDate = date.toISOString();
    }

    if (this.outcome == 'Cancelled') {
      this.newStatus = 'Reapproach';
      var date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      this.onHoldDate = date.toISOString();
    }

    if (this.outcome == 'Redirected') {
      this.newStatus = 'Disqualified';
      this.onHoldDate = null;
    }
  }

  proceed() {
    this.log();
  }

}
