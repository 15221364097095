<sc-modal #modal>
    <div class="modal-header">
      <h4 class="modal-title">Fehler beim Speichern des Projekts</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul>
        Das Speichern ist fehlgeschlagen!
      </ul>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="modal.dismiss()">OK</button>
    </div>
  </sc-modal>