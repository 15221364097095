import { Component } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

  reports : any = [];

  report : any = "";

  constructor(private api : ScApiService)
  {}

  ngOnInit()
  {
    this.api.get('reports').subscribe(d => {
      this.reports = d;
    });
  }

  generate(report)
  {
    this.api.get('reports/' + report.FullName).subscribe(d => {
      this.report = d;
    });
  }

}
