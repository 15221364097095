<div class="form-control clickable" (click)="selectPerson()">
  <div class="person-container" *ngIf="person">
    <div class="avatar-container">
      <sc-avatar size="xs" [user]="person"></sc-avatar>
    </div>
    <span class="name-container ms-2">{{ person.FirstName }} {{ person.LastName }}</span>
  </div>

  <div class="person-container" *ngIf="!person">
    <div class="avatar-container">
      <sc-avatar size="xs"></sc-avatar>
    </div>
    <span class="name-container ms-2 text-muted">Person wählen</span>
  </div>
</div>
<app-modal-select-person #personModal></app-modal-select-person>
