import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ScuiModalComponent, ScApiService, ModalWizardComponent } from '@sc-ui';
import { SlideInOutAnimation } from 'src/animations/slide';
import { environment } from 'src/environment.prod';
import { Router } from '@angular/router';

@Component({
  animations: [SlideInOutAnimation],
  selector: 'app-modal-edit-skills',
  templateUrl: './modal-edit-skills.component.html',
  styleUrls: ['./modal-edit-skills.component.scss']
})
export class ModalEditSkillsComponent implements OnInit {

  @Input()
  values : any = [];

  @Output()
  valuesChange = new EventEmitter<any>();

  @ViewChild('modal')
  modal : ModalWizardComponent;

  selectedValue : any;

  skills : any = [];

  q = "";

  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit() {
  }  

  show()
  {
    this.modal.show();
  } 

  deleteSkill(e)
  {
    e.IsExcluded = true;
    this.onChange();
  }

  onChange()
  {
    this.valuesChange.emit(this.values);
  }

  visibleValues()
  {
    if (!this.values) return [];
    return this.values.filter(v => !v.IsExcluded);
  }

  addSkill(s)
  {
    var assignment = {
      SkillId: s.Id,
      Skill : s
    };

    this.values.push(assignment);
    this.onChange();
  }


  searchTimeout : any = null;

  searchSoon()
  {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => { this.search() }, 500);
  }

  search()
  {
    this.api.get('skills?q=' + this.q).subscribe(d => {
      this.skills = d;
    });
  }


}
