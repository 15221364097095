import { Component, OnInit } from '@angular/core';
import { EventService, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

  people : any = [];

  searchTimer: any;

  firstName = '';

  lastName = '';

  eMail = '';

  skip = 0;

  take = 100;

  isLoading = false;

  hasMoreItems = false;

  total = 0;

  constructor(private api : ScApiService) { }

  ngOnInit() {
    
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.people.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    this.isLoading = true;
    this.api.get(`candidates?lastName=${this.lastName}&firstName=${this.firstName}&eMail=${this.eMail}&skip=${ this.skip }&take=${ this.take }`).subscribe((d : any) => {
      this.skip += this.take;
      this.people.push.apply(this.people, d.Items);
      this.hasMoreItems = this.people.length < d.TotalCount;
      this.total = d.TotalCount;
      this.isLoading = false;
    });
  }

}
