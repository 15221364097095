<!-- <div class="form-control clickable" (click)="selectAddress()">
  <div class="person-container" *ngIf="address">
    <span class="name-container ms-2">{{ address?.Address }} </span>
  </div>
</div> -->
<div class="address-container clickable" (click)="selectAddress()">
  <div class="row" *ngIf="address">
    <div class="col">
      <p class="mb-0" style="white-space: pre;">{{ address.Address }}</p>
      <p class="mb-0 oneline" *ngIf="address.EMail">{{ address.EMail }}</p>
    </div>
    <div class="col-auto">
      <span class="badge badge-pill badge-primary-soft" *ngIf="address.IsDefault">Standard</span>
    </div>
  </div>
</div>

<app-modal-select-billing-address #modal></app-modal-select-billing-address>
