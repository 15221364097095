<sc-modal-wizard #modal size="lg">

  <div class="modal-header">
    <h4 class="modal-title">Fachskills</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-wizard-body">

    <sc-modal-wizard-page #add>
      <div class="modal-body" style="height: 60vh;">

        <div class="row mb-4">
          <div class="col-12">
            <div class="input-group input-group-flush input-group-merge">
              <input type="search" [(ngModel)]="q" (ngModelChange)="searchSoon()" class="form-control form-control-prepended search" placeholder="Search">
              <div class="input-group-text">
                <i class="fa-regular fa-magnifying-glass"></i>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-2 clickable" *ngFor="let s of skills; let i = index;" (click)="addSkill(s);">

          <div class="col small">
            <div class="text-truncate">
              <strong>{{ s.Name }}</strong>
            </div>
          </div>

          <div class="col-auto">
            <button class="btn btn-dark btn-sm btn-circle lift">
              <i class="fa-regular fa-plus"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-rounded btn-sm btn-secondary lift" (click)="modal.dismiss();">
          Fertig
        </button>
      </div>

    </sc-modal-wizard-page>

  </div>
</sc-modal-wizard>
