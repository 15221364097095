<div class="list-group">
  <div class="list-group-item list-group-item-action" (click)="selected.next(billingAddress)" *ngFor="let billingAddress of billingAddresses">
    <div class="row">
      <div class="col">
        <p class="mb-0 small" style="white-space: pre;">{{ billingAddress.Address }}</p>
        <p class="mb-0 small oneline" *ngIf="billingAddress.EMail">{{ billingAddress.EMail }}</p>
      </div>
      <div class="col-auto">
        <span class="badge badge-pill badge-primary-soft" *ngIf="billingAddress.IsDefault">Standard</span>
      </div>
    </div>
  </div>
</div>
