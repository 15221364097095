<div *ngIf="candidate">

  <div class="card mb-3">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="card-header-title">
            Stammdaten
          </h6>
        </div>
        <div class="col-auto me-n2">
          <a *ngIf="candidate.XingId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.xing.com/xtm/profiles/{{candidate.XingId}}" target="_blank">
            <fa class="fa-brands fa-xing"></fa>
          </a>
          <a *ngIf="!candidate.XingId" [href]="xingSearchUri" target="_blank" class="btn btn-sm btn-secondary btn-circle me-2">
            <fa class="fa-brands fa-xing"></fa>
          </a>
          <a *ngIf="candidate.LinkedInId" class="btn btn-sm btn-dark btn-circle me-2" href="https://www.linkedin.com/talent/profile/{{candidate.LinkedInId}}" target="_blank">
            <fa class="fa-brands fa-linkedin-in"></fa>
          </a>
          <a *ngIf="!candidate.LinkedInId" [href]="linkedInSearchUri" target="_blank" class="btn btn-sm btn-secondary btn-circle me-2">
            <fa class="fa-brands fa-linkedin-in"></fa>
          </a>

          <a class="btn btn-sm btn-secondary btn-round me-2" (click)="editModal.show()">
            <i class="fa-sharp fa-solid fa-pen-to-square me-2"></i> Bearbeiten
          </a>
        </div>
      </div>

    </div>
    <div class="card-body">
      <div class="row">

        <div class="col-12 order-2 col-lg order-lg-1">

          <sc-form>  
            <!-- <sc-form-row label="Kontakt-Status" *ngIf="candidate.SalesStatus == 'Closed'">
              <select [(ngModel)]="candidate.ContactStatus" (ngModelChange)="onChange()">
                <option ngValue="None">-</option>
                <option ngValue="User">User</option>
                <option ngValue="Contact">Contact</option>
                <option ngValue="Promoter">Promoter</option>
              </select>
            </sc-form-row> -->

            <sc-form-row label="On-hold bis">
              <sc-date-picker-inline [(date)]="candidate.SalesOnHoldUntil" (dateChange)="onChange()"></sc-date-picker-inline>
            </sc-form-row>

            <sc-form-row label="Sales-Status">
              <app-inline-select-sales-status [(candidate)]="candidate" (changed)="activityFeed.feed.reloadItems()"></app-inline-select-sales-status>
            </sc-form-row>

            <sc-form-row label="Lead-Quelle">
              <select [(ngModel)]="candidate.SalesLeadSource" (ngModelChange)="onChange()">
                <option ngValue="None">Keine</option>
                <option ngValue="Outreach">Outreach</option>
                <option ngValue="Research">Research</option>
              </select>
            </sc-form-row>

            <sc-form-row label="Tags">
              <input [(ngModel)]="candidate.Tags" (ngModelChange)="onChange()" />
            </sc-form-row>

            <sc-form-row label="Kommentar">
              <autosize-textarea [(text)]="candidate.Profile.Comment" (textChange)="onChange();"></autosize-textarea>
            </sc-form-row>
          </sc-form>
        </div>

        <div class="col-12 order-1 col-lg-auto order-lg-2">
          <sc-image-select-crop aspectRatio="1" minWidth="512" (uploaded)="setImage($event);">
            <sc-avatar size="xxl" [user]="candidate"></sc-avatar>
          </sc-image-select-crop>
        </div>
      </div>
    </div>
  </div>

</div>

<sc-modal #editModal>
  <sc-modal-header>
    Bearbeiten
  </sc-modal-header>
  <sc-modal-body>
    <sc-form>

      <sc-form-row label="Gender">
        <select [(ngModel)]="candidate.Gender" (ngModelChange)="onChange()">
          <option [ngValue]="null">Unbestimmt</option>
          <option ngValue="m">Männlich</option>
          <option ngValue="f">Weiblich</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Vorname">
        <input [(ngModel)]="candidate.FirstName" (ngModelChange)="onChange();" />
      </sc-form-row>

      <sc-form-row label="Nachname">
        <input [(ngModel)]="candidate.LastName" (ngModelChange)="onChange();" />
      </sc-form-row>

      <sc-form-row label="Job-Titel">
        <input [(ngModel)]="candidate.JobTitle" (ngModelChange)="onChange();" />
      </sc-form-row>

      <sc-form-row label="E-Mail">
        <input [(ngModel)]="candidate.EMail" (ngModelChange)="onChange();" />
      </sc-form-row>

      <sc-form-row label="Telefon">
        <input [(ngModel)]="candidate.Phone" (ngModelChange)="onChange();" />
      </sc-form-row>

      <sc-form-row label="Mobil">
        <input [(ngModel)]="candidate.Mobile" (ngModelChange)="onChange();" />
      </sc-form-row>
      
    </sc-form>
  </sc-modal-body>
</sc-modal>