<div class="list-group">
  <a class="list-group-item list-group-item-action" [routerLink]="navigateOnClick ? '/packages/' + package.Id : null" (click)="selected.next(package)" *ngFor="let package of packages">
    <div class="row align-items-center">
      <div class="col">
        <span>
          <h6 class="mb-1 oneline d-inline">
            {{package.Id}} - {{ package.Volume }} Stellen <span *ngIf="package.Expiry">bis {{ package.Expiry | date : 'dd.MM.yy' }}</span>
          </h6>
        </span>

        <p class="card-text text-muted small mb-0 oneline">
          <span>{{package.Company?.Name }}</span>
          <span *ngIf="package.BillingCompany && package.Company?.Id != package.BillingCompany?.Id"> über {{package.BillingCompany?.Name }}</span>
        </p>
      </div>

      <div class="col-auto">
        <span *ngIf="package.Status == 'AwaitingQuote'" class="badge badge-pill badge-primary-soft">Angebot ausstehen</span>
        <span *ngIf="package.Status == 'AwaitingOrder'" class="badge badge-pill badge-light">Warte auf Auftrag</span>
        <span *ngIf="package.IsActive" class="badge badge-pill badge-success-soft ms-2">Aktiv</span>
      </div>

      <div class="col-auto">
        <div class="h4 mb-0">
          {{ package.UsableVolume }}
        </div>

      </div>
    </div>
  </a>
</div>