<div class="card" scAnchorTarget="Personen">

  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-header-title">
          Personen
        </h6>
      </div>
	  <div class="col-auto">
		<button class="btn btn-dark btn-circle btn-sm" (click)="addPerson()"><i class="fa-regular fa-plus"></i></button>
	  </div>
    </div>
  </div>

  <div class="card-body card-body-list">
    <app-list-people [people]="people" [navigateOnClick]="true"></app-list-people>
  </div>
</div>

<app-modal-select-person #selectPersonModal></app-modal-select-person>
