import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-sourcing-completed',
  templateUrl: './activity-item-project-sourcing-completed.component.html',
  styleUrls: ['./activity-item-project-sourcing-completed.component.scss']
})
export class ActivityItemProjectSourcingCompletedComponent {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  context : any;

}
