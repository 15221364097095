import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-modal-select-sales-status',
  templateUrl: './modal-select-sales-status.component.html',
  styleUrls: ['./modal-select-sales-status.component.scss']
})
export class ModalSelectSalesStatusComponent {

  @Input()
  candidate : any;

  @Output()
  changed = new EventEmitter<any>();

  @ViewChild('modal')
  modal : ScuiModalComponent;

  comment = ''

  newStatus = ''

  eventType = 'None'

  onHoldDate = ''

  statusDisplayNames = {
    None: "Kein Status",
    New: "New",
    WarmDirect: "WarmDirect",
    WarmIndirect: "WarmIndirect",
    Scheduling: "Scheduling",
    Scheduled: "Scheduled",
    Negotiating: "Negotiating",
    Closing: "Closing",
    Upselling: "Upselling",
    Closed: "Closed",
    Reapproach: "Reapproach",
    Disqualified: "Disqualified",
    Blacklisted: "Blacklisted"
  };

  constructor(private api : ScApiService)
  {}

  ngOnChanges()
  {
    this.newStatus = this.candidate.SalesStatus
    var date = new Date();
    date.setHours(date.getHours() + 24);
    this.onHoldDate = date.toISOString();
  }

  show()
  {
    this.modal.show();
  }

  updateStatus()
  {
    var activity: any = {
      Content: '#sales',
      CandidateId: this.candidate.Guid,
      CompanyId: this.candidate.Organization?.Id,
      Payload: {
        Type: this.eventType,
        Comment: this.comment,
        OldStatus: this.candidate.SalesStatus,
        NewStatus: this.newStatus,
        OldSalesOnHoldUntil: this.candidate.SalesOnHoldUntil,
        NewSalesOnHoldUntil: this.onHoldDate
      }
    };

    console.log(activity);

    var updatedCandidate : any = {
    }

    if (this.onHoldDate) {
      this.candidate.SalesOnHoldUntil = this.onHoldDate;
      updatedCandidate.SalesOnHoldUntil = this.onHoldDate;
    }

    if (this.newStatus) {
      this.candidate.SalesStatus = this.newStatus;
      updatedCandidate.SalesStatus = this.newStatus;
    }

    if (this.newStatus || this.onHoldDate) {
      this.api.put('candidates/' + this.candidate.Guid, updatedCandidate).subscribe();      
    }

    this.api.post('activities', activity).subscribe(() => {
      this.changed.next();
    });

    var date = new Date();
    date.setHours(date.getHours() + 24);
    this.onHoldDate = date.toISOString();
    this.newStatus = this.candidate.SalesStatus;
    this.eventType = 'None';
    this.modal.dismiss();

  }

}
