<ul class="list-group">   
  <a class="list-group-item list-group-item-action" routerLink="/invoices/{{invoice.Id}}" *ngFor="let invoice of invoices">
    <div class="row">
      <div class="col">
        <span>
          <h6 class="mb-1 oneline d-inline">
            <span *ngIf="invoice.Project">Einzelprojekt - {{ invoice.Project.Name }} <span class="small ms-2">({{ invoice.Project.Id }})</span> </span>
            <span *ngIf="invoice.Package">Kontingent - {{ invoice.Package.Title }} <span class="small ms-2">({{ invoice.Package.Id }})</span> </span>
            <span *ngIf="!invoice.Package && !invoice.Project">Keine Zuordnung</span>
          </h6>
        </span>

        <p class="card-text text-muted small mb-0 oneline">
          {{ invoice.InvoiceNumber }} <span *ngIf="invoice.Company">- {{invoice.Company?.Name }}</span> - {{invoice.Date | date : 'dd.MM.yy'}}
        </p>
      </div>
      <div class="col-auto">
        <div class="d-inline-block" style="width: 110px;">
          <span class="oneline">{{ (invoice.Amount - invoice.Discount) | number : '1.2-2'}} €</span>
        </div>

        <span class="badge badge-pill badge-light">{{ invoice.Status }}</span>
        <span *ngIf="invoice?.IsFirstReminderSent && !invoice?.IsSecondReminderSent" class="badge badge-pill badge-warning-soft ms-2">Erste Mahnung</span>
        <span *ngIf="invoice?.IsSecondReminderSent" class="badge badge-pill badge-danger-soft ms-2">Zweite Mahnung</span>
      </div>
    </div>
  </a>
</ul>
