import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScUploadService } from '@sc-ui';

@Component({
  selector: 'app-impression-editor',
  templateUrl: './impression-editor.component.html',
  styleUrls: ['./impression-editor.component.scss']
})
export class ImpressionEditorComponent implements OnInit {

  @Input()
  impressions : any = [];

  @Output()
  changed = new EventEmitter<any>();

  constructor(private uploadApi : ScUploadService) { }

  ngOnInit(): void {
  }

  
  public addImpression() {

    var impression = {
      Title: 'Headline',
      Description: 'An impression of your future place to work. The kind of thing you love about a workplace.',
      Image: 'https://picsum.photos/640/360?q=' + (new Date()).toISOString()
    };

    this.impressions.push(impression);
    this.changed.next();
  }

  onUpload(impression : any, args : any)
  {
    impression.imageError = false;
    impression.ImageUri = args.Uri;
    impression.Image = args.Uri; 
    this.changed.next();
  }

  removeImpression(impression) {
    var index = this.impressions.indexOf(impression);
    this.impressions.splice(index, 1);
    this.changed.next();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.changed.next();
  }

  uploadImage(impression, files) {
    this.uploadApi.upload(files, (d: any) => {
      impression.imageError = false;
      impression.ImageUri = d.Uri;
      impression.Image = d.Uri;
      this.changed.next();
    });
  }

}
