import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectChatbotComponent } from '../modal-select-chatbot/modal-select-chatbot.component';

@Component({
  selector: 'app-chatbot-selection',
  templateUrl: './chatbot-selection.component.html',
  styleUrls: ['./chatbot-selection.component.scss']
})
export class ChatbotSelectionComponent implements OnInit, OnChanges {

  @Input()
  selectedChatbotId: any = null;
  
  @Output()
  selectedChatbotIdChange: EventEmitter<any> = new EventEmitter<any>();

  selectedChatbot : any;

  @ViewChild('selectModal')
  selectModal: ModalSelectChatbotComponent;

  constructor(private api: ScApiService) { }

  ngOnInit() {
  }

  ngOnChanges()
  {
    if (!this.selectedChatbotId || this.selectedChatbotId == '00000000-0000-0000-0000-000000000000')
    {
      this.selectedChatbot = null;
    }
    else {
      this.api.get('chatbots/' + this.selectedChatbotId).subscribe((d : any) => {
        this.selectedChatbot = d;
      });
    }
  }

  select() {
    this.selectModal.show().subscribe((d: any) => {
      this.selectedChatbotId = d.Id;
      this.selectedChatbot = d;
      this.selectedChatbotIdChange.emit(this.selectedChatbotId);
    })
  }
}
