<nav class="navbar navbar-secondary border-0 bg-white shadow-sm pb-1" *ngIf="userService.me">
  <div class="container">
    <div class="row w-100 h-100 align-items-center g-0">
      <div class="col-10">
        <h5 class="mb-0 project-title oneline clickable" routerLink="/sidebar/projects" *ngIf="sidebarService.project">
			       <a>{{ sidebarService.project?.Name }} <span class="small">| {{ sidebarService.projectId }}</span></a>
        </h5>
        <span class="text-primary clickable" *ngIf="!sidebarService.project" routerLink="/sidebar/projects">Bitte Projekt auswählen!</span>
      </div>
      <div class="col-2 justify-content-end d-flex">
		     <sc-avatar size="sm" [user]="userService.me"></sc-avatar>       
      </div>
    </div>
  </div>
</nav>
