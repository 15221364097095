import { Component, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {

  @Input()
  task: any;

  constructor()
  {}

  ngOnChanges()
  {
   
  }
  
}
