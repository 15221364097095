<div [scAnchorTarget]="'Skills'" *ngIf="candidate">
  <div class="card">
    <div class="card-header">
      <h6 class="card-header-title">
        Skills
      </h6>
    </div>
    <div class="card-body">
      <div (click)="skillsModal.show()">
        <p *ngIf="!skills?.length">
          Es liegen keine Angaben vor
        </p>
        <div class="clickable tag-list">
          <span class="tag me-2 mb-2" *ngFor="let tag of skills">{{tag}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-modal size="lg" #skillsModal>
  <div class="modal-header">
    Skills bearbeiten
  </div>
  <div class="modal-body">
    <textarea [(ngModel)]="candidate.Haves" (ngModelChange)="updateSkills(); onChange()" class="form-control" rows="10"></textarea>
  </div>
</sc-modal>
