import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private api: ScApiService) { }

  eMail: string;

  password: string;

  successMessage: string = "";

  loginError: string;

  isSending: boolean = false;

  ngOnInit() { }  

  login() {

    if (this.isSending) return;

    this.loginError = "";
    this.successMessage = "";

    if (!this.password && !this.eMail) {
      this.loginError = "Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein";
      return;
    }

    if (!this.password) {
      this.loginError = "Bitte geben Sie Ihr Passwort ein";
      return;
    }

    if (!this.eMail) {
      this.loginError = "Bitte geben Sie Ihre E-Mail-Adresse ein";
      return;
    }

    this.isSending = true;

    this.api.login(this.eMail, this.password).subscribe(
      (d: any) => {

        this.isSending = false;

        if (d.Succeeded) {
          this.router.navigateByUrl('/projects');
        }

        else {          
          this.loginError = d.Error;
        }
      },
      error => {
        console.log(error);
        this.isSending = false;
        if (error.message)
          this.loginError = error.message;
        else
          this.loginError = "Ein Fehler ist aufgetreten";
      },
      () => { this.isSending = false; });
  }


}
