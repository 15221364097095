<div class="card-inner" *ngIf="group">
    <div class="row mb-5">

        <div class="col">
            <a routerLink="/pools/{{group.Pool?.Id}}" class="link-arrow left">{{group.Pool?.Name}}</a>
        </div>

        <div class="col-auto pr-0">

            <div ngbDropdown >
                <div ngbDropdownToggle>
                    <button class="btn btn-secondary btn-circle btn-sm">
                        <i class="fa-regular fa-ellipsis"></i>
                    </button>
                </div>
                <div ngbDropdownMenu> 
                    <a ngbDropdownItem (click)="editModal.show()">
                        Bearbeiten
                    </a>
                    <a ngbDropdownItem (click)="deleteModal.show()">
                        Gruppe löschen
                    </a>
                </div>
            </div>

            

        </div>

    </div>

    <div class="row">
        <div class="col-12 col-lg-8">
            <h1 class="d-inline-block mb-3">{{ group.Name }}</h1>
            <div class="pool-subline">{{ group.Description }}</div>
            <div class="pool-stats mt-3">
                <i class="fa-solid fa-users me-2"></i> {{ group.CandidateCount }} Kandidat:innen
            </div>
        </div>
    </div>
</div>

<sc-modal #editModal>
    <div class="modal-header">
        <div class="row align-items-center">
            <div class="col">
                <h4 class="modal-title mb-0">Gruppe bearbeiten</h4>
            </div>
        </div>
        <button type="button" class="btn-close" aria-label="Close" (click)="editModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="group">
        <div class="row mb-3">
            <div class="col-12">
                <label>Title</label>
            </div>
            <div class="col-12">
                <input class="form-control" [(ngModel)]="group.Name" (ngModelChange)="saveSoon()" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label>Beschreibung</label>
            </div>
            <div class="col-12">
                <textarea rows="5" class="form-control" [(ngModel)]="group.Description" (ngModelChange)="saveSoon()" style="resize: none;"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="editModal.dismiss();">Fertig</button>
    </div>
</sc-modal>

<sc-modal #deleteModal>
    <div class="modal-header">
        <div class="row align-items-center">
            <div class="col">
                <h4 class="modal-title mb-0">Gruppe wirklich löschen?</h4>
            </div>
        </div>
        <button type="button" class="btn-close" aria-label="Close" (click)="deleteModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Möchten Sie die Gruppe wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden. Die Kandidat:innen bleiben
            im Pool selbstverständlich erhalten.
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="deleteModal.dismiss();">Abbrechen</button>
        <button class="btn btn-danger" [disabled]="isDeleting" (click)="delete();">
            <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
            Löschen
        </button>
    </div>
</sc-modal>