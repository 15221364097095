<sc-modal #modal>
  <sc-modal-header>
    Kadenz bearbeiten
  </sc-modal-header>

  <sc-modal-body>
    <sc-form *ngIf="assignment" form="default" (change)="saveSoon()">

      <sc-form-row label="Status">
        <select [(ngModel)]="assignment.Status">
          <option ngValue="Active">Aktiv</option>
          <option ngValue="Paused">Pausiert</option>
          <option ngValue="Cancelled">Abgebrochen</option>
          <option ngValue="Success">Erfolg</option>
          <option ngValue="Failed">Fehler</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Nächster Schritt" [disabled]="assignment.Status != 'Active'">
        <select [(ngModel)]="assignment.CurrentStepId">
          <option *ngFor="let step of steps" [ngValue]="step.Id">{{ step.Name }}</option>
        </select>
      </sc-form-row>

      <sc-form-row label="Nächste Ausführung" [disabled]="assignment.Status != 'Active'">
        <sc-date-picker [(date)]="assignment.CurrentStepDue" (dateChange)="saveSoon()"></sc-date-picker>
      </sc-form-row>

    </sc-form>
  </sc-modal-body>
  <sc-modal-footer>

    <button *ngIf="showGoToProfile" (click)="navigateToPerson()" class="btn btn-secondary">
      Zum Profil
    </button>

     <button *ngIf="showGoToCadence" (click)="navigateToCadence()" class="btn btn-secondary">
      Zur Kadenz
    </button>

    <button (click)="modal.dismiss()" class="btn btn-dark">
      Ok
    </button>    
  </sc-modal-footer>
</sc-modal>
