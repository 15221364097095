import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { ModalSelectManagerComponent } from 'src/components/modal-select-manager/modal-select-manager.component';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-leads',
  templateUrl: './board-customer-acquisition.component.html',
  styleUrls: ['./board-customer-acquisition.component.scss']
})
export class LeadsComponent implements OnInit {

  people: any = [];

  searchTimer: any;

  firstName = '';

  lastName = '';

  eMail = '';

  leadSource = '';

  salesStatus = 'New';

  tags = '';

  ownerId = 0;

  skip = 0;

  take = 25;

  isLoading = false;

  hasMoreItems = false;

  total = 0;

  stats: any;

  selectedManager: any;

  @ViewChild('userModal')
  userModal: ModalSelectManagerComponent;

  constructor(private api: ScApiService, private route: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: any) => {
      this.salesStatus = params['status'] ?? 'New';
      this.leadSource = params['leadSource'] ?? '';
      this.firstName = params['firstName'] ?? '';
      this.lastName = params['lastName'] ?? '';
      this.eMail = params['eMail'] ?? '';
      this.ownerId = params['ownerId'] ?? 0;
      this.tags = params['tags'] ?? '';

      if (params['ownerId'] == undefined) {
        if (this.userService.me) {
          this.selectedManager = this.userService.me;
          this.ownerId = this.userService.me?.Id ?? 0;
          this.load();
          return;
        }
        else {
          this.userService.meChange.subscribe(i => {
            this.ownerId = this.userService.me?.Id ?? 0;
            this.load();
          });

          return;
        }
      }

      this.skip = 0;
      this.people = [];
      this.loadMore();
      this.loadStats();
    });
  }

  loadStats() {

    var url = 'lead-stats?';
    if (this.leadSource) url += 'leadSource=' + this.leadSource + '&';
    if (this.ownerId != undefined) url += 'ownerId=' + this.ownerId + '&';
    if (this.tags) url += 'tags=' +   encodeURIComponent(this.tags) + '&';

    this.api.get(url).subscribe(d => {
      this.stats = d;
    });

    if (this.ownerId && this.ownerId != this.selectedManager?.Id) {
      this.api.get('contacts/' + this.ownerId).subscribe(c => {
        this.selectedManager = c;
      });
    }
  }

  selectManager() {

    this.userModal.show().subscribe(
      (m: any) => {
        this.selectedManager = m;
        this.ownerId = m ? m.Id : 0;
        this.load();
      });
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.load();
    }, 500);
  }

  load() {
    var url = '/leads?';
    if (this.firstName) url += 'firstName=' + this.firstName + '&';
    if (this.lastName) url += 'lastName=' + this.lastName + '&';
    if (this.eMail) url += 'eMail=' + this.eMail + '&';
    if (this.salesStatus) url += 'status=' + this.salesStatus + '&';
    if (this.leadSource) url += 'leadSource=' + this.leadSource + '&';
    if (this.ownerId != undefined) url += 'ownerId=' + this.ownerId + '&';
    if (this.tags) url += 'tags=' + encodeURIComponent(this.tags) + '&';
    this.router.navigateByUrl(url);
  }

  loadMore() {
    
    this.isLoading = true;

    this.api.get(`leads?lastName=${this.lastName}&firstName=${this.firstName}&eMail=${this.eMail}&salesStatus=${this.salesStatus}&tags=${encodeURIComponent(this.tags)}&leadSource=${this.leadSource}&ownerId=${this.ownerId}&skip=${this.skip}&take=${this.take}`).subscribe((d: any) => {
      this.skip += this.take;
      this.people.push.apply(this.people, d.Items);
      this.hasMoreItems = this.people.length < d.Total;
      this.total = d.Total;
      this.isLoading = false;
    });
  }

}

