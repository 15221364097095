import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScuiModalComponent, ScApiService } from '@sc-ui';
import { ClipboardService } from 'ngx-clipboard';
import { SidebarService } from 'src/services/sidebar.service';

@Component({
  selector: 'app-sidebar-candidate-merge',
  templateUrl: './sidebar-candidate-merge.component.html',
  styleUrls: ['./sidebar-candidate-merge.component.scss']
})
export class SidebarCandidateMergeComponent implements OnInit {

  id: any;

  candidate: any;

  blacklistInfo: any;

  assignment: any = null;

  assignments: any = [];

  skills: any;

  isAssignedToProject = false;

  visibleProjectItemCount = 3;

  updateSettings = {
    Haves: true,
    Experience: true,
    Avatar: true
  };

  isUpdatingProfile = false;

  @ViewChild('updateModal')
  updateModal : ScuiModalComponent;

  constructor(private api: ScApiService,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.id = params.id;
      this.load();
    });
  }

  load()
  {
    this.api.get('candidates/' + this.id).subscribe((d: any) => {
          this.candidate = d;
        });

        this.api.get('sidebar/blacklist-info/' + this.id + '?projectId=' + this.sidebarService.projectId).subscribe((d: any) => {
          this.blacklistInfo = d;
        });

        this.api.get('candidates/' + this.id + '/project-assignments/').subscribe((d: any) => {
          this.assignments = d;
          this.assignment = d.find(a => a.Project.Id == this.sidebarService.projectId);
          this.isAssignedToProject = this.assignment != null;
        });
  }

  onChange() { }

  join() {

    var args = null;

    if (!this.candidate.XingId && this.sidebarService.profile.XingId) {
      args = { XingId: this.sidebarService.profile.XingId };
    }

    if (!this.candidate.LinkedInId && this.sidebarService.profile.LinkedInId) {
      args = { LinkedInId: this.sidebarService.profile.LinkedInId };
    }

    if (!args) return;

    this.api.put(`candidates/${this.id}/merge`, args).subscribe((d: any) => {
      this.router.navigateByUrl('/sidebar/candidate/' + this.id);
    });
  }
}
