import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityFeedComponent, ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-candidate-activities',
  templateUrl: './candidate-activities.component.html',
  styleUrls: ['./candidate-activities.component.scss']
})
export class CandidateActivitiesComponent implements OnInit {

  @Input()
  candidate : any;

  callComment = '';

  introductoryCallComment = '';

  @ViewChild('feed')
  feed : ActivityFeedComponent;

  @ViewChild('callModal')
  public callModal : ScuiModalComponent;
  
  constructor(private api : ScApiService) { }

  ngOnInit(): void {
  }

  postCall()
  {
    var args = {
      Content: '#commented',
      CandidateId: this.candidate.Guid,
      CompanyId: this.candidate.OrganizationId,
      Payload: {
        Body: this.callComment
      }
    };

    this.api.post('activities', args).subscribe(() => {
      this.feed.reloadItems();
      this.callComment = '';
    });
  }

  postIntroductoryCall()
  {
    var args = {
      Content: '#democall',
      CandidateId: this.candidate.Guid,
      CompanyId: this.candidate.OrganizationId,
      Payload: {
        Comment: this.introductoryCallComment
      }
    };

    this.api.post('activities', args).subscribe(() => {
      this.feed.reloadItems();
    });

  }
}
