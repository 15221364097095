import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ModalSelectOrganizationComponent } from '../modal-select-organization/modal-select-organization.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inline-select-organization',
  templateUrl: './inline-select-organization.component.html',
  styleUrls: ['./inline-select-organization.component.scss']
})
export class InlineSelectOrganizationComponent {

  @Input()
  organizationId: any;

  @Input()
  canSetNull = false;

  @Output()
  organizationIdChange = new EventEmitter<any>();

  @ViewChild(ModalSelectOrganizationComponent)
  modal: ModalSelectOrganizationComponent;

  organization: any;

  constructor(private api: ScApiService, private router: Router) { }

  ngOnChanges() {
    if (!this.organizationId) {
      this.organization = null;
      return;
    }

    if (this.organizationId && this.organizationId != this.organization?.Id) {
      this.api.get('companies/' + this.organizationId).subscribe(d => {
        this.organization = d;
      })
    }
  }

  setNull()
  {
    this.organization = null;
    this.organizationId = 0;
    this.organizationIdChange.next(this.organizationId);
  }

  selectOrganization() {
    this.modal.show().subscribe((d: any) => {
      this.organization = d;
      this.organizationId = d.Id;
      this.organizationIdChange.next(this.organizationId);
    });
  }

  navigate() {
    this.router.navigateByUrl('/companies/' + this.organizationId);
    this.modal.dismiss();
  }
}
