<sc-modal #modal>
	<sc-modal-header>
		Branche hinzufügen
	</sc-modal-header>
	<sc-modal-body>
		<div class="row">
		  <div class="col-12">
			<sc-form>
			  <sc-form-row label="Name">
				<input [(ngModel)]="industry.Id" [editable]="false" [resultFormatter]="industryIdToIdWithName" [inputFormatter]="industryIdToName" [ngbTypeahead]="searchIndustries" />
  
			  </sc-form-row>
  
			  <sc-form-row label="Typ">
				<select [(ngModel)]="industry.Type">
				  <option [ngValue]="'MustHave'">Must-Have</option>
				  <option [ngValue]="'NiceToHave'">Nice-to-Have</option>
				  <option [ngValue]="'Alternative'" *ngIf="industries?.length">Alternative</option>
				</select>
			  </sc-form-row>
			  <sc-form-row label="Alternative zu" [disabled]="industry.Type != 'Alternative'">
				<select [(ngModel)]="industry.AlternativeTo">
				  <option *ngFor="let s of industries" [ngValue]="s.Id">{{ s.Id }}</option>
				</select>
			  </sc-form-row>
			</sc-form>
		  </div>
		</div>
	  </sc-modal-body>
  
	  <sc-modal-footer>
		<button class="btn btn-secondary btn-round" (click)="modal.dismiss()">Abbrechen</button>
		<button class="btn btn-dark btn-round" (click)="select()" [disabled]="!industry.Id || (industry.Type == 'Alternative' && !industry.AlternativeTo)">Hinzufügen</button>
	  </sc-modal-footer>
</sc-modal>