import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectStatus'
})
export class ProjectStatusPipe implements PipeTransform {

  private map = {
    AwaitingAnalysis: 'Potenzialanalyse',
    AwaitingQuote: 'Angebot ausstehend',
    AwaitingOrder: 'Warte auf Auftrag',
    AwaitingBriefing: 'Briefing ausstehend',
    AwaitingJobsite: 'JS-Erstellung',
    AwaitingJobsiteFeedback: 'JS-Freigabe',
    AwaitingJobsiteChanges: 'JS-Überarbeitung',
    Sourcing: 'Erste Ansprache',
    AwaitingSourcing: 'Ansprache ausstehend',
    AwaitingReview: 'Review ausstehend',
    SecondSourcing: 'Zweite Ansprache',
    AwaitingSecondSourcing: 'Zweitansprache ausstehend',
    Completed: 'Abgeschlossen'
  }

  transform(value: string, ...args: unknown[]): unknown {
    return this.map[value] ? this.map[value] : value;
  }

}
