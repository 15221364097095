import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ScuiModalComponent } from '@sc-ui';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import industries from 'refs/sc-ui/resources/industries';

@Component({
  selector: 'app-project-briefing-modal-industries',
  templateUrl: './project-briefing-modal-industries.component.html',
  styleUrls: ['./project-briefing-modal-industries.component.scss']
})
export class ProjectBriefingModalIndustriesComponent {

  industry = {
    Id : 0,
    Type : 'MustHave',
    AlternativeTo: ''
  }

  @ViewChild('modal')
  modal : ScuiModalComponent;

  @Input()
  industries : any = []


  show()
  {
    return this.modal.show();
  }

  select() {
    this.modal.complete(this.industry);
    this.industry = {
      Id : 0,
      Type : 'MustHave',
      AlternativeTo: ''
    }

  }

  industryIdToName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry?.name;
  };

  industryIdToIdWithName = (id) => {
    var industry = industries.find(i => i.id == id);
    if (!industry) return '';
    return industry.id + ' - ' + industry.name;
  };

  searchIndustries: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return industries
          .filter(i => i.id % 1000 == 0 && i.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort(i => i.name.toLowerCase().indexOf(term.toLowerCase()))
          .slice(0, 10)
          .map(i => i.id);
      }));

}
